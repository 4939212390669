import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { YesNoDialogComponent } from "src/app/dialogs";
import { ValidationItem } from "src/app/models";
import { ValidationItemFormComponent } from "../../components/validation-item-form";

@Component({
  selector: "app-validation-item-dialog",
  templateUrl: "./validation-item-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationItemDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ValidationItemDialogComponent>,
    private dialog: MatDialog
  ) {}

  @HostBinding("class") _class = "app-validation-item-dialog";

  @ViewChild(ValidationItemFormComponent)
  itemForm: ValidationItemFormComponent;

  item: ValidationItem;

  get valid(): boolean {
    return this.itemForm?.valid;
  }

  delete(): void {
    this.dialog
      .open(YesNoDialogComponent)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result === true) {
          this.close("delete");
        }
      });
  }

  close(type: ValidationItemDialogResultType = "update"): void {
    const result: ValidationItemDialogResult = {
      type,
      item: (type === "update" && this.itemForm.item) || null,
      isDirty: this.itemForm.dirty,
    };

    this.dialogRef.close(result);
  }
}

export interface ValidationItemDialogResult {
  item: ValidationItem;
  type: ValidationItemDialogResultType;
  isDirty: boolean;
}

export type ValidationItemDialogResultType = "delete" | "update" | "cancel";
