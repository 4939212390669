<h2 mat-dialog-title>Edit Program</h2>
<mat-dialog-content>
  <app-program-form #programForm [(ngModel)]="program"></app-program-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>CANCEL</button>
  <button
    mat-raised-button
    [disabled]="!programForm.valid"
    (click)="updateProgram()">
    Update Program
  </button>
</mat-dialog-actions>
