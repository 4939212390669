<div [formGroup]="formGroup">
  <div fxLayout fxLayoutGap="8px">
    <app-icon-picker
      [icon]="_icon"
      (iconChange)="_onIconChange($event)"></app-icon-picker>
    <mat-form-field>
      <mat-label>Program Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>
  <div fxLayout fxLayoutGap="8px">
    <div fxFlex="65" fxLayout="column">
      <div>
        <h3>Program Description</h3>
        <app-rich-text-editor
          placeholder="Please add a program description here."
          formControlName="description"
          [required]="true"></app-rich-text-editor>
      </div>
      <div>
        <h3>Parent Portal Description</h3>
        <app-rich-text-editor
          formControlName="parentDescription"
          [required]="true"></app-rich-text-editor>
      </div>
    </div>
    <div fxFlex="35" fxLayout="column">
      <div>
        <h3>Users</h3>
        <app-group>
          <div class="options-container">
            <label id="program-user-group-label">Program User</label>
            <mat-radio-group
              color="primary"
              aria-labelledby="program-user-group-label"
              formControlName="target">
              <mat-radio-button value="ninja">Ninja</mat-radio-button>
              <mat-radio-button value="sensei">Sensei</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="options-container">
            <label id="program-grader-group-label">Program Grader</label>
            <mat-radio-group
              color="primary"
              aria-labelledby="program-grader-group-label"
              formControlName="grader">
              <mat-radio-button value="sensei">Sensei</mat-radio-button>
              <mat-radio-button value="director">Director</mat-radio-button>
              <mat-radio-button value="owner">Owner</mat-radio-button>
            </mat-radio-group>
          </div>
        </app-group>
      </div>
      <div>
        <h3>Program Background</h3>
        <app-image-picker
          [url]="_background | appImageUrl : 'background'"
          (imageChange)="_onBackgroundChange($event)"></app-image-picker>
      </div>
    </div>
  </div>
</div>
