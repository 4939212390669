import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { defaultValidationItems, ValidationItems } from "src/app/models";
import { ValidationListItemComponent } from "../../components/validation-list-item";

@Component({
  selector: "app-validation-list",
  templateUrl: "./validation-list.component.html",
  styleUrls: ["./validation-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationListComponent {
  constructor(private cdr: ChangeDetectorRef) {}

  @HostBinding("class") _class = "app-validation-list";

  @ViewChildren(ValidationListItemComponent)
  _itemComponents: QueryList<ValidationListItemComponent>;

  @Input() set items(items: ValidationItems) {
    this._items = (items?.length && items) || [...defaultValidationItems];
    this.addedOrRemovedValidationItem = false;
  }

  get items(): ValidationItems {
    return this._itemComponents?.map((i) => i.item);
  }

  get isDeleteEnabled(): boolean {
    return this._items.length > 1;
  }

  get valid(): boolean {
    return this._itemComponents?.map((c) => c).every((c) => c.valid);
  }

  private addedOrRemovedValidationItem = false;
  get dirty(): boolean {
    return (
      this._itemComponents?.map((c) => c).some((c) => c.dirty) ||
      this.addedOrRemovedValidationItem
    );
  }

  _items: ValidationItems = [];

  add(): void {
    this.items = [...this._items, defaultValidationItems[0]];
    if (!this.addedOrRemovedValidationItem) {
      this.addedOrRemovedValidationItem = true;
    }
    this.cdr.markForCheck();
  }

  deleteItemAt(index: number): void {
    this._items = this._items.filter((_, i) => index !== i);
    if (!this.addedOrRemovedValidationItem) {
      this.addedOrRemovedValidationItem = true;
    }
    if (this._items.length === 0) {
      this._items = [...defaultValidationItems];
    }

    this.cdr.markForCheck();
  }
}
