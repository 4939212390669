import { EntityState, createEntityAdapter } from "@ngrx/entity";
import {
  AppState,
  DiscoverActivity,
  DiscoverActivityStep,
} from "src/app/models";

export const featureKey = "discover-activity";

export interface DiscoverActivityStepsState
  extends EntityState<DiscoverActivityStep> {}
export interface State extends AppState {
  activity: DiscoverActivity;
  steps: DiscoverActivityStepsState;
}

export const stepsAdapter = createEntityAdapter<DiscoverActivityStep>({
  sortComparer: (a, b) => a.id - b.id,
});

export const initialState: State = {
  activity: null,
  isLoading: false,
  steps: stepsAdapter.getInitialState(),
  error: "",
};
