export type IconType = "image/svg" | "image/png" | "image/jpeg";

export interface Icon {
  id: string;
  name: string;
  type: IconType;
  updatedOn: Date;
}

export type Icons = Icon[];
