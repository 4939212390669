<div
  matRipple
  matRippleColor="rgb(0 147 252 / 20%)"
  class="upload-wrapper"
  (drop)="onFileDrop($event)"
  (dragover)="onDragOver($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (click)="fileUploader.click()">
  <ng-content></ng-content>
</div>
<input
  hidden
  #fileUploader
  type="file"
  [accept]="accept"
  (change)="onFileChange($event.target)" />
