import { Component, HostBinding, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-activity-step",
  templateUrl: "./activity-step.component.html",
  styleUrls: ["./activity-step.component.scss"],
})
export class ActivityStepComponent {
  @HostBinding("class") class = "app-activity-step";

  @ViewChild(TemplateRef, { static: true })
  content: TemplateRef<any>;
}
