import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { YesNoDialogComponent } from "src/app/dialogs";
import { IconsService } from "src/app/services/icons.service";
import { AppStoreState } from "src/app/store";
import { deleteFailureMessage } from "src/app/user-messages";
import * as FeatureActions from "./actions";
import * as FeatureSelectors from "./selectors";

@Injectable()
export class Effects {
  constructor(
    private store$: Store<AppStoreState>,
    private actions$: Actions,
    private service: IconsService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  loadIconPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadIconPage),
      withLatestFrom(
        this.store$.select(FeatureSelectors.selectSelectedPagination)
      ),
      switchMap(([_, paginationParams]) =>
        this.service.getIcons(paginationParams).pipe(
          map((iconPage) => FeatureActions.loadIconPageSuccess({ iconPage })),
          catchError((error) =>
            of(FeatureActions.loadIconPageFailure({ error }))
          )
        )
      )
    )
  );

  uploadIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.uploadIcon),
      switchMap(({ iconFile }) => this.service.uploadIcon(iconFile)),
      switchMap((icon) => [
        FeatureActions.uploadIconSuccess({ icon }),
        FeatureActions.loadIconPage(),
      ]),
      catchError((error) => of(FeatureActions.uploadIconFailure({ error })))
    )
  );

  replaceIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.replaceIcon),
      switchMap(({ iconFile, iconId }) =>
        this.service.replaceIcon(iconId, iconFile)
      ),
      switchMap((icon) => [
        FeatureActions.replaceIconSuccess({ icon }),
        FeatureActions.loadIconPage(),
      ]),
      catchError((error) => of(FeatureActions.replaceIconFailure({ error })))
    )
  );

  deleteIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteIcon),
      switchMap(({ icon }) =>
        this.dialog
          .open(YesNoDialogComponent)
          .afterClosed()
          .pipe(
            map((confirm) =>
              confirm
                ? FeatureActions.deleteIconConfirm({ icon })
                : FeatureActions.deleteIconCancel()
            )
          )
      )
    )
  );

  confirmDeleteIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteIconConfirm),
      switchMap(({ icon }) =>
        this.service.deleteIcon(icon.id).pipe(
          switchMap(() => [
            FeatureActions.deleteIconSuccess(),
            FeatureActions.loadIconPage(),
          ]),
          catchError((error) => of(FeatureActions.deleteIconFailure({ error })))
        )
      )
    )
  );

  deleteIconFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.deleteIconFailure),
        tap(({ error: { error } }) => {
          let errorMessage = "";

          switch (error.status) {
            case 403:
              errorMessage = "The icon is being used, can not be deleted.";
              break;
            case 400:
              errorMessage = "Icon not found.";
              break;
            default:
              errorMessage = deleteFailureMessage;
              break;
          }

          this.snackbar.open(errorMessage, "OK", { duration: 5000 });
        })
      ),
    {
      dispatch: false,
    }
  );

  goToPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.goToPage),
      withLatestFrom(this.store$.pipe(select(FeatureSelectors.isPageLoaded))),
      map(([_, { isPageLoaded }]) =>
        !isPageLoaded
          ? FeatureActions.loadIconPage()
          : FeatureActions.pageAlreadyLoaded()
      )
    )
  );
}
