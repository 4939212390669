import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Icon } from "src/app/models";

@Component({
  selector: "app-icon",
  template: `<img *ngIf="icon != null" [src]="icon | appImageUrl : 'icon'" />
    <div *ngIf="icon == null" class="img-placeholder"></div>`,
  styles: [
    ":host { display: block; } img,.img-placeholder { width: inherit; height: inherit; border-radius: 5px; } .img-placeholder {background: #0000005e}",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon: Icon;
}
