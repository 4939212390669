import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { Document, Documents } from "src/app/models";

@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListComponent {
  @HostBinding("class") class = "app-document-list";

  @Input() documents: Documents;

  @Input() selectedDocument: Document;

  @Output() selectedDocumentChange = new EventEmitter<Document>();

  select(document: Document): void {
    this.selectedDocumentChange.emit(document);
  }
}
