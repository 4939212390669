import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../directives";
import { EditorsModule } from "../editors";
import { SharedModule } from "../shared";
import { components } from "./components";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    DirectivesModule,
    EditorsModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class QuizBuilderModule {}
