import { CreateDocumentComponent } from "./create-document";
import { DocumentsComponent } from "./documents";
import { EditDocumentComponent } from "./edit-document";

export const containers = [
  CreateDocumentComponent,
  DocumentsComponent,
  EditDocumentComponent,
];

export { CreateDocumentComponent, DocumentsComponent, EditDocumentComponent };
