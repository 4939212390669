import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { Breadcrumb, Course, Level } from "src/app/models";

@Component({
  selector: "app-level-breadcrumbs",
  templateUrl: "./level-breadcrumbs.component.html",
  styleUrls: ["./level-breadcrumbs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelBreadcrumbsComponent {
  @HostBinding("class") class = "app-level-breadcrumbs";

  @Input() course: Course;

  @Input() level: Level;

  get previous(): Breadcrumb {
    return {
      routerLink: ["/", "programs", `${this.course?.programId}`],
      title: "courses",
    };
  }

  get breadcrumbs(): Breadcrumb[] {
    return [
      {
        routerLink: "/",
        title: "Home",
      },
      {
        routerLink: "/programs",
        title: "Programs",
      },
      {
        routerLink: ["/", "programs", `${this.course?.programId}`],
        title: this.course?.title,
      },
      {
        title: this.level?.title,
      },
    ];
  }
}
