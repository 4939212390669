import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import { SenseiStep } from "src/app/models";
import { QuizBuilderComponent } from "src/app/quiz-builder";
import { SenseiValidationChecklistComponent } from "../code-adventure-activity/sensei-validation-checklist";

@Component({
  selector: "app-sensei-step",
  templateUrl: "./sensei-step.component.html",
  styleUrls: ["./sensei-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SenseiStepComponent {
  @HostBinding("class") class = "app-sensei-step";

  @Input() step: SenseiStep;

  @ViewChild("playtestQuestions")
  playtestQuestionsBuilder: QuizBuilderComponent;

  @ViewChild(SenseiValidationChecklistComponent)
  validationChecklistBuilder: SenseiValidationChecklistComponent;

  @ViewChild("feedbackItems")
  feedbackItemsBuilder: QuizBuilderComponent;

  get value(): SenseiStep {
    return {
      ...this.step,
      playtestQuestions: this.playtestQuestionsBuilder.questions,
      validationChecklist: this.validationChecklistBuilder.checklist,
      feedbackItems: this.feedbackItemsBuilder.questions,
    };
  }

  get valid(): boolean {
    return (
      this.playtestQuestionsBuilder.valid &&
      this.validationChecklistBuilder.valid &&
      this.feedbackItemsBuilder.valid
    );
  }

  get dirty(): boolean {
    return (
      this.playtestQuestionsBuilder.dirty ||
      this.validationChecklistBuilder.dirty ||
      this.feedbackItemsBuilder.dirty
    );
  }
}
