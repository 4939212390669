import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DirectivesModule } from "../directives";
import { EditorsModule } from "../editors";
import { ImagesModule } from "../images";
import { LevelsModule } from "../levels";
import { SharedModule } from "../shared";
import { components } from "./components";

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    EditorsModule,
    SharedModule,
    LevelsModule,
    ImagesModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class CoursesModule {}
