import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import {
  InstructionsEditorComponent,
  HintsEditorComponent,
  SenseiHelpEditorComponent,
} from "src/app/editors";
import { ExploreSubActivityStep, SolveSubActivityStep } from "src/app/models";

@Component({
  selector: "app-explore-sub-activity-step",
  templateUrl: "./explore-sub-activity-step.component.html",
  styleUrls: ["./explore-sub-activity-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExploreSubActivityStepComponent {
  @HostBinding("class") class = "app-explore-sub-activity-step";

  @Input() step: ExploreSubActivityStep;

  @ViewChild(InstructionsEditorComponent)
  instructions: InstructionsEditorComponent;

  @ViewChild(HintsEditorComponent)
  hints: HintsEditorComponent;

  @ViewChild(SenseiHelpEditorComponent)
  senseiHelp: SenseiHelpEditorComponent;

  get value(): SolveSubActivityStep {
    return {
      ...this.step,
      instructions: this.instructions.value,
      hints: this.hints.value,
      senseiHelp: this.senseiHelp.value,
    };
  }

  get valid(): boolean {
    return !!(
      this.instructions?.valid &&
      this.hints?.valid &&
      this.senseiHelp?.valid
    );
  }

  get dirty(): boolean {
    return !!(
      this.instructions?.dirty ||
      this.hints?.dirty ||
      this.senseiHelp?.dirty
    );
  }
}
