import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, State } from "./state";
import * as FeatureActions from "./actions";

const featureReducer = createReducer(
  initialState,
  on(
    FeatureActions.loadCodeAdventureActivity,
    FeatureActions.updateActivity,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(FeatureActions.loadCodeAdventureActivity, (state) => ({
    ...state,
    isLoading: true,
    activity: null,
  })),
  on(
    FeatureActions.loadCodeAdventureActivitySuccess,
    (state, { activity }) => ({
      ...state,
      isLoading: false,
      activity,
    })
  ),
  on(
    FeatureActions.updateActivitySuccess,
    (state, { activity, mediaFile }) => ({
      ...state,
      isLoading: false,
      activity: { ...activity, mediaFile },
    })
  ),
  on(
    FeatureActions.loadCodeAdventureActivityFailure,
    FeatureActions.updateActivityFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
