import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  FillInTheBlankQuestion,
  FillInTheBlankStatement,
} from "src/app/models";

@Component({
  selector: "app-fill-in-the-blank-question",
  templateUrl: "./fill-in-the-blank-question.component.html",
  styleUrls: ["./fill-in-the-blank-question.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillInTheBlankQuestionComponent {
  @HostBinding("class") class = "app-fill-in-the-blank-question";

  @Input() set question(question: FillInTheBlankQuestion) {
    this.myQuestion = question;
    this.useWordBankControl.reset();
    this.useWordBankControl.setValue(!!question.useWordbank);

    this.statements.clear();
    this.statements.reset();
    question?.statements?.forEach((s) =>
      this.statements.push(this.statementToFormGroup(s))
    );

    this.wordbank.clear();
    this.wordbank.reset();
    question?.wordbank?.forEach((w) =>
      this.wordbank.push(this.wordToFormGroup(w))
    );
  }

  get question(): FillInTheBlankQuestion {
    return {
      ...this.myQuestion,
      useWordbank: this.useWordBankControl.value,
      statements: this.statements.value,
      wordbank: this.wordbank.controls.map(
        (fg: FormGroup) => fg.controls.text.value
      ),
    };
  }

  useWordBankControl = new FormControl(true);

  statements = new FormArray([]);

  wordbank = new FormArray([]);

  get dirty(): boolean {
    return !!(
      this.statements.dirty ||
      this.wordbank.dirty ||
      this.useWordBankControl.dirty
    );
  }

  get valid(): boolean {
    return !!(
      this.statements.valid &&
      (this.wordbank.status === "DISABLED" || this.wordbank.status === "VALID")
    );
  }

  private myQuestion: FillInTheBlankQuestion;

  addField(): void {
    this.statements.push(
      new FormGroup({
        text: new FormControl("", Validators.required),
        answer: new FormControl(),
      })
    );

    this.statements.markAsDirty();
  }

  removeFieldAt(index: number): void {
    this.statements.removeAt(index);
    this.statements.markAsDirty();
  }

  addWord(): void {
    this.wordbank.push(
      new FormGroup({
        text: new FormControl("", Validators.required),
      })
    );

    this.wordbank.markAsDirty();
  }

  removeWordAt(index: number): void {
    this.wordbank.removeAt(index);
    this.wordbank.markAsDirty();
  }

  private statementToFormGroup(statement: FillInTheBlankStatement): FormGroup {
    return new FormGroup({
      text: new FormControl(statement.text, Validators.required),
      answer: new FormControl(statement.answer),
    });
  }

  private wordToFormGroup(word: string): FormGroup {
    return new FormGroup({
      text: new FormControl(word, Validators.required),
    });
  }
}
