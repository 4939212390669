import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Background, Icon, ProgramFormDto } from "src/app/models";

@Component({
  selector: "app-program-form",
  templateUrl: "./program-form.component.html",
  styleUrls: ["./program-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProgramFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ProgramFormComponent,
    },
  ],
})
export class ProgramFormComponent
  implements ControlValueAccessor, OnDestroy, Validator
{
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  @HostBinding("class") class = "app-program-form";

  @Output() programChange = new EventEmitter<ProgramFormDto>();

  formGroup = this.fb.group({
    type: ["CREATE"],
    name: ["", Validators.required],
    description: ["", Validators.required],
    parentDescription: ["", Validators.required],
    target: ["ninja", Validators.required],
    grader: ["sensei", Validators.required],
    icon: [null],
    background: [null],
    newBackground: [null],
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }

  get _background(): Background {
    return this.formGroup.get("background").value;
  }

  get _icon(): Icon {
    return this.formGroup.get("icon").value;
  }

  private onTouched: () => void;

  private destroyed$ = new Subject<void>();

  writeValue(program: ProgramFormDto): void {
    this.formGroup.patchValue(program, { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (document: Document) => void): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(onChange);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.formGroup[["enable", "disable"][+isDisabled]]();
    this.cdr.markForCheck();
  }

  validate(): ValidationErrors {
    if (this.formGroup.valid) {
      return null;
    }

    let errors: any = {};

    errors = this.addControlErrors(errors, "name");
    errors = this.addControlErrors(errors, "description");
    errors = this.addControlErrors(errors, "parentDescription");
    errors = this.addControlErrors(errors, "target");
    errors = this.addControlErrors(errors, "grader");
    errors = this.addControlErrors(errors, "icon");
    errors = this.addControlErrors(errors, "background");
    errors = this.addControlErrors(errors, "newBackground");

    return errors;
  }

  addControlErrors(
    allErrors: any,
    controlName: string
  ): { [controlName: string]: ValidationErrors } {
    const errors = { ...allErrors };

    const controlErrors = this.formGroup.controls[controlName].errors;

    if (controlErrors) {
      errors[controlName] = controlErrors;
    }

    return errors;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  _onBackgroundChange(background: File): void {
    this.formGroup.get("newBackground").setValue(background);
  }

  _onIconChange(icon: Icon): void {
    this.formGroup.get("icon").patchValue(icon);
  }
}
