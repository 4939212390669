export function appErrorReducer(error: any): string {
  let message = "";
  if (error?.errors) {
    for (const e in error.errors) {
      if (this.formErrors.hasOwnProperty(e)) {
        message += `"${e}": ${error.errors[e]} \n`;
      }
    }
  }

  return (
    message ||
    error?.title ||
    error?.body ||
    error?.message ||
    "Update failed. Please try again at a later time."
  );
}
