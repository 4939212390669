<div fxLayout.gt-lg="row" fxLayout="column" fxLayoutGap.gt-lg="8px">
  <app-code-viewer
    [code]="code"
    [style.maxHeight.px]="300"
    ngStyle.gt-lg="max-width: 600px">
  </app-code-viewer>
  <div
    class="edit-in-makecode-container"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center">
    <div
      class="edit-in-makecode-container"
      fxLayout="column"
      fxLayoutAlign="center">
      <span class="edit-in-text">{{ editText }}</span>
      <span class="makecode-text">MakeCode</span>
      <button mat-raised-button color="primary" (click)="openMakecode()">
        MakeCode
      </button>
    </div>
  </div>
</div>
