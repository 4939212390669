import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { SidenavModule } from "./sidenav";
import { AppRoutingModule } from "./routing";
import { AppStoreModule } from "./store";
import { AuthenticationModule } from "./authentication";
import { HomeModule } from "./home";
import { LevelsModule } from "./levels";
import { ProgramsModule } from "./programs";
import { SharedModule } from "./shared";
import { ApiInterceptor, ErrorInterceptor } from "./interceptors";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    AuthenticationModule,
    HomeModule,
    LevelsModule,
    ProgramsModule,
    SharedModule,
    SidenavModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
