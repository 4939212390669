import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { iif, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
  CreateDocumentDto,
  Document,
  DocumentsPage,
  EditDocumentDto,
  QueryParams,
} from "../models";

@Injectable({ providedIn: "root" })
export class DocumentsService {
  constructor(private http: HttpClient) {}

  getDocumentsPage(queryParams: QueryParams): Observable<DocumentsPage> {
    const params = {
      ...queryParams,
      search: queryParams.search || "",
    };
    return this.http.get<DocumentsPage>("api/documents", { params });
  }

  createDocument(document: CreateDocumentDto): Observable<Document> {
    return this.http
      .post<Document>("api/documents", document)
      .pipe(
        switchMap((newDocument) =>
          this.uploadDocumentImage(
            newDocument.id,
            document.image,
            of(newDocument)
          )
        )
      );
  }

  updateDocument(document: EditDocumentDto): Observable<Document> {
    return this.http
      .put<Document>(`api/documents/${document.id}`, document)
      .pipe(
        switchMap((updatedDocument) =>
          this.uploadDocumentImage(
            document.id,
            document.image,
            of(updatedDocument)
          )
        )
      );
  }

  private uploadDocumentImage<T>(
    documentId: string,
    image: File,
    result: Observable<T>
  ): Observable<T> {
    const formData = new FormData();

    formData.append("formFile", image);
    formData.append("documentId", documentId);

    return iif(
      () => !!image,
      this.http
        .post<void>("api/documentImages", formData)
        .pipe(switchMap(() => result)),
      result
    );
  }
}
