import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { LevelStatus } from "src/app/models";

@Component({
  selector: "app-level-status-bar",
  template: ``,
  styleUrls: ["./level-status-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelStatusBarComponent {
  @HostBinding("class") class = "app-level-status-bar";

  @HostBinding("class.compact")
  get isCompact(): boolean {
    return this.compact;
  }

  @Input() status: LevelStatus;

  @Input() compact: boolean;
}
