<div fxFlexFill fxLayout="row wrap" fxLayoutGap="10px grid">
  <ng-container *ngIf="documents.length; else noDocuments">
    <div fxFlex="50" *ngFor="let document of documents">
      <app-document-box
        [document]="document"
        [selected]="document.id === selectedDocument?.id"
        (click)="select(document)"></app-document-box>
    </div>
  </ng-container>
</div>

<ng-template #noDocuments>
  <div fxFlexFill>
    <h1>No documents.</h1>
  </div>
</ng-template>
