import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Output,
} from "@angular/core";

@Component({
  selector: "app-add-sub-activity-button",
  templateUrl: "./add-sub-activity-button.component.html",
  styleUrls: ["./add-sub-activity-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSubActivityButtonComponent {
  @HostBinding("class") class = "app-add-sub-activity-button";

  @Output() add = new EventEmitter<void>();

  addSubActivity(): void {
    this.add.emit();
  }
}
