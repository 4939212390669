<div class="image-picker-container">
  <div fxLayout fxFlex fxLayoutAlign="center">
    <div fxLayout fxLayoutAlign="center center">
      <img
        class="image"
        [src]="_imageUrl"
        appDefault="/assets/thumbnail.png"
        alt="thumbnail" />
    </div>
  </div>
  <app-file-uploader fxFlex (uploadFile)="_onUploadImage($event)">
    <div class="upload-area">
      <h4>Drag & Drop File</h4>
      or
      <button color="primary" mat-raised-button>
        {{ actionTitle }}
      </button>
    </div>
  </app-file-uploader>
</div>
