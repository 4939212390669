import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import {
  Document,
  DocumentFilters,
  DocumentsPage,
  DocumentsSort,
} from "src/app/models";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "../../store/actions";
import * as FeatureSelectors from "../../store/selectors";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit, OnDestroy {
  constructor(
    private store$: Store<AppStoreState>,
    private cdr: ChangeDetectorRef
  ) {}

  @HostBinding("class") class = "app-documents";

  documentsPage: DocumentsPage;

  selectedDocument: Document;

  isLoading: boolean;

  documentsPage$: Observable<DocumentsPage> = this.store$.pipe(
    select(FeatureSelectors.selectDocumentsPage)
  );

  selectedPageIndex$: Observable<number> = this.store$.pipe(
    select(FeatureSelectors.selectSelectedPage),
    map(({ pageIndex }) => pageIndex)
  );

  selectedPageSize$: Observable<number> = this.store$.pipe(
    select(FeatureSelectors.selectSelectedPage),
    map(({ pageSize }) => pageSize)
  );

  selectedDocument$: Observable<Document> = this.store$.pipe(
    select(FeatureSelectors.selectSelectedDocument)
  );

  sortBy$: Observable<string> = this.store$.pipe(
    select(FeatureSelectors.selectSortBy)
  );

  filters$: Observable<DocumentFilters> = this.store$.pipe(
    select(FeatureSelectors.selectFilters)
  );

  search$: Observable<string> = this.store$.pipe(
    select(FeatureSelectors.selectSearch)
  );

  isLoading$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsLoading)
  );

  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.documentsPage$.pipe(takeUntil(this.destroyed$)).subscribe((page) => {
      this.documentsPage = page;
      this.cdr.markForCheck();
    });

    this.selectedDocument$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((document) => {
        this.selectedDocument = document;
        this.cdr.markForCheck();
      });

    this.isLoading$.pipe(takeUntil(this.destroyed$)).subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.cdr.markForCheck();
    });

    this.store$.dispatch(FeatureActions.loadDocumentsPage());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  onSortByChange(sortBy: DocumentsSort): void {
    this.store$.dispatch(FeatureActions.changeSortBy({ sortBy }));
  }

  onFiltersChange(filters: DocumentFilters): void {
    this.store$.dispatch(FeatureActions.changeFilters({ filters }));
  }

  onSearchChange(search: string): void {
    this.store$.dispatch(FeatureActions.changeSearch({ search }));
  }

  onSelectedDocumentChange(document: Document): void {
    this.store$.dispatch(FeatureActions.changeSelectedDocument({ document }));
  }

  onPageChange({ pageIndex, pageSize }: PageEvent): void {
    this.store$.dispatch(
      FeatureActions.changeSelectedPage({ pageIndex, pageSize })
    );
  }
}
