import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Icon } from "src/app/models";

@Component({
  selector: "app-badge-list-item",
  templateUrl: "./badge-list-item.component.html",
  styleUrls: ["./badge-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeListItemComponent {
  @Input() badge: FormGroup;

  @Output() badgeRemove = new EventEmitter<void>();
  @Output() badgeAdd = new EventEmitter<void>();

  @HostBinding("class") class = "";

  icon = null;
  active = false;

  get titleControl(): FormControl {
    return this.badge.get("title") as FormControl;
  }

  get descriptionControl(): FormControl {
    return this.badge.get("description") as FormControl;
  }

  get iconControl(): FormControl {
    return this.badge.get("icon") as FormControl;
  }

  @HostListener("click")
  toggle(): void {
    this.active = !this.active;
    this.class = this.active ? "active" : "";
  }

  onAddClick(e: Event): void {
    this.badgeAdd.emit();
    e.stopPropagation();
  }

  onRemoveClick(e: Event): void {
    this.badgeRemove.emit();
    e.stopPropagation();
  }

  onIconChange(icon: Icon): void {
    this.icon = icon;
    this.iconControl.setValue(icon);
    this.iconControl.markAsDirty();
  }
}
