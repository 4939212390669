<div class="media-editor">
  <mat-spinner *ngIf="loading"></mat-spinner>
  <div class="media-editor-header" *ngIf="!isEmpty">
    <h4 class="media-name">
      <a
        *ngIf="loadedMedia?.fileName; else fileName"
        target="_blank"
        [appFileLink]="loadedMedia?.fileName">
        <ng-container *ngTemplateOutlet="fileName"></ng-container>
      </a>
      <ng-template #fileName>
        {{ mediaControl.value?.name ?? loadedMedia?.name }}
      </ng-template>
    </h4>
    <button class="remove-btn" (click)="onRemoveClick()" mat-icon-button>
      <mat-icon>cancel_outline</mat-icon>
    </button>
  </div>
  <app-file-uploader
    *ngIf="isEmpty && !loading"
    accept="image/*,video/*"
    (uploadFile)="onUploadFile($event)">
    <div class="no-media-indicator">
      <h4 class="no-media">No Media</h4>
      selected for this activity
    </div>

    <div class="upload-indications">
      <span>Drag and Drop</span>
      or
      <button mat-raised-button color="primary">
        <mat-icon>add_circle_outline</mat-icon> Add Media
      </button>
    </div>
  </app-file-uploader>
</div>
