import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"],
})
export class GroupComponent {
  @HostBinding("class") class = "app-group";
}
