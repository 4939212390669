import { IdSelectorStr } from "@ngrx/entity/src/models";
import { Icon } from "./icon";

export interface Activity {
  id: number;
  levelId?: number;
  type?: ActivityType;
  title?: string;
  icon?: string;
  sortOrder: number;
}

export type Activities = Activity[];

export type ActivityType =
  | "Build"
  | "Explore"
  | "Solve"
  | "Discover"
  | "Adventure"
  | "Quest"
  | "LevelUp";

export type ActivityTypeIcons = {
  [key in ActivityType]: Icon;
};

export const identifyActivity: IdSelectorStr<Activity> = (a) =>
  `${a.id} - ${a.type}`;
