<app-feature>
  <app-feature-title>
    Programs
    <span *ngIf="lastUpdated$ | async as lastUpdated" class="float-right-text">
      last edited {{ lastUpdated | appRelativeDate }} ago
    </span>
  </app-feature-title>

  <div class="programs-container" fxLayout>
    <mat-card
      fxLayout="column"
      fxLayoutAlign="center"
      *ngFor="let program of programs$ | async"
      [ngClass]="program.disabled ? 'program-card-disabled' : 'program-card'">
      <mat-card-content>
        <div
          [ngClass]="
            program.disabled ? 'program-name-disabled' : 'program-name'
          ">
          {{ program.name | uppercase }}
        </div>
        <div class="program-options">
          <a mat-raised-button [routerLink]="program.id">Enter Program</a>
          <button mat-stroked-button (click)="editProgram(program)">
            Edit Program
          </button>
          <button mat-stroked-button>Duplicate</button>
          <button
            mat-stroked-button
            [appUserCanDelete]="'program'"
            (click)="toggleDisabled(program)">
            {{ program.disabled ? "Enable" : "Disable" }}
          </button>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <span class="gray-text-acent">last edited</span>
        {{ program.lastUpdated | date : "short" }}</mat-card-footer
      >
    </mat-card>
    <mat-card
      class="program-card add-program-card"
      fxLayout
      fxLayoutAlign="center center"
      (click)="addProgram()">
      <mat-icon class="add-program-icon">add_circle_outline</mat-icon>
    </mat-card>
  </div>
</app-feature>
