import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { YesNoDialogComponent } from "src/app/dialogs";
import { selectSelectedActivity } from "src/app/levels/components/level/store/selectors";
import { SolveActivitiesService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import { levelId } from "../../explore-activity/store/selectors";
import * as FeatureActions from "./actions";
import { selectSolveActivity, selectSubActivities } from "./selectors";
import { updateLevelStatus } from "src/app/levels/components/level/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private activitiesService: SolveActivitiesService
  ) {}

  triggerLoadActivity$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      filter((a) => !!a && a.type === "Solve"),
      distinctUntilKeyChanged("id"),
      map(({ id: activityId }) =>
        FeatureActions.loadSolveActivity({ activityId })
      )
    )
  );

  loadSolveActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadSolveActivity),
      switchMap(({ activityId }) =>
        this.activitiesService.getSolveActivityById(activityId).pipe(
          map((activity) =>
            FeatureActions.loadSolveActivitySuccess({ activity })
          ),
          catchError((error) =>
            of(FeatureActions.loadSolveActivityFailure({ error }))
          )
        )
      )
    )
  );

  clearSubActivities$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      withLatestFrom(
        this.store$.pipe(select(selectSubActivities)),
        this.store$.pipe(select(selectSolveActivity))
      ),
      filter(
        ([activity, subActivities, solveActivity]) =>
          !activity ||
          (!!solveActivity &&
            activity.type === "Solve" &&
            activity.id !== solveActivity.id &&
            !!subActivities.length)
      ),
      map(() => FeatureActions.clearSubActivities())
    )
  );

  triggerLoadSubActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadSolveActivitySuccess),
      filter(({ activity }) => !!activity),
      map(({ activity }) =>
        FeatureActions.loadSubActivities({ activityId: activity.id })
      )
    )
  );

  loadSubActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadSubActivities),
      switchMap(({ activityId }) =>
        this.activitiesService.getSubActivitiesByActivityId(activityId).pipe(
          map((subActivities) =>
            FeatureActions.loadSubActivitiesSuccess({ subActivities })
          ),
          catchError((error) =>
            of(FeatureActions.loadSubActivitiesFailure({ error }))
          )
        )
      )
    )
  );

  updateSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateSubActivity),
      exhaustMap(({ subActivity, mediaFile, levelId }) =>
        this.activitiesService.update(subActivity, mediaFile, levelId).pipe(
          map((mediaFile) =>
            FeatureActions.updateSubActivitySuccess({
              subActivity,
              mediaFile,
              levelId,
            })
          ),
          catchError((error) =>
            of(FeatureActions.updateSubActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateSubActivitySuccess),
      map((_) => {
        return updateLevelStatus({
          status: "draft",
        });
      })
    )
  );

  confirmDeleteSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteSubActivity),
      switchMap(({ subActivity }) =>
        this.dialog
          .open(YesNoDialogComponent)
          .afterClosed()
          .pipe(
            map((confirm) =>
              !!confirm
                ? FeatureActions.deleteSubActivityConfirm({ subActivity })
                : FeatureActions.deleteSubActivityCancel()
            )
          )
      )
    )
  );

  deleteSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteSubActivityConfirm),
      concatMap(({ subActivity }) =>
        this.activitiesService.deleteSubActivity(subActivity.id).pipe(
          map(() =>
            FeatureActions.deleteSubActivitySuccess({
              subActivityId: subActivity.id,
            })
          ),
          catchError((error) =>
            of(FeatureActions.deleteSubActivityFailure({ subActivity, error }))
          )
        )
      )
    )
  );

  addSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addSubActivity),
      withLatestFrom(this.store$.pipe(select(selectSolveActivity))),
      exhaustMap(([{ title }, { id: activityId }]) =>
        this.activitiesService.addSubActivity({ activityId, title }).pipe(
          map(({ id, stepId }) =>
            FeatureActions.addSubActivitySuccess({
              subActivity: {
                id,
                activityId,
                title,
                steps: [{ id: stepId, subActivityId: id }],
              },
            })
          ),
          catchError((error) =>
            of(FeatureActions.addSubActivityFailure({ error }))
          )
        )
      )
    )
  );

  addSubActivityStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addStep),
      exhaustMap(({ subActivityId }) =>
        this.activitiesService.addSubActivityStep({ subActivityId }).pipe(
          map((id) =>
            FeatureActions.addStepSuccess({ step: { id, subActivityId } })
          ),
          catchError((error) => of(FeatureActions.addStepFailure({ error })))
        )
      )
    )
  );

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteStep),
      concatMap(({ step }) =>
        this.activitiesService.deleteSubActivityStep(step.id).pipe(
          map(() => FeatureActions.deleteStepSuccess()),
          catchError((error) =>
            of(FeatureActions.deleteStepFailure({ step, error }))
          )
        )
      )
    )
  );

  displaySubActivityUpdateFailureSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.updateSubActivityFailure),
        tap(() =>
          this.displayMessage(
            "Updated failed. Please try again at a later time."
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  displayStepDeleteFailureSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.deleteStepFailure),
        tap(() =>
          this.displayMessage(
            "Delete failed. Please try again at a later time.",
            { duration: 5000 }
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  private displayMessage(
    message: string,
    options: { duration: number } = { duration: 3000 }
  ): void {
    this.snackbar.open(message, "OK", options);
  }
}
