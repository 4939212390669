import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-activity-title",
  templateUrl: "./activity-title.component.html",
  styleUrls: ["./activity-title.component.scss"],
})
export class ActivityTitleComponent {
  @HostBinding("class") class = "app-activity-title";

  @Input() set title(title: string) {
    this.titleControl.reset();
    this.titleControl.setValue(title);
  }

  @Output() delete = new EventEmitter<void>();

  @Input() collapsed = false;

  @Output() collapsedChange = new EventEmitter<boolean>();

  @Input() isSubActivity = false;

  get value(): string {
    return this.titleControl.value;
  }

  get valid(): boolean {
    return this.titleControl.valid;
  }

  get dirty(): boolean {
    return this.titleControl.dirty;
  }

  titleControl = new FormControl("", [Validators.required]);

  deleteActivity(): void {
    this.delete.emit();
  }

  toggleDetails(): void {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
  }
}
