import { FormControl } from "@angular/forms";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-sensei-help-editor",
  templateUrl: "./sensei-help-editor.component.html",
})
export class SenseiHelpEditorComponent {
  senseiHelpControl = new FormControl("");

  @Input() set value(value: string) {
    this.senseiHelpControl.reset();
    this.senseiHelpControl.setValue(value);
  }

  get value(): string {
    return this.senseiHelpControl.value;
  }

  get valid(): boolean {
    return this.senseiHelpControl.valid;
  }

  get dirty(): boolean {
    return this.senseiHelpControl.dirty;
  }
}
