<div
  fxFlex
  fxLayout="column"
  fxLayoutGap="4px"
  cdkDropList
  (cdkDropListDropped)="onDrop($event)">
  <div
    *ngFor="let level of levels"
    cdkDragLockAxis="y"
    cdkDrag
    [cdkDragData]="level">
    <div fxLayout fxLayoutGap="4px">
      <app-group class="less-padding">
        <app-level-status-bar
          appLevelStatus
          [status]="level.status"
          [compact]="true"></app-level-status-bar>
        <div
          class="draggable"
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <mat-icon>drag_indicator</mat-icon>
          <span class="level-title">
            {{ level.title }}
          </span>
          <div class="level-actions">
            <button mat-icon-button (click)="onDeleteButtonClick(level)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="onDuplicateButtonClick(level)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>
      </app-group>
      <a
        [class]="level.status"
        [routerLink]="['/levels', level.id]"
        mat-stroked-button
        fxLayoutAlign="center center">
        <mat-icon>chevron_right</mat-icon>
      </a>
    </div>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="4px">
  <div *ngFor="let level of levels" [class]="level.status">
    <ul>
      <li>{{ getLevelStatusMessage(level.status) | titlecase }}</li>
    </ul>
  </div>
</div>
