import { Component, HostBinding, Input, ViewChild } from "@angular/core";
import { Question, QuestionType } from "src/app/models";
import { AnswerFieldQuestionComponent } from "../answer-field-question";
import { EmojiAnswerQuestionComponent } from "../emoji-answer-question";
import { FillInTheBlankQuestionComponent } from "../fill-in-the-blank-question";
import { MultiSelectQuestionComponent } from "../multi-select-question";
import { QuestionHeaderComponent } from "../question-header";
import { SingleSelectQuestionComponent } from "../single-select-question";
import { UploadFileComponent } from "../upload-file";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.scss"],
})
export class QuestionComponent {
  @HostBinding("class") class = "app-question";

  @Input() set question(question: Question) {
    this.myQuestion = question;
  }

  get _question(): Question {
    return this.myQuestion;
  }

  @ViewChild(QuestionHeaderComponent)
  titleComponent: QuestionHeaderComponent;

  @ViewChild(SingleSelectQuestionComponent)
  singleSelect: SingleSelectQuestionComponent;

  @ViewChild(MultiSelectQuestionComponent)
  multiSelect: MultiSelectQuestionComponent;

  @ViewChild(AnswerFieldQuestionComponent)
  answerField: AnswerFieldQuestionComponent;

  @ViewChild(FillInTheBlankQuestionComponent)
  fillInTheBlank: FillInTheBlankQuestionComponent;

  @ViewChild(EmojiAnswerQuestionComponent)
  emojiAnswer: EmojiAnswerQuestionComponent;

  @ViewChild(UploadFileComponent)
  uploadFileSelection: UploadFileComponent;

  get value(): Question {
    return this.buildQuestion(
      this.singleSelect?.question ||
        this.multiSelect?.question ||
        this.answerField?.question ||
        this.fillInTheBlank?.question ||
        this.uploadFileSelection?.question
    );
  }

  get dirty(): boolean {
    return !!(
      this.titleComponent?.dirty ||
      this.singleSelect?.dirty ||
      this.multiSelect?.dirty ||
      this.answerField?.dirty ||
      this.fillInTheBlank?.dirty ||
      this.uploadFileSelection?.dirty
    );
  }

  get valid(): boolean {
    return !!(
      this.titleComponent?.valid &&
      (!this.singleSelect || this.singleSelect.valid) &&
      (!this.multiSelect || this.multiSelect.valid) &&
      (!this.answerField || this.answerField.valid) &&
      (!this.fillInTheBlank || this.fillInTheBlank.valid) &&
      (!this.uploadFileSelection || this.uploadFileSelection.valid)
    );
  }

  private myQuestion: Question;

  changeQuestionType(type: QuestionType): void {
    this.myQuestion = { ...this.myQuestion, type };
  }

  private buildQuestion(question: Question): Question {
    return {
      ...question,
      title: this.titleComponent?.title,
      hint: this.titleComponent?.hint,
      correctResponse: this.titleComponent?.correctResponse,
      type: this.titleComponent?.type,
    };
  }
}
