import { Directive, HostBinding, HostListener, Input } from "@angular/core";

@Directive({
  selector: "img[appDefault]",
})
export class ImagePreloadDirective {
  @HostBinding("src") @Input() src: string;

  @Input() appDefault: string;

  @HostBinding("class") class = "";

  @HostListener("error") updateUrl(): void {
    this.src = this.appDefault;
  }

  @HostListener("load") load(): void {
    this.class = "image-loaded";
  }
}
