import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Question, QuestionType } from "src/app/models";
import { QuestionComponent } from "../question";

@Component({
  selector: "app-quiz-builder",
  templateUrl: "./quiz-builder.component.html",
  styleUrls: ["./quiz-builder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizBuilderComponent {
  @HostBinding("class") class = "app-quiz-builder";

  @Input() set questions(questions: Question[]) {
    this.myQuestions = [...(questions || [])];
  }

  get questions(): Question[] {
    return this.questionComponents?.map((q) => q.value);
  }

  get _questions(): Question[] {
    return this.myQuestions;
  }

  @ViewChildren(QuestionComponent)
  questionComponents = new QueryList<QuestionComponent>();

  get valid(): boolean {
    return !!this.questionComponents?.map((q) => q).every((q) => q.valid);
  }

  get dirty(): boolean {
    return (
      !!this.questionComponents?.map((q) => q).find((q) => q.dirty) ||
      this.questionAddedOrDeleted
    );
  }

  private myQuestions: Question[];
  private questionAddedOrDeleted = false;

  changeQuestionType(question: Question, type: QuestionType): void {
    this.myQuestions = this.myQuestions.map((q) =>
      q === question ? { title: q.title, type } : q
    );
  }

  addQuestion(): void {
    this.myQuestions.push({ title: "New Question", type: "answer-field" });
    this.questionAddedOrDeleted = true;
  }

  deleteQuestion(question: Question): void {
    const deleteIndex = this.myQuestions.indexOf(question);
    this.myQuestions.splice(deleteIndex, 1);
    this.questionAddedOrDeleted = true;
  }
}
