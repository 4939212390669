import { MediaFile } from ".";
import { Activity } from "./activity";
import { ActivityToggles } from "./activity-toggles";
import { Question } from "./question";
import { ActivityStep } from "./step";

export interface LevelUpActivity extends Activity, ActivityToggles {
  title: string;
  introduction?: string;
  steps: LevelUpActivityStep[];
  mediaFile?: MediaFile;
}

export interface LevelUpActivityStep extends ActivityStep {
  type: LevelUpActivityType;
  reflectionQuestions?: Question[];
  badges?: LevelUpActivityStepBadge[];
}

export type CreateLevelUpActivityStepDto = Required<
  Pick<LevelUpActivityStep, "activityId">
> &
  Partial<Omit<LevelUpActivityStep, "activityId">>;

export interface LevelUpActivityStepBadge {
  icon?: string;
  title: string;
  description: string;
}

export type LevelUpActivityType = "reflectionQuestions" | "badgesDisplayed";
