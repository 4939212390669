import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared";
import { CoursesModule } from "../courses";
import { DialogsModule } from "../dialogs";
import { DirectivesModule } from "../directives";
import { EditorsModule } from "../editors";
import { ProgramsRoutingModule } from "./routing";
import { components } from "./components";
import { dialogs } from "./dialogs";
import { PipesModule } from "../pipes";
import { ImagesModule } from "../images";

@NgModule({
  declarations: [...components, ...dialogs],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoursesModule,
    DialogsModule,
    DirectivesModule,
    EditorsModule,
    ProgramsRoutingModule,
    PipesModule,
    ImagesModule,
  ],
  entryComponents: [...dialogs],
})
export class ProgramsModule {}
