<div (mouseover)="showAddActivity = true" (mouseout)="showAddActivity = false">
  <mat-list-item class="dark-button mat-elevation-z3">
    <div fxFlex fxLayoutAlign="center center" fxLayoutGap="4px">
      <mat-icon>add_circle_outline</mat-icon>
      <span fxLayoutAlign="center center">Add Activity</span>
    </div>
  </mat-list-item>
  <div [@bodyExpansion]="showAddActivity ? 'expanded' : 'collapsed'">
    <mat-list-item class="gray-button mat-elevation-z3">
      <div fxFlex fxLayoutAlign="center center" fxLayoutGap="4px">
        <div>
          <ng-container *ngFor="let activityType of activityTypes">
            <button mat-icon-button [matTooltip]="activityType">
              <mat-icon [inline]="true" (click)="addActivity(activityType)">
                check_box
              </mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </mat-list-item>
  </div>
</div>

<mat-action-list fxLayout="column" fxLayoutGap="12px">
  <div
    fxLayout="column"
    fxLayoutGap="12px"
    cdkDropList
    (cdkDropListDropped)="drop($event)">
    <mat-list-item
      *ngFor="let activity of activities"
      class="mat-elevation-z3"
      cdkDragLockAxis="y"
      cdkDrag
      [cdkDragData]="activity"
      [class.selected]="isSelected(activity)"
      (click)="selectActivity(activity)">
      <div class="draggable" fxLayoutAlign="center center">
        <mat-icon [inline]="true">drag_indicator</mat-icon>
      </div>

      <app-activity-type-icon
        [activityType]="activity.type"></app-activity-type-icon>

      <div fxFlex="column" class="item-title">
        <div class="type">{{ activity.type }}</div>
        <div class="truncate title">{{ activity.title }}</div>
      </div>

      <div
        fxLayoutGap="4px"
        fxFlex="1 0 row"
        fxLayoutAlign="end"
        class="item-options">
        <button
          mat-icon-button
          [matTooltip]="'More options'"
          [matMenuTriggerFor]="moreMenu">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </div>
</mat-action-list>

<mat-menu #moreMenu="matMenu">
  <button mat-menu-item (click)="deleteActivity()">
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
