import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { HomeComponent } from "../components";

const routes = [
  {
    path: "",
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [{ path: "home", component: HomeComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
