<div class="sidenav-content">
  <img class="code-ninjas-logo" src="assets/codeninjas.svg" alt="Code Ninjas" />

  <div fxLayout="column" fxLayoutGap="1rem">
    <div>
      <div>Tenjin</div>
      <div class="curriculum-title">Curriculum</div>
    </div>

    <mat-nav-list>
      <a routerLink="home" routerLinkActive="active-link" mat-list-item>
        <mat-icon class="button-icon">home</mat-icon>
        <span>Home</span>
      </a>
      <a routerLinkActive="active-link" mat-list-item>
        <mat-icon class="button-icon">speed</mat-icon>
        <span>Reports</span>
      </a>
      <a routerLinkActive="active-link" mat-list-item>
        <mat-icon class="button-icon">shield</mat-icon>
        <span>Badges</span>
      </a>
      <a routerLink="programs" routerLinkActive="active-link" mat-list-item>
        <mat-icon class="button-icon">school</mat-icon>
        <span>Programs</span>
      </a>
      <a routerLinkActive="active-link" mat-list-item>
        <mat-icon class="button-icon">search</mat-icon>
        <span>View As</span>
      </a>
      <a routerLink="documents" routerLinkActive="active-link" mat-list-item>
        <mat-icon class="button-icon">description</mat-icon>
        <span>Documents</span>
      </a>
    </mat-nav-list>
  </div>

  <!-- Placeholder -->
  <div></div>
</div>

<div class="bottom-section">
  <div class="bottom-section-content" fxLayout="column">
    <app-account></app-account>
  </div>
</div>
