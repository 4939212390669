import { Action, createReducer, on } from "@ngrx/store";
import { initialState, State } from "./state";
import * as FeatureActions from "./actions";
import { appErrorReducer } from "src/app/store/app-error-reducer";

const featureReducer = createReducer<State>(
  initialState,

  on(
    FeatureActions.updateProgramActivityTypeIcon,
    FeatureActions.loadActivityTypeIcons,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),

  on(
    FeatureActions.loadActivityTypeIconsSuccess,
    (state, { activityTypeIcons }) => ({
      ...state,
      activityTypeIcons: {
        ...state.activityTypeIcons,
        ...activityTypeIcons,
      },
    })
  ),

  on(
    FeatureActions.updateProgramActivityTypeIconSuccess,
    (state, { activityType, icon }) => ({
      ...state,
      activityTypeIcons: {
        ...state.activityTypeIcons,
        [activityType]: icon,
      },
      isLoading: false,
    })
  ),

  on(
    FeatureActions.updateProgramActivityTypeIconError,
    FeatureActions.loadActivityTypeIconsFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
