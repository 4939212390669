import { FormArray, ValidationErrors } from "@angular/forms";

export class MultiCheckBoxValidator {
  static multipleCheckboxRequireOne(
    checkboxes: FormArray
  ): null | ValidationErrors {
    let valid = false;

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes.at(i).value) {
        valid = true;
        break;
      }
    }
    return valid ? null : { multipleCheckboxRequireOne: true };
  }
}
