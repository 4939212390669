import { MediaFile } from ".";
import { Activity } from "./activity";
import { ActivityToggles } from "./activity-toggles";
import { MakecodeProject } from "./makecode-project";
import { ActivityStep } from "./step";
import { ValidationItem } from "./validation-item";

export interface BuildActivity extends Activity, ActivityToggles {
  title: string;
  introduction?: string;
  project?: MakecodeProject;
  validationItems?: ValidationItem[];
  mediaFile?: MediaFile;
}

export interface BuildActivityStep extends ActivityStep {
  instructions?: string;
  hints?: string;
  senseiHelp?: string;
}
