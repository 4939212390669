import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  MediaFile,
  SolveActivity,
  SolveSubActivity,
  SolveSubActivityStep,
} from "../models";

@Injectable({ providedIn: "root" })
export class SolveActivitiesService {
  constructor(private http: HttpClient) {}

  getSolveActivityById(id: number): Observable<SolveActivity> {
    return this.http.get<SolveActivity>(`api/solveActivities/${id}`);
  }

  getSubActivitiesByActivityId(id: number): Observable<SolveSubActivity[]> {
    return this.http.get<SolveSubActivity[]>(
      `api/solveActivities/${id}/subActivities`
    );
  }

  addSubActivity(
    subActivity: SolveSubActivity
  ): Observable<{ id: number; stepId: number }> {
    return this.http.post<{ id: number; stepId: number }>(
      `api/solveActivities/${subActivity.activityId}/subActivities`,
      subActivity
    );
  }

  update(
    subActivity: SolveSubActivity,
    mediaFile: File | null,
    levelId: number
  ): Observable<MediaFile | null> {
    return this.http
      .put<void>(`api/solveSubActivities/${subActivity.id}`, {
        ...subActivity,
        project: JSON.stringify(subActivity.project),
      })
      .pipe(
        switchMap((_) => {
          if (mediaFile === null && !!subActivity.mediaFile?.name) {
            return this.http
              .delete(`api/solveSubActivities/${subActivity.id}/media`)
              .pipe(map((_) => null));
          } else if (
            mediaFile !== null &&
            mediaFile.name !== subActivity.mediaFile?.name
          ) {
            const formData = new FormData();

            formData.append("mediaFile", mediaFile);
            formData.append("subActivityId", subActivity.id.toString());

            return this.http.post<MediaFile>(
              `api/solveSubActivities/media`,
              formData
            );
          }

          return of(subActivity.mediaFile);
        })
      );
  }

  deleteSubActivity(subActivityId: number): Observable<void> {
    return this.http.delete<void>(`api/solveSubActivities/${subActivityId}`);
  }

  addSubActivityStep(step: SolveSubActivityStep): Observable<number> {
    return this.http.post<number>(
      `api/solveSubActivities/${step.subActivityId}/steps`,
      step
    );
  }

  deleteSubActivityStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/solveSubActivitySteps/${stepId}`);
  }
}
