import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Document, EditDocumentDto } from "src/app/models";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "../../store/actions";
import * as FeatureSelectors from "../../store/selectors";

@Component({
  selector: "app-edit-document",
  templateUrl: "./edit-document.component.html",
  styleUrls: ["./edit-document.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDocumentComponent {
  constructor(private store$: Store<AppStoreState>) {}

  @HostBinding("class") class = "app-edit-document";

  tagMatches$: Observable<string[]> = this.store$.pipe(
    select(FeatureSelectors.selectTagMatches)
  );

  @Input() set document(document: Document) {
    this.myDocument = document;
    this.documentChanges = null;
  }

  get document(): Document {
    return this.myDocument;
  }

  isLoading$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsLoading)
  );

  isUpdating$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsUpdating)
  );

  private myDocument: Document;
  private documentChanges: EditDocumentDto;

  updateDocument(): void {
    this.store$.dispatch(
      FeatureActions.updateDocument({
        original: this.document,
        updates: {
          ...this.document,
          ...this.documentChanges,
        },
      })
    );
  }

  onDocumentChange(document: EditDocumentDto): void {
    this.documentChanges = document;
  }

  onTagChange(tag: string): void {
    this.store$.dispatch(FeatureActions.searchTags({ keyword: tag }));
  }
}
