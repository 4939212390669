import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CreateProgramDto, EditProgramDto, Program } from "src/app/models";

@Injectable({ providedIn: "root" })
export class ProgramsService {
  private programsUrl = "api/programs";

  constructor(private http: HttpClient) {}

  getPrograms(): Observable<Program[]> {
    return this.http.get<Program[]>(this.programsUrl);
  }

  getProgram(id: number): Observable<Program> {
    return this.http.get<Program>(`${this.programsUrl}/${id}`);
  }

  addProgram(program: CreateProgramDto): Observable<void> {
    return this.http.post<void>(this.programsUrl, program);
  }

  deleteProgramById(programId: number): Observable<Program> {
    return this.http.delete<Program>(`${this.programsUrl}/${programId}`);
  }

  updateProgram(program: EditProgramDto): Observable<void> {
    return this.http.put<void>(`${this.programsUrl}/${program.id}`, program);
  }

  toggleProgramDisabled(programId: number): Observable<void> {
    return this.http.put<void>(
      `${this.programsUrl}/${programId}/disabled`,
      null
    );
  }
}
