import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { catchError, skipWhile } from "rxjs/operators";
import { ActivitiesService } from "src/app/services";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-rich-text-editor",
  templateUrl: "./rich-text-editor.component.html",
  styleUrls: ["./rich-text-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RichTextEditorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RichTextEditorComponent,
    },
  ],
})
export class RichTextEditorComponent
  implements ControlValueAccessor, OnDestroy, Validator
{
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private activitiesService: ActivitiesService,
    private snackbar: MatSnackBar
  ) {}

  @ViewChild("fileInput") fileInputRef: ElementRef;

  quillRef: any;

  @HostBinding("class") class = "app-rich-text-editor";

  @Input() placeholder: string;

  @Input() required = false;

  formGroup = this.fb.group({
    text: this.fb.control(""),
  });

  imageUploading = false;
  private onTouched: () => void;

  private onChangeSubs: Subscription[] = [];

  writeValue(text: string): void {
    this.formGroup.setValue({ text: text || "" }, { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (text: string) => void): void {
    this.onChangeSubs.push(
      this.formGroup.valueChanges
        .pipe(skipWhile(() => !this.formGroup.get("text").dirty))
        .subscribe(({ text }) => onChange(text))
    );
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.formGroup[["enable", "disable"][+isDisabled]]();
    this.cdr.markForCheck();
  }

  validate(): ValidationErrors {
    if (this.formGroup.valid) {
      return null;
    }

    const errors = {};

    if (this.formGroup.get("text").errors) {
      errors["text"] = this.formGroup.get("text").errors;
    }

    return errors;
  }

  setImageHandler(editorInstance: any) {
    this.quillRef = editorInstance;
    const toolbar = editorInstance.getModule("toolbar");
    toolbar.addHandler("image", this.imageUploadHandler.bind(this));
  }

  imageUploadHandler() {
    this.fileInputRef.nativeElement.click();
  }

  uploadImage(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    (event.target as HTMLInputElement).value = null;
    const formData = new FormData();
    formData.append("file", file);
    this.imageUploading = true;
    this.onChangeSubs.push(
      this.activitiesService
        .uploadImage(formData)
        .pipe(
          catchError((error) => {
            this.imageUploading = false;
            this.cdr.markForCheck();
            console.error(error);
            this.snackbar.open("Something went wrong, try again later.", "OK", {
              duration: 3000,
            });

            return null;
          })
        )
        .subscribe((response: string) => {
          try {
            const filename = response;
            const img = `<img src="${environment.assetsUrl}/${filename}"></img>`;
            const range = this.quillRef.getSelection();
            this.quillRef.clipboard.dangerouslyPasteHTML(range.index, img);
            this.imageUploading = false;
            const text = this.quillRef.root.innerHTML;
            //update form value
            this.formGroup.setValue({ text });
            this.cdr.markForCheck();
          } catch (error) {
            console.error(error);
            this.snackbar.open("Something went wrong, try again later.", "OK", {
              duration: 3000,
            });
          }
        })
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }
}
