import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-panel",
  templateUrl: "./panel.component.html",
  styleUrls: ["./panel.component.scss"],
})
export class PanelComponent {
  @HostBinding("class") class = "app-panel";
}
