<div fxLayout fxLayoutGap="8px">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControl]="_tagControl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of matches" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div fxLayoutAlign="center center">
    <button mat-raised-button [disabled]="!tag" (click)="addTag()">
      Add Tag
    </button>
  </div>
</div>

<mat-chip-list aria-label="Document tags">
  <mat-chip
    color="accent"
    *ngFor="let tag of tags"
    [removable]="true"
    (removed)="removeTag(tag)">
    {{ tag }} <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>
