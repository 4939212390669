import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { ActivitiesStoreModule } from "../activities/store";
import { AuthenticationStoreModule } from "../authentication";
import { CoursesStoreModule } from "../courses/store";
import { DocumentsStoreModule } from "../documents";
import { ImagesStoreModule } from "../images";
import { LevelsStoreModule } from "../levels/store";
import { ProgramsStoreModule } from "../programs/store";
import { Effects } from "./effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([Effects]),
    ActivitiesStoreModule,
    AuthenticationStoreModule,
    CoursesStoreModule,
    DocumentsStoreModule,
    ImagesStoreModule,
    LevelsStoreModule,
    ProgramsStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
})
export class AppStoreModule {}
