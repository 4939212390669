import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Program } from "src/app/models";

@Component({
  templateUrl: "./new-program-dialog.component.html",
})
export class NewProgramDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<NewProgramDialogComponent, Program>
  ) {}

  program: Program;

  updateProgram(program: Program): void {
    this.program = program;
  }

  createProgram(): void {
    this.dialogRef.close(this.program);
  }
}
