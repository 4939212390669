import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-feature",
  templateUrl: "./feature-view.component.html",
  styleUrls: ["./feature-view.component.scss"],
})
export class FeatureViewComponent {
  @HostBinding("class") class = "app-feature";
}
