import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Program, ProgramFormDto, ProgramFormResultDto } from "src/app/models";

@Component({
  templateUrl: "./edit-program-dialog.component.html",
})
export class EditProgramDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { program: Program },
    private dialogRef: MatDialogRef<
      EditProgramDialogComponent,
      ProgramFormResultDto
    >
  ) {
    this.program = { ...data.program };
  }

  program: ProgramFormDto;

  updateProgram(): void {
    const result: ProgramFormResultDto = {
      ...this.data.program,
      ...this.program,
    };
    this.dialogRef.close(result);
  }
}
