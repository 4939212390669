import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { Icon, Icons } from "src/app/models";
import { AppStoreState } from "src/app/store";
import * as Selectors from "../../store/selectors";
import * as Actions from "../../store/actions";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  templateUrl: "./icon-library.component.html",
  styleUrls: ["./icon-library-component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLibraryComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: { icon: Icon },
    private store$: Store<AppStoreState>,
    private cdr: ChangeDetectorRef
  ) {
    if (data.icon) {
      this.store$.dispatch(Actions.changeSelectedIcon({ icon: data.icon }));
    }
  }

  selectedIcon: Icon | null = null;

  icons$: Observable<Icons> = this.store$.pipe(
    select(Selectors.selectCurrentPage)
  );

  pageIndex$ = this.store$.pipe(select(Selectors.selectPageIndex));

  totalPages$ = this.store$.pipe(select(Selectors.selectTotalPages));

  uploadActionTitle: "Upload" | "Replace" = "Upload";

  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.store$
      .pipe(select(Selectors.selectSelectedIcon), takeUntil(this.destroyed$))
      .subscribe((icon) => {
        this.selectedIcon = icon;
        this.uploadActionTitle = (icon && "Replace") || "Upload";
        this.cdr.markForCheck();
      });

    this.store$.dispatch(Actions.loadIconPage());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  onSelectIcon(icon: Icon): void {
    let selectedIcon = icon;
    if (icon.id === this.selectedIcon?.id) {
      selectedIcon = null;
    }

    this.store$.dispatch(Actions.changeSelectedIcon({ icon: selectedIcon }));
  }

  onGoToPage(pageIndex: number): void {
    this.store$.dispatch(Actions.goToPage({ pageIndex }));
  }

  onUploadImage(iconFile: File): void {
    if (this.selectedIcon?.id) {
      this.store$.dispatch(
        Actions.replaceIcon({ iconFile, iconId: this.selectedIcon.id })
      );
    } else {
      this.store$.dispatch(Actions.uploadIcon({ iconFile }));
    }
  }

  onDeleteImage(icon: Icon): void {
    this.store$.dispatch(Actions.deleteIcon({ icon }));
  }
}
