import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { ActivityType, ActivityTypeIcons, Icon } from "src/app/models";

export const loadActivityTypeIcons = createAction(
  "[Activity Icon] Load ActivityType Icons",
  props<{ programId: number }>()
);

export const loadActivityTypeIconsSuccess = createAction(
  "[Activity Icon] Load ActivityType Icons Success",
  props<{ activityTypeIcons: ActivityTypeIcons }>()
);

export const loadActivityTypeIconsFailure = createAction(
  "[Activity Icon] Load ActivityType Icons Failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateProgramActivityTypeIcon = createAction(
  "[Activity Icon] Update program activity type icon",
  props<{ activityType: ActivityType; icon: Icon }>()
);

export const updateProgramActivityTypeIconSuccess = createAction(
  "[Activity Icon] Program activity type icon updated successfully",
  props<{ activityType: ActivityType; icon: Icon }>()
);

export const updateProgramActivityTypeIconError = createAction(
  "[Activity Icon] Program activity type icon failure",
  props<{ error: HttpErrorResponse }>()
);
