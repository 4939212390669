import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { DiscoverActivity, DiscoverActivityStep } from "src/app/models";

export const loadDiscoverActivity = createAction(
  "[Discover Activity Component] Load discover activity",
  props<{ activityId: number }>()
);
export const loadDiscoverActivitySuccess = createAction(
  "[Discover Activity Component] Load discover activity success",
  props<{ activity: DiscoverActivity }>()
);
export const loadDiscoverActivityFailure = createAction(
  "[Discover Activity Component] Load discover activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const update = createAction(
  "[Discover Activity Component] Update activity",
  props<{ activity: DiscoverActivity; steps: DiscoverActivityStep[] }>()
);
export const updateSuccess = createAction(
  "[Discover Activity Component] Update activity success",
  props<{ activity: DiscoverActivity; steps: DiscoverActivityStep[] }>()
);
export const updateFailure = createAction(
  "[Discover Activity Component] Update activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadSteps = createAction(
  "[Discover Activity Component] Load steps",
  props<{ activityId: number }>()
);
export const loadStepsSuccess = createAction(
  "[Discover Activity Component] Load steps success",
  props<{ steps: DiscoverActivityStep[] }>()
);
export const loadStepsFailure = createAction(
  "[Discover Activity Component] Load steps failure",
  props<{ error: HttpErrorResponse }>()
);

export const addStep = createAction(
  "[Discover Activity Component] Add step",
  props<{ step: DiscoverActivityStep }>()
);
export const addStepSuccess = createAction(
  "[Discover Activity Component] Add step success",
  props<{ step: DiscoverActivityStep }>()
);
export const addStepFailure = createAction(
  "[Discover Activity Component] Add step failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteStep = createAction(
  "[Discover Activity Component] Delete step",
  props<{ step: DiscoverActivityStep }>()
);
export const deleteStepSuccess = createAction(
  "[Discover Activity Component] Delete step success"
);
export const deleteStepFailure = createAction(
  "[Discover Activity Component] Delete step failure",
  props<{ step: DiscoverActivityStep; error: HttpErrorResponse }>()
);
