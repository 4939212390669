import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared";
import { components } from "./components";

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [...components],
  exports: [...components],
})
export class BreadcrumbsModule {}
