import { createFeatureSelector, createSelector } from "@ngrx/store";
import { activitiesAdapter, featureKey, State } from "./state";
import {
  selectProgram,
  selectSelectedCourse,
} from "src/app/programs/components/edit-program/store/selectors";

const selectState = createFeatureSelector<State>(featureKey);
const selectActivitiesState = createSelector(
  selectState,
  (state) => state.activities
);

export const selectLevel = createSelector(selectState, (state) => state.level);

export const selectCourse = createSelector(
  selectState,
  (state) => state.course
);

export const { selectAll: selectActivities } = activitiesAdapter.getSelectors(
  selectActivitiesState
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectSelectedActivity = createSelector(
  selectState,
  (state) => state.selectedActivity
);

export const selectTagMatches = createSelector(
  selectState,
  (state) => state.tagMatches
);

export const selectError = createSelector(selectState, (state) => state.error);

export const selectLevelProgramId = createSelector(
  selectState,
  (state) => state.course?.programId
);

export const selectIsUploading = createSelector(
  selectState,
  (state) => state.isUploading
);

export const selectIsImagesUploading = createSelector(
  selectState,
  (state) => state.isImagesUploading
);

export const selectIsIconsUploading = createSelector(
  selectState,
  (state) => state.isIconsUploading
);

export const selectFileUrl = createSelector(
  selectState,
  (state) => state.fileUrl
);

export const selectPublishData = createSelector(
  selectLevel,
  selectProgram,
  selectSelectedCourse,
  (level, program, course) => {
    return {
      level,
      program,
      course,
    };
  }
);

export const selectIsSyncing = createSelector(
  selectState,
  (state) => state.isSyncing
);
