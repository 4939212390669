import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ValidationItemParameter,
  VariableValidationItem,
} from "src/app/models";
import { ChildFormBase, CHILD_FORM_TOKEN } from "../../directives";

const MAX_PARAMETER_COUNT = 10;

@Component({
  selector: "app-variable-validation",
  templateUrl: "./variable-validation.component.html",
  styleUrls: ["./variable-validation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHILD_FORM_TOKEN,
      useExisting: forwardRef(() => VariableValidationComponent),
    },
  ],
})
export class VariableValidationComponent extends ChildFormBase {
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    super();
  }

  @HostBinding("class") class = "app-variable-validation";

  _form = this.fb.group({
    instanceCount: this.fb.control(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(7),
    ]),
    functionName: this.fb.control("", Validators.required),
    parameters: this.fb.array([]),
  });

  get childForm(): FormGroup {
    return this._form;
  }

  get _parametersFormArray(): FormArray {
    return this._form.get("parameters") as FormArray;
  }

  get _allowAddParameter(): boolean {
    return this._parametersFormArray.length < MAX_PARAMETER_COUNT;
  }

  setChildForm(item: VariableValidationItem): void {
    this._form.patchValue(item);
    item?.parameters?.forEach((p) => this.addParameter(p));
  }

  removeParameterAt(index: number): void {
    this._parametersFormArray.removeAt(index);
    this._parametersFormArray.markAsDirty();
    this.cdr.markForCheck();
  }

  addParameter(parameter: ValidationItemParameter = null): void {
    if (this._parametersFormArray.length === MAX_PARAMETER_COUNT) {
      return;
    }

    this._parametersFormArray.push(
      this.fb.control(parameter || { operator: "gte" })
    );
    this._parametersFormArray.markAsDirty();
    this.cdr.markForCheck();
  }
}
