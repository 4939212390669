import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared";
import { ActivitiesModule } from "../activities";
import { BreadcrumbsModule } from "../breadcrumbs";
import { EditorsModule } from "../editors";
import { TagsModule } from "src/app/tags/tags.module";
import { LevelsRoutingModule } from "./routing";
import { components } from "./components";
import { directives } from "./directives";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    LevelsRoutingModule,
    ActivitiesModule,
    BreadcrumbsModule,
    EditorsModule,
    TagsModule,
  ],
  declarations: [...components, ...directives],
  exports: [...components],
})
export class LevelsModule {}
