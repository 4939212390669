<mat-list>
  <mat-list-item *ngFor="let r of _requirements.controls; let index = index">
    <mat-form-field>
      <input matInput placeholder="Text" [formControl]="r.controls.text" />
    </mat-form-field>

    <button
      mat-icon-button
      color="primary"
      (click)="removeRequirementAt(index)">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-list-item>
  <mat-list-item>
    <div fxFlex fxLayout="column">
      <button mat-button (click)="addRequirement()">Add Requirement</button>
    </div>
  </mat-list-item>
</mat-list>
