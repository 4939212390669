<app-icon-picker [icon]="iconControl.value" (iconChange)="onIconChange($event)">
</app-icon-picker>
<span class="badge-title" *ngIf="!active">
  {{ !!titleControl.value ? titleControl.value : "Badge Title" }}
</span>
<mat-form-field *ngIf="active">
  <input
    matInput
    placeholder="Badge Title"
    [formControl]="badge.controls.title"
    (click)="$event.stopPropagation()" />
</mat-form-field>
<div class="actions">
  <button mat-icon-button color="primary" (click)="onAddClick($event)">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
  <button *ngIf="active" mat-icon-button (click)="onRemoveClick($event)">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>
<app-rich-text-editor
  *ngIf="active"
  [formControl]="descriptionControl"
  placeholder="Please update description"
  (click)="$event.stopPropagation()">
</app-rich-text-editor>
