import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { MakecodeEditorDialogComponent } from "src/app/dialogs";
import { MakecodeProject } from "src/app/models";

@Component({
  selector: "app-edit-in-makecode",
  templateUrl: "./edit-in-makecode.component.html",
  styleUrls: ["./edit-in-makecode.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditInMakecodeComponent {
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  @HostBinding("class") class = "app-edit-in-makecode";

  @Input() editText = "Edit In";

  @Input() set project(project: MakecodeProject) {
    let tempProj = project;
    if (project) {
      tempProj = JSON.parse(JSON.stringify(project));
      tempProj.source.header.targetVersion = "1.12.32";
    }
    this.myProject = tempProj;
    this.dirty = false;
  }

  get project(): MakecodeProject {
    return this.myProject;
  }

  get code(): string {
    return this.myProject?.source?.text["main.ts"];
  }

  get valid(): boolean {
    // Use MakeCode's validation in beta.
    return true;
  }

  dirty = false;

  private myProject: MakecodeProject;

  openMakecode(): void {
    this.dialog
      .open(MakecodeEditorDialogComponent, {
        data: { project: this.project },
        minWidth: "100vw",
        height: "100vh",
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((makecodeProject) => {
        if (makecodeProject) {
          this.myProject = makecodeProject;
          this.dirty = true;

          this.cdr.markForCheck();
        }
      });
  }
}
