<ng-container *ngIf="levelAndCourse$ | async as lc">
  <div class="mat-elevation-z12" [@slideInOut]>
    <app-level-status-bar
      appLevelStatus
      [status]="lc.level.status"></app-level-status-bar>
    <app-level-breadcrumbs
      [level]="lc.level"
      [course]="lc.course"></app-level-breadcrumbs>

    <app-edit-level
      [level]="lc.level"
      [course]="lc.course"
      [tagMatches]="tagMatches$ | async"
      (tagChange)="onTagChange($event)"
      (levelChange)="updateLevel($event)"
      (statusChange)="changeLevelStatus($event)"></app-edit-level>
  </div>

  <div fxLayout>
    <div
      class="activity-list-container"
      fxFlex="0 0 30"
      fxFlex.lg="0 0 25"
      fxFlex.gt-lg="0 0 15">
      <app-activity-list
        [activities]="activities$ | async"
        [selection]="selectedActivity"
        (selectionChange)="selectActivity($event)"
        (add)="add($event, lc.level)"
        (delete)="delete($event)"
        (rearrange)="rearrange($event)"></app-activity-list>
    </div>

    <div class="content-container" fxFlex="1 1 0">
      <!-- This is file upload code -->
      <div class="button-upload-container">
        <div class="upload-container">
          <input
            hidden
            type="file"
            accept="video/mp4"
            #file
            (change)="uploadFile($event)" />
          <button
            [disabled]="isUploading$ | async"
            mat-raised-button
            color="accent"
            (click)="file.click()">
            <div
              *ngIf="!(isUploading$ | async)"
              fxLayout
              fxLayoutAlign="center center"
              fxLayoutGap="4px">
              <mat-icon>upload_file</mat-icon>
              <span>Upload Video</span>
            </div>

            <div
              *ngIf="isUploading$ | async"
              fxLayout
              fxLayoutAlign="center center"
              fxLayoutGap="4px">
              <mat-spinner [diameter]="25"></mat-spinner>
              <span>Uploading</span>
            </div>
          </button>
          <div
            *ngIf="!(isUploading$ | async) && (fileUrl$ | async) as fileUrl"
            class="url-copy-board">
            <input
              class="mr-15"
              type="text"
              [value]="fileUrl"
              [disabled]="true"
              #hostedlUrl />
            <button
              mat-raised-button
              color="accent"
              (click)="copyHostedUrl(hostedlUrl.value)">
              <div fxLayout fxLayoutAlign="center center" fxLayoutGap="4px">
                <mat-icon>content_copy</mat-icon>
                <span>Copy URL</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="note">
        <span
          >Note : Please wait until the upload/sync is complete, it might take a
          few minutes</span
        >
      </div>
      <!-- File Upload code ends here -->
      <app-edit-activity [activity]="selectedActivity"></app-edit-activity>
    </div>
  </div>
</ng-container>
