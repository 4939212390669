import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-feature-title",
  templateUrl: "./feature-title.component.html",
  styleUrls: ["./feature-title.component.scss"],
})
export class FeatureTitleComponent {
  @HostBinding("class") class = "app-feature-title";
}
