import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  CreateDocumentDto,
  Document,
  DocumentFilters,
  DocumentsPage,
  DocumentsSort,
  EditDocumentDto,
} from "src/app/models";

export const loadDocumentsPage = createAction(
  "[Documents Component] Load documents page"
);
export const loadDocumentsPageSuccess = createAction(
  "[Documents Component] Load documents page success",
  props<{ page: DocumentsPage }>()
);
export const loadDocumentsPageFailure = createAction(
  "[Documents Component] Load documents page failure",
  props<{ error: HttpErrorResponse }>()
);

export const changeSelectedPage = createAction(
  "[Documents Component] Change selected page",
  props<{ pageSize: number; pageIndex: number }>()
);

export const changeSortBy = createAction(
  "[Documents Component] Change sort by",
  props<{ sortBy: DocumentsSort }>()
);

export const changeFilters = createAction(
  "[Documents Component] Change filters",
  props<{ filters: DocumentFilters }>()
);

export const changeSearch = createAction(
  "[Documents Component] Change search",
  props<{ search: string }>()
);

export const changeSelectedDocument = createAction(
  "[Documents Component] Change selected document",
  props<{ document: Document }>()
);

export const createDocument = createAction(
  "[Documents Component] Create document",
  props<{ document: CreateDocumentDto }>()
);
export const createDocumentSuccess = createAction(
  "[Documents Component] Create document success",
  props<{ document: Document }>()
);
export const createDocumentFailure = createAction(
  "[Documents Component] Create document failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateDocument = createAction(
  "[Documents Component] Update document",
  props<{ original: Document; updates: EditDocumentDto }>()
);
export const updateDocumentSuccess = createAction(
  "[Documents Component] Update document success",
  props<{ document }>()
);
export const updateDocumentFailure = createAction(
  "[Documents Component] Update document failure",
  props<{ error: HttpErrorResponse; original: Document }>()
);

export const searchTags = createAction(
  "[Documents Component] Search tags",
  props<{ keyword: string }>()
);
export const searchTagsSuccess = createAction(
  "[Documents Component] Search tags success",
  props<{ tagMatches: string[] }>()
);
export const searchTagsFailure = createAction(
  "[Documents Component] Search tags failure",
  props<{ error: HttpErrorResponse }>()
);
