import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { EditCourseDto, Level, Levels } from "src/app/models";

export const loadLevels = createAction(
  "[Edit Course Component] Load levels",
  props<{ courseId: number }>()
);
export const loadLevelsSuccess = createAction(
  "[Edit Course Component] Load levels success",
  props<{ levels: Levels }>()
);
export const loadLevelsFailure = createAction(
  "[Edit Course Component] Load levels failure",
  props<{ error: HttpErrorResponse }>()
);

export const addLevel = createAction(
  "[Edit Course Component] Add level",
  props<{ courseId: number }>()
);
export const addLevelSuccess = createAction(
  "[Edit Course Component] Add level success"
);
export const addLevelFailure = createAction(
  "[Edit Course Component] Add level failure",
  props<{ error: HttpErrorResponse }>()
);

export const copyLevel = createAction(
  "[Edit Course Component] Copy level",
  props<{ levelId: number }>()
);
export const copyLevelSuccess = createAction(
  "[Edit Course Component] Copy level success"
);
export const copyLevelFailure = createAction(
  "[Edit Course Component] Copy level failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteLevel = createAction(
  "[Edit Course Component] Delete level",
  props<{ level: Level }>()
);
export const deleteLevelConfirm = createAction(
  "[Edit Course Component] Delete level confirm",
  props<{ level: Level }>()
);
export const deleteLevelCancel = createAction(
  "[Edit Course Component] Delete level cancel"
);
export const deleteLevelSuccess = createAction(
  "[Edit Course Component] Delete level success"
);
export const deleteLevelFailure = createAction(
  "[Edit Course Component] Delete level failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateCourse = createAction(
  "[Edit Course Component] Update course",
  props<{ course: EditCourseDto }>()
);
export const updateCourseSuccess = createAction(
  "[Edit Course Component] Update course success"
);
export const updateCourseFailure = createAction(
  "[Edit Course Component] Update course failure",
  props<{ error: HttpErrorResponse }>()
);

export const rearrange = createAction(
  "[Edit Course Component] Rearrange levels",
  props<{ levels: Levels }>()
);
export const rearrangeSuccess = createAction(
  "[Edit Course Component] Rearrange levels success"
);
export const rearrangeFailure = createAction(
  "[Edit Course Component] Rearrange levels failure",
  props<{ error: HttpErrorResponse }>()
);
