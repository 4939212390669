import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Icon } from "src/app/models";

@Component({
  selector: "app-icon-uploader",
  templateUrl: "./icon-uploader.component.html",
  styleUrls: ["./icon-uploader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconUploaderComponent {
  @Input() selectedIcon: Icon;
  @Input() actionTitle = "Upload";

  @Output() uploadImage = new EventEmitter<File>();
  @Output() deleteImage = new EventEmitter<Icon>();

  onImageChange(iconFile: File): void {
    this.uploadImage.emit(iconFile);
  }

  onDeleteButtonClick(): void {
    this.deleteImage.emit(this.selectedIcon);
  }
}
