import { Activity } from "./activity";
import { ActivityToggles } from "./activity-toggles";
import { CodingStep } from "./coding-step";
import { NinjaRequirement } from "./ninja-requirement";
import { SenseiStep } from "./sensei-step";
import { MakecodeProject } from "./makecode-project";
import { MediaFile } from ".";

export interface ChallengeQuestActivity extends Activity, ActivityToggles {
  title: string;
  introduction?: string;
  ninjaRequirements?: NinjaRequirement[];
  codingStep?: CodingStep;
  senseiStep?: SenseiStep;
  project?: MakecodeProject;
  starterCode?: MakecodeProject;
  mediaFile?: MediaFile;
}
