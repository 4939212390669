import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { DiscoverActivity, DiscoverActivityStep } from "../models";

@Injectable({ providedIn: "root" })
export class DiscoverActivitiesService {
  constructor(private http: HttpClient) {}

  getDiscoverActivityById(id: number): Observable<DiscoverActivity> {
    return this.http.get<DiscoverActivity>(`api/discoverActivities/${id}`);
  }

  getSteps(activityId: number): Observable<DiscoverActivityStep[]> {
    return this.http.get<DiscoverActivityStep[]>(
      `api/discoverActivities/${activityId}/steps`
    );
  }

  updateDiscoverActivity(
    activity: DiscoverActivity,
    steps: DiscoverActivityStep[]
  ): Observable<void> {
    return this.http.put<void>("api/discoverActivities", {
      activity,
      steps,
    });
  }

  addStep(step: DiscoverActivityStep): Observable<number> {
    return this.http.post<number>(
      `api/discoverActivities/${step.activityId}/steps`,
      step
    );
  }

  deleteStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/discoverActivitySteps/${stepId}`);
  }
}
