<div [formGroup]="_form">
  <div fxLayout fxLayoutGap="4px">
    <mat-form-field class="instance-count-select" floatLabel="never">
      <span matPrefix>Contains&nbsp;</span>
      <mat-select formControlName="instanceCount">
        <mat-option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]" [value]="i">
          {{ i }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="never">
      <span matPrefix>of&nbsp;</span>
      <input
        matInput
        placeholder="sprites.create"
        formControlName="functionName" />
    </mat-form-field>
  </div>
  <div formArrayName="parameters" fxLayout="column">
    <div
      fxLayout
      *ngFor="let control of _parametersFormArray.controls; index as i">
      <app-parameter [formControlName]="i">Parameter {{ i + 1 }}</app-parameter>

      <button mat-icon-button color="primary" (click)="removeParameterAt(i)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div fxFlex fxLayout="column">
      <button
        mat-button
        [disabled]="!_allowAddParameter"
        (click)="addParameter()">
        Add Parameter
      </button>
    </div>
  </div>
</div>
