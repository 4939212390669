import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  CodeAdventureActivity,
  CodeAdventurePlanningStep,
  CodingStep,
  MediaFile,
  SenseiStep,
} from "../models";

@Injectable({ providedIn: "root" })
export class CodeAdventureActivitiesService {
  constructor(private http: HttpClient) {}

  getCodeAdventureActivityById(id: number): Observable<CodeAdventureActivity> {
    return this.http.get<CodeAdventureActivity>(
      `api/codeAdventureActivities/${id}`
    );
  }

  updateCodeAdventureActivity(
    activity: CodeAdventureActivity,
    mediaFile: File | null
  ): Observable<MediaFile | null> {
    return this.http
      .put<void>(`api/codeAdventureActivities/${activity.id}`, activity)
      .pipe(
        switchMap((_) => {
          if (mediaFile === null && activity.mediaFile?.name !== null) {
            return this.http
              .delete(`api/codeAdventureActivities/${activity.id}/media`)
              .pipe(map((_) => null));
          } else if (
            mediaFile !== null &&
            mediaFile.name !== activity.mediaFile?.name
          ) {
            const formData = new FormData();

            formData.append("mediaFile", mediaFile);
            formData.append("activityId", activity.id.toString());

            return this.http.post<MediaFile>(
              `api/codeAdventureActivities/media`,
              formData
            );
          }

          return of(activity.mediaFile);
        })
      );
  }

  addPlanningStep(activityId: number): Observable<CodeAdventurePlanningStep> {
    return this.http.post<CodeAdventurePlanningStep>(
      `api/codeAdventureActivities/${activityId}/planningSteps`,
      null
    );
  }

  addCodingStep(activityId: number): Observable<CodingStep> {
    return this.http.post<CodingStep>(
      `api/codeAdventureActivities/${activityId}/codingSteps`,
      null
    );
  }

  addSenseiStep(activityId: number): Observable<SenseiStep> {
    return this.http.post<SenseiStep>(
      `api/codeAdventureActivities/${activityId}/senseiSteps`,
      null
    );
  }

  deletePlanningStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/codeAdventurePlanningSteps/${stepId}`);
  }

  deleteCodingStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/codeAdventureCodingSteps/${stepId}`);
  }

  deleteSenseiStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/codeAdventureSenseiSteps/${stepId}`);
  }
}
