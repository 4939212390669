<mat-tab-group color="primary">
  <mat-tab label="Hints">
    <app-hints-editor [value]="step?.hints"></app-hints-editor>
  </mat-tab>
  <mat-tab label="Sensei Help">
    <app-sensei-help-editor [value]="step?.senseiHelp"></app-sensei-help-editor>
  </mat-tab>
  <mat-tab label="Code Editor">
    <app-edit-in-makecode [project]="step?.project"></app-edit-in-makecode>
  </mat-tab>
  <mat-tab label="Validation">
    <app-validation-list [items]="step?.validationItems"></app-validation-list>
  </mat-tab>
</mat-tab-group>
