import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  CreateLevelUpActivityStepDto,
  LevelUpActivity,
  LevelUpActivityStep,
  MediaFile,
} from "../models";

@Injectable({ providedIn: "root" })
export class LevelUpActivitiesService {
  constructor(private http: HttpClient) {}

  getLevelUpActivityById(id: number): Observable<LevelUpActivity> {
    return this.http.get<LevelUpActivity>(`api/levelUpActivities/${id}`);
  }

  updateActivity(
    activity: LevelUpActivity,
    mediaFile: File | null,
    isMediaDirty?: boolean
  ): Observable<MediaFile | null> {
    return this.http
      .put<void>(`api/levelUpActivities/${activity.id}`, activity)
      .pipe(
        switchMap((_) => {
          if (
            isMediaDirty &&
            mediaFile === null &&
            activity.mediaFile?.name !== null
          ) {
            return this.http
              .delete(`api/levelUpActivities/${activity.id}/media`)
              .pipe(map((_) => null));
          } else if (
            isMediaDirty &&
            mediaFile !== null &&
            mediaFile.name !== activity.mediaFile?.name
          ) {
            const formData = new FormData();
            formData.append("mediaFile", mediaFile);
            formData.append("activityId", activity.id.toString());
            return this.http.post<MediaFile>(
              `api/levelUpActivities/media`,
              formData
            );
          }

          return of(activity.mediaFile);
        })
      );
  }

  addStep(step: CreateLevelUpActivityStepDto): Observable<LevelUpActivityStep> {
    return this.http.post<LevelUpActivityStep>(
      `api/levelUpActivities/${step.activityId}/steps`,
      step
    );
  }

  deleteStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/levelUpActivitySteps/${stepId}`);
  }
}
