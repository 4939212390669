import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { sortById } from "src/app/entity-adapter";
import { AppState, BuildActivity, BuildActivityStep } from "src/app/models";

export const featureKey = "build-activity";

export interface BuildActivityStepsState
  extends EntityState<BuildActivityStep> {}

export interface State extends AppState {
  activity: BuildActivity;
  steps: BuildActivityStepsState;
}

export const stepsAdapter = createEntityAdapter<BuildActivityStep>({
  sortComparer: sortById,
});

export const initialState: State = {
  activity: null,
  steps: stepsAdapter.getInitialState(),
  isLoading: false,
  error: "",
};
