<div [formGroup]="formGroup" class="editor-container">
  <div class="hidden">
    <input
      #fileInput
      type="file"
      (change)="uploadImage($event)"
      accept="image/*" />
  </div>
  <div
    [ngClass]="{ 'loader-overlay': imageUploading, hidden: !imageUploading }">
    Uploading image...
  </div>
  <quill-editor
    formControlName="text"
    [required]="required"
    [placeholder]="placeholder"
    (onEditorCreated)="setImageHandler($event)"
    [sanitize]="true"></quill-editor>
</div>

<mat-error *ngIf="formGroup.get('text').errors?.required === true">
  Required
</mat-error>
