import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationItemType, ValidationRequirement } from "src/app/models";
import { ChildFormBase, CHILD_FORM_TOKEN } from "../../directives";

@Component({
  selector: "app-validation-requirements-form",
  templateUrl: "./validation-requirements-form.component.html",
  styleUrls: ["./validation-requirements-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHILD_FORM_TOKEN,
      useExisting: forwardRef(() => ValidationRequirementsFormComponent),
    },
  ],
})
export class ValidationRequirementsFormComponent extends ChildFormBase {
  constructor(private fb: FormBuilder) {
    super();
  }

  @HostBinding("class") _class = "app-validation-requirements-form";

  @Input() itemType: ValidationItemType;

  _form = this.fb.group({
    file: this.fb.control("", Validators.required),
    validateInsideBlockType : this.fb.control("")
  });

  get childForm(): FormGroup {
    return this._form;
  }

  requirement: ValidationRequirement;

  setChildForm(requirement: ValidationRequirement): void {
    this._form.patchValue(requirement);
    this.requirement = requirement;
  }
}
