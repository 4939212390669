import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { BuildActivity, BuildActivityStep, MediaFile } from "src/app/models";

export const loadBuildActivity = createAction(
  "[Build Activity Component] Load build activity",
  props<{ activityId: number }>()
);
export const loadBuildActivitySuccess = createAction(
  "[Build Activity Component] Load build activity success",
  props<{ activity: BuildActivity }>()
);
export const loadBuildActivityFailure = createAction(
  "[Build Activity Component] Load build activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const update = createAction(
  "[Build Activity Component] Update activity",
  props<{
    activity: BuildActivity;
    steps: BuildActivityStep[];
    mediaFile: File | null;
  }>()
);
export const updateSuccess = createAction(
  "[Build Activity Component] Update activity success",
  props<{
    activity: BuildActivity;
    steps: BuildActivityStep[];
    mediaFile?: MediaFile;
  }>()
);
export const updateFailure = createAction(
  "[Build Activity Component] Update activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadSteps = createAction(
  "[Build Activity Component] Load steps",
  props<{ activityId: number }>()
);
export const loadStepsSuccess = createAction(
  "[Build Activity Component] Load steps success",
  props<{ steps: BuildActivityStep[] }>()
);
export const loadStepsFailure = createAction(
  "[Build Activity Component] Load steps failure",
  props<{ error: HttpErrorResponse }>()
);

export const addStep = createAction(
  "[Build Activity Component] Add step",
  props<{ activityId: number }>()
);
export const addStepSuccess = createAction(
  "[Build Activity Component] Add step success",
  props<{ step: BuildActivityStep }>()
);
export const addStepFailure = createAction(
  "[Build Activity Component] Add step failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteStep = createAction(
  "[Build Activity Component] Delete step",
  props<{ step: BuildActivityStep }>()
);
export const deleteStepSuccess = createAction(
  "[Build Activity Component] Delete step success"
);
export const deleteStepFailure = createAction(
  "[Build Activity Component] Delete step failure",
  props<{ step: BuildActivityStep; error: HttpErrorResponse }>()
);
