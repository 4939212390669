<mat-card>
  <mat-card-content>
    <app-question-header
      [title]="_question.title"
      [type]="_question.type"
      [hint]="_question.hint"
      [correctResponse]="_question.correctResponse"
      (typeChange)="changeQuestionType($event)"></app-question-header>

    <ng-container [ngSwitch]="_question.type">
      <app-single-select-question
        *ngSwitchCase="'single-select'"
        [question]="_question">
      </app-single-select-question>

      <app-multi-select-question
        *ngSwitchCase="'multi-select'"
        [question]="_question"></app-multi-select-question>

      <app-answer-field-question
        *ngSwitchCase="'answer-field'"
        [question]="_question"></app-answer-field-question>

      <app-fill-in-the-blank-question
        *ngSwitchCase="'fill-in-the-blank'"
        [question]="_question"></app-fill-in-the-blank-question>

      <app-emoji-answer-question
        *ngSwitchCase="'emoji-answer'"></app-emoji-answer-question>

      <app-upload-file
        *ngSwitchCase="'upload-file'"
        [question]="_question"></app-upload-file>
    </ng-container>
  </mat-card-content>
</mat-card>
