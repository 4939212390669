import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { UserProfile } from "../models";

@Injectable({ providedIn: "root" })
export class MicrosoftGraphService {
  constructor(private http: HttpClient, private service: MsalService) {}

  getProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>("https://graph.microsoft.com/v1.0/me");
  }

  getImage(): Observable<Blob> {
    return this.http.get<Blob>(
      "https://graph.microsoft.com/v1.0/me/photos/64x64/$value",
      { responseType: "Blob" as "json" }
    );
  }

  logout(): void {
    this.service.logout();
  }
}
