<ng-container *ngIf="_questions.length">
  <div fxLayout fxLayoutGap="8px" *ngFor="let question of _questions">
    <div class="question-container" fxFlex>
      <app-question [question]="question"></app-question>
    </div>

    <div>
      <div class="question-actions">
        <button mat-icon-button color="primary" (click)="addQuestion()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteQuestion(question)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!_questions.length">
  <div fxLayout fxLayoutGap="8px">
    <div fxLayout="column" fxFlex fxLayoutGap="4px">
      <app-random-questions-quote></app-random-questions-quote>
    </div>
    <div>
      <div class="question-actions">
        <button mat-icon-button color="primary" (click)="addQuestion()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
