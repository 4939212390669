<ng-container [ngSwitch]="activity?.type">
  <app-build-activity *ngSwitchCase="'Build'"></app-build-activity>

  <app-challenge-quest-activity *ngSwitchCase="'Quest'">
  </app-challenge-quest-activity>

  <app-code-adventure-activity *ngSwitchCase="'Adventure'">
  </app-code-adventure-activity>

  <app-discover-activity *ngSwitchCase="'Discover'"></app-discover-activity>

  <app-explore-activity *ngSwitchCase="'Explore'"></app-explore-activity>

  <app-level-up-activity *ngSwitchCase="'LevelUp'"></app-level-up-activity>

  <app-solve-activity *ngSwitchCase="'Solve'"></app-solve-activity>
</ng-container>
