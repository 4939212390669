import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { CreateDocumentDto } from "src/app/models";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "../../store/actions";
import * as FeatureSelectors from "../../store/selectors";

@Component({
  selector: "app-create-document",
  templateUrl: "./create-document.component.html",
  styleUrls: ["./create-document.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDocumentComponent {
  constructor(private store$: Store<AppStoreState>) {}

  @HostBinding("class") class = "app-create-document";

  tagMatches$: Observable<string[]> = this.store$.pipe(
    select(FeatureSelectors.selectTagMatches)
  );

  isFormDisabled$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsLoading)
  );

  isUpdating$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsUpdating)
  );

  document: CreateDocumentDto;

  createDocument(): void {
    this.store$.dispatch(
      FeatureActions.createDocument({ document: this.document })
    );
  }

  onTagChange(tag: string): void {
    this.store$.dispatch(FeatureActions.searchTags({ keyword: tag }));
  }
}
