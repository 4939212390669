<h2 mat-dialog-title>New Program</h2>
<mat-dialog-content>
  <app-program-form
    #programForm
    (ngModelChange)="updateProgram($event)"></app-program-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>CANCEL</button>
  <button
    mat-raised-button
    [disabled]="!programForm.valid"
    (click)="createProgram()">
    Create Program
  </button>
</mat-dialog-actions>
