<app-panel *ngIf="activity">
  <app-panel-title>
    <app-activity-type-icon-editor
      [activityType]="activityType"></app-activity-type-icon-editor>
    {{ activity.type }}
    <app-panel-subtitle>{{ activity.title }}</app-panel-subtitle>
  </app-panel-title>

  <app-activity [collapsed]="collapsed">
    <app-activity-title
      [title]="activity.title"
      (delete)="deleteActivity()"
      [(collapsed)]="collapsed">
    </app-activity-title>

    <app-activity-content>
      <div fxLayout fxLayoutGap="8px">
        <mat-tab-group fxFlex="75" fxFlex.gt-lg="70" color="primary">
          <mat-tab label="Introduction">
            <app-introduction-editor [value]="activity.introduction">
              <app-media-editor
                [loading]="isLoading$ | async"
                [value]="activity.mediaFile">
              </app-media-editor>
            </app-introduction-editor>
          </mat-tab>
          <mat-tab label="Ninja Requirements">
            <app-ninja-requirements-builder
              [requirements]="activity.ninjaRequirements">
            </app-ninja-requirements-builder>
          </mat-tab>
          <mat-tab label="Example Game">
            <app-edit-in-makecode
              #exampleGame
              [project]="activity.project"
              [editText]="'Open Example In'">
            </app-edit-in-makecode>
          </mat-tab>
          <mat-tab label="Code Editor">
            <app-edit-in-makecode
              #codeEditor
              [project]="activity.starterCode"
              [editText]="'Edit In'">
            </app-edit-in-makecode>
          </mat-tab>
        </mat-tab-group>
        <app-activity-toggles
          fxFlex="25"
          fxFlex.gt-lg="30"
          [activity]="activity">
        </app-activity-toggles>
      </div>
    </app-activity-content>
    <app-activity-stepper title="Planning Step" mode="single">
      <app-activity-step>
        <app-code-adventure-planning-step
          [step]="activity.planningStep"></app-code-adventure-planning-step>
      </app-activity-step>
    </app-activity-stepper>
    <app-activity-stepper title="Coding Step" mode="single">
      <app-activity-step>
        <app-coding-step [step]="activity.codingStep"></app-coding-step>
      </app-activity-step>
    </app-activity-stepper>
    <app-activity-stepper title="Sensei Step" mode="single">
      <app-activity-step>
        <app-sensei-step [step]="activity.senseiStep"></app-sensei-step>
      </app-activity-step>
    </app-activity-stepper>
    <app-activity-actions>
      <button
        color="primary"
        mat-raised-button
        [disabled]="!dirty || !valid || (isLoading$ | async)"
        (click)="updateActivity()">
        Update Activity
      </button>
    </app-activity-actions>
  </app-activity>
</app-panel>
