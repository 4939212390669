import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of, merge } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { selectSelectedActivity } from "src/app/levels/components/level/store/selectors";
import { DiscoverActivitiesService } from "src/app/services/discover-activities.service";
import { AppStoreState } from "src/app/store";
import { deleteFailureMessage } from "src/app/user-messages";
import * as FeatureActions from "./actions";
import { selectDiscoverActivity } from "./selectors";
import { updateLevelStatus } from "src/app/levels/components/level/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private snackbar: MatSnackBar,
    private activitiesService: DiscoverActivitiesService
  ) {}

  triggerLoadActivity$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      filter((a) => !!a && a.type === "Discover"),
      distinctUntilKeyChanged("id"),
      map(({ id: activityId }) =>
        FeatureActions.loadDiscoverActivity({ activityId })
      )
    )
  );

  loadDiscoverActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadDiscoverActivity),
      switchMap(({ activityId }) =>
        this.activitiesService.getDiscoverActivityById(activityId).pipe(
          map((activity) =>
            FeatureActions.loadDiscoverActivitySuccess({ activity })
          ),
          catchError((error) =>
            of(FeatureActions.loadDiscoverActivityFailure({ error }))
          )
        )
      )
    )
  );

  triggerLoadSteps$ = createEffect(() =>
    merge(
      this.store$.pipe(
        select(selectDiscoverActivity),
        filter((activity) => !!activity),
        map(({ id }) => id),
        distinctUntilChanged()
      )
    ).pipe(map((activityId) => FeatureActions.loadSteps({ activityId })))
  );

  loadSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadSteps),
      switchMap(({ activityId }) =>
        this.activitiesService.getSteps(activityId).pipe(
          map((steps) => FeatureActions.loadStepsSuccess({ steps })),
          catchError((error) => of(FeatureActions.loadStepsFailure({ error })))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.update),
      exhaustMap(({ activity, steps }) =>
        this.activitiesService.updateDiscoverActivity(activity, steps).pipe(
          map(() => FeatureActions.updateSuccess({ activity, steps })),
          catchError((error) => of(FeatureActions.updateFailure({ error })))
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateSuccess),
      map((_) => {
        return updateLevelStatus({
          status: "draft",
        });
      })
    )
  );

  addStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addStep),
      exhaustMap(({ step }) =>
        this.activitiesService.addStep(step).pipe(
          map((id) =>
            FeatureActions.addStepSuccess({
              step: { id, ...step },
            })
          ),
          catchError((error) => of(FeatureActions.addStepFailure({ error })))
        )
      )
    )
  );

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteStep),
      concatMap(({ step }) =>
        this.activitiesService.deleteStep(step.id).pipe(
          map(() => FeatureActions.deleteStepSuccess()),
          catchError((error) =>
            of(FeatureActions.deleteStepFailure({ step, error }))
          )
        )
      )
    )
  );

  displayDeleteFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.deleteStepFailure),
        tap(() => this.snackbar.open(deleteFailureMessage, "OK"))
      ),
    { dispatch: false }
  );
}
