import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  LevelUpActivityStep,
  LevelUpActivityStepBadge,
  Question,
} from "src/app/models";
import { QuizBuilderComponent } from "src/app/quiz-builder";
import { LevelUpActivityStepBadgeListComponent } from "../level-up-activity-step-badge-list";

@Component({
  selector: "app-level-up-activity-step",
  templateUrl: "./level-up-activity-step.component.html",
  styleUrls: ["./level-up-activity-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelUpActivityStepComponent {
  @HostBinding("class") class = "app-level-up-activity-step";

  @Input() set step(step: LevelUpActivityStep) {
    this.myStep = step;

    this.selectedOptionControl.reset();
    this.selectedOptionControl.setValue(step?.type || "LevelUpActivityStep");

    this.questions = step?.reflectionQuestions || [];
    this.badges = step?.badges || [];
  }

  get step(): LevelUpActivityStep {
    return {
      ...this.myStep,
      type: this.selectedOptionControl.value,
      reflectionQuestions: this.quizBuilder?.questions || this.questions,
      badges: this.badgeList?.badges || this.badges,
    };
  }

  @ViewChild(QuizBuilderComponent)
  quizBuilder: QuizBuilderComponent;

  @ViewChild(LevelUpActivityStepBadgeListComponent)
  badgeList: LevelUpActivityStepBadgeListComponent;

  selectedOptionControl = new FormControl(
    "reflectionQuestions",
    Validators.required
  );

  questions: Question[] = [];
  badges: LevelUpActivityStepBadge[] = [];

  get valid(): boolean {
    return !!(
      this.selectedOptionControl.valid &&
      (!this.quizBuilder || this.quizBuilder.valid) &&
      (!this.badgeList || this.badgeList.valid)
    );
  }

  get dirty(): boolean {
    return !!(
      this.selectedOptionControl.dirty ||
      this.quizBuilder?.dirty ||
      this.badgeList?.dirty
    );
  }

  private myStep: LevelUpActivityStep;
}
