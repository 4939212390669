import { ActivityToggles } from "./activity-toggles";
import { MakecodeProject } from "./makecode-project";
import { SubActivity } from "./sub-activity";
import { SubActivityStep } from "./step";
import { ValidationItem } from "./validation-item";
import { MediaFile } from ".";

export interface SolveSubActivity extends SubActivity, ActivityToggles {
  introduction?: string;
  project?: MakecodeProject;
  validationItems?: ValidationItem[];
  steps?: SolveSubActivityStep[];
  mediaFile?: MediaFile;
}

export interface SolveSubActivityStep extends SubActivityStep {
  instructions?: string;
  hints?: string;
  senseiHelp?: string;
}
