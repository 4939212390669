import { Action, createReducer, on } from "@ngrx/store";
import { initialState, State, stepsAdapter } from "./state";
import * as FeatureActions from "./actions";
import { appErrorReducer } from "src/app/store/app-error-reducer";

const featureReducer = createReducer<State>(
  initialState,
  on(
    FeatureActions.loadBuildActivity,
    FeatureActions.update,
    FeatureActions.loadSteps,
    FeatureActions.addStep,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(FeatureActions.loadBuildActivitySuccess, (state, { activity }) => ({
    ...state,
    isLoading: false,
    activity,
    steps: stepsAdapter.getInitialState(),
  })),
  on(FeatureActions.updateSuccess, (state, { activity, steps, mediaFile }) => ({
    ...state,
    isLoading: false,
    activity: {
      ...activity,
      mediaFile,
    },

    steps: stepsAdapter.updateMany(
      steps.map((s) => ({ id: s.id, changes: s })),
      state.steps
    ),
  })),
  on(FeatureActions.loadStepsSuccess, (state, { steps }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.addMany(steps, state.steps),
  })),
  on(FeatureActions.addStepSuccess, (state, { step }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.addOne(step, state.steps),
  })),
  on(FeatureActions.deleteStep, (state, { step }) => ({
    ...state,
    isLoading: true,
    steps: stepsAdapter.removeOne(step.id, state.steps),
  })),
  on(FeatureActions.deleteStepFailure, (state, { step }) => ({
    ...state,
    steps: stepsAdapter.addOne(step, state.steps),
  })),
  on(
    FeatureActions.loadBuildActivityFailure,
    FeatureActions.updateFailure,
    FeatureActions.loadStepsFailure,
    FeatureActions.addStepFailure,
    FeatureActions.deleteStepFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
