import { KeyValue } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ParameterOperator,
  ValidationItem,
  ValidationItemType,
} from "src/app/models";

@Component({
  selector: "app-validation-item-type-control",
  templateUrl: "./validation-item-type-control.component.html",
  styleUrls: ["./validation-item-type-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ValidationItemTypeControlComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ValidationItemTypeControlComponent,
    },
  ],
})
export class ValidationItemTypeControlComponent
  implements ControlValueAccessor, OnDestroy, Validator
{
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  @HostBinding("class") _class = "app-validation-item-type-control";

  type = this.fb.control("", Validators.required);

  get selectedItemTypeText(): string {
    return this._itemTypes[this.type.value]?.text;
  }

  _itemTypes: ItemTypes = {
    "perfect-match": { text: "Perfect Match", icon: "check_circle" },
    contains: { text: "Contains [#] of [Code]", icon: "group_work" },
    "anything-has-changed": {
      text: "Anything has changed",
      icon: "invert_colors",
    },
    "project-has-been-run": {
      text: "Project has been run",
      icon: "play_circle_outline",
    },
    "no-errors": { text: "No errors detected", icon: "warning" },
    "variable-validation": {
      text: "Variable Validation",
      icon: "perm_identity",
    },
  };

  private onTouched: () => void;

  private onChangeSubs: Subscription[] = [];

  ngOnDestroy(): void {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  writeValue(item: ValidationItem): void {
    this.type.patchValue(item, { emitEvent: false });

    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (item: ValidationItem) => void): void {
    const sub = this.type.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.type[["enable", "disable"][+isDisabled]]();
    this.cdr.markForCheck();
  }

  validate(): ValidationErrors {
    if (this.type.valid) {
      return null;
    }

    return this.type.errors;
  }

  _originalOrder(
    _: KeyValue<ParameterOperator, string>,
    __: KeyValue<ParameterOperator, string>
  ): number {
    return 0;
  }
}

type ItemTypes = {
  [type in ValidationItemType]: { text: string; icon: string };
};
