import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { SharedModule } from "../shared";
import { components, exports } from "./components";
import { containers } from "./containers";
import { pipes } from "./pipes";
import { directives } from "./directives";

@NgModule({
  imports: [CommonModule, SharedModule, MatRippleModule],
  declarations: [...containers, ...components, ...pipes, ...directives],
  providers: [...pipes],
  exports: [...containers, ...exports, ...pipes],
})
export class ImagesModule {}
