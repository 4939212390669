<div class="icon-list">
  <div class="header">
    <h3 class="title">Library</h3>
    <ng-content></ng-content>
  </div>
  <div class="content">
    <div
      class="image-holder mat-elevation-z2"
      [ngClass]="{ selected: icon.id === selectedIconId }"
      *ngFor="let icon of icons">
      <img [src]="icon | appImageUrl : 'icon'" (click)="select(icon)" />
    </div>
  </div>
</div>
