import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { DocumentFilters } from "src/app/models";

@Component({
  selector: "app-program-display-control",
  templateUrl: "./program-display-control.component.html",
  styleUrls: ["./program-display-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramDisplayControlComponent {
  @HostBinding("class") class = "app-program-display-control";

  @Input() set filters(filters: DocumentFilters) {
    this.myIncludeCreate = filters.create;
    this.myIncludeCamps = filters.camps;
    this.myIncludeJr = filters.jr;
  }

  @Output() filtersChange = new EventEmitter<DocumentFilters>();

  get includeCreate(): boolean {
    return this.myIncludeCreate;
  }

  set includeCreate(includeCreate: boolean) {
    this.myIncludeCreate = includeCreate;
    this.emitChange();
  }

  get includeCamps(): boolean {
    return this.myIncludeCamps;
  }

  set includeCamps(includeCamps: boolean) {
    this.myIncludeCamps = includeCamps;
    this.emitChange();
  }

  get includeJr(): boolean {
    return this.myIncludeJr;
  }

  set includeJr(includeJr: boolean) {
    this.myIncludeJr = includeJr;
    this.emitChange();
  }

  private myIncludeCreate: boolean;
  private myIncludeCamps: boolean;
  private myIncludeJr: boolean;

  private emitChange(): void {
    this.filtersChange.emit({
      camps: this.myIncludeCamps,
      create: this.myIncludeCreate,
      jr: this.myIncludeJr,
    });
  }
}
