export interface ActivityStep {
  id?: number;
  activityId?: number;
}

export interface SubActivityStep {
  id?: number;
  subActivityId?: number;
}

export type Step = ActivityStep & SubActivityStep;
