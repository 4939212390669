import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Icon, PaginatedResult, PaginationParams } from "../models";

@Injectable({ providedIn: "root" })
export class IconsService {
  constructor(private http: HttpClient) {}

  getIcons(params: PaginationParams): Observable<PaginatedResult<Icon>> {
    const { pageIndex, pageSize } = params;
    return this.http.get<PaginatedResult<Icon>>(
      `api/icons?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getIcon(iconId: string): Observable<Icon> {
    return this.http.get<Icon>(`api/icons/${iconId}`);
  }

  uploadIcon(iconFile: File): Observable<Icon> {
    const formData: FormData = new FormData();
    formData.append("file", iconFile, iconFile.name);

    return this.http.post<Icon>("api/icons", formData);
  }

  replaceIcon(iconId: string, iconFile: File): Observable<Icon> {
    const formData: FormData = new FormData();
    formData.append("iconId", iconId);
    formData.append("file", iconFile, iconFile.name);

    return this.http.post<Icon>("api/icons", formData);
  }

  deleteIcon(iconId: string): Observable<void> {
    return this.http.delete<void>("api/icons/" + iconId);
  }
}
