import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { components } from "./components";
import { materialModules } from "./material-modules";

@NgModule({
  imports: [CommonModule, ...materialModules],
  declarations: [...components],
  exports: [...materialModules, ...components, FlexLayoutModule],
})
export class SharedModule {}
