import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { filter, map } from "rxjs/operators";
import { Icon } from "src/app/models";
import { IconLibraryComponent } from "../icon-library";

@Component({
  selector: "app-icon-picker",
  templateUrl: "./icon-picker.component.html",
  styleUrls: ["./icon-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPickerComponent {
  constructor(private dialog: MatDialog) {}

  @Input() icon: Icon | null = null;

  @Output() iconChange = new EventEmitter<Icon>();

  openDialog(): void {
    this.dialog
      .open(IconLibraryComponent, {
        data: { icon: this.icon },
        width: "900px",
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.hasOwnProperty("icon") === true),
        map(({ icon }) => icon)
      )
      .subscribe((icon) => this.iconChange.emit(icon));
  }
}
