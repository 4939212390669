<form [formGroup]="formGroup" class="configure-options">
  <mat-card>
    <mat-slide-toggle formControlName="hasLowThreshold">
      Low Threshold
    </mat-slide-toggle>

    <mat-form-field floatLabel="never">
      <span matPrefix>Score is ≤ than&nbsp;</span>
      <input
        matInput
        placeholder="#"
        type="number"
        formControlName="lowThreshold"
        [appDisableControl]="!hasLowThreshold" />
    </mat-form-field>
  </mat-card>
  <mat-card>
    <mat-slide-toggle formControlName="hasPassingThreshold">
      Passing Threshold
    </mat-slide-toggle>

    <mat-form-field floatLabel="never">
      <span matPrefix>Score is ≥ than&nbsp;</span>
      <input
        matInput
        placeholder="#"
        type="number"
        formControlName="passingThreshold"
        [appDisableControl]="!hasPassingThreshold" />
    </mat-form-field>
  </mat-card>
  <mat-card>
    <mat-slide-toggle formControlName="peerReview">
      Peer Review
    </mat-slide-toggle>

    <mat-form-field floatLabel="never">
      <span matPrefix># of Reviews:&nbsp;</span>
      <input
        matInput
        placeholder="#"
        type="number"
        formControlName="numOfReviews"
        [appDisableControl]="!peerReview" />
    </mat-form-field>
  </mat-card>
</form>
