import { Directive, HostBinding, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Directive({
  selector: "a[appFileLink]",
})
export class FileLinkDirective {
  @HostBinding("href") href: string;

  @Input() set appFileLink(value: string) {
    this.href = `${environment.filesHostUrl}/${value}`;
  }
}
