<mat-tab-group color="primary">
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="step-label">
        <span>Playtest Questions</span>
        <span *ngIf="!playtestQuestionsBuilder?.valid">
          <img src="assets/exclamation-circle.svg" alt="Error"
        /></span>
      </div>
    </ng-template>
    <app-quiz-builder
      matTabConten
      #playtestQuestions
      [questions]="step?.playtestQuestions"></app-quiz-builder>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div class="step-label">
        <span>Sensei Validation Checklist</span>
        <span *ngIf="!validationChecklistBuilder?.valid">
          <img src="assets/exclamation-circle.svg" alt="Error"
        /></span>
      </div>
    </ng-template>

    <app-sensei-validation-checklist
      [checklist]="step?.validationChecklist"></app-sensei-validation-checklist>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div class="step-label">
        <span>Sensei Feedback</span>
        <span *ngIf="!feedbackItemsBuilder?.valid">
          <img src="assets/exclamation-circle.svg" alt="Error"
        /></span>
      </div>
    </ng-template>
    <app-quiz-builder
      #feedbackItems
      [questions]="step?.feedbackItems"></app-quiz-builder>
  </mat-tab>
</mat-tab-group>
