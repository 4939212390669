<app-badge-list-item
  *ngFor="let badge of _badges.controls; let index = index"
  [badge]="badge"
  (badgeAdd)="onBadgeAdd()"
  (badgeRemove)="onBadgeRemove(index)">
</app-badge-list-item>

<div *ngIf="_badges.controls.length === 0" fxFlex fxLayout="column">
  <button mat-button (click)="onBadgeAdd()">Add Badge</button>
</div>
