<div class="title-row">
  <div class="titles" fxLayout="column">
    <div class="course-title">{{ course?.title }}</div>
    <div class="level-title">{{ level?.title }}</div>
  </div>

  <div fxFlex fxLayout fxLayoutAlign="center center">
    <div fxFlex fxLayout fxLayoutAlign="center center">
      <mat-form-field>
        <mat-label>Level Title</mat-label>
        <input matInput [formControl]="titleControl" />
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="toggleDetails()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <div fxLayout fxLayoutAlign="center center" fxLayoutGap="4px">
      <button
        mat-stroked-button
        [disabled]="!dirty || !valid"
        (click)="update()">
        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="4px">
          <mat-icon>save</mat-icon>
          <span>Save Progress</span>
        </div>
      </button>

      <app-level-status-button
        appLevelStatus
        [status]="level?.status"
        (statusChange)="changeLevelStatus($event)"></app-level-status-button>
    </div>
  </div>
</div>
<div class="level-details" [@bodyExpansion]="expandedState">
  <div class="instructions">
    <div>
      <div class="instructions-title">Getting Started</div>
      <div>Each level consists of <span class="bold">Activities</span>.</div>
      <div>(ex: Build, Explore, Solve, etc)</div>
    </div>
    <div>
      <div><span class="bold">Activities</span> require an</div>
      <div>
        <span class="bold">icon</span>, <span class="bold">title</span> and
        <span class="bold">steps</span> to configure.
      </div>
    </div>
  </div>

  <div class="level-details-controls">
    <mat-slide-toggle [formControl]="mustCompletePreviousControl">
      Must Complete Previous
    </mat-slide-toggle>
    <div fxLayout="row" fxLayoutGap="10px">
      <div>
        <span>Description</span>
        <app-rich-text-editor
          [formControl]="descriptionControl"
          placeholder="Please update description."
          [required]="true"></app-rich-text-editor>
      </div>
      <div fxFlex="50">
        <span>Document Tags</span>
        <app-tags
          [tags]="_tags"
          [matches]="tagMatches"
          [label]="'Select a document tag for this level'"
          (tagChange)="_onTagChange($event)"
          (add)="_onTagAdd($event)"
          (remove)="_onTagRemove($event)">
        </app-tags>
      </div>
    </div>
  </div>
</div>
