import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectIconByActivityType } from "./store/selectors";
import { ActivityType, AppState, Icon } from "src/app/models";

@Component({
  selector: "app-activity-type-icon",
  template: '<app-icon [icon]="icon$ | async"></app-icon>',
  styles: ["app-icon { width: 32px; height: 32px;}"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTypeIconComponent {
  constructor(private store$: Store<AppState>) {}

  @Input() set activityType(activityType: ActivityType) {
    this.icon$ = this.store$.select(selectIconByActivityType(activityType));
  }

  icon$: Observable<Icon> = null;
}
