import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-sensei-validation-checklist",
  templateUrl: "./sensei-validation-checklist.component.html",
  styleUrls: ["./sensei-validation-checklist.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SenseiValidationChecklistComponent {
  @HostBinding("class") class = "app-sensei-validation-checklist";

  @Input() set checklist(checklist: string[]) {
    this.forms.clear();
    this.forms.reset();
    (checklist || []).forEach((i) => this.forms.push(this.itemToFormGroup(i)));
  }

  get checklist(): string[] {
    return this.forms.controls.map((fg: FormGroup) => fg.controls.text.value);
  }

  get valid(): boolean {
    return this.forms.valid;
  }

  get dirty(): boolean {
    return this.forms.dirty;
  }

  get _validationItems(): FormArray {
    return this.forms;
  }

  private forms = new FormArray([]);

  addItem(): void {
    this.forms.push(this.itemToFormGroup(""));
    this.forms.markAsDirty();
  }

  removeItemAt(index: number): void {
    this.forms.removeAt(index);
    this.forms.markAsDirty();
  }

  private itemToFormGroup(item: string): FormGroup {
    return new FormGroup({
      text: new FormControl(item, Validators.required),
    });
  }
}
