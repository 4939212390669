import { IdSelectorStr } from "@ngrx/entity/src/models";
import { Icon } from ".";

export interface Course {
  id: number;
  programId: number;
  order: number;
  title: string;
  description: string;
  icon?: Icon;
}

export type CreateCourseDto = Partial<Omit<Course, "id" | "icon">> & {
  iconId?: string;
};

export type EditCourseDto = Required<Pick<Course, "id">> &
  Partial<Omit<Course, "id" | "icon">> & {
    iconId?: string;
  };

export type Courses = Course[];

export const identifyCourse: IdSelectorStr<Course> = (c) => `${c.id}`;
