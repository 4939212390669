import { Background } from "./background";
import { Icon } from "./icon";

export interface Program {
  id: number;
  type: "CREATE";
  name: string;
  description: string;
  parentDescription: string;
  target: ProgramTarget;
  grader: ProgramGrader;
  disabled: boolean;
  lastUpdated: string;
  background: Background | null;
  icon: Icon | null;
}

export type CreateProgramDto = Partial<
  Omit<Program, "id" | "background" | "icon">
> & { backgroundId: string; iconId: string };

export type EditProgramDto = Required<Pick<Program, "id">> &
  Omit<Program, "background" | "icon"> &
  Partial<{ backgroundId: string; iconId: string }>;

export type ProgramFormDto = Omit<Program, "id"> &
  Partial<{ newBackground: File; iconId: string }>;

export type ProgramFormResultDto = ProgramFormDto &
  Required<Pick<Program, "id">>;

export type ProgramGrader = "sensei" | "director" | "owner";
export type ProgramTarget = "sensei" | "ninja";
