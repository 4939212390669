import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { BuildActivity, BuildActivityStep, MediaFile } from "../models";

@Injectable({ providedIn: "root" })
export class BuildActivitiesService {
  constructor(private http: HttpClient) {}

  getBuildActivityById(id: number): Observable<BuildActivity> {
    return this.http.get<BuildActivity>(`api/buildActivities/${id}`);
  }

  updateBuildActivity(
    activity: BuildActivity,
    steps: BuildActivityStep[],
    mediaFile: File | null
  ): Observable<MediaFile | null> {
    return this.http
      .put<void>("api/buildActivities", {
        activity,
        steps,
      })
      .pipe(
        switchMap((_) => {
          if (mediaFile === null && activity.mediaFile?.name !== null) {
            return this.http
              .delete(`api/buildActivities/${activity.id}/media`)
              .pipe(map((_) => null));
          } else if (
            mediaFile !== null &&
            mediaFile.name !== activity.mediaFile?.name
          ) {
            const formData = new FormData();

            formData.append("mediaFile", mediaFile);
            formData.append("activityId", activity.id.toString());

            return this.http.post<MediaFile>(
              `api/buildActivities/media`,
              formData
            );
          }

          return of(activity.mediaFile);
        })
      );
  }

  getSteps(activityId: number): Observable<BuildActivityStep[]> {
    return this.http.get<BuildActivityStep[]>(
      `api/buildActivities/${activityId}/steps`
    );
  }

  addStep(step: BuildActivityStep): Observable<number> {
    return this.http.post<number>(
      `api/buildActivities/${step.activityId}/steps`,
      step
    );
  }

  deleteStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/buildActivitySteps/${stepId}`);
  }
}
