import { YesNoDialogComponent } from "./yes-no-dialog";
import { EditTextDialogComponent } from "./edit-text-dialog";
import { MakecodeEditorDialogComponent } from "./makecode-editor-dialog";

export const components = [
  YesNoDialogComponent,
  EditTextDialogComponent,
  MakecodeEditorDialogComponent,
];

export {
  YesNoDialogComponent,
  EditTextDialogComponent,
  MakecodeEditorDialogComponent,
};
