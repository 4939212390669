import { createFeatureSelector } from "@ngrx/store";
import * as fromRouter from "@ngrx/router-store";

interface RouterState {
  router: fromRouter.RouterReducerState<any>;
}

const selectRouter = createFeatureSelector<
  RouterState,
  fromRouter.RouterReducerState<any>
>("router");

export const { selectRouteParam, selectRouteData } =
  fromRouter.getSelectors(selectRouter);

export const selectProgramId = selectRouteParam("programId");
export const selectLevelId = selectRouteParam("levelId");
