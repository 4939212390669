import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-random-questions-quote",
  templateUrl: "./random-questions-quote.component.html",
  styleUrls: ["./random-questions-quote.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RandomQuestionQuoteComponent {
  @HostBinding("class") class = "app-random-questions-quote";

  get randomQuote(): Quote {
    const randomIndex = this.getRandomNumberBetween(0, this.quotes.length - 1);
    return this.quotes[randomIndex];
  }

  quotes: Quote[] = [
    {
      author: "Stephen Hawking",
      text:
        "I am just a child who has never grown up. " +
        "I still keep asking these 'how' and 'why' questions. Occasionally, I find an answer.",
    },
    {
      author: "Albert Einstein",
      text: "A question that sometimes drives me hazy: am I or are the others crazy?",
    },
    {
      author: "Albert Einstein",
      text: "The important thing is not to stop questioning. Curiosity has its own reason for existing.",
    },
    {
      author: "Albert Einstein",
      text: "Learn from yesterday, live for today, hope for tomorrow. The important thing is not to stop questioning.",
    },
  ];

  private getRandomNumberBetween(min: number, max: number): number {
    return Math.floor(Math.random() * max) + min;
  }
}

interface Quote {
  author: string;
  text: string;
}
