import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import {
  HintsEditorComponent,
  InstructionsEditorComponent,
  SenseiHelpEditorComponent,
} from "src/app/editors/components";
import { BuildActivityStep } from "src/app/models";

@Component({
  selector: "app-build-activity-step",
  templateUrl: "./build-activity-step.component.html",
  styleUrls: ["./build-activity-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildActivityStepComponent {
  @HostBinding("class") class = "app-build-activity-step";

  @Input() step: BuildActivityStep;

  @ViewChild(InstructionsEditorComponent)
  instructions: InstructionsEditorComponent;

  @ViewChild(HintsEditorComponent)
  hints: HintsEditorComponent;

  @ViewChild(SenseiHelpEditorComponent)
  senseiHelp: SenseiHelpEditorComponent;

  get value(): BuildActivityStep {
    return {
      ...this.step,
      instructions: this.instructions.value,
      hints: this.hints.value,
      senseiHelp: this.senseiHelp.value,
    };
  }

  get valid(): boolean {
    return !!(
      this.instructions?.valid &&
      this.hints?.valid &&
      this.senseiHelp?.valid
    );
  }

  get dirty(): boolean {
    return !!(
      this.instructions?.dirty ||
      this.hints?.dirty ||
      this.senseiHelp?.dirty
    );
  }
}
