import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthStoreActions, AuthStoreSelectors } from "src/app/authentication";
import { UserProfile } from "src/app/models";
import { AppStoreState } from "src/app/store";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent {
  constructor(
    private store$: Store<AppStoreState>,
    private sanitizer: DomSanitizer
  ) {}

  @HostBinding("class") class = "app-account";

  profile$: Observable<UserProfile> = this.store$.pipe(
    select(AuthStoreSelectors.selectUser)
  );

  image$: Observable<SafeUrl | null> = this.store$.pipe(
    select(AuthStoreSelectors.selectImage),
    map((blob) =>
      blob
        ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
        : null
    )
  );

  logOut(): void {
    this.store$.dispatch(AuthStoreActions.logOut());
  }
}
