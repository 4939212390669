import { Clipboard } from "@angular/cdk/clipboard";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { delay, filter, map, share, takeUntil } from "rxjs/operators";
import {
  Activities,
  Activity,
  ActivityType,
  Course,
  Level,
  LevelStatus,
} from "src/app/models";
import { AppStoreState } from "src/app/store";
import { slideInOut } from "./level-animations";
import * as FeatureActions from "./store/actions";
import * as FeatureSelectors from "./store/selectors";
import { selectMail } from "src/app/authentication/store/selectors";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./level.component.html",
  styleUrls: ["./level.component.scss"],
  animations: [slideInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelComponent implements OnInit, OnDestroy {
  constructor(
    private store$: Store<AppStoreState>,
    private cdr: ChangeDetectorRef,
    private clipboard: Clipboard
  ) {}

  private destroyed$ = new Subject<void>();

  private mySelectedActivity: Activity;

  fileName: string;
  isUploading$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsUploading)
  );

  isIconsUploading$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsIconsUploading)
  );

  isImagesUploading$: Observable<boolean> = this.store$.pipe(
    select(FeatureSelectors.selectIsImagesUploading)
  );

  selectMail$: Observable<string> = this.store$.select(selectMail);

  environmentName = environment.envName;

  fileUrl$: Observable<string> = this.store$.pipe(
    select(FeatureSelectors.selectFileUrl)
  );

  level$: Observable<Level> = this.store$.pipe(
    select(FeatureSelectors.selectLevel),
    filter((level) => !!level),
    delay(0)
  );

  course$: Observable<Course> = this.store$.pipe(
    select(FeatureSelectors.selectCourse),
    filter((course) => !!course),
    delay(0)
  );

  levelAndCourse$ = combineLatest([this.level$, this.course$]).pipe(
    map(([level, course]) => ({ level, course }))
  );

  activities$: Observable<Activities> = this.store$.pipe(
    select(FeatureSelectors.selectActivities),
    delay(0)
  );

  selectedActivity$: Observable<Activity> = this.store$.pipe(
    select(FeatureSelectors.selectSelectedActivity),
    share(),
    delay(0)
  );

  tagMatches$: Observable<string[]> = this.store$.pipe(
    select(FeatureSelectors.selectTagMatches)
  );

  onTagChange(tag: string): void {
    this.store$.dispatch(FeatureActions.searchTags({ keyword: tag }));
  }

  set selectedActivity(activity: Activity) {
    this.mySelectedActivity = activity;
    this.cdr.detectChanges();
  }

  get selectedActivity(): Activity {
    return this.mySelectedActivity;
  }

  ngOnInit(): void {
    this.selectedActivity$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((activity) => (this.selectedActivity = activity));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  selectActivity(activity: Activity): void {
    this.store$.dispatch(FeatureActions.changeSelectedActivity({ activity }));
  }

  updateLevel(level: Level): void {
    this.store$.dispatch(FeatureActions.updateLevel({ level }));
  }

  changeLevelStatus(status: LevelStatus): void {
    if (["testing", "published"].includes(status)) {
      this.store$.dispatch(
        FeatureActions.publishLevel({ status: status, entity: "level" })
      );
    } else {
      this.store$.dispatch(
        FeatureActions.updateLevelStatus({ status: status })
      );
    }
  }

  delete(activity: Activity): void {
    this.store$.dispatch(FeatureActions.deleteActivity({ activity }));
  }

  add(activityType: ActivityType, level: Level): void {
    this.store$.dispatch(
      FeatureActions.addActivity({
        activityType,
        levelId: level.id,
      })
    );
  }

  rearrange(activities: Activities): void {
    this.store$.dispatch(FeatureActions.rearrange({ activities }));
  }

  copyHostedUrl(hostedlUrl: string) {
    this.clipboard.copy(hostedlUrl);
  }

  uploadFile($event) {
    const file: File = $event.target.files[0];
    $event.target.value = null;

    if (file) {
      this.fileName = file.name;
      this.store$.dispatch(FeatureActions.uploadedVideo({ file: file }));
    }
  }
}
