import {
  Directive,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";

export abstract class ChildFormBase {
  public abstract get childForm(): FormGroup;
  public setChildForm?(_any): void {}
}

export const CHILD_FORM_TOKEN = new InjectionToken<ChildFormBase>(
  "CHILD_FORM_TOKEN"
);

@Directive({
  selector: "[appParentForm]",
})
export class BindChildFormDirective implements OnInit, OnDestroy {
  constructor(
    @Inject(CHILD_FORM_TOKEN) private childComponent: ChildFormBase
  ) {}

  @Input("appParentForm") parentFormGroup: AbstractControl;

  @Input() appChildFormValue: any;

  ngOnInit(): void {
    const childForm = this.childComponent.childForm;
    if (this.appChildFormValue) {
      this.childComponent.setChildForm(this.appChildFormValue);
    }

    if ((this.parentFormGroup as FormArray).push) {
      (this.parentFormGroup as FormArray).push(childForm);
    } else if ((this.parentFormGroup as FormGroup).addControl) {
      for (const control of Object.keys(childForm.controls)) {
        (this.parentFormGroup as FormGroup).addControl(
          control,
          childForm.controls[control]
        );
      }
    }
  }

  ngOnDestroy(): void {
    const childForm = this.childComponent.childForm;
    if ((this.parentFormGroup as FormArray).clear) {
      this.removeFromFormArray(this.parentFormGroup as FormArray, childForm);
    } else if ((this.parentFormGroup as FormGroup).removeControl) {
      for (const control of Object.keys(childForm.controls)) {
        (this.parentFormGroup as FormGroup).removeControl(control);
      }
    }
  }

  private removeFromFormArray(
    formArray: FormArray,
    childForm: AbstractControl
  ): void {
    let formIndex = -1;
    formArray.controls.forEach((control, index) => {
      if (control === childForm) {
        formIndex = index;
      }
    });

    if (formIndex > -1) {
      formArray.removeAt(formIndex);
    }
  }
}
