import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Course, Courses, CreateCourseDto, EditCourseDto } from "../models";

@Injectable({ providedIn: "root" })
export class CoursesService {
  constructor(private http: HttpClient) {}

  getCourseById(courseId: number): Observable<Course> {
    return this.http.get<Course>(`api/courses/${courseId}`);
  }

  getCoursesByProgramId(programId: number): Observable<Courses> {
    return this.http.get<Courses>(`api/programs/${programId}/courses`);
  }

  update(course: EditCourseDto): Observable<void> {
    return this.http.put<void>(`api/courses/${course.id}`, course);
  }

  addCourse(programId: number): Observable<void> {
    const newCourse: CreateCourseDto = {
      programId,
      title: "New Course",
    };

    return this.http.post<void>(`api/programs/${programId}/courses`, newCourse);
  }

  duplicateCourse(courseId: number): Observable<void> {
    return this.http.post<void>(`api/courses/${courseId}/copy`, null);
  }

  deleteCourse(courseId: number): Observable<void> {
    return this.http.delete<void>(`api/courses/${courseId}`);
  }

  updateCourses(programId: number, courses: Courses): Observable<void> {
    return this.http.put<void>(`api/programs/${programId}/courses`, {
      courses,
    });
  }
}
