import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { DocumentsService, TagsService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";
import * as FeatureSelectors from "./selectors";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private service: DocumentsService,
    private tagsService: TagsService
  ) {}

  triggerDocumentsPageLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FeatureActions.changeFilters,
        FeatureActions.changeSearch,
        FeatureActions.changeSelectedPage,
        FeatureActions.changeSortBy,
        FeatureActions.createDocumentSuccess
      ),
      map(() => FeatureActions.loadDocumentsPage())
    )
  );

  loadDocumentsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadDocumentsPage),
      withLatestFrom(
        this.store$.pipe(select(FeatureSelectors.selectQueryParams))
      ),
      switchMap(([_, queryParams]) =>
        this.service.getDocumentsPage(queryParams).pipe(
          map((page) => FeatureActions.loadDocumentsPageSuccess({ page })),
          catchError((error) =>
            of(FeatureActions.loadDocumentsPageFailure({ error }))
          )
        )
      )
    )
  );

  createDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.createDocument),
      exhaustMap(({ document }) =>
        this.service.createDocument(document).pipe(
          map((document) => FeatureActions.createDocumentSuccess({ document })),
          catchError((error) =>
            of(FeatureActions.createDocumentFailure({ error }))
          )
        )
      )
    )
  );

  updateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateDocument),
      switchMap(({ original, updates }) =>
        this.service.updateDocument(updates).pipe(
          map((document) => FeatureActions.updateDocumentSuccess({ document })),
          catchError((error) =>
            of(FeatureActions.updateDocumentFailure({ error, original }))
          )
        )
      )
    )
  );

  seachTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.searchTags),
      switchMap(({ keyword }) =>
        this.tagsService.searchTags(keyword).pipe(
          map((tagMatches) => FeatureActions.searchTagsSuccess({ tagMatches })),
          catchError((error) => of(FeatureActions.searchTagsFailure({ error })))
        )
      )
    )
  );
}
