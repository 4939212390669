import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared";
import { EditorsModule } from "../editors";
import { components } from "./components";

@NgModule({
  imports: [CommonModule, SharedModule, ReactiveFormsModule, EditorsModule],
  declarations: [...components],
  exports: [...components],
  entryComponents: [...components],
})
export class DialogsModule {}
