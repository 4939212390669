import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
  name: "appImageUrl",
})
export class ImageUrlPipe implements PipeTransform {
  transform(image: Image, resource: string): string {
    if (!image?.id || !resource) {
      return "";
    }

    const { id, updatedOn } = image;

    return `${environment.imagesHostUrl}/api/${resource}s/${id}/download?m=${updatedOn}`;
  }
}

type Image = { id: string; updatedOn: Date };
