import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { State, initialState } from "./state";
import * as FeatureActions from "./actions";
import { IconsStoreActions } from "src/app/images/store";

const featureReducer = createReducer(
  initialState,
  on(
    FeatureActions.loadPrograms,
    FeatureActions.addProgramConfirm,
    FeatureActions.editProgramConfirm,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(FeatureActions.loadProgramsSuccess, (state, { programs }) => {
    const dates = programs.map((p) => new Date(p.lastUpdated));

    dates.sort((a, b) => b.getTime() - a.getTime());

    return {
      ...state,
      isLoading: false,
      programs,
      lastEditDate: dates[0] ?? null,
    };
  }),
  on(
    FeatureActions.addProgramSuccess,
    FeatureActions.editProgramSuccess,
    FeatureActions.toggleProgramDisabledSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(FeatureActions.toggleProgramDisabled, (state, { program }) => ({
    ...state,
    isLoading: true,
    programs: state.programs.map((p) =>
      p.id === program.id ? { ...p, disabled: !p.disabled } : p
    ),
  })),
  on(FeatureActions.toggleProgramDisabledFailure, (state, { program }) => ({
    ...state,
    programs: state.programs.map((p) =>
      p.id === program.id ? { ...p, disabled: program.disabled } : p
    ),
  })),
  on(IconsStoreActions.replaceIconSuccess, (state, { icon }) => ({
    ...state,
    programs: state.programs.map((p) => {
      let updatedIcon = p.icon;
      if (p.icon && p.icon.id === icon.id) {
        updatedIcon = icon;
      }

      return {
        ...p,
        icon: updatedIcon,
      };
    }),
  })),
  on(
    FeatureActions.loadProgramsFailure,
    FeatureActions.addProgramFailure,
    FeatureActions.editProgramFailure,
    FeatureActions.toggleProgramDisabledFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
