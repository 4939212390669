import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  MediaFile,
  SolveActivity,
  SolveSubActivity,
  SolveSubActivityStep,
} from "src/app/models";

export const loadSolveActivity = createAction(
  "[Solve Activity Component] Load solve activity",
  props<{ activityId: number }>()
);
export const loadSolveActivitySuccess = createAction(
  "[Solve Activity Component] Load solve activity success",
  props<{ activity: SolveActivity }>()
);
export const loadSolveActivityFailure = createAction(
  "[Solve Activity Component] Load solve activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadSubActivities = createAction(
  "[Solve Activity Component] Load sub-activities",
  props<{ activityId: number }>()
);
export const loadSubActivitiesSuccess = createAction(
  "[Solve Activity Component] Load sub-activities success",
  props<{ subActivities: SolveSubActivity[] }>()
);
export const loadSubActivitiesFailure = createAction(
  "[Solve Activity Component] Load sub-activities failure",
  props<{ error: HttpErrorResponse }>()
);

export const clearSubActivities = createAction(
  "[Solve Activity Component] Clear sub-activities"
);

export const updateSubActivity = createAction(
  "[Solve Activity Component] Update sub-activity",
  props<{
    subActivity: SolveSubActivity;
    mediaFile: File | null;
    levelId: number;
  }>()
);
export const updateSubActivitySuccess = createAction(
  "[Solve Activity Component] Update sub-activity success",
  props<{
    subActivity: SolveSubActivity;
    mediaFile?: MediaFile;
    levelId: number;
  }>()
);
export const updateSubActivityFailure = createAction(
  "[Solve Activity Component] Update sub-activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteSubActivity = createAction(
  "[Solve Activity Component] Delete sub-activity",
  props<{ subActivity: SolveSubActivity }>()
);
export const deleteSubActivityConfirm = createAction(
  "[Solve Activity Component] Delete sub-activity confirm",
  props<{ subActivity: SolveSubActivity }>()
);
export const deleteSubActivityCancel = createAction(
  "[Solve Activity Component] Delete sub-activity cancel"
);
export const deleteSubActivitySuccess = createAction(
  "[Solve Activity Component] Delete sub-activity success",
  props<{ subActivityId: number }>()
);
export const deleteSubActivityFailure = createAction(
  "[Solve Activity Component] Delete sub-activity failure",
  props<{ subActivity: SolveSubActivity; error: HttpErrorResponse }>()
);

export const addSubActivity = createAction(
  "[Solve Activity Component] Add solve activity",
  props<{ title: string }>()
);
export const addSubActivitySuccess = createAction(
  "[Solve Activity Component] Add solve activity success",
  props<{ subActivity: SolveSubActivity }>()
);
export const addSubActivityFailure = createAction(
  "[Solve Activity Component] Add solve activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const addStep = createAction(
  "[Solve Activity Component] Add sub activity step",
  props<{ subActivityId: number }>()
);
export const addStepSuccess = createAction(
  "[Solve Activity Component] Add sub activity step success",
  props<{ step: SolveSubActivityStep }>()
);
export const addStepFailure = createAction(
  "[Solve Activity Component] Add sub activity step failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteStep = createAction(
  "[Solve Activity Component] Delete step",
  props<{ step: SolveSubActivityStep }>()
);
export const deleteStepSuccess = createAction(
  "[Solve Activity Component] Delete step success"
);
export const deleteStepFailure = createAction(
  "[Solve Activity Component] Delete step failure",
  props<{ step: SolveSubActivityStep; error: HttpErrorResponse }>()
);
