import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State } from "./state";

const selectState = createFeatureSelector<State>(featureKey);

export const selectCodeAdventureActivity = createSelector(
  selectState,
  (state) => state.activity
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);
