<mat-radio-group
  aria-label="Select an option"
  [formControl]="selectedOptionControl">
  <mat-radio-button value="reflectionQuestions">
    Reflection Questions
  </mat-radio-button>
  <mat-radio-button value="badgesDisplayed">Badges Displayed</mat-radio-button>
</mat-radio-group>

<ng-container [ngSwitch]="selectedOptionControl.value">
  <app-quiz-builder
    *ngSwitchCase="'reflectionQuestions'"
    [questions]="questions"
    (questionAdd)="addQuestion()"
    (questionDelete)="deleteQuestion($event)"></app-quiz-builder>

  <app-level-up-activity-step-badge-list
    *ngSwitchCase="'badgesDisplayed'"
    [badges]="badges"></app-level-up-activity-step-badge-list>
</ng-container>
