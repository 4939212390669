import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-panel-title",
  templateUrl: "./panel-title.component.html",
  styleUrls: ["./panel-title.component.scss"],
})
export class PanelTitleComponent {
  @HostBinding("class") class = "app-panel-title";
}
