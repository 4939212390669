import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, State, stepsAdapter } from "./state";
import * as FeatureActions from "./actions";

const featureReducer = createReducer(
  initialState,
  on(FeatureActions.loadLevelUpActivity, (state) => ({
    ...state,
    isLoading: true,
    steps: stepsAdapter.getInitialState(),
  })),
  on(FeatureActions.loadLevelUpActivitySuccess, (state, { activity }) => ({
    ...state,
    isLoading: false,
    activity,
    steps: stepsAdapter.setAll(activity?.steps || [], state.steps),
  })),
  on(FeatureActions.loadStepsSuccess, (state, { steps }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.setAll(steps, state.steps),
  })),
  on(
    FeatureActions.updateActivity,
    FeatureActions.loadSteps,
    FeatureActions.addStep,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    FeatureActions.updateActivitySuccess,
    (state, { activity, mediaFile }) => ({
      ...state,
      isLoading: false,
      activity: { ...activity, mediaFile },
      steps: stepsAdapter.updateMany(
        activity.steps.map((s) => ({ id: s.id, changes: s })),
        state.steps
      ),
    })
  ),
  on(FeatureActions.addStepSuccess, (state, { step }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.addOne(step, state.steps),
  })),
  on(FeatureActions.deleteStep, (state, { step }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.removeOne(step.id, state.steps),
  })),
  on(FeatureActions.deleteStepFailure, (state, { step }) => ({
    ...state,
    steps: stepsAdapter.addOne(step, state.steps),
  })),
  on(
    FeatureActions.loadLevelUpActivityFailure,
    FeatureActions.updateActivityFailure,
    FeatureActions.loadStepsFailure,
    FeatureActions.addStepFailure,
    FeatureActions.deleteStepFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
