import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-panel-subtitle",
  templateUrl: "./panel-subtitle.component.html",
  styleUrls: ["./panel-subtitle.component.scss"],
})
export class PanelSubtitleComponent {
  @HostBinding("class") class = "app-panel-subtitle";
}
