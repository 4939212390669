import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
@Component({
  selector: "cmp-lazy-load-container",
  templateUrl: "./lazy-load-container.component.html",
})
export class LazyLoadContainerComponent implements AfterContentChecked {
  constructor(private elRef: ElementRef, private cdr: ChangeDetectorRef) {}

  @ContentChild("body", { static: false })
  contentTemplate: TemplateRef<ElementRef>;

  loadContent: boolean;
  ngAfterContentChecked() {
    if (this.elRef.nativeElement.offsetParent) {
      this.loadContent = true;
      this.cdr.markForCheck();
    }
  }
}
