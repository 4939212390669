import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MediaFile } from "src/app/models";

@Component({
  selector: "app-media-editor",
  templateUrl: "./media-editor.component.html",
  styleUrls: ["./media-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaEditorComponent {
  @HostBinding("class") class = "app-media-editor";

  @Input() set value(value: MediaFile) {
    this.mediaControl.reset();
    this.mediaControl.setValue(value);
  }

  @Input() loading = false;

  get selectedMediaName(): string {
    return (this.mediaControl.value as File)?.name;
  }

  get mediaValue(): File | null {
    return this.mediaControl.value as File;
  }

  set mediaValue(value: File | null) {
    this.mediaControl.setValue(value);
    this.mediaControl.markAsDirty();
  }

  get valid(): boolean {
    return this.mediaControl.valid;
  }

  get dirty(): boolean {
    return this.mediaControl.dirty;
  }

  get isEmpty(): boolean {
    return !this.mediaControl.value;
  }

  mediaControl = new FormControl(null);

  onUploadFile(file: File): void {
    this.mediaValue = file;
  }

  onRemoveClick(): void {
    this.mediaValue = null;
  }
}
