<mat-tab-group color="primary">
  <mat-tab label="Instructions">
    <app-instructions-editor [value]="step.instructions">
    </app-instructions-editor>
  </mat-tab>
  <mat-tab label="Hints">
    <app-hints-editor [value]="step.hints"></app-hints-editor>
  </mat-tab>
  <mat-tab label="Sensei Help">
    <app-sensei-help-editor [value]="step.senseiHelp"></app-sensei-help-editor>
  </mat-tab>
</mat-tab-group>
