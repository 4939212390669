import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { activitiesAdapter, initialState, State } from "./state";
import * as FeatureActions from "./actions";
import { Activity, identifyActivity } from "src/app/models";

const featureReducer = createReducer(
  initialState,
  on(FeatureActions.loadLevel, (state, { levelId }) => {
    let newState = state;
    if (state.level?.id !== levelId) {
      newState = initialState;
    }

    return {
      ...newState,
      isLoading: true,
    };
  }),
  on(FeatureActions.loadLevelSuccess, (state, { level }) => ({
    ...state,
    isLoading: false,
    level,
  })),
  on(
    FeatureActions.loadActivities,
    FeatureActions.loadCourse,
    FeatureActions.updateLevel,
    FeatureActions.deleteActivityConfirm,
    FeatureActions.addActivity,
    FeatureActions.updateLevelStatus,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(FeatureActions.publishLevel, (state) => ({
    ...state,
    isSyncing: true,
  })),
  on(FeatureActions.publishLevelSuccess, (state, { entity }) => {
    if (entity !== "level") {
      return {
        ...state,
        isSyncing: false,
      };
    }
    return state;
  }),
  on(FeatureActions.updateLevelStatusSuccess, (state, { status }) => ({
    ...state,
    isLoading: false,
    isSyncing: false,
    level: {
      ...state.level,
      status: status,
    },
  })),
  // on(FeatureActions.updateLevelStatusFailure, (state) => ({
  //   ...state,
  //   isLoading: false,
  // })),
  // on(FeatureActions.publishLevel, (state) => ({
  //   ...state,
  //   isLoading: true,
  //   level: {
  //     ...state.level,
  //     status: 'publish-requested',
  //   },
  // })),
  // on(FeatureActions.publishLevelFailure, (state) => ({
  //   ...state,
  //   isLoading: false,
  //   level: {
  //     ...state.level,
  //     status: 'testing',
  //   },
  // })),
  on(FeatureActions.loadActivitiesSuccess, (state, { activities }) => {
    let selectedActivity: Activity;
    if (state.selectedActivity) {
      selectedActivity = activities.find(
        (a) => a.id === state.selectedActivity.id && a.type === state.selectedActivity.type
      );
    }

    if (!selectedActivity && activities.length) {
      selectedActivity = activities[0];
    }

    return {
      ...state,
      isLoading: false,
      activities: activitiesAdapter.setAll(activities, state.activities),
      selectedActivity,
    };
  }),
  on(FeatureActions.loadCourseSuccess, (state, { course }) => ({
    ...state,
    isLoading: false,
    course,
  })),
  on(FeatureActions.changeSelectedActivity, (state, { activity }) => ({
    ...state,
    selectedActivity: activity,
  })),
  on(FeatureActions.rearrange, (state, { activities }) => ({
    ...state,
    isLoading: true,
    activities: activitiesAdapter.updateMany(
      activities.map((a) => ({
        id: identifyActivity(a),
        changes: a,
      })),
      state.activities
    ),
  })),
  on(
    FeatureActions.updateLevelSuccess,
    FeatureActions.deleteActivitySuccess,
    FeatureActions.addActivitySuccess,
    FeatureActions.rearrangeSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(FeatureActions.searchTagsSuccess, (state, { tagMatches }) => ({
    ...state,
    tagMatches,
  })),
  on(
    FeatureActions.loadLevelFailure,
    FeatureActions.loadActivitiesFailure,
    FeatureActions.loadCourseFailure,
    FeatureActions.updateLevelFailure,
    FeatureActions.publishLevelFailure,
    FeatureActions.updateLevelStatusFailure,
    FeatureActions.deleteActivityFailure,
    FeatureActions.addActivityFailure,
    FeatureActions.rearrangeFailure,
    FeatureActions.searchTagsFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      isSyncing: false,
      error: appErrorReducer(error),
    })
  ),
  on(
    FeatureActions.uploadedVideo,
    FeatureActions.searchTagsFailure,
    (state) => ({
      ...state,
      isUploading: true,
      fileUrl: null,
    })
  ),
  on(FeatureActions.uploadedVideoFailure, (state) => ({
    ...state,
    isUploading: false,
  })),
  on(FeatureActions.uploadedVideoSuccess, (state, { fileUrl }) => ({
    ...state,
    fileUrl: fileUrl,
    isUploading: false,
  })),
  on(
    FeatureActions.uploadImages,
    FeatureActions.searchTagsFailure,
    (state) => ({
      ...state,
      isImagesUploading: true,
      fileUrl: null,
    })
  ),
  on(FeatureActions.uploadedImagesFailure, (state) => ({
    ...state,
    isImagesUploading: false,
  })),
  on(FeatureActions.uploadedImagesSuccess, (state) => ({
    ...state,
    isImagesUploading: false,
  })),
  on(FeatureActions.uploadIcons, FeatureActions.searchTagsFailure, (state) => ({
    ...state,
    isIconsUploading: true,
    fileUrl: null,
  })),
  on(FeatureActions.uploadedIconsFailure, (state) => ({
    ...state,
    isIconsUploading: false,
  })),
  on(FeatureActions.uploadedIconsSuccess, (state) => ({
    ...state,
    isIconsUploading: false,
  }))
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
