export interface PaginatedResult<T> {
  items: T[];
  pageIndex: number;
  totalItems: number;
}

export interface PaginationParams {
  pageSize: number;
  pageIndex: number;
}
