import { Component } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { ExploreSubActivity } from "src/app/models";
import { AppStoreState } from "src/app/store";
import { addSubActivity } from "./store/actions";
import { selectSubActivities } from "./store/selectors";

@Component({
  selector: "app-explore-activity",
  templateUrl: "./explore-activity.component.html",
  styleUrls: ["./explore-activity.component.scss"],
})
export class ExploreActivityComponent {
  constructor(private store$: Store<AppStoreState>) {}

  subActivities$: Observable<ExploreSubActivity[]> = this.store$.pipe(
    select(selectSubActivities),
    delay(0)
  );

  addExploreSubActivity(): void {
    this.store$.dispatch(addSubActivity({ title: "Untitled Activity" }));
  }
}
