import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { UploadFileQuestion, UploadFileType } from "src/app/models";
import { MultiCheckBoxValidator } from "./multi-checkbox-validator";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["upload-file.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponent {
  constructor(private fb: FormBuilder) {}

  @HostBinding("class") class = "app-upload-file";

  get fileTypeControls(): FormArray {
    return this.uploadFileGroup.get("fileTypes") as FormArray;
  }

  get fileCountControl(): FormControl {
    return this.uploadFileGroup.get("fileCount") as FormControl;
  }

  get fileSizeControl(): FormControl {
    return this.uploadFileGroup.get("fileSize") as FormControl;
  }

  uploadFileGroup: FormGroup = this.fb.group({
    fileTypes: new FormArray(
      [],
      MultiCheckBoxValidator.multipleCheckboxRequireOne
    ),
    fileCount: ["", Validators.required],
    fileSize: ["", Validators.required],
  });

  private myQuestion: UploadFileQuestion;

  @Input() set question(question: UploadFileQuestion) {
    this.myQuestion = question;
    this.uploadFileGroup.reset();
    this.uploadFileGroup.patchValue({
      fileCount: question?.fileCount,
      fileSize: question?.fileSize,
    });

    question?.fileTypes?.forEach((fileType) => {
      if (this.fileTypes[fileType]) {
        this.fileTypes[fileType].selected = true;
        this.fileTypeControls.push(new FormControl(fileType));
      }
    });
  }

  get question(): UploadFileQuestion {
    return {
      ...this.myQuestion,
      ...this.uploadFileGroup.value,
    };
  }

  fileTypes: { [type in UploadFileType]: { name: string; selected: boolean } } =
    {
      unity: { name: "Unity Files", selected: false },
      pdf: { name: "PDF Files", selected: false },
    };

  // 1 through 10
  fileCount: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  fileSizes: string[] = ["1mb", "5mb", "10mb", "50mb", "100mb", "500mb", "1gb"];

  get valid(): boolean {
    return this.uploadFileGroup.valid;
  }

  get dirty(): boolean {
    return this.uploadFileGroup.dirty;
  }

  onFileTypeCheckChange(
    event: MatCheckboxChange,
    filetype: "unity-files" | "pdf-files"
  ): void {
    if (event.checked) {
      this.fileTypeControls.push(new FormControl(filetype));
      this.fileTypeControls.markAsDirty();
    } else {
      this.fileTypeControls.controls.forEach(
        (fileTypeControl: FormControl, index) => {
          if (fileTypeControl.value === filetype) {
            this.fileTypeControls.removeAt(index);
            this.fileTypeControls.markAsDirty();
          }
        }
      );
    }
  }
}
