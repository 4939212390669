<div fxLayout="column" fxLayoutGap="16px">
  <div>
    <span class="title">Create Document</span>
    <app-document-form
      #form
      [tagMatches]="tagMatches$ | async"
      [disabled]="isFormDisabled$ | async"
      [(ngModel)]="document"
      (tagChange)="onTagChange($event)"></app-document-form>
    <mat-divider [inset]="true"></mat-divider>
  </div>

  <div fxLayout fxLayoutAlign="flex-end">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      [appLoading]="isUpdating$ | async"
      (click)="createDocument()">
      Add Document
    </button>
  </div>
</div>
