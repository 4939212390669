import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  EditProgramDialogComponent,
  NewProgramDialogComponent,
} from "src/app/programs/dialogs";
import { ProgramsService, BackgroundsService } from "src/app/services";
import * as FeatureActions from "./actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private service: ProgramsService,
    private backgroundsService: BackgroundsService,
    private dialog: MatDialog
  ) {}

  loadPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FeatureActions.loadPrograms,
        FeatureActions.addProgramSuccess,
        FeatureActions.editProgramSuccess
      ),
      switchMap(() =>
        this.service.getPrograms().pipe(
          map((programs) => FeatureActions.loadProgramsSuccess({ programs })),
          catchError((error) =>
            of(FeatureActions.loadProgramsFailure({ error }))
          )
        )
      )
    )
  );

  confirmAddProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addProgram),
      switchMap(() =>
        this.dialog
          .open(NewProgramDialogComponent)
          .afterClosed()
          .pipe(
            map((program) =>
              program
                ? FeatureActions.addProgramConfirm({ program })
                : FeatureActions.addProgramCancel()
            )
          )
      )
    )
  );

  addProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addProgramConfirm),
      switchMap(({ program }) => {
        let backgroundId = of(program.background?.id);
        if (program.newBackground) {
          backgroundId = this.backgroundsService
            .uploadBackground(program.newBackground)
            .pipe(
              map(({ id }) => id),
              catchError(() => of(null))
            );
        }

        return backgroundId.pipe(
          switchMap((backgroundId) =>
            this.service
              .addProgram({
                ...program,
                backgroundId,
                iconId: program.icon?.id,
              })
              .pipe(
                map(() => FeatureActions.addProgramSuccess()),
                catchError((error) =>
                  of(FeatureActions.addProgramFailure({ error }))
                )
              )
          )
        );
      })
    )
  );

  confirmEditProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.editProgram),
      switchMap(({ program }) =>
        this.dialog
          .open(EditProgramDialogComponent, {
            data: { program: { ...program } },
          })
          .afterClosed()
          .pipe(
            map((updatedProgram) =>
              updatedProgram
                ? FeatureActions.editProgramConfirm({
                    program: updatedProgram,
                  })
                : FeatureActions.editProgramCancel()
            )
          )
      )
    )
  );

  updateProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.editProgramConfirm),
      switchMap(({ program }) => {
        let backgroundId = of(program.background?.id);
        if (program.newBackground) {
          backgroundId = this.backgroundsService
            .uploadBackground(program.newBackground)
            .pipe(
              map(({ id }) => id),
              catchError(() => of(null))
            );
        }

        return backgroundId.pipe(
          switchMap((backgroundId) =>
            this.service
              .updateProgram({
                ...program,
                backgroundId,
                iconId: program.icon?.id,
              })
              .pipe(
                map(() => FeatureActions.editProgramSuccess()),
                catchError((error) =>
                  of(FeatureActions.editProgramFailure({ error }))
                )
              )
          )
        );
      })
    )
  );

  toggleProgramDisabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.toggleProgramDisabled),
      switchMap(({ program }) =>
        this.service.toggleProgramDisabled(program.id).pipe(
          map(() => FeatureActions.toggleProgramDisabledSuccess()),
          catchError((error) =>
            of(FeatureActions.toggleProgramDisabledFailure({ program, error }))
          )
        )
      )
    )
  );
}
