<fieldset [formGroup]="form" fxLayout="column" fxLayoutGap="8px">
  <mat-form-field>
    <mat-label>Document Title</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>

  <app-rich-text-editor
    formControlName="text"
    placeholder="Enter document text here"></app-rich-text-editor>

  <mat-divider [inset]="true"></mat-divider>

  <app-image-picker
    [url]="_document | appImageUrl : 'documentImage'"
    (imageChange)="_onImageChange($event)"></app-image-picker>

  <mat-divider [inset]="true"></mat-divider>

  <app-tags
    [tags]="_tags"
    [matches]="tagMatches"
    (tagChange)="_onTagChange($event)"
    (add)="_onTagAdd($event)"
    (remove)="_onTagRemove($event)"></app-tags>
</fieldset>
