<div fxLayout fxLayoutGap="16px">
  <div fxFlex>
    <div
      fxLayout
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
      *ngFor="let statement of statements.controls">
      <mat-form-field fxFlex>
        <mat-label>Text</mat-label>
        <input matInput [formControl]="statement.controls.text" />
      </mat-form-field>

      <button mat-icon-button color="primary" (click)="removeFieldAt(index)">
        <mat-icon>clear</mat-icon>
      </button>

      <mat-form-field fxFlex="30">
        <mat-label>Answer</mat-label>
        <mat-select
          [formControl]="statement.controls.answer"
          [disabled]="!useWordBankControl.value">
          <mat-option
            *ngFor="let word of wordbank.controls"
            [value]="word.controls.text.value">
            {{ word.controls.text.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <button mat-button (click)="addField()">Add Field</button>
    </div>
  </div>
  <div>
    <mat-list>
      <mat-list-item>
        <div fxFlex fxLayout fxLayoutAlign="flex-start center">
          <mat-slide-toggle color="primary" [formControl]="useWordBankControl">
            Word Bank
          </mat-slide-toggle>
        </div>
      </mat-list-item>

      <mat-list-item *ngFor="let word of wordbank.controls">
        <mat-form-field>
          <input
            matInput
            [formControl]="word.controls.text"
            [appDisableControl]="!useWordBankControl.value" />
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="removeWordAt(index)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-list-item>
      <mat-list-item>
        <div fxFlex fxLayout="column">
          <button
            mat-button
            [disabled]="!useWordBankControl.value"
            (click)="addWord()">
            Add Word
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
