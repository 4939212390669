import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { ActivitiesService } from "src/app/services";
import * as FeatureActions from "./actions";
import * as LevelFeatureActions from "src/app/levels/components/level/store/actions";
import { LevelStoreSelectors } from "src/app/levels/components/level/store";
import { AppStoreState } from "src/app/store";
import { Store } from "@ngrx/store";

@Injectable()
export class Effects {
  constructor(
    private store$: Store<AppStoreState>,
    private actions$: Actions,
    private service: ActivitiesService
  ) {}

  triggerLoadActivityTypeIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LevelFeatureActions.loadCourseSuccess),
      exhaustMap(({ course: { programId } }) =>
        of(FeatureActions.loadActivityTypeIcons({ programId }))
      )
    )
  );

  loadActivityTypesIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadActivityTypeIcons),
      switchMap(({ programId }) =>
        this.service.getActivityTypeIconByProgramId(programId).pipe(
          map((activityTypeIcons) =>
            FeatureActions.loadActivityTypeIconsSuccess({
              activityTypeIcons,
            })
          ),
          catchError((error) =>
            of(FeatureActions.loadActivityTypeIconsFailure({ error }))
          )
        )
      )
    )
  );

  updateActivityTypeIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateProgramActivityTypeIcon),
      withLatestFrom(
        this.store$.select(LevelStoreSelectors.selectLevelProgramId)
      ),
      switchMap(([{ activityType, icon }, programId]) =>
        this.service
          .setActivityTypeIconByProgramId(programId, activityType, icon.id)
          .pipe(
            map(() =>
              FeatureActions.updateProgramActivityTypeIconSuccess({
                activityType,
                icon,
              })
            ),
            catchError((error) =>
              of(FeatureActions.updateProgramActivityTypeIconError({ error }))
            )
          )
      )
    )
  );
}
