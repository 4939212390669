import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, filter } from "rxjs/operators";
import {
  availableLanguages,
  getLanguageOptionFromLanguages,
  getLanguagesFromOption,
  MakeCodeLanguage,
  LanguageRestriction,
} from "src/app/models";

@Component({
  selector: "app-makecode-language-selector",
  styleUrls: ["./makecode-language-selector.component.scss"],
  template: ` <mat-form-field appearance="fill">
    <mat-label>Course Language</mat-label>
    <mat-select [formControl]="languageControl" multiple required>
      <mat-option *ngFor="let language of languageOptions" [value]="language">{{
        language | titlecase
      }}</mat-option>
    </mat-select>
  </mat-form-field>`,
})
export class MakecodeLanguageSelectorComponent implements OnDestroy {
  languageControl = new FormControl(availableLanguages);
  languageOptions = availableLanguages;

  subscription = this.languageControl.valueChanges
    .pipe(
      filter((v) => v.length > 0),
      debounceTime(500)
    )
    .subscribe((value: Array<MakeCodeLanguage>) =>
      this.valueChange.emit(getLanguageOptionFromLanguages(value))
    );

  @Input() set value(value: LanguageRestriction) {
    this.languageControl.setValue(getLanguagesFromOption(value ?? ""));
  }

  @Output() valueChange = new EventEmitter<LanguageRestriction>();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
