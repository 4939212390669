import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  CreateLevelUpActivityStepDto,
  LevelUpActivity,
  LevelUpActivityStep,
  MediaFile,
} from "src/app/models";

export const loadLevelUpActivity = createAction(
  "[Level Up Activity Component] Load level up activity",
  props<{ activityId: number }>()
);
export const loadLevelUpActivitySuccess = createAction(
  "[Level Up Activity Component] Load level up success",
  props<{ activity: LevelUpActivity }>()
);
export const loadLevelUpActivityFailure = createAction(
  "[Level Up Activity Component] Load level up failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateActivity = createAction(
  "[Level Up Activity Component] update activity",
  props<{
    activity: LevelUpActivity;
    mediaFile: File | null;
    isMediaDirty?: boolean;
  }>()
);
export const updateActivitySuccess = createAction(
  "[Level Up Activity Component] update activity success",
  props<{ activity: LevelUpActivity; mediaFile?: MediaFile }>()
);
export const updateActivityFailure = createAction(
  "[Level Up Activity Component] update activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadSteps = createAction(
  "[Level Up Activity Component] Load steps",
  props<{ activityId: number }>()
);
export const loadStepsSuccess = createAction(
  "[Level Up Activity Component] Load steps success",
  props<{ steps: LevelUpActivityStep[] }>()
);
export const loadStepsFailure = createAction(
  "[Level Up Activity Component] Load steps failure",
  props<{ error: HttpErrorResponse }>()
);

export const addStep = createAction(
  "[Level Up Activity Component] Add step",
  props<{ step: CreateLevelUpActivityStepDto }>()
);
export const addStepSuccess = createAction(
  "[Level Up Activity Component] Add step success",
  props<{ step: LevelUpActivityStep }>()
);
export const addStepFailure = createAction(
  "[Level Up Activity Component] Add step failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteStep = createAction(
  "[Level Up Activity Component] Delete step",
  props<{ step: LevelUpActivityStep }>()
);
export const deleteStepSuccess = createAction(
  "[Level Up Activity Component] Delete step success"
);
export const deleteStepFailure = createAction(
  "[Level Up Activity Component] Delete step failure",
  props<{ step: LevelUpActivityStep; error: HttpErrorResponse }>()
);
