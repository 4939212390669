import { ValidatorFn, FormGroup } from "@angular/forms";

export function validToggleValue(
  toggleValueTuples: ToggleValuePair[]
): ValidatorFn {
  return (fg: FormGroup): { [key: string]: any } | null => {
    const min = 0;
    const max = 100;
    for (const { toggleName, valueName } of toggleValueTuples) {
      if (fg.get(toggleName).value === true) {
        if (fg.get(valueName).value < min || fg.get(valueName).value > max) {
          return { invalidRange: true };
        }
      }
    }
    return null;
  };
}

export interface ToggleValuePair {
  toggleName: string;
  valueName: string;
}
