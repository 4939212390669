import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MultiSelectQuestion, MultiSelectQuestionAnswer } from "src/app/models";

@Component({
  selector: "app-multi-select-question",
  templateUrl: "./multi-select-question.component.html",
  styleUrls: ["./multi-select-question.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectQuestionComponent {
  @HostBinding("class") class = "app-multi-select-question";

  @Input() set question(question: MultiSelectQuestion) {
    this.myQuestion = question;

    this.answers.clear();
    this.answers.reset();
    question?.answers?.forEach((a) =>
      this.answers.push(this.getFormGroupFromAnswer(a))
    );
  }

  get question(): MultiSelectQuestion {
    return {
      ...this.myQuestion,
      answers: this.answers.value,
    };
  }

  get valid(): boolean {
    return this.answers.valid;
  }

  get dirty(): boolean {
    return this.answers.dirty;
  }

  answers = new FormArray([]);

  private myQuestion: MultiSelectQuestion;

  getIsCorrectTitle(isCorrect: boolean): string {
    return isCorrect ? "Correct" : "Incorrect";
  }

  toggleIsCorrect(isCorrectControl: FormControl): void {
    isCorrectControl.setValue(!isCorrectControl.value);
    isCorrectControl.markAsDirty();
  }

  addAnswer(): void {
    this.answers.push(
      new FormGroup({
        text: new FormControl("", Validators.required),
        isCorrect: new FormControl(false),
      })
    );
    this.answers.markAsDirty();
  }

  removeAnswerAtIndex(index: number): void {
    this.answers.removeAt(index);
    this.answers.markAsDirty();
  }

  getFormGroupFromAnswer(answer: MultiSelectQuestionAnswer): FormGroup {
    return new FormGroup({
      text: new FormControl(answer.text, Validators.required),
      isCorrect: new FormControl(answer.isCorrect || false),
    });
  }
}
