import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-feature-subtitle",
  templateUrl: "./feature-subtitle.component.html",
  styleUrls: ["./feature-subittle.component.scss"],
})
export class FeatureSubtitleComponent {
  @HostBinding("class") class = "app-feature-subtitle";
}
