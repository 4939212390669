import { Question } from "./question";

export interface FillInTheBlankQuestion extends Question {
  useWordbank?: boolean;
  statements?: FillInTheBlankStatement[];
  wordbank?: string[];
}

export interface FillInTheBlankStatement {
  text?: string;
  answer?: string;
}
