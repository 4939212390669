export const ToolbarConfig = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    { color: [] },
    {
      background: [
        "#00000000",
        "#4B7BEC",
        "#D54322",
        "#8854D0",
        "#E30FC0",
        "#4B6584",
        "#CF6A87",
        "#20BF6B",
        "#45AAF2",
        "#EC3B59",
        "#A55EEA",
      ],
    },
  ], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button

  ["link", "image", "video"],
];
