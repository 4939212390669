import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { NgrxRouterStoreModule } from "./store";

const routes: Routes = [
  {
    path: "",
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [{ path: "", redirectTo: "home", pathMatch: "full" }],
  },
  {
    path: "documents",
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    loadChildren: () => import("../documents").then((m) => m.DocumentsModule),
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? "enabled" : "disabled",
    }),
    NgrxRouterStoreModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
