import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ActivityType, AppState, Icon } from "src/app/models";
import { updateProgramActivityTypeIcon } from "./store/actions";
import { ActivityTypeIconSelectors } from "./store";

@Component({
  selector: "app-activity-type-icon-editor",
  template: `<app-icon-picker
    [icon]="icon$ | async"
    (iconChange)="onIconChange($event)">
  </app-icon-picker>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTypeIconEditorComponent {
  constructor(private store$: Store<AppState>) {}
  private _activityType: ActivityType;

  @Input() set activityType(activityType: ActivityType) {
    this._activityType = activityType;
    this.icon$ = this.store$.select(
      ActivityTypeIconSelectors.selectIconByActivityType(activityType)
    );
  }

  get activityType(): ActivityType {
    return this._activityType;
  }

  icon$: Observable<Icon> = null;

  onIconChange(icon: Icon): void {
    const { activityType } = this;

    this.store$.dispatch(updateProgramActivityTypeIcon({ activityType, icon }));
  }
}
