import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-activity-actions",
  templateUrl: "./activity-actions.component.html",
  styleUrls: ["./activity-actions.component.scss"],
})
export class ActivityActionsComponent {
  @HostBinding("class") class = "app-activity-actions";
}
