import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import {
  IntroductionEditorComponent,
  MediaEditorComponent,
} from "src/app/editors/components";
import {
  CreateLevelUpActivityStepDto,
  LevelUpActivity,
  LevelUpActivityStep,
} from "src/app/models";
import { AppStoreState } from "src/app/store";
import { ActivityEditorBaseComponent } from "../activity";
import { ActivityTogglesComponent } from "../activity-toggles";
import { LevelUpActivityStepComponent } from "./level-up-activity-step";
import { selectLevelUpActivity, selectSteps } from "./store/selectors";
import { addStep, deleteStep, updateActivity } from "./store/actions";

@Component({
  selector: "app-level-up-activity",
  templateUrl: "./level-up-activity.component.html",
  styleUrls: ["./level-up-activity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelUpActivityComponent
  extends ActivityEditorBaseComponent
  implements OnInit
{
  constructor(
    protected store$: Store<AppStoreState>,
    private cdr: ChangeDetectorRef
  ) {
    super(store$);
    this.activityType = "LevelUp";
  }

  @HostBinding("class") class = "app-level-up-activity";

  @ViewChild(IntroductionEditorComponent)
  introduction: IntroductionEditorComponent;

  @ViewChild(ActivityTogglesComponent)
  activityToggles: ActivityTogglesComponent;

  @ViewChildren(LevelUpActivityStepComponent)
  steps = new QueryList<LevelUpActivityStepComponent>();

  @ViewChild(MediaEditorComponent)
  mediaEditor: MediaEditorComponent;

  get valid(): boolean {
    return !!(
      this.title?.valid &&
      this.introduction?.valid &&
      this.activityToggles?.valid &&
      this.mediaEditor?.valid &&
      this.steps?.map((s) => s).every((s) => s.valid)
    );
  }

  get dirty(): boolean {
    return !!(
      this.title?.dirty ||
      this.introduction?.dirty ||
      this.activityToggles?.dirty ||
      this.mediaEditor?.dirty ||
      this.steps?.map((s) => s).find((s) => s.dirty)
    );
  }

  set activity(activity: LevelUpActivity) {
    this.myActivity = activity;
    this.cdr.detectChanges();
  }

  get activity(): LevelUpActivity {
    return this.myActivity;
  }

  steps$: Observable<LevelUpActivityStep[]> = this.store$.pipe(
    select(selectSteps),
    tap((steps) => (this.mySteps = steps))
  );

  private myActivity: LevelUpActivity;
  mySteps: LevelUpActivityStep[] = [];

  ngOnInit(): void {
    this.store$
      .pipe(select(selectLevelUpActivity), takeUntil(this.destroyed$))
      .subscribe((activity) => (this.activity = activity));
  }

  getDeleteAction(): Action {
    throw Error("Not implememted");
  }

  getUpdateAction(): Action {
    const activity: LevelUpActivity = {
      ...this.myActivity,
      hasLowThreshold: this.activityToggles.hasLowThreshold,
      lowThreshold: this.activityToggles.lowThreshold,
      hasPassingThreshold: this.activityToggles.hasPassingThreshold,
      passingThreshold: this.activityToggles.passingThreshold,
      peerReview: this.activityToggles.peerReview,
      numOfReviews: this.activityToggles.numOfReviews,
      title: this.title.value,
      introduction: this.introduction.value,
      steps: this.steps.filter((s) => s.dirty).map((s) => s.step),
    };

    return updateActivity({
      activity,
      mediaFile: this.mediaEditor.mediaValue,
      isMediaDirty: this.mediaEditor.dirty,
    });
  }

  addStep(): void {
    const step: CreateLevelUpActivityStepDto = {
      activityId: this.activity.id,
    };
    this.store$.dispatch(addStep({ step }));
  }

  deleteStep(index: number): void {
    this.store$.dispatch(deleteStep({ step: this.mySteps[index] }));
  }
}
