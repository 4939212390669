import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Course, Courses, Program } from "src/app/models";

export const loadProgram = createAction(
  "[Edit Program Component] Load program",
  props<{ programId: number }>()
);
export const loadProgramSuccess = createAction(
  "[Edit Program Component] Load program success",
  props<{ program: Program }>()
);
export const loadProgramFailure = createAction(
  "[Edit Program Component] Load program failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadCourses = createAction(
  "[Edit Program Component] Load courses",
  props<{ programId: number }>()
);
export const loadCoursesSuccess = createAction(
  "[Edit Program Component] Load courses success",
  props<{ courses: Courses }>()
);
export const loadCoursesFailure = createAction(
  "[Edit Program Component] Load courses failure",
  props<{ error: HttpErrorResponse }>()
);

export const rearrange = createAction(
  "[Edit Program Component] Rearrange courses",
  props<{ courses: Courses }>()
);
export const rearrangeSuccess = createAction(
  "[Edit Program Component] Rearrange courses success"
);
export const rearrangeFailure = createAction(
  "[Edit Program Component] Rearrange courses failure",
  props<{ error: HttpErrorResponse }>()
);

export const addCourse = createAction("[Edit Program Component] Add course");
export const addCourseSuccess = createAction(
  "[Edit Program Component] Add course success"
);
export const addCourseFailure = createAction(
  "[Edit Program Component] Add course failure",
  props<{ error: HttpErrorResponse }>()
);

export const changeSelectedCourse = createAction(
  "[Edit Program Component] Change selected course",
  props<{ course: Course }>()
);

export const duplicateCourse = createAction(
  "[Edit Program Component] Duplicate course",
  props<{ courseId: number }>()
);
export const duplicateCourseSuccess = createAction(
  "[Edit Program Component] Duplicate course success"
);
export const duplicateCourseFailure = createAction(
  "[Edit Program Component] Duplicate course failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteCourse = createAction(
  "[Edit Program Component] Delete course",
  props<{ courseId: number }>()
);
export const deleteCourseConfirm = createAction(
  "[Edit Program Component] Delete course confirm",
  props<{ courseId: number }>()
);
export const deleteCourseCancel = createAction(
  "[Edit Program Component] Delete course cancel"
);
export const deleteCourseSuccess = createAction(
  "[Edit Program Component] Delete course success"
);
export const deleteCourseFailure = createAction(
  "[Edit Program Component] Delete course failure",
  props<{ error: HttpErrorResponse }>()
);
