import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import {
  IntroductionEditorComponent,
  MediaEditorComponent,
} from "src/app/editors/components";
import { ExploreSubActivity, ExploreSubActivityStep } from "src/app/models";
import { AppStoreState } from "src/app/store";
import { ValidationListComponent } from "src/app/validation-builder";
import { ActivityEditorBaseComponent } from "../activity";
import { ActivityTogglesComponent } from "../activity-toggles";
import { EditInMakecodeComponent } from "../edit-in-makecode";
import { ExploreSubActivityStepComponent } from "./explore-sub-activity-step.component";
import {
  addStep,
  deleteStep,
  deleteSubActivity,
  updateSubActivity,
} from "./store/actions";
import { selectSteps, levelId } from "./store/selectors";

@Component({
  selector: "app-explore-sub-activity",
  templateUrl: "./explore-sub-activity.component.html",
  styleUrls: ["./explore-sub-activity.component.scss"],
})
export class ExploreSubActivityComponent
  extends ActivityEditorBaseComponent
  implements AfterViewChecked
{
  constructor(
    store$: Store<AppStoreState>,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(store$);
    this.activityType = "Explore";
  }

  @HostBinding("class") class = "app-explore-activity";

  @Input() activity: ExploreSubActivity;
  @Input() shouldHaveMedia = false;
  @Input() index: number;

  steps$: Observable<ExploreSubActivityStep[]> = this.store$.pipe(
    select(selectSteps),
    map((steps) => steps.filter((s) => s.subActivityId === this.activity.id)),
    tap((steps) => (this.mySteps = steps))
  );

  @ViewChild(IntroductionEditorComponent)
  introduction: IntroductionEditorComponent;

  @ViewChild(ActivityTogglesComponent)
  activityToggles: ActivityTogglesComponent;

  @ViewChild(EditInMakecodeComponent)
  codeEditor: EditInMakecodeComponent;

  @ViewChild(ValidationListComponent)
  validationItems: ValidationListComponent;

  @ViewChildren(ExploreSubActivityStepComponent)
  steps = new QueryList<ExploreSubActivityStepComponent>();

  @ViewChild(MediaEditorComponent)
  mediaEditor: MediaEditorComponent;
  levelId = 0;
  levelId$ = this.store$
    .select(levelId)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((l) => {
      this.levelId = l;
    });

  get valid(): boolean {
    return !!(
      this.title?.valid &&
      this.introduction?.valid &&
      this.activityToggles?.valid &&
      this.codeEditor?.valid &&
      this.validationItems?.valid &&
      (this.mediaEditor?.valid || !this.mediaEditor) &&
      this.steps?.map((s) => s).every((s) => s.valid)
    );
  }

  get dirty(): boolean {
    return !!(
      this.title?.dirty ||
      this.introduction?.dirty ||
      this.activityToggles?.dirty ||
      this.codeEditor?.dirty ||
      this.validationItems?.dirty ||
      this.mediaEditor?.dirty ||
      this.steps?.map((s) => s).some((s) => s.dirty)
    );
  }

  private mySteps: ExploreSubActivityStep[];

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  addStep(): void {
    this.store$.dispatch(addStep({ subActivityId: this.activity.id }));
  }

  deleteStep(index: number): void {
    const step = this.mySteps[index];
    this.store$.dispatch(deleteStep({ step }));
  }

  protected getUpdateAction(): Action {
    return updateSubActivity({
      subActivity: {
        ...this.activity,
        title: this.title.value,
        introduction: this.introduction.value,
        hasLowThreshold: this.activityToggles.hasLowThreshold,
        lowThreshold: this.activityToggles.lowThreshold,
        hasPassingThreshold: this.activityToggles.hasPassingThreshold,
        passingThreshold: this.activityToggles.passingThreshold,
        peerReview: this.activityToggles.peerReview,
        numOfReviews: this.activityToggles.numOfReviews,
        project: this.codeEditor.project,
        validationItems: this.validationItems.items,
        steps: this.steps.filter((s) => s.dirty).map((s) => s.value),
      },
      mediaFile: this.mediaEditor?.mediaValue || null,
      levelId: this.levelId,
    });
  }

  protected getDeleteAction(): Action {
    return deleteSubActivity({ subActivity: this.activity });
  }
}
