import { PaginatedResult, PaginationParams } from "./pagination";

export interface Document {
  id: string;
  title: string;
  text: string;
  tags: string[];
  updatedOn: Date;
}

export type Documents = Document[];

export type DocumentsPage = PaginatedResult<Document>;

export type DocumentsSort = "dateAdded" | "beltOrder" | "alpha";

export type DocumentFilters = {
  create: boolean;
  camps: boolean;
  jr: boolean;
};

export type QueryParams = PaginationParams &
  DocumentFilters & { sortBy: DocumentsSort; search: string };

type ImageUploadDto = { image: File };

export type CreateDocumentDto = Partial<Omit<Document, "id" | "updatedOn">> &
  Partial<ImageUploadDto>;

export type EditDocumentDto = Partial<Omit<Document, "id" | "updatedOn">> &
  Required<Pick<Document, "id">> &
  Partial<ImageUploadDto>;
