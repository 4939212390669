import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { QuillModule } from "ngx-quill";
import { SharedModule } from "../shared";
import { components } from "./components";
import { ImagesModule } from "../images";
import { ToolbarConfig } from "../models";
import { DirectivesModule } from "../directives";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    QuillModule.forRoot({
      modules: {
        toolbar: ToolbarConfig,
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    ImagesModule,
    DirectivesModule,
  ],
  declarations: [...components],
  exports: [...components],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EditorsModule {}
