<form [formGroup]="uploadFileGroup">
  <div fxLayout fxLayoutGap="16px">
    <div class="file-types" fxLayout="column wrap">
      <p>Required File Types</p>
      <p *ngFor="let kv of fileTypes | keyvalue">
        <mat-checkbox
          [value]="kv.value.selected"
          [checked]="kv.value.selected"
          (change)="onFileTypeCheckChange($event, kv.key)">
          {{ kv.value.name }}
        </mat-checkbox>
      </p>
    </div>
    <mat-form-field>
      <mat-label>Maximum Files</mat-label>
      <mat-select
        id="fileCount"
        formControlName="fileCount"
        placeholder="Select File Count">
        <mat-option *ngFor="let count of fileCount" [value]="count">
          {{ count }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Maximum File Size</mat-label>
      <mat-select
        id="fileSize"
        formControlName="fileSize"
        placeholder="Select File Size">
        <mat-option *ngFor="let size of fileSizes" [value]="size">
          {{ size }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
