import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RelativeDatePipe } from "./relative-date.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [RelativeDatePipe],
  exports: [RelativeDatePipe],
})
export class PipesModule {}
