import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { NinjaRequirement } from "src/app/models";

@Component({
  selector: "app-ninja-requirements-builder",
  templateUrl: "./ninja-requirements-builder.component.html",
  styleUrls: ["./ninja-requirements-builder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NinjaRequirementsBuilderComponent {
  @HostBinding("class") class = "app-ninja-requirements-builder";

  @Input() set requirements(requirements: NinjaRequirement[]) {
    this.forms.clear();
    this.forms.reset();
    (requirements || []).forEach((r) =>
      this.forms.push(this.requirementToFormGroup(r))
    );
  }

  get requirements(): NinjaRequirement[] {
    return this.forms.controls.map((fg: FormGroup) => fg.value);
  }

  get valid(): boolean {
    return this.forms.valid;
  }

  get dirty(): boolean {
    return this.forms.dirty;
  }

  get _requirements(): FormArray {
    return this.forms;
  }

  private forms = new FormArray([]);

  addRequirement(): void {
    this.forms.push(this.requirementToFormGroup());
    this.forms.markAsDirty();
  }

  removeRequirementAt(index: number): void {
    this.forms.removeAt(index);
    this.forms.markAsDirty();
  }

  private requirementToFormGroup(
    { text }: NinjaRequirement = { text: "" }
  ): FormGroup {
    return new FormGroup({
      text: new FormControl(text, Validators.required),
    });
  }
}
