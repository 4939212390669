<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label> {{ label }} </mat-label>
    <input matInput [formControl]="textControl" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="textControl.value"
    [disabled]="textControl.invalid">
    OK
  </button>
</mat-dialog-actions>
