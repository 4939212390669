<app-panel *ngFor="let sub of subActivities$ | async; let index = index">
  <app-solve-sub-activity
    [index]="index"
    [activity]="sub"
    [shouldHaveMedia]="index === 0"></app-solve-sub-activity>
</app-panel>

<app-add-sub-activity-button (add)="addSolveSubActivity()">
  Add Solve Activity
</app-add-sub-activity-button>
