<div
  mat-dialog-content
  class="library-wraper"
  fxLayout="row"
  fxLayoutAlign="space-between">
  <app-icon-list
    [icons]="icons$ | async"
    [selectedIconId]="selectedIcon?.id"
    (selectIcon)="onSelectIcon($event)"
    fxFlex="50">
    <app-icon-pagination
      [pageIndex]="pageIndex$ | async"
      [totalPages]="totalPages$ | async"
      (goToPage)="onGoToPage($event)"></app-icon-pagination>
  </app-icon-list>
  <app-icon-uploader
    fxFlex="50"
    [actionTitle]="uploadActionTitle"
    [selectedIcon]="selectedIcon"
    (uploadImage)="onUploadImage($event)"
    (deleteImage)="onDeleteImage($event)"></app-icon-uploader>
</div>

<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="{ icon: selectedIcon }">
    Done
  </button>
</div>
