import { trigger, transition, style, animate } from "@angular/animations";

export const slideInOut = trigger("slideInOut", [
  transition(":enter", [
    style({ transform: "translateY(-200%)" }),
    animate("600ms ease-in", style({ transform: "translateY(0%)" })),
  ]),
  transition(":leave", [
    animate("600ms ease-in", style({ transform: "translateY(-200%)" })),
  ]),
]);
