import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, levelsAdapter, State } from "./state";
import * as FeatureActions from "./actions";

const featureReducer = createReducer(
  initialState,
  on(FeatureActions.loadLevels, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(FeatureActions.loadLevelsSuccess, (state, { levels }) => ({
    ...state,
    isLoading: false,
    levels: levelsAdapter.setAll(levels, state.levels),
  })),
  on(
    FeatureActions.addLevel,
    FeatureActions.copyLevel,
    FeatureActions.updateCourse,
    FeatureActions.deleteLevelConfirm,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    FeatureActions.addLevelSuccess,
    FeatureActions.copyLevelSuccess,
    FeatureActions.updateCourseSuccess,
    FeatureActions.deleteLevelSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FeatureActions.loadLevelsFailure,
    FeatureActions.addLevelFailure,
    FeatureActions.copyLevelFailure,
    FeatureActions.updateCourseFailure,
    FeatureActions.deleteLevelFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
