import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  ExploreActivity,
  ExploreSubActivity,
  ExploreSubActivityStep,
  MediaFile,
} from "../models";

@Injectable({ providedIn: "root" })
export class ExploreActivitiesService {
  constructor(private http: HttpClient) {}

  getExploreActivityById(id: number): Observable<ExploreActivity> {
    return this.http.get<ExploreActivity>(`api/exploreActivities/${id}`);
  }

  getSubActivities(activityId: number): Observable<ExploreSubActivity[]> {
    return this.http.get<ExploreSubActivity[]>(
      `api/exploreActivities/${activityId}/subActivities`
    );
  }

  addSubActivity(
    subActivity: ExploreSubActivity
  ): Observable<{ id: number; stepId: number }> {
    return this.http.post<{ id: number; stepId: number }>(
      `api/exploreActivities/${subActivity.activityId}/subActivities`,
      subActivity
    );
  }
  updateSubActivity(
    subActivity: ExploreSubActivity,
    mediaFile: File | null,
    levelId: number
  ): Observable<MediaFile | null> {
    return this.http.put<void>("api/exploreSubActivities", subActivity).pipe(
      switchMap((_) => {
        if (mediaFile === null && !!subActivity.mediaFile?.name) {
          return this.http
            .delete(`api/exploreSubActivities/${subActivity.id}/media`)
            .pipe(map((_) => null));
        } else if (
          mediaFile !== null &&
          mediaFile.name !== subActivity.mediaFile?.name
        ) {
          const formData = new FormData();

          formData.append("mediaFile", mediaFile);
          formData.append("subActivityId", subActivity.id.toString());

          return this.http.post<MediaFile>(
            `api/exploreSubActivities/media`,
            formData
          );
        }

        return of(subActivity.mediaFile);
      })
    );
  }

  deleteSubActivity(subActivityId: number): Observable<void> {
    return this.http.delete<void>(`api/exploreSubActivities/${subActivityId}`);
  }

  addSubActivityStep(step: ExploreSubActivityStep): Observable<number> {
    return this.http.post<number>(
      `api/exploreSubActivities/${step.subActivityId}/steps`,
      step
    );
  }

  deleteSubActivityStep(stepId: number): Observable<void> {
    return this.http.delete<void>(`api/exploreSubActivitySteps/${stepId}`);
  }
}
