import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { takeUntil, tap } from "rxjs/operators";
import { DiscoverActivity, DiscoverActivityStep } from "src/app/models";
import { AppStoreState } from "src/app/store";
import { ActivityEditorBaseComponent } from "../activity";
import { DiscoverActivityStepComponent } from "./discover-activity-step";
import { selectDiscoverActivity, selectSteps } from "./store/selectors";
import { addStep, deleteStep, update } from "./store/actions";
import { Observable } from "rxjs";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { ActivityStepperComponent } from "../activity-stepper";

@Component({
  selector: "app-discover-activity",
  templateUrl: "./discover-activity.component.html",
  styleUrls: ["./discover-activity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverActivityComponent
  extends ActivityEditorBaseComponent
  implements OnInit
{
  constructor(
    protected store$: Store<AppStoreState>,
    private cdr: ChangeDetectorRef
  ) {
    super(store$);

    this.activityType = "Discover";
  }

  @HostBinding("class") class = "app-discover-activity";

  @ViewChildren(DiscoverActivityStepComponent) steps =
    new QueryList<DiscoverActivityStepComponent>();

  @ViewChild(ActivityStepperComponent)
  activityStepper: ActivityStepperComponent;

  get valid(): boolean {
    return !!(
      this.title?.valid && this.steps?.map((s) => s).every((s) => s.valid)
    );
  }

  get dirty(): boolean {
    return !!(
      this.title?.dirty || this.steps?.map((s) => s).some((s) => s.dirty)
    );
  }

  set activity(activity: DiscoverActivity) {
    this.myActivity = activity;
    this.cdr.detectChanges();
  }

  get activity(): DiscoverActivity {
    return this.myActivity;
  }

  steps$: Observable<DiscoverActivityStep[]> = this.store$.pipe(
    select(selectSteps),
    tap((steps) => (this.mySteps = steps))
  );

  private myActivity: DiscoverActivity;
  mySteps: DiscoverActivityStep[] = [];

  ngOnInit(): void {
    this.store$
      .pipe(select(selectDiscoverActivity))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((activity) => (this.activity = activity));
  }

  addStep(): void {
    const step: DiscoverActivityStep = {
      activityId: this.activity.id,
      type: "textMedia",
      mediaType: "video",
    };
    this.store$.dispatch(addStep({ step }));
  }

  deleteStep(index: number): void {
    this.store$.dispatch(deleteStep({ step: this.mySteps[index] }));
  }

  getDeleteAction(): Action {
    throw Error("Not implememted");
  }

  drop(event: CdkDragDrop<string[]>) {
    // const items = [...this.mySteps.map((step) => step)]; // Convert to array new array
    const items = [...this.steps.map((s) => s.value)];
    console.log("Item Before: ", items);
    const newArr = this.array_move(
      items,
      event.previousIndex,
      event.currentIndex
    );
    console.log("Items After: ", newArr);
    this.mySteps = newArr;
    this.activityStepper.changeSelectedIndex(event.currentIndex);
    setTimeout(() => {
      this.steps?.forEach((s) => {
        // s.instructions.instructionsControl.markAsDirty();
        s.selectedOptionControl.markAsDirty();
      });
      this.cdr.detectChanges();
    }, 500);
  }
  array_move(arr, old_index, new_index) {
    const oldItemIndex = arr[old_index];
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    if (old_index <= new_index) {
      const newArr = arr.map((item, index) => {
        const newItem = { ...item };
        if (
          Math.min(old_index, new_index) < index &&
          Math.max(old_index, new_index) >= index
        ) {
          newItem.id = arr[index - 1].id;
        } else if (Math.min(old_index, new_index) === index) {
          newItem.id = oldItemIndex.id;
        }
        return newItem;
      });
      return newArr;
    } else {
      const newArr = arr.map((item, index) => {
        const newItem = { ...item };
        if (
          Math.min(old_index, new_index) <= index &&
          Math.max(old_index, new_index) > index
        ) {
          newItem.id = arr[index + 1].id;
        } else if (Math.max(old_index, new_index) === index) {
          newItem.id = oldItemIndex.id;
        }
        return newItem;
      });
      return newArr;
    }
  }

  getUpdateAction(): Action {
    const activity: DiscoverActivity = {
      ...this.myActivity,
      title: this.title.value,
    };

    const steps = this.steps.filter((s) => s.dirty).map((s) => s.value);

    return update({ activity, steps });
  }
}
