import { MediaFile } from ".";
import { ActivityToggles } from "./activity-toggles";
import { MakecodeProject } from "./makecode-project";
import { SubActivityStep } from "./step";
import { SubActivity } from "./sub-activity";
import { ValidationItem } from "./validation-item";

export interface ExploreSubActivity extends SubActivity, ActivityToggles {
  introduction?: string;
  project?: MakecodeProject;
  validationItems?: ValidationItem[];
  steps?: ExploreSubActivityStep[];
  mediaFile?: MediaFile;
}

export interface ExploreSubActivityStep extends SubActivityStep {
  instructions?: string;
  hints?: string;
  senseiHelp?: string;
}
