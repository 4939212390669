import { FormControl } from "@angular/forms";
import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-hints-editor",
  templateUrl: "./hints-editor.component.html",
  styleUrls: ["./hints-editor.component.scss"],
})
export class HintsEditorComponent {
  hintsControl = new FormControl("");

  @HostBinding("class") class = "app-hints-editor";

  @Input() set value(value: string) {
    this.hintsControl.reset();
    this.hintsControl.setValue(value);
  }

  get value(): string {
    return this.hintsControl.value;
  }

  get valid(): boolean {
    return this.hintsControl.valid;
  }

  get dirty(): boolean {
    return this.hintsControl.dirty;
  }
}
