import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { selectSelectedActivity } from "src/app/levels/components/level/store/selectors";
import { ChallengeQuestActivitiesService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";
import { updateLevelStatus } from "src/app/levels/components/level/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private service: ChallengeQuestActivitiesService
  ) {}

  triggerLoadActivity$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      filter((a) => !!a && a.type === "Quest"),
      distinctUntilKeyChanged("id"),
      map(({ id: activityId }) =>
        FeatureActions.loadChallengeQuestActivity({ activityId })
      )
    )
  );

  loadChallengeQuestActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadChallengeQuestActivity),
      switchMap(({ activityId }) =>
        this.service.getChallengeQuestActivityById(activityId).pipe(
          map((activity) =>
            FeatureActions.loadChallengeQuestActivitySuccess({ activity })
          ),
          catchError((error) =>
            of(FeatureActions.loadChallengeQuestActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateChallengeQuestActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateActivity),
      switchMap(({ activity, mediaFile }) =>
        this.service.updateChallengeQuestActivity(activity, mediaFile).pipe(
          map((mediaFile) =>
            FeatureActions.updateActivitySuccess({ activity, mediaFile })
          ),
          catchError((error) =>
            of(FeatureActions.updateActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateActivitySuccess),
      map((_) => {
        return updateLevelStatus({
          status: "draft",
        });
      })
    )
  );
}
