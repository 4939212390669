<app-feature>
  <app-feature-title>Documents</app-feature-title>

  <mat-card class="mat-elevation-z8">
    <mat-card-content>
      <div
        gdAreas="title search form | divider divider form | filters documents form"
        gdColumns="auto 1fr 1fr"
        gdRows="auto auto 1fr"
        gdGap="0 40px">
        <span class="title" gdArea="title">Documents</span>
        <app-search-input
          gdArea="search"
          [search]="search$ | async"
          (searchChange)="onSearchChange($event)"></app-search-input>
        <mat-divider
          class="gimme-fifty-feet"
          gdArea="divider"
          [inset]="true"></mat-divider>
        <div gdArea="filters">
          <app-sort-by-control
            [sortBy]="sortBy$ | async"
            (sortByChange)="onSortByChange($event)"></app-sort-by-control>
          <app-program-display-control
            [filters]="filters$ | async"
            (filtersChange)="
              onFiltersChange($event)
            "></app-program-display-control>
        </div>
        <div gdArea="documents">
          <div *ngIf="isLoading" fxLayout fxFlex fxLayoutAlign="center">
            <mat-spinner></mat-spinner>
          </div>

          <ng-container *ngIf="!isLoading">
            <div fxLayout="column" *ngIf="documentsPage">
              <mat-paginator
                [length]="documentsPage.totalItems"
                [pageIndex]="selectedPageIndex$ | async"
                [pageSize]="selectedPageSize$ | async"
                [pageSizeOptions]="[6, 12, 30, 100]"
                (page)="onPageChange($event)"
                aria-label="Select page">
              </mat-paginator>
              <app-document-list
                [documents]="documentsPage.items"
                [selectedDocument]="selectedDocument"
                (selectedDocumentChange)="
                  onSelectedDocumentChange($event)
                "></app-document-list>
            </div>
          </ng-container>
        </div>
        <app-create-document
          *ngIf="!selectedDocument"
          gdArea="form"></app-create-document>

        <app-edit-document
          *ngIf="selectedDocument"
          gdArea="form"
          [document]="selectedDocument"></app-edit-document>
      </div>
    </mat-card-content>
  </mat-card>
</app-feature>
