import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  Activities,
  Activity,
  ActivityType,
  Course,
  Level,
  LevelStatus,
  PublishableEntities,
} from "src/app/models";

export const loadLevel = createAction(
  "[Level Component] Load level",
  props<{ levelId: number }>()
);
export const loadLevelSuccess = createAction(
  "[Level Component] Load level success",
  props<{ level: Level }>()
);
export const loadLevelFailure = createAction(
  "[Level Component] Load level failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadCourse = createAction(
  "[Level Component] Load course",
  props<{ courseId: number }>()
);
export const loadCourseSuccess = createAction(
  "[Level Component] Load course success",
  props<{ course: Course }>()
);
export const loadCourseFailure = createAction(
  "[Level Component] Load course failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadActivities = createAction(
  "[Level Component] Load activities",
  props<{ levelId: number }>()
);
export const loadActivitiesSuccess = createAction(
  "[Level Component] Load activities success",
  props<{ activities: Activities }>()
);
export const loadActivitiesFailure = createAction(
  "[Level Component] Load activities failure",
  props<{ error: HttpErrorResponse }>()
);

export const changeSelectedActivity = createAction(
  "[Level Component] Change selected activity",
  props<{ activity: Activity }>()
);

export const updateLevel = createAction(
  "[Level Component] Update level",
  props<{ level: Level }>()
);
export const updateLevelSuccess = createAction(
  "[Level Component] Update level success"
);
export const updateLevelFailure = createAction(
  "[Level Component] Update level failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateLevelStatus = createAction(
  "[Level Component] Update level Status",
  props<{ status: LevelStatus }>()
);

export const updateLevelStatusSuccess = createAction(
  "[Level Component] Update level Status success",
  props<{ status: LevelStatus }>()
);
export const updateLevelStatusFailure = createAction(
  "[Level Component] Update level Status failure",
  props<{ error: HttpErrorResponse }>()
);

export const publishLevel = createAction(
  "[Level Component] Publish level",
  props<{ status: LevelStatus; entity: PublishableEntities }>()
);
export const publishLevelSuccess = createAction(
  "[Level Component] Publish level success",
  props<{ status: LevelStatus; entity: PublishableEntities }>()
);
export const publishLevelFailure = createAction(
  "[Level Component] Publish level failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteActivity = createAction(
  "[Level Component] Delete activity",
  props<{ activity: Activity }>()
);
export const deleteActivityConfirm = createAction(
  "[Level Component] Delete activity confirm",
  props<{ activity: Activity }>()
);
export const deleteActivityCancel = createAction(
  "[Level Component] Delete activity cancel"
);
export const deleteActivitySuccess = createAction(
  "[Level Component] Delete activity success"
);
export const deleteActivityFailure = createAction(
  "[Level Component] Delete activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const addActivity = createAction(
  "[Level Component] Add activity",
  props<{ activityType: ActivityType; levelId: number }>()
);
export const addActivitySuccess = createAction(
  "[Level Component] Add activity success"
);
export const addActivityFailure = createAction(
  "[Level Component] Add activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const rearrange = createAction(
  "[Level Component] Rearrange activities",
  props<{ activities: Activities }>()
);
export const rearrangeSuccess = createAction(
  "[Level Component] Rearrange activities success"
);
export const rearrangeFailure = createAction(
  "[Level Component] Rearrange activities failure",
  props<{ error: HttpErrorResponse }>()
);

export const searchTags = createAction(
  "[Level Component] Search tags",
  props<{ keyword: string }>()
);
export const searchTagsSuccess = createAction(
  "[Level Component] Search tags success",
  props<{ tagMatches: string[] }>()
);
export const searchTagsFailure = createAction(
  "[Level Component] Search tags failure",
  props<{ error: HttpErrorResponse }>()
);
export const uploadedVideo = createAction(
  "[Level Component] Upload Video",
  props<{ file: File; forceOverwrite?: boolean }>()
);
export const uploadedVideoFailure = createAction(
  "[Level Component] Upload Video Failure",
  props<{ error: HttpErrorResponse; file: File }>()
);
export const uploadedVideoSuccess = createAction(
  "[Level Component] Upload Video Success",
  props<{ fileUrl: string }>()
);

export const uploadImages = createAction("[Level Component] Upload Images");
export const uploadedImagesFailure = createAction(
  "[Level Component] Upload Images Failure",
  props<{ error: HttpErrorResponse }>()
);
export const uploadedImagesSuccess = createAction(
  "[Level Component] Upload Images Success"
);
export const uploadIcons = createAction("[Level Component] Upload Icons");
export const uploadedIconsFailure = createAction(
  "[Level Component] Upload Icons Failure",
  props<{ error: HttpErrorResponse }>()
);
export const uploadedIconsSuccess = createAction(
  "[Level Component] Upload Icons Success"
);
