import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared";
import { components } from "./components";

@NgModule({
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  declarations: [...components],
  exports: [...components],
})
export class TagsModule {}
