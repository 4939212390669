import { Component, HostBinding, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-instructions-editor",
  templateUrl: "./instructions-editor.component.html",
  styleUrls: ["./instructions-editor.component.scss"],
})
export class InstructionsEditorComponent {
  instructionsControl = new FormControl("", Validators.required);

  @HostBinding("class") class = "app-instructions-editor";

  @Input() set value(value: string) {
    this.instructionsControl.reset();
    this.instructionsControl.setValue(value);
  }

  get value(): string {
    return this.instructionsControl.value;
  }

  get valid(): boolean {
    return this.instructionsControl.valid;
  }

  get dirty(): boolean {
    return this.instructionsControl.dirty;
  }
}
