import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { filter } from "rxjs/operators";
import {
  horizontalStepTransition,
  StepContentPositionState,
} from "src/app/app-animations";
import { YesNoDialogComponent } from "src/app/dialogs";
import { ActivityStepComponent } from "./activity-step.component";
import { CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-activity-stepper",
  templateUrl: "./activity-stepper.component.html",
  styleUrls: ["./activity-stepper.component.scss"],
  animations: [horizontalStepTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStepperComponent {
  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog) {}

  @HostBinding("class") class = "app-activity-stepper";

  @Input() title = "";

  @Input() mode: "multiple" | "single" = "multiple";

  // There is a weird bug that causes
  // the 'current' element to be duplicated.
  // This circumvents the issue.
  disableAnimations = false;

  @ContentChildren(ActivityStepComponent)
  set steps(steps: QueryList<ActivityStepComponent>) {
    this.disableAnimations = true;
    this.mySteps = steps || new QueryList<ActivityStepComponent>();
    this.cdr.detectChanges();
    this.disableAnimations = false;
  }

  get steps(): QueryList<ActivityStepComponent> {
    return this.mySteps;
  }

  @Output() stepAdd = new EventEmitter<void>();

  @Output() stepDelete = new EventEmitter<number>();

  @Output() dropItem = new EventEmitter<any>();

  @HostBinding("class.single-mode")
  get isSingleMode(): boolean {
    return this.mode === "single";
  }

  get isInMultipleMode(): boolean {
    return this.mode === "multiple";
  }

  selectedIndex = 0;

  private mySteps: QueryList<ActivityStepComponent>;

  addStep(): void {
    this.stepAdd.emit();
  }

  deleteStep(index: number): void {
    this.dialog
      .open(YesNoDialogComponent)
      .afterClosed()
      .pipe(filter((confirm) => confirm === true))
      .subscribe(() => {
        this.stepDelete.emit(index);

        if (this.selectedIndex >= this.mySteps.length - 1) {
          this.selectedIndex -= 1;
        }
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.dropItem.emit(event);
  }

  changeSelectedIndex(index: number): void {
    this.selectedIndex = index;

    // Additional fix for aforementioned weird bug
    // that causes the 'current' element to be duplicated.
    // Trigger change detection while animations are disabled
    // and after timeout for the change step animation
    setTimeout(() => {
      this.disableAnimations = true;
      this.cdr.detectChanges();
      this.disableAnimations = false;
    }, 500);
  }

  /** Returns position state of the step with the given index. */
  getAnimationDirection(index: number): StepContentPositionState {
    const position = index - this.selectedIndex;
    if (position < 0) {
      return "previous";
    } else if (position > 0) {
      return "next";
    }

    return "current";
  }
}
