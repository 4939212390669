import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
} from "rxjs/operators";
import { MicrosoftGraphService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";
import { selectUser } from "./selectors";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private service: MicrosoftGraphService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  loadUserRoles$ = createEffect(() =>
    this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ),
      take(1),
      map((result) => result.payload as AuthenticationResult),
      map(({ idTokenClaims }) => idTokenClaims["roles"]),
      map((roles) => FeatureActions.setUserRoles({ roles }))
    )
  );

  triggerLoadUser$ = createEffect(() =>
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => FeatureActions.loadUserProfile())
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadUserProfile),
      switchMap(() =>
        this.service.getProfile().pipe(
          map((user) => FeatureActions.loadUserProfileSuccess({ user })),
          catchError((error) =>
            of(FeatureActions.loadUserProfileFailure({ error }))
          )
        )
      )
    )
  );

  triggerLoadImage$ = createEffect(() =>
    this.store$.pipe(
      select(selectUser),
      filter((user) => !!user),
      distinctUntilKeyChanged("id"),
      map(() => FeatureActions.loadUserImage())
    )
  );

  loadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadUserImage),
      switchMap(() =>
        this.service.getImage().pipe(
          map((image) => FeatureActions.loadUserImageSuccess({ image })),
          catchError((error) => {
            if (error.status !== 404) {
              return of(FeatureActions.loadUserImageFailure({ error }));
            } else {
              return of(FeatureActions.loadUserImageSuccess({ image: null }));
            }
          })
        )
      )
    )
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.logOut),
        map(() => this.msalService.logout())
      ),
    {
      dispatch: false,
    }
  );
}
