import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-activity-content",
  templateUrl: "./activity-content.component.html",
  styleUrls: ["./activity-content.component.scss"],
})
export class ActivityContentComponent {
  @HostBinding("class") class = "app-activity-content";
}
