import { QuizBuilderComponent } from "./quiz-builder";
import { QuestionComponent } from "./question";
import { QuestionHeaderComponent } from "./question-header";
import { FillInTheBlankQuestionComponent } from "./fill-in-the-blank-question";
import { MultiSelectQuestionComponent } from "./multi-select-question";
import { SingleSelectQuestionComponent } from "./single-select-question";
import { AnswerFieldQuestionComponent } from "./answer-field-question";
import { EmojiAnswerQuestionComponent } from "./emoji-answer-question";
import { RandomQuestionQuoteComponent } from "./random-questions-quote";
import { UploadFileComponent } from "./upload-file/upload-file.component";

export const components = [
  QuizBuilderComponent,
  QuestionComponent,
  QuestionHeaderComponent,
  FillInTheBlankQuestionComponent,
  MultiSelectQuestionComponent,
  SingleSelectQuestionComponent,
  AnswerFieldQuestionComponent,
  EmojiAnswerQuestionComponent,
  RandomQuestionQuoteComponent,
  UploadFileComponent,
];

export { QuizBuilderComponent };
