import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { merge, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { YesNoDialogComponent } from "src/app/dialogs/components/yes-no-dialog";
import { selectLevelId } from "src/app/routing/store/selectors";
import {
  ActivitiesService,
  CoursesService,
  LevelsService,
  TagsService,
} from "src/app/services";
import { AppStoreState } from "src/app/store";
import { deleteFailureMessage } from "src/app/user-messages";
import * as FeatureActions from "./actions";
import { selectLevel, selectPublishData } from "./selectors";
import { HttpErrorResponse } from "@angular/common/http";
import { loadLevels } from "src/app/courses/components/edit-course/store/actions";
import { selectSelectedCourse } from "src/app/programs/components/edit-program/store/selectors";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private levelsService: LevelsService,
    private tagsService: TagsService,
    private activitiesService: ActivitiesService,
    private coursesService: CoursesService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  triggerLoadLevel$ = createEffect(() =>
    this.store$.pipe(
      select(selectLevelId),
      filter((id) => !!id),
      map((id) => +id),
      map((levelId) => FeatureActions.loadLevel({ levelId }))
    )
  );

  loadLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadLevel),
      withLatestFrom(
        this.store$.pipe(
          select(selectLevelId),
          filter((id) => !!id),
          map((id) => +id)
        )
      ),
      switchMap(([_, levelId]) =>
        this.levelsService.getLevelById(levelId).pipe(
          switchMap((level) => [
            FeatureActions.loadLevelSuccess({ level }),
            FeatureActions.loadCourse({ courseId: level.courseId }),
          ]),
          catchError((error) => of(FeatureActions.loadLevelFailure({ error })))
        )
      )
    )
  );

  loadCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadCourse),
      switchMap(({ courseId }) =>
        this.coursesService.getCourseById(courseId).pipe(
          map((course) => FeatureActions.loadCourseSuccess({ course })),
          catchError((error) => of(FeatureActions.loadCourseFailure({ error })))
        )
      )
    )
  );

  triggerLoadActivities$ = createEffect(() =>
    merge(
      this.store$.pipe(
        select(selectLevel),
        filter((level) => !!level),
        map(({ id }) => id)
      )
    ).pipe(map((levelId) => FeatureActions.loadActivities({ levelId })))
  );

  loadActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadActivities),
      switchMap(({ levelId }) =>
        this.activitiesService.getActivitiesByLevelId(levelId).pipe(
          map((activities) =>
            FeatureActions.loadActivitiesSuccess({ activities })
          ),
          catchError((error) =>
            of(FeatureActions.loadActivitiesFailure({ error }))
          )
        )
      )
    )
  );

  updateLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateLevel),
      switchMap(({ level }) =>
        this.levelsService.update(level).pipe(
          switchMap(() => [
            FeatureActions.updateLevelSuccess(),
            FeatureActions.loadLevel({ levelId: level.id }),
          ]),
          catchError((error) =>
            of(FeatureActions.updateLevelFailure({ error }))
          )
        )
      )
    )
  );

  updateLevelStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateLevelStatus),
      withLatestFrom(this.store$.select(selectLevel)),
      switchMap(([{ status }, { id }]) =>
        this.levelsService.updateLevelStatus(id, status).pipe(
          map(() => FeatureActions.updateLevelStatusSuccess({ status })),
          catchError((error) =>
            of(FeatureActions.updateLevelStatusFailure({ error }))
          )
        )
      )
    )
  );

  publishLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.publishLevel),
      withLatestFrom(this.store$.select(selectPublishData)),
      switchMap(([{ status, entity }, publishData]) => {
        const data: {
          env: string;
          programid?: number;
          courseid?: number;
          levelid?: number;
        } = {
          env: status === "published" ? "prod" : "stage",
        };

        if (entity === "course") {
          data.courseid = publishData.course.id;
        } else if (entity === "level") {
          data.levelid = publishData.level.id;
        } else if (entity === "program") {
          data.programid = publishData.program.id;
        }

        if (!data.courseid && !data.levelid && !data.programid) {
          return of(
            FeatureActions.publishLevelFailure({
              error: new HttpErrorResponse({
                error: "Data not present",
                status: 500,
              }),
            })
          );
        }

        return this.levelsService.publishData(data).pipe(
          map(() => FeatureActions.publishLevelSuccess({ status, entity })),
          catchError((error) =>
            of(FeatureActions.publishLevelFailure({ error }))
          )
        );
      })
    )
  );

  publishLevelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.publishLevelSuccess),
      withLatestFrom(this.store$.select(selectSelectedCourse)),
      // filter(({ entity }) => entity === 'level'),
      map(([{ status, entity }, course]) => {
        if (entity === "level") {
          return FeatureActions.updateLevelStatusSuccess({ status });
        } else {
          return loadLevels({ courseId: course.id });
        }
      })
    )
  );

  confirmDeleteActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteActivity),
      switchMap(({ activity }) =>
        this.dialog
          .open(YesNoDialogComponent)
          .afterClosed()
          .pipe(
            map((confirm) =>
              !!confirm
                ? FeatureActions.deleteActivityConfirm({ activity })
                : FeatureActions.deleteActivityCancel()
            )
          )
      )
    )
  );

  deleteActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteActivityConfirm),
      switchMap(({ activity }) =>
        this.activitiesService.delete(activity).pipe(
          switchMap(() => [
            FeatureActions.deleteActivitySuccess(),
            FeatureActions.loadActivities({ levelId: activity.levelId }),
          ]),
          catchError((error) =>
            of(FeatureActions.deleteActivityFailure({ error }))
          )
        )
      )
    )
  );

  addActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addActivity),
      switchMap(({ activityType, levelId }) =>
        this.activitiesService.add(activityType, levelId).pipe(
          switchMap(() => [
            FeatureActions.addActivitySuccess(),
            FeatureActions.loadActivities({ levelId }),
          ]),
          catchError((error) =>
            of(FeatureActions.addActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.rearrange),
      withLatestFrom(this.store$.pipe(select(selectLevel))),
      switchMap(([{ activities }, { id: levelId }]) =>
        this.activitiesService.update(levelId, activities).pipe(
          map(() => FeatureActions.rearrangeSuccess()),
          catchError((error) => of(FeatureActions.rearrangeFailure({ error })))
        )
      )
    )
  );

  seachTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.searchTags),
      switchMap(({ keyword }) =>
        this.tagsService.searchTags(keyword).pipe(
          map((tagMatches) => FeatureActions.searchTagsSuccess({ tagMatches })),
          catchError((error) => of(FeatureActions.searchTagsFailure({ error })))
        )
      )
    )
  );

  displayDeleteFailureSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.deleteActivityFailure),
        tap(() => this.displayMessage(deleteFailureMessage))
      ),
    {
      dispatch: false,
    }
  );

  private displayMessage(message: string): void {
    this.snackbar.open(message, "OK", { duration: 5000 });
  }

  uploadVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.uploadedVideo),
      switchMap(({ file, forceOverwrite }) => {
        const formData = new FormData();
        formData.append("file", file);
        return this.activitiesService
          .uploadeVideo(formData, forceOverwrite)
          .pipe(
            map((responseData) => {
              return FeatureActions.uploadedVideoSuccess({
                fileUrl: responseData,
              });
            }),
            catchError((error) =>
              of(FeatureActions.uploadedVideoFailure({ error, file }))
            )
          );
      })
    )
  );

  uploadImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.uploadImages),
      switchMap(({}) => {
        return this.activitiesService.uploadImages().pipe(
          map((responseData) => {
            return FeatureActions.uploadedImagesSuccess();
          }),
          catchError((error) =>
            of(FeatureActions.uploadedImagesFailure({ error }))
          )
        );
      })
    )
  );

  uploadIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.uploadIcons),
      switchMap(({}) => {
        return this.activitiesService.uploadIcons().pipe(
          map((responseData) => {
            return FeatureActions.uploadedIconsSuccess();
          }),
          catchError((error) =>
            of(FeatureActions.uploadedIconsFailure({ error }))
          )
        );
      })
    )
  );

  uploadVideoFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.uploadedVideoFailure),
      map(({ error, file }) => {
        if (error.error === "BlobAlreadyExists") {
          let text = "File already exist! would you like to overrite it?";
          if (confirm(text)) {
            return FeatureActions.uploadedVideo({
              file: file,
              forceOverwrite: true,
            });
          }
        }
      })
    )
  );

  uploadedIconsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.uploadedIconsSuccess),
        tap(() => this.displayMessage("Icons Uploaded Successfully"))
      ),
    {
      dispatch: false,
    }
  );

  uploadedImagesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.uploadedImagesSuccess),
        tap(() => this.displayMessage("Images Uploaded Successfully"))
      ),
    {
      dispatch: false,
    }
  );
}
