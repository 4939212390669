import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { components } from "./components";
import { HomeRoutingModule } from "./routing";

@NgModule({
  imports: [CommonModule, HomeRoutingModule],
  declarations: [...components],
  exports: [...components],
})
export class HomeModule {}
