import { Component, HostBinding, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivityToggles } from "src/app/models";
import { validToggleValue } from "../../validators";

@Component({
  selector: "app-activity-toggles",
  templateUrl: "./activity-toggles.component.html",
  styleUrls: ["./activity-toggles.component.scss"],
})
export class ActivityTogglesComponent {
  @HostBinding("class") class = "app-activity-toggles";

  @Input() set activity(activity: ActivityToggles) {
    this.formGroup.reset();
    this.formGroup.patchValue({
      hasLowThreshold: !!activity.hasLowThreshold,
      lowThreshold: activity.lowThreshold,
      hasPassingThreshold: !!activity.hasPassingThreshold,
      passingThreshold: activity.passingThreshold,
      peerReview: !!activity.peerReview,
      numOfReviews: activity.numOfReviews,
    });
  }

  formGroup = new FormGroup(
    {
      hasLowThreshold: new FormControl(false, Validators.required),
      lowThreshold: new FormControl(0, [Validators.pattern(/^[0-9]\d*$/)]),
      hasPassingThreshold: new FormControl(false, Validators.required),
      passingThreshold: new FormControl(0, [Validators.pattern(/^[0-9]\d*$/)]),
      peerReview: new FormControl(false, Validators.required),
      numOfReviews: new FormControl(1, [Validators.pattern(/^[0-9]\d*$/)]),
    },
    [
      validToggleValue([
        { toggleName: "hasLowThreshold", valueName: "lowThreshold" },
        { toggleName: "hasPassingThreshold", valueName: "passingThreshold" },
        { toggleName: "peerReview", valueName: "numOfReviews" },
      ]),
    ]
  );

  get hasLowThreshold(): boolean {
    return this.formGroup.get("hasLowThreshold").value;
  }

  get lowThreshold(): number {
    return this.formGroup.get("lowThreshold").value;
  }

  get hasPassingThreshold(): boolean {
    return this.formGroup.get("hasPassingThreshold").value;
  }

  get passingThreshold(): number {
    return this.formGroup.get("passingThreshold").value;
  }

  get peerReview(): boolean {
    return this.formGroup.get("peerReview").value;
  }

  get numOfReviews(): number {
    return this.formGroup.get("numOfReviews").value;
  }

  get valid(): boolean {
    return !!this.formGroup.valid;
  }

  get dirty(): boolean {
    return !!this.formGroup.dirty;
  }
}
