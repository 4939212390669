<div fxLayout="column" fxLayoutGap="4px">
  <div
    fxLayout
    fxLayoutAlign="center center"
    fxLayoutGap="4px"
    *ngFor="let item of _items; index as i">
    <app-validation-list-item
      class="mat-elevation-z4"
      [item]="item"
      (delete)="deleteItemAt(i)"></app-validation-list-item>

    <div class="action-box">
      <button mat-icon-button color="primary" (click)="add()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
  </div>
</div>
