import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import {
  IntroductionEditorComponent,
  MediaEditorComponent,
  NinjaRequirementsBuilderComponent,
} from "src/app/editors";
import { ChallengeQuestActivity } from "src/app/models";
import { AppStoreState } from "src/app/store";
import { ActivityEditorBaseComponent } from "../activity";
import { ActivityTogglesComponent } from "../activity-toggles";
import { CodingStepComponent } from "../coding-step";
import { SenseiStepComponent } from "../sensei-step";
import { updateActivity } from "./store/actions";
import { selectChallengeQuestActivity } from "./store/selectors";
import { EditInMakecodeComponent } from "../edit-in-makecode";

@Component({
  selector: "app-challenge-quest-activity",
  templateUrl: "./challenge-quest-activity.component.html",
  styleUrls: ["./challenge-quest-activity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChallengeQuestActivityComponent
  extends ActivityEditorBaseComponent
  implements OnInit
{
  constructor(
    protected store$: Store<AppStoreState>,
    private cdr: ChangeDetectorRef
  ) {
    super(store$);
    this.activityType = "Quest";
  }

  @HostBinding("class") class = "app-challenge-quest-activity";

  @ViewChild(IntroductionEditorComponent)
  introduction: IntroductionEditorComponent;

  @ViewChild(ActivityTogglesComponent)
  activityToggles: ActivityTogglesComponent;

  @ViewChild(NinjaRequirementsBuilderComponent)
  ninjaRequirementsBuilder: NinjaRequirementsBuilderComponent;

  @ViewChild(CodingStepComponent)
  codingStep: CodingStepComponent;

  @ViewChild(SenseiStepComponent)
  senseiStep: SenseiStepComponent;

  @ViewChild("exampleGame")
  exampleGame: EditInMakecodeComponent;

  @ViewChild("codeEditor")
  starterCode: EditInMakecodeComponent;

  @ViewChild(MediaEditorComponent)
  mediaEditor: MediaEditorComponent;

  get valid(): boolean {
    return !!(
      this.title?.valid &&
      this.introduction?.valid &&
      this.activityToggles?.valid &&
      this.ninjaRequirementsBuilder?.valid &&
      this.codingStep?.valid &&
      this.senseiStep?.valid &&
      this.exampleGame?.valid &&
      this.starterCode?.valid &&
      this.mediaEditor?.valid
    );
  }

  get dirty(): boolean {
    return !!(
      this.title?.dirty ||
      this.introduction?.dirty ||
      this.activityToggles?.dirty ||
      this.ninjaRequirementsBuilder?.dirty ||
      this.codingStep?.dirty ||
      this.senseiStep?.dirty ||
      this.exampleGame?.dirty ||
      this.starterCode?.dirty ||
      this.mediaEditor?.dirty
    );
  }

  set activity(activity: ChallengeQuestActivity) {
    this.myActivity = activity;
    this.cdr.detectChanges();
  }

  get activity(): ChallengeQuestActivity {
    return this.myActivity;
  }

  private myActivity: ChallengeQuestActivity;

  ngOnInit(): void {
    this.store$
      .pipe(select(selectChallengeQuestActivity))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((activity) => (this.activity = activity));
  }

  getDeleteAction(): Action {
    throw Error("Not implememted");
  }

  getUpdateAction(): Action {
    return updateActivity({
      activity: {
        ...this.myActivity,
        title: this.title.value,
        introduction: this.introduction.value,
        ninjaRequirements: this.ninjaRequirementsBuilder.requirements,
        hasLowThreshold: this.activityToggles.hasLowThreshold,
        lowThreshold: this.activityToggles.lowThreshold,
        hasPassingThreshold: this.activityToggles.hasPassingThreshold,
        passingThreshold: this.activityToggles.passingThreshold,
        peerReview: this.activityToggles.peerReview,
        numOfReviews: this.activityToggles.numOfReviews,
        codingStep: this.codingStep.value,
        senseiStep: this.senseiStep.value,
        project: this.exampleGame?.project,
        starterCode: this.starterCode?.project,
      },
      mediaFile: this.mediaEditor.mediaValue,
    });
  }
}
