import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActivityType } from "src/app/models";
import { featureKey, State } from "./state";

const selectState = createFeatureSelector<State>(featureKey);

export const selectIconByActivityType = (activityType: ActivityType) =>
  createSelector(selectState, (state) => {
    return state.activityTypeIcons[activityType];
  });
