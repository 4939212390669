import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";

export const slideInOut = trigger("slideInOut", [
  transition(":enter", [
    style({ transform: "translateX(-100%)" }),
    animate("300ms ease-in", style({ transform: "translateX(0%)" })),
  ]),
  transition(":leave", [
    animate("300ms ease-in", style({ transform: "translateX(-100%)" })),
  ]),
]);

export const detailsExpansion = trigger("bodyExpansion", [
  state(
    "collapsed, void",
    style({ overflow: "hidden", height: "0px", visibility: "hidden" })
  ),
  state(
    "expanded",
    style({ overflow: "hidden", height: "*", visibility: "visible" })
  ),
  transition(
    "expanded <=> collapsed, void => collapsed",
    animate("300ms cubic-bezier(0.4,0.0,0.2,1)")
  ),
]);

export const horizontalStepTransition = trigger("stepTransition", [
  state(
    "previous",
    style({ transform: "translate3d(-100%, 0, 0)", visibility: "hidden" })
  ),
  state("current", style({ transform: "none", visibility: "inherit" })),
  state(
    "next",
    style({ transform: "translate3d(100%, 0, 0)", visibility: "hidden" })
  ),
  transition("* => *", animate("500ms cubic-bezier(0.35, 0, 0.25, 1)")),
]);

export type StepContentPositionState = "previous" | "current" | "next";
