<mat-form-field>
  <mat-label>Activity Title</mat-label>
  <input matInput [formControl]="titleControl" />
</mat-form-field>

<div fxLayout fxLayoutAlign="center center">
  <button *ngIf="isSubActivity" mat-icon-button (click)="deleteActivity()">
    <mat-icon>delete</mat-icon>
  </button>
  <button mat-icon-button (click)="toggleDetails()">
    <mat-icon *ngIf="!collapsed">close_fullscreen</mat-icon>
    <mat-icon *ngIf="collapsed">open_in_full</mat-icon>
  </button>
</div>
