<div class="account-container" fxLayout="column" fxLayoutAlign="center start">
  <div *ngFor="let image of [image$ | async]">
    <mat-icon
      *ngIf="!image"
      class="account-icon"
      aria-hidden="false"
      aria-label="User icon">
      account_circle
    </mat-icon>
    <img
      *ngIf="image"
      class="profile-image"
      [src]="image"
      alt="User profile image" />
  </div>
  <div class="logged-in-as">logged in as</div>
  <div *ngIf="profile$ | async as profile; else loading" class="user-name">
    {{ profile.displayName }}
  </div>
  <ng-template #loading><span>Loading...</span></ng-template>
</div>

<div fxLayout="column" fxLayoutGap="1rem">
  <button mat-stroked-button>
    <mat-icon class="button-icon">settings</mat-icon>
    <span>Settings</span>
  </button>

  <button mat-stroked-button (click)="logOut()">
    <mat-icon class="button-icon">logout</mat-icon>
    <span>Log Out</span>
  </button>
</div>
