import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { sortById } from "src/app/entity-adapter";
import {
  AppState,
  ExploreActivity,
  ExploreSubActivity,
  ExploreSubActivityStep,
} from "src/app/models";

export const featureKey = "explore-activity";

export interface ExploreSubActivitiesState
  extends EntityState<ExploreSubActivity> {}

export interface ExploreSubActivityStepsState
  extends EntityState<ExploreSubActivityStep> {}

export interface State extends AppState {
  activity: ExploreActivity;
  subActivities: ExploreSubActivitiesState;
  steps: ExploreSubActivityStepsState;
}

export const subActivitiesAdapter = createEntityAdapter<ExploreSubActivity>({
  sortComparer: sortById,
});

export const stepsAdapter = createEntityAdapter<ExploreSubActivityStep>({
  sortComparer: sortById,
});

export const initialState: State = {
  activity: null,
  subActivities: subActivitiesAdapter.getInitialState(),
  steps: stepsAdapter.getInitialState(),
  isLoading: false,
  error: "",
};
