import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { CodeAdventurePlanningStep } from "src/app/models";

@Component({
  selector: "app-code-adventure-planning-step",
  templateUrl: "./code-adventure-planning-step.component.html",
  styleUrls: ["./code-adventure-planning-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeAdventurePlanningStepComponent {
  @HostBinding("class") class = "app-code-adventure-planning-step";

  @Input() set step(step: CodeAdventurePlanningStep) {
    this.myStep = step;
    this.textControl.reset();
    this.textControl.setValue(step?.text);
  }

  get value(): CodeAdventurePlanningStep {
    return {
      ...this.myStep,
      text: this.textControl.value,
    };
  }

  get valid(): boolean {
    return this.textControl.valid;
  }

  get dirty(): boolean {
    return this.textControl.dirty;
  }

  textControl = new FormControl("");

  private myStep: CodeAdventurePlanningStep;
}
