export interface Block {
  name: string;
  displayName?: string;
  active?: boolean;
  dependency?: string; // dependency on extension
}

export interface BlockNamespace {
  name: string;
  displayName?: string;
  blocks: Block[];
  active: boolean;
  dependency?: string; // dependency on an extension
  disable?: boolean; //disables namespace for make code editor, namespace filter is also removed
}

export type OptionToggles = {
  [key: string]: boolean;
};

export interface FilterDefinition {
  namespaces: OptionToggles;
  blocks: OptionToggles;
}

export function optimizeNamespaceDefintion(
  namespaces: BlockNamespace[]
): BlockNamespace[] {
  return namespaces
    .filter((namespace) => !namespace.active)
    .map((namespace) => ({
      ...namespace,
      ...{
        blocks: namespace.blocks.filter(
          (block) => block.active !== namespace.active
        ),
      },
    }));
}

export function generateFilterDefinition(
  filters: BlockNamespace[]
): FilterDefinition {
  if (!filters) {
    return null;
  }

  const namespaces = generateNamespaceObject(filters);
  const blocks = generateBlocksObject(filters);

  return {
    namespaces,
    blocks,
  };
}

function generateNamespaceObject(
  blockNamespaces: BlockNamespace[]
): OptionToggles {
  const namespacesArray = blockNamespaces.map((namespace) => ({
    [namespace.name]: namespace.active,
  }));

  return Object.assign({}, ...namespacesArray);
}

function generateBlocksObject(
  blockNamespaces: BlockNamespace[]
): OptionToggles {
  const blocksArrays = blockNamespaces.map((namespace) =>
    namespace.blocks
      .filter((block) => namespace.active !== block.active)
      .map((block) => ({
        [block.name]: !!block.active,
      }))
  );

  let defaultBlocks = {};
  const variableNSEnabled = !blockNamespaces.find(
    (namespace) => namespace.name === "variables"
  );

  const functionNSEnabled = !blockNamespaces.find(
    (namespace) => namespace.name === "functions"
  );
  // filtering with variable namespace does not work so add the variables blocks if variable namespace is enabled
  if (variableNSEnabled) {
    VARIABLES.blocks.forEach((block) => {
      defaultBlocks = {
        ...defaultBlocks,
        [block.name]: true,
      };
    });
  }

  if (functionNSEnabled) {
    FUNCTIONS.blocks.forEach((block) => {
      defaultBlocks = {
        ...defaultBlocks,
        [block.name]: true,
      };
    });
  }

  const flattenedBlocks = [].concat.apply([], blocksArrays);

  return Object.assign({ ...defaultBlocks }, ...flattenedBlocks);
}

const SPRITES: BlockNamespace = {
  name: "sprites",
  displayName: "Sprites",
  active: true,
  blocks: [
    {
      name: "spritescreate",
      displayName: "Set sprite to",
    },
    {
      name: "spritescreatenoset",
      displayName: "Update sprite image and kind",
    },

    {
      name: "spritesetvel",
      displayName: "Set sprite velocity",
    },
    {
      name: "spritesetpos",
      displayName: "Set sprite position",
    },
    {
      name: "Sprite_blockCombine_set",
      displayName: "Set sprite x",
    },
    {
      name: "Sprite_blockCombine_change",
      displayName: "Change sprite x",
    },
    {
      name: "Sprite_blockCombine_get",
      displayName: "Sprite x",
    },
    {
      name: "spriteFollowOtherSprite",
      displayName: "Set sprite follow",
    },

    {
      name: "startEffectOnSprite",
      displayName: "Start sprite effect",
    },
    {
      name: "particlesclearparticles",
      displayName: "Stop sprite effect",
    },
    {
      name: "spritedestroy2",
      displayName: "Destroy sprite",
    },
    {
      name: "sprites_destroy_all_sprites_of_kind",
      displayName: "Destroy all sprites of kind",
    },
    {
      name: "spritesaytext",
      displayName: "Sprite say text",
    },
    {
      name: "spritesetsetstayinscreen",
      displayName: "Set sprite stay in screen",
    },
    {
      name: "spritesetsetbounceonwall",
      displayName: "Set sprite bounce on walls",
    },
    {
      name: "spritesetsetflag",
      displayName: "Set sprite flag",
    },

    {
      name: "spritescreateprojectilefromsprite",
      displayName: "Create projectile from sprite",
    },
    {
      name: "spritescreateprojectilefromside",
      displayName: "Create projectile from side",
    },

    {
      name: "spritesoverlap",
      displayName: "On sprite overlaps sprite",
    },
    {
      name: "spriteoverlapswith",
      displayName: "Sprite overlaps with sprite",
    },
    {
      name: "spritesetkind",
      displayName: "Set sprite kind",
    },
    {
      name: "spritegetkind",
      displayName: "Get sprite kind",
    },

    {
      name: "spritesoncreated",
      displayName: "On created",
    },
    {
      name: "spritesondestroyed",
      displayName: "On destroyed",
    },
    {
      name: "allOfKind",
      displayName: "Array of sprites of kind",
    },
    {
      name: "spritekind",
      displayName: "Sprite kind",
    },

    {
      name: "spriteimage",
      displayName: "Get sprite image",
    },
    {
      name: "spritesetimage",
      displayName: "Set sprite image",
    },

    {
      name: "sprite_change_scale",
      displayName: "Change sprite scale",
    },
    {
      name: "sprite_set_scale",
      displayName: "Set sprite scale",
    },
  ],
};

const CONTROLLER: BlockNamespace = {
  name: "controller",
  displayName: "Controller",
  active: true,
  blocks: [
    {
      name: "game_control_sprite",
      displayName: "Move sprite with buttons",
    },
    {
      name: "keyonevent",
      displayName: "On button pressed",
    },
    {
      name: "keyispressed",
      displayName: "If button is pressed",
    },
    {
      name: "keydx",
      displayName: "Left right buttons",
    },
    {
      name: "keydy",
      displayName: "Up down buttons",
    },
    {
      name: "repeatDefaultDelayInterval",
      dependency: "controller",
      displayName: "Set button repeat delay",
    },

    {
      name: "ctrlgame_control_sprite",
      displayName: "Move player 2 with buttons",
    },
    {
      name: "ctrlonevent",
      displayName: "On player 2 button connected",
    },
    {
      name: "ctrlonbuttonevent",
      displayName: "On player 2 button pressed",
    },
    {
      name: "ctrlispressed",
      displayName: "If player 2 button pressed",
    },
    {
      name: "ctrldx",
      displayName: "player 2 left right buttons ",
    },
    {
      name: "ctrldy",
      displayName: "player 2 up down buttons",
    },

    {
      name: "ctrlonlightcondition",
      dependency: "controller",
      displayName: "Run code on light condition",
    },
    {
      name: "ctrllightpulse",
      dependency: "controller",
      displayName: "Start light pulse for ms",
    },
    {
      name: "ctrlaccelerationvalue",
      dependency: "controller",
      displayName: "Get acceleration",
    },
    {
      name: "ctrlongesture",
      dependency: "controller",
      displayName: "On gesture",
    },
    { name: "ctrlvibrate", dependency: "controller", displayName: "Vibrate" },
    {
      name: "controller_show_animation",
      dependency: "controller",
      displayName: "Start light animation",
    },
    {
      name: "ctrllightlevel",
      dependency: "controller",
      displayName: "Get light level",
    },
    {
      name: "controller_crank_position",
      dependency: "controller",
      displayName: "Get crank position",
    },
    {
      name: "controller_crank_setpins",
      dependency: "controller",
      displayName: "Set crank pinA and pinB",
    },
    {
      name: "ctrltemperature",
      dependency: "controller",
      displayName: "Get temperature",
    },
  ],
};

const GAME: BlockNamespace = {
  name: "game",
  displayName: "Game",
  active: true,
  blocks: [
    {
      name: "gameupdate",
      displayName: "On game update",
    },
    {
      name: "gameinterval",
      displayName: "On game update every ms",
    },
    {
      name: "arcade_game_runtime",
      displayName: "Get game run time",
    },
    {
      name: "arcade_game_reset",
      displayName: "Reset game",
    },

    {
      name: "gameOver2",
      displayName: "Game over",
    },
    {
      name: "game_setgameovereffect",
      displayName: "Set game over effect",
    },
    {
      name: "game_setgameoverplayable",
      displayName: "Set game over sound",
    },
    {
      name: "game_setgameovermessage",
      displayName: "Set game over message",
    },
    {
      name: "game_setgameoverscoringtype",
      displayName: "Set best score",
    },

    {
      name: "gameSplash",
      displayName: "Splash",
    },
    {
      name: "gameask",
      displayName: "Ask",
    },
    {
      name: "gameaskfornumber",
      displayName: "Ask for number",
    },
    {
      name: "gameaskforstring",
      displayName: "Ask for string",
    },

    {
      name: "game_dialog_set_text_color",
      displayName: "Set dialog text color",
    },
    {
      name: "game_dialog_set_cursor",
      displayName: "Set dialog cursor",
    },
    {
      name: "game_dialog_set_frame",
      displayName: "Set dialog frame",
    },
    {
      name: "game_show_long_text",
      displayName: "Show long text",
    },
  ],
};

const MUSIC: BlockNamespace = {
  name: "music",
  displayName: "Music",
  active: true,
  blocks: [
    {
      name: "music_string_playable",
      displayName: "Play melody",
    },
    {
      name: "music_song_field_editor",
      displayName: "Play custom song",
    },

    {
      name: "music_playable_play",
      displayName: "Play sound",
    },
    {
      name: "music_stop_all_sounds",
      displayName: "Stop all sounds",
    },
    {
      name: "soundExpression_createSoundEffect",
      displayName: "Play custom sound",
    },
    {
      name: "soundExpression_generateSimilarSound",
      displayName: "Custom sound",
    },

    {
      name: "music_ring",
      displayName: "Ring tone at note",
    },
    {
      name: "music_rest",
      displayName: "Rest for",
    },
    {
      name: "music_tone_playable",
      displayName: "Play tone",
    },
    {
      name: "device_note",
      displayName: "Note",
    },

    {
      name: "synth_set_volume",
      displayName: "Set volume",
    },

    {
      name: "device_change_tempo",
      displayName: "Change tempo by",
    },
    {
      name: "device_set_tempo",
      displayName: "Set tempo to",
    },
    {
      name: "device_tempo",
      displayName: "Tempo",
    },
    {
      name: "device_beat",
      displayName: "Beat",
    },
  ],
};

const SCENE: BlockNamespace = {
  name: "scene",
  displayName: "Scene",
  active: true,
  blocks: [
    {
      name: "scenescreenwidth",
      displayName: "Get screen width",
    },
    {
      name: "scenescreenheight",
      displayName: "Get screen height",
    },
    {
      name: "gamesetbackgroundcolor",
      displayName: "Set background color",
    },
    {
      name: "gamesetbackgroundimage",
      displayName: "Set background image",
    },
    {
      name: "gamebackgroundimage",
      displayName: "Get background image",
    },
    {
      name: "gamebackgroundcolor",
      displayName: "Get background color",
    },

    {
      name: "camerafollow",
      displayName: "Camera follow",
    },
    {
      name: "camerashake",
      displayName: "Camera shake",
    },
    {
      name: "camerapos",
      displayName: "Center camera at position",
    },
    {
      name: "cameraproperty",
      displayName: "Get camera x",
    },

    {
      name: "particlesStartScreenAnimation",
      displayName: "Start screen effect",
    },
    {
      name: "particlesEndScreenAnimation",
      displayName: "End screen effect",
    },

    {
      name: "set_current_tilemap",
      displayName: "Set tilemap to",
    },
    {
      name: "tiles_tilemap_editor",
      displayName: "Get tilemap",
    },
    {
      name: "spriteshittile",
      displayName: "On sprite overlaps tile",
    },
    {
      name: "spriteshitwall",
      displayName: "On sprite hits wall",
    },
    {
      name: "mapplaceontile",
      displayName: "Place sprite on top of tile position",
    },
    {
      name: "mapplaceonrandomtile",
      displayName: "Place sprite on top of random tile",
    },

    {
      name: "mapsettileat",
      displayName: "Set tile at",
    },
    {
      name: "mapsetwallat",
      displayName: "Set wall at",
    },

    {
      name: "mapgettile",
      displayName: "Get tile position",
    },
    {
      name: "tiles_location_of_sprite",
      displayName: "Tilemap location of sprite",
    },
    {
      name: "tiles.Location_blockCombine_get",
      displayName: "Location column",
    },
    {
      name: "maplocationistile",
      displayName: "Tile at position is tile",
    },
    {
      name: "tiles_tile_at_location_is_wall",
      displayName: "Tile at position is wall",
    },
    {
      name: "spritetileat",
      displayName: "Tile to direction of sprite is tile",
    },
    {
      name: "spritehasobstacle",
      displayName: "Is sprite hitting wall in direction",
    },
    {
      name: "mapgettilestype",
      displayName: "Array of all tile locations",
    },
    {
      name: "tiles_location_get_neighboring_location",
      displayName: "Tilemap location direction of sprite",
    },
    {
      name: "tiles_image_at_location",
      displayName: "Tile image at location",
    },

    {
      name: "tiles_image_at_location",
      displayName: "Tile image at location",
    },
    {
      name: "tiles_image_at_location",
      displayName: "Tile image at location",
    },
  ],
};

const INFO: BlockNamespace = {
  name: "info",
  displayName: "Info",
  active: true,
  blocks: [
    {
      name: "hudScore",
      displayName: "Get score",
    },
    {
      name: "highScore",
      displayName: "Get high score",
    },
    {
      name: "hudsetScore",
      displayName: "Set score to",
    },
    {
      name: "hudChangeScoreBy",
      displayName: "Change score by",
    },
    {
      name: "gameonscore",
      displayName: "On score equals number",
    },

    {
      name: "hudLife",
      displayName: "Get life",
    },
    {
      name: "hudSetLife",
      displayName: "Set life to",
    },
    {
      name: "hudChangeLifeBy",
      displayName: "Change life by",
    },
    {
      name: "gamelifeevent",
      displayName: "On life zero",
    },

    {
      name: "gamegetcountdown",
      displayName: "Get countdown timer",
    },
    {
      name: "gamecountdown",
      displayName: "Start countdown timer",
    },
    {
      name: "gamechangecountdown",
      displayName: "Change countdown by",
    },
    {
      name: "gamestopcountdown",
      displayName: "Stop countdown",
    },
    {
      name: "gamecountdownevent",
      displayName: "On countdown end",
    },

    {
      name: "playerinfoonscore",
      displayName: "On player 2 score equals number",
    },
    {
      name: "playerinfoonlifezero",
      displayName: "On player 2 life zero",
    },
    {
      name: "pihaslife",
      displayName: "If player 2 has life value",
    },
    {
      name: "pichangelife",
      displayName: "Change player 2 life by",
    },
    {
      name: "pisetlife",
      displayName: "Set player 2 life to",
    },
    {
      name: "info_PlayerInfo_life",
      displayName: "Get player 2 life value",
    },
    {
      name: "pichangescore",
      displayName: "Change player 2 score by",
    },
    {
      name: "pisetscore",
      displayName: "Set player 2 score to",
    },
    {
      name: "piscore",
      displayName: "Get player 2 score value",
    },
  ],
};

const ANIMATION: BlockNamespace = {
  name: "animation",
  displayName: "Animation",
  active: true,
  blocks: [
    {
      name: "run_image_animation",
      displayName: "Run image animation",
    },
    {
      name: "run_movement_animation",
      displayName: "Run movement animation",
    },
    {
      name: "stop_animations",
      displayName: "Stop animation",
    },
    {
      name: "animation_editor",
      displayName: "Get image animation",
    },

    {
      name: "createAnimation",
      displayName: "Set animation to",
    },
    {
      name: "addAnimationFrame",
      displayName: "Add frame to animation",
    },
    {
      name: "attachAnimation",
      displayName: "Attach animation to sprite",
    },
    {
      name: "setAction",
      displayName: "Activate animation on sprite",
    },
    {
      name: "action_enum_shim",
      displayName: "Get animation type",
    },
  ],
};

const IMAGES: BlockNamespace = {
  name: "images",
  displayName: "Images",
  active: true,
  blocks: [
    {
      name: "Image_fill",
      displayName: "Fill picture with color",
    },
    {
      name: "Image_getPixel",
      displayName: "Get color at pixel position",
    },
    {
      name: "Image_setPixel",
      displayName: "Set pixel color at pixel position",
    },
    {
      name: "Image_drawRect",
      displayName: "Draw rectangle at position",
    },
    {
      name: "Image_drawLine",
      displayName: "Draw line at position",
    },
    {
      name: "Image_fillRect",
      displayName: "Fill rectangle at position",
    },

    {
      name: "screen_image_picker",
      displayName: "Get custom image",
    },
    {
      name: "image_create",
      displayName: "Create image",
    },
    {
      name: "Image_clone",
      displayName: "Clone image",
    },
    {
      name: "imagescreen",
      displayName: "Get screen image",
    },
    {
      name: "image_picker",
      displayName: "Get gallery image",
    },

    {
      name: "Image_replace",
      displayName: "Change color in picture",
    },
    {
      name: "Image_flipY",
      displayName: "Flip picture vertically",
    },
    {
      name: "Image_flipX",
      displayName: "Flip picture horizontally",
    },
    {
      name: "Image_equals",
      displayName: "If picture is equal to image",
    },
  ],
};

const LOOPS: BlockNamespace = {
  name: "loops",
  displayName: "Loops",
  active: true,
  blocks: [
    {
      name: "forever",
      displayName: "Forever",
    },
    {
      name: "device_pause",
      displayName: "Pause",
    },
    {
      name: "controls_repeat_ext",
      displayName: "Repeat",
    },
    {
      name: "device_while",
      displayName: "While",
    },
    {
      name: "pxt_controls_for",
      displayName: "For index",
    },
    {
      name: "pxt_controls_for_of",
      displayName: "For element of",
    },
    {
      name: "break_keyword",
      displayName: "Break",
    },
    {
      name: "continue_keyword",
      displayName: "Continue",
    },
    {
      name: "pxt-on-start",
      displayName: "On start",
    },
    {
      name: "pxt_pause_until",
      displayName: "Pause until true",
    },
  ],
};

const MATH: BlockNamespace = {
  name: "Math",
  displayName: "Math",
  active: true,
  blocks: [
    {
      name: "math_arithmetic",
      displayName: "Math operations",
    },
    {
      name: "math_number",
      displayName: "Get number",
    },
    {
      name: "math_modulo",
      displayName: "Remainder of",
    },
    {
      name: "math_op2",
      displayName: "Minimum/Maximum of",
    },
    {
      name: "math_op3",
      displayName: "Absolute of",
    },
    {
      name: "math_js_op",
      displayName: "Square root of",
    },
    {
      name: "math_js_round",
      displayName: "Round",
    },
    {
      name: "device_random",
      displayName: "Pick random",
    },
    {
      name: "math_constrain_value",
      displayName: "Constrain values",
    },
    {
      name: "math_map",
      displayName: "Map values from low to high",
    },
    {
      name: "percentchance",
      displayName: "Percent chance",
    },
  ],
};

const LOGIC: BlockNamespace = {
  name: "logic",
  displayName: "Logic",
  active: true,
  blocks: [
    {
      name: "controls_if",
      displayName: "If/else",
    },
    {
      name: "logic_compare",
      displayName: "Relational operators",
    },
    {
      name: "logic_operation",
      displayName: "Boolean operators",
    },
    {
      name: "logic_negate",
      displayName: "Inequality operator",
    },
    {
      name: "logic_boolean",
      displayName: "Booleans",
    },
  ],
};

const ARRAY: BlockNamespace = {
  name: "arrays",
  displayName: "Arrays",
  active: true,
  blocks: [
    {
      name: "lists_create_with",
      displayName: "Set list to",
    },

    {
      name: "lists_length",
      displayName: "Length of array",
    },
    {
      name: "lists_index_get",
      displayName: "Get value at index",
    },
    {
      name: "array_removeat",
      displayName: "Get and remove value at index",
    },
    {
      name: "array_pop",
      displayName: "Get and remove last value from list",
    },
    {
      name: "array_shift",
      displayName: "Get and remove first value from list",
    },
    {
      name: "array_pickRandom",
      displayName: "Get random value from list",
    },

    {
      name: "lists_index_set",
      displayName: "Set value at index",
    },
    {
      name: "array_push",
      displayName: "Add value to end",
    },
    {
      name: "array_pop_statement",
      displayName: "Remove last value from list",
    },
    {
      name: "array_shift_statement",
      displayName: "Remove first value from list",
    },
    {
      name: "array_unshift",
      displayName: "Get and insert value at beginning of list",
    },
    {
      name: "array_unshift_statement",
      displayName: "Insert value at beginning of list",
    },
    {
      name: "array_insertAt",
      displayName: "Insert value at index",
    },
    {
      name: "array_removeat_statement",
      displayName: "Remove value at index",
    },

    {
      name: "array_indexof",
      displayName: "Find index of value",
    },
    {
      name: "array_reverse",
      displayName: "Reverse list",
    },
  ],
};

const TEXT: BlockNamespace = {
  name: "text",
  displayName: "Text",
  active: true,
  blocks: [
    {
      name: "text",
      displayName: "Get text",
    },
    {
      name: "text_length",
      displayName: "Length of string",
    },
    {
      name: "text_join",
      displayName: "Join strings",
    },
    {
      name: "string_parsefloat",
      displayName: "Parse string to number",
    },
    {
      name: "string_split",
      displayName: "Split string at value",
    },
    {
      name: "string_includes",
      displayName: "If string includes value",
    },
    {
      name: "string_indexof",
      displayName: "Find index of value in string",
    },
    {
      name: "string_isempty",
      displayName: "If string is empty",
    },
    {
      name: "string_substr",
      displayName: "Get substring of string from index of length",
    },
    {
      name: "string_compare",
      displayName: "Compare 2 strings",
    },
    {
      name: "string_get",
      displayName: "Character from string at index",
    },
    {
      name: "variable_to_text",
      displayName: "Convert value to text",
    },
    {
      name: "stringFromCharCode",
      displayName: "Get text from character code",
    },
  ],
};

const VARIABLES: BlockNamespace = {
  name: "variables",
  displayName: "Variables",
  active: true,
  blocks: [{ name: "variables_set", displayName: "Set variable to" }],
};

const CONSOLE: BlockNamespace = {
  name: "console",
  displayName: "Console",
  active: true,
  blocks: [
    {
      name: "console_log",
      displayName: "Console log",
    },
    {
      name: "console_log_value",
      displayName: "Console log value if string equals value",
    },
  ],
};

const LIGHT: BlockNamespace = {
  name: "light",
  displayName: "Light",
  active: false,
  blocks: [],
  disable: true,
};

const ENCODERS: BlockNamespace = {
  name: "encoders",
  displayName: "Encoders",
  active: false,
  blocks: [],
  disable: true,
};

const TILE_UTIL: BlockNamespace = {
  name: "tileUtil",
  displayName: "Tile Utils",
  active: true,
  dependency: "arcade-tile-util",
  blocks: [
    {
      name: "tileUtil_tilemapProperty",
      displayName: "Get tilemap property",
    },
    {
      name: "tileUtil_createSmallMap",
      displayName: "Create 8x8 tilemap",
    },
    {
      name: "tileUtil_getLoadedMap",
      displayName: "Get current tilemap",
    },
    {
      name: "tileUtil_cloneMap",
      displayName: "Clone tilemap",
    },

    {
      name: "tileUtil_coverAllTiles",
      displayName: "Cover all tiles with tile",
    },
    {
      name: "tileUtil_coverTile",
      displayName: "Cover tile at position with tile",
    },
    {
      name: "tileUtil_createSpritesOnTiles",
      displayName: "On each tile create sprite with image and kind",
    },
    {
      name: "tileUtil_replaceAllTiles",
      displayName: "Replace all tiles with tile",
    },

    {
      name: "tileUtil_onMapLoaded",
      displayName: "On tilemap loaded",
    },
    {
      name: "tileUtil_onMapUnloaded",
      displayName: "On tilemap unloaded",
    },
    {
      name: "tileUtil_unloadTilemap",
      displayName: "Unload current tilemap",
    },

    {
      name: "tileUtil_connectMapById",
      displayName: "Connect tilemaps by door",
    },
    {
      name: "tileUtil_loadConnectedMap",
      displayName: "Load tilemap connected by door",
    },
    {
      name: "tileUtil_getConnectedMap",
      displayName: "Get tilemap connected to tilemap by door",
    },
    {
      name: "tileUtil_connectionKind",
      displayName: "Get tillemap door",
    },

    {
      name: "tileUtil_createCameraOnTile",
      displayName: "Center camera on tile position",
    },
  ],
};

const SCROLLER: BlockNamespace = {
  name: "scroller",
  displayName: "Scroller",
  active: true,
  dependency: "arcade-background-scroll",
  blocks: [
    {
      name: "scroller_scrollBackgroundWithCamera",
      displayName: "Scroll background with camera",
    },
    {
      name: "scroller_scrollBackgroundWithSpeed",
      displayName: "Scroll background with speed",
    },
    {
      name: "scroller_setCameraScrollingMultipliers",
      displayName: "Scroll background with multipliers",
    },

    {
      name: "scroller_setBackgroundScrollOffset",
      displayName: "Set background offset",
    },
    {
      name: "scroller_getBackgroundXOffset",
      displayName: "Get background x offset",
    },
    {
      name: "scroller_getBackgroundYOffset",
      displayName: "Get background y offset",
    },

    {
      name: "scroller_setLayerImage",
      displayName: "Set background layer image",
    },
    {
      name: "scroller_backgroundLayer",
      displayName: "Get background layer",
    },
  ],
};

const STATUS_BARS: BlockNamespace = {
  name: "statusbars",
  displayName: "Status Bars",
  active: true,
  dependency: "pxt-status-bar",
  blocks: [
    {
      name: "statusbars_create",
      displayName: "Set status bar to",
    },

    {
      name: "StatusBarSprite_blockCombine_set",
      displayName: "Set status bar value to",
    },
    {
      name: "StatusBarSprite_blockCombine_change",
      displayName: "Change status bar value by",
    },
    {
      name: "StatusBarSprite_blockCombine_get",
      displayName: "Get status bar value",
    },

    {
      name: "statusbars_attachToSprite",
      displayName: "Attach status bar to sprite",
    },
    {
      name: "statusbars_spriteStatusBarIsAttachedTo",
      displayName: "Get status bar of kind attached to sprite",
    },
    {
      name: "statusbars_attachSpriteGetter",
      displayName: "Get sprite that status is attached to",
    },

    {
      name: "statusbars_setColor",
      displayName: "Set status bar color",
    },
    {
      name: "statusbars_setBorder",
      displayName: "Set status bar border",
    },
    {
      name: "statusbar_setLabel",
      displayName: "Set status bar label",
    },
    {
      name: "statusbars_setFlag",
      displayName: "Set status bar flag",
    },
    {
      name: "statusbars_setBarSize",
      displayName: "Set status bar size",
    },
    {
      name: "statusbars_positionNextToSprite",
      displayName: "Set status bar position",
    },
    {
      name: "setPaddingOffset",
      displayName: "Set status bar padding",
    },

    {
      name: "statusbars_onZero",
      displayName: "On status bar value zero",
    },
    {
      name: "statusbars_postprocessDisplay",
      displayName: "On status bar display updated",
    },
    {
      name: "statusbars_onStatusReached",
      displayName: "On status bar value reached",
    },

    {
      name: "statusbars_arrayOfKind",
      displayName: "Set status bar list to",
    },
  ],
};

const STORY: BlockNamespace = {
  name: "story",
  displayName: "Story",
  active: true,
  dependency: "arcade-story",
  blocks: [
    {
      name: "story_show_text",
      displayName: "Print text at position",
    },
    {
      name: "story_sprite_say_text",
      displayName: "Sprite say text",
    },
    {
      name: "story_print_dialog",
      displayName: "Print text at camera position in box",
    },
    {
      name: "story_set_page_pause_length",
      displayName: "Set page pause",
    },
    {
      name: "arcade_story_print_character_text",
      displayName: "Print character text",
    },
    {
      name: "story_set_sound_enabled",
      displayName: "Set text sound enabled",
    },

    {
      name: "story_sprite_move_to_location",
      displayName: "Sprite move to position with speed",
    },
    {
      name: "story_sprite_cancel_movement",
      displayName: "Cancel sprite movement",
    },

    {
      name: "arcade_story_start_cutscene",
      displayName: "Start cutscene",
    },
    {
      name: "arcade_story_cancel_cutscene",
      displayName: "Cancel cutscene",
    },
    {
      name: "arcade_story_cancel_all_cutscenes",
      displayName: "Cancel all cutscenes",
    },

    {
      name: "arcade_story_show_player_choices",
      displayName: "Show player choices",
    },
    {
      name: "arcade_story_last_answer",
      displayName: "If last answer equals string",
    },
    {
      name: "arcade_story_get_last_answer",
      displayName: "Get last answer",
    },
    {
      name: "arcade_story_is_menu_open",
      displayName: "If menu is open",
    },
  ],
};

const TEXT_SPRITE: BlockNamespace = {
  name: "textsprite",
  displayName: "Text Sprite",
  active: true,
  dependency: "arcade-text",
  blocks: [
    {
      name: "textsprite_create",
      displayName: "Set text sprite to",
    },

    {
      name: "TextSprite_setMaxFontHeight",
      displayName: "Set text sprite max font height",
    },
    {
      name: "TextSprite_setOutline",
      displayName: "Set text sprite outline",
    },
    {
      name: "TextSprite_setBorder",
      displayName: "Set text sprite border",
    },
    {
      name: "TextSprite_setText",
      displayName: "Set text sprite text",
    },
    {
      name: "TextSprite_setIcon",
      displayName: "Set text sprite icon",
    },
  ],
};

const MINIMAP: BlockNamespace = {
  name: "minimap",
  displayName: "Mini Map",
  active: true,
  dependency: "arcade-minimap",
  blocks: [
    {
      name: "create_minimap",
      displayName: "Set minimap to",
    },
    {
      name: "minimap_getImage",
      displayName: "Get minimap image",
    },

    {
      name: "minimap_includeSprite",
      displayName: "Draw sprite on minimap",
    },
  ],
};

const RENDER: BlockNamespace = {
  name: "Render",
  displayName: "Render",
  active: true,
  dependency: "pxt-raycasting",
  blocks: [
    {
      name: "rcRender_getRenderSpriteVariable",
      displayName: "Set sprite to 3D sprite",
    },
    {
      name: "rcRender_getRenderSpriteInstance",
      displayName: "Get 3D sprite",
    },

    {
      name: "rcRender_toggleViewMode",
      displayName: "Toggle current view mode",
    },
    {
      name: "rcRender_isViewMode",
      displayName: "If current view is mode",
    },
    {
      name: "rcRender_setViewMode",
      displayName: "Set view mode",
    },
    {
      name: "rcRender_getAttribute",
      displayName: "Get render attribute",
    },
    {
      name: "rcRender_setAttribute",
      displayName: "Set render attribute",
    },
    {
      name: "rcRender_getDefaultFov",
      displayName: "Get default field of view",
    },
    {
      name: "rcRender_setViewAngleInDegree",
      displayName: "Set view angle",
    },
    {
      name: "rcRender_setViewAngle",
      displayName: "Set view angle by",
    },

    {
      name: "rcRender_setSpriteAttribute",
      displayName: "Set sprite render attribute",
    },
    {
      name: "rcRender_getSpriteAttribute",
      displayName: "Get sprite render attribute",
    },
    {
      name: "rcRender_isSpritesOverlapZ",
      displayName: "If sprites overlap in z dimension",
    },
    {
      name: "rcRender_jumpWithHeightAndDuration",
      displayName: "Set sprite jump with height and duration",
    },
    {
      name: "rcRender_jump",
      displayName: "Set sprite jump with speed",
    },
    {
      name: "rcRender_move",
      displayName: "Set sprite move with speed",
    },

    {
      name: "create_animation",
      displayName: "Create 3D animation",
    },
    {
      name: "set_animation",
      displayName: "Set sprite with 3D animation",
    },

    {
      name: "rcRender_takeoverSceneSprites",
      displayName: "Takeover sprites in scene",
    },
    {
      name: "rcRender_registerOnSpriteDirectionUpdateHandler",
      displayName: "Run code when sprite direction is updated",
    },
  ],
};

const TIMER: BlockNamespace = {
  name: "timer",
  displayName: "Timer",
  active: true,
  dependency: "Timers",
  blocks: [
    {
      name: "timer_throttle",
      displayName: "Timer throttle",
    },
    {
      name: "timer_debounce",
      displayName: "Timer debounce",
    },
    {
      name: "timer_background",
      displayName: "Timer background",
    },
    {
      name: "timer_after",
      displayName: "Timer after",
    },
  ],
};

const MULTIPLAYER: BlockNamespace = {
  name: "mp",
  displayName: "Multi Player",
  active: true,
  dependency: "multiplayer",
  blocks: [
    {
      name: "mp_setPlayerSprite",
      displayName: "Set player 1 to sprite of kind",
    },
    {
      name: "mp_playerSelector",
      displayName: "Get player num",
    },
    {
      name: "mp_allPlayers",
      displayName: "Array of all players",
    },
    {
      name: "mp_getPlayerSprite",
      displayName: "Select player by number",
    },
    {
      name: "mp_getPlayerBySprite",
      displayName: "Get sprite of the player",
    },

    {
      name: "mp_moveWithButtons",
      displayName: "Move player with buttons",
    },
    {
      name: "mp_onButtonEvent",
      displayName: "On button pressed for player",
    },
    {
      name: "mp_isButtonPressed",
      displayName: "If player's button is pressed",
    },
    {
      name: "mp_onControllerEvent",
      displayName: "On player connected",
    },
    {
      name: "mp_isConnected",
      displayName: "If player is connected",
    },

    {
      name: "mp_getPlayerProperty",
      displayName: "Get player property",
    },
    {
      name: "mp_getPlayerState",
      displayName: "Get player score",
    },
    {
      name: "mp_setPlayerState",
      displayName: "Set player score",
    },
    {
      name: "mp_changePlayerStateBy",
      displayName: "Change player score by",
    },
    {
      name: "mp_onScore",
      displayName: "On score equals value for player",
    },
    {
      name: "mp_onLifeZero",
      displayName: "On life zero for player",
    },

    {
      name: "mp_gameOverPlayerWin",
      displayName: "Game over player wins",
    },

    {
      name: "mp_setPlayerIndicatorsVisible",
      displayName: "Set player indicators on",
    },
    {
      name: "mp_getPlayerByIndex",
      displayName: "Get player at index",
    },
    {
      name: "mp_getPlayerByNumber",
      displayName: "Get player number",
    },
  ],
};

const ARCADE_SPRITE_UTIL: BlockNamespace = {
  name: "spriteutils",
  displayName: "Sprite Utils",
  active: true,
  dependency: "arcade-sprite-util",
  blocks: [
    {
      name: "spriteutilextisdestroyed",
      displayName: "If sprite is destroyed",
    },
    {
      name: "spriteutilextdistbw",
      displayName: "Get distance between sprites",
    },
    {
      name: "spriteutils_getSpritesWithin",
      displayName: "Get all sprites of kind within area",
    },
    {
      name: "spriteutilextanglebw",
      displayName: "Angle between sprites",
    },
    {
      name: "spriteutiljumpimpulse",
      displayName: "Make sprite jump",
    },
    {
      name: "spriteutilextplaceanglefrom",
      displayName: "Place sprite distance from other sprite",
    },
    {
      name: "spriteutilextsetspeedanglefrom",
      displayName: "Set sprite velocity at angle and speed",
    },
    {
      name: "spriteutilonspritekindupdateinterval",
      displayName: "On sprite of kind update every ms",
    },
    {
      name: "spriteutilonspriteupdateinterval",
      displayName: "On sprite update every ms",
    },

    {
      name: "spriteutilextdegtorad",
      displayName: "Convert degrees to radians",
    },
    {
      name: "spriteutilextradtodeg",
      displayName: "Convert radians to degrees",
    },
    {
      name: "spriteutilextcreaterenderable",
      displayName: "Render on z-index to screen",
    },
    {
      name: "spriteutilextdrawtransparentimg",
      displayName: "Draw image at image position",
    },
    {
      name: "spriteutilextdrawcircle",
      displayName: "Draw circle in image",
    },
    {
      name: "spriteutilextdrawfilledcircle",
      displayName: "Fill circle in image",
    },
    {
      name: "spriteutilextsetconsolevisible",
      displayName: "Set console overlay off",
    },
    {
      name: "spriteutilextsetlifeimage",
      displayName: "Set life image",
    },
    {
      name: "spriteutilextroundwithprecision",
      displayName: "Round value to decimal places",
    },
    {
      name: "spriteutilnullconsts",
      displayName: "Null consts",
    },
    {
      name: "spriteutilmathconsts",
      displayName: "Math consts",
    },
    {
      name: "spriteutiladdeventhandler",
      displayName: "Run game before game engine updates controller",
    },
  ],
};

const FUNCTIONS: BlockNamespace = {
  name: "functions",
  displayName: "Functions",
  active: true,
  blocks: [
    {
      name: "function_definition",
      displayName: "Function Define",
    },
  ],
};

export const BLOCK_NAMESPACES: BlockNamespace[] = [
  SPRITES,
  ARCADE_SPRITE_UTIL,
  CONTROLLER,
  GAME,
  MULTIPLAYER,
  MUSIC,
  SCENE,
  INFO,
  STATUS_BARS,
  LOOPS,
  LOGIC,
  VARIABLES,
  MATH,
  TILE_UTIL,
  SCROLLER,
  STORY,
  TEXT_SPRITE,
  MINIMAP,
  TIMER,
  RENDER,
  ANIMATION,
  IMAGES,
  FUNCTIONS,
  ARRAY,
  TEXT,
  CONSOLE,
  LIGHT,
  ENCODERS,
];
