<button
  [disabled]="
    (status === 'prod_ready' &&
      !ALLOWED_EMAILS.includes(selectMail$ | async)) ||
    (syncing$ | async)
  "
  mat-stroked-button
  (click)="upgradeStatus()">
  <div
    *ngIf="!(syncing$ | async)"
    fxLayout
    fxLayoutAlign="center center"
    fxLayoutGap="4px">
    <mat-icon>content_paste</mat-icon>
    <span>{{ nextStatusAction }}</span>
  </div>
  <div
    *ngIf="syncing$ | async"
    fxLayout
    fxLayoutAlign="center center"
    fxLayoutGap="4px">
    <mat-icon class="spin">sync</mat-icon>
    <span>Syncing</span>
  </div>
</button>
