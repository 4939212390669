import { AppState, CodeAdventureActivity } from "src/app/models";

export const featureKey = "code-adventure-activity";

export interface State extends AppState {
  activity: CodeAdventureActivity;
}

export const initialState: State = {
  activity: null,
  isLoading: false,
  error: "",
};
