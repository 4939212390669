import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActivityTypeIconsStoreModule } from "../components/activity-type-icon/store";
import { BuildActivityStoreModule } from "../components/build-activity/store";
import { ChallengeQuestActivityStoreModule } from "../components/challenge-quest-activity/store";
import { CodeAdventureActivityStoreModule } from "../components/code-adventure-activity/store";
import { DiscoverActivityStoreModule } from "../components/discover-activity/store";
import { ExploreActivityStoreModule } from "../components/explore-activity/store";
import { LevelUpActivityStoreModule } from "../components/level-up-activity/store";
import { SolveActivityStoreModule } from "../components/solve-activity/store";

@NgModule({
  imports: [
    CommonModule,
    BuildActivityStoreModule,
    ChallengeQuestActivityStoreModule,
    CodeAdventureActivityStoreModule,
    DiscoverActivityStoreModule,
    ExploreActivityStoreModule,
    LevelUpActivityStoreModule,
    SolveActivityStoreModule,
    ActivityTypeIconsStoreModule,
  ],
})
export class ActivitiesStoreModule {}
