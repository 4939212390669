import { createFeatureSelector, createSelector } from "@ngrx/store";
import { levelsAdapter, featureKey, State } from "./state";

const selectState = createFeatureSelector<State>(featureKey);
const selectLevelsState = createSelector(selectState, (state) => state.levels);

export const { selectAll: selectLevels } =
  levelsAdapter.getSelectors(selectLevelsState);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);
