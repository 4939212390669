<div class="app-makecode-blocks-filter" [@openClose]="animationState">
  <div class="pannel-header">
    <h2>Filter Blocks</h2>
  </div>
  <div class="panel-body">
    <form [formGroup]="filtersForm">
      <div formArrayName="namespaces">
        <ng-container *ngFor="let namespace of namespaceForms.controls">
          <mat-accordion
            *ngIf="!namespace.value.disable"
            [formGroup]="namespace">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>{{ namespace.value.displayName | titlecase }}</span>
                  <mat-slide-toggle
                    color="primary"
                    formControlName="active"
                    (click)="$event.stopPropagation()"
                    (change)="onNamespaceChange(namespace)"></mat-slide-toggle>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                class="filter-row"
                *ngFor="let blockForm of namespace.get('blocks').controls"
                [formGroup]="blockForm">
                <span [class.active]="blockForm.value.active">{{
                  blockForm.value.displayName ||
                    "'" + blockForm.value.name + "'"
                }}</span>
                <mat-slide-toggle
                  color="primary"
                  formControlName="active"
                  (change)="onBlockChange(namespace)"></mat-slide-toggle>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="panel-footer">
    <button mat-button (click)="onBackClick()">Back</button>
    <button mat-raised-button color="primary" (click)="onUpdateClick()">
      Update
    </button>
  </div>
</div>
<div
  [@backgroudBlur]="animationState"
  (click)="onOverlayClick()"
  class="app-makecode-blocks-filter-overlay"></div>
