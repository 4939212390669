import { Component, HostBinding, OnDestroy } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { delay, map, takeUntil } from "rxjs/operators";
import { slideInOut } from "./app-animations";
import { RouterStoreSelectors } from "./routing/store";
import { AppStoreSelectors, AppStoreState } from "./store";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInOut],
})
export class AppComponent implements OnDestroy {
  constructor(private store$: Store<AppStoreState>) {
    this.store$
      .pipe(
        select(RouterStoreSelectors.selectRouteData),
        map((data) => data?.isFullscreen === true),
        delay(0),
        takeUntil(this.destroyed$)
      )
      .subscribe((isFullscreen) => (this.fullscreen = isFullscreen));
  }

  @HostBinding("class") class = "app-root";

  @HostBinding("class.fullscreen") fullscreen = false;

  isLoading$: Observable<boolean> = this.store$.pipe(
    select(AppStoreSelectors.selectIsLoading),
    delay(0)
  );

  private destroyed$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
