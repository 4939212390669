import { AppState, UserProfile } from "src/app/models";

export const featureKey = "authentication";

export interface State extends AppState {
  user: UserProfile;
  roles: string[];
  image: Blob;
}

export const initialState: State = {
  user: null,
  roles: [],
  image: null,
  isLoading: false,
  error: null,
};
