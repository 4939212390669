import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  ViewChild,
} from "@angular/core";
import {
  BlockNamespace,
  FilterDefinition,
  generateFilterDefinition,
  LanguageRestriction,
  MakecodeProject,
} from "src/app/models";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-makecode-editor",
  templateUrl: "makecode-editor.component.html",
  styleUrls: ["./makecode-editor.component.scss"],
})
export class MakecodeEditorComponent implements AfterViewInit {
  @HostBinding("class") class = "app-makecode-editor";

  @ViewChild("editor", { static: true, read: ElementRef })
  editor: ElementRef;

  projectDependencies: string[] = [];
  get project(): MakecodeProject {
    return this.makecodeProject;
  }

  set project(val: MakecodeProject) {
    this.makecodeProject = val;
    this.filters = this.project?.editor?.filters ?? [];
    this.languageRestictions = this.project?.languageRestrictions ?? "";
  }

  private makecodeProject: MakecodeProject;

  displayBlocksFilter = false;

  filters: BlockNamespace[] = [];
  languageRestictions: LanguageRestriction = "";

  private get iframe(): HTMLIFrameElement {
    return this.editor.nativeElement;
  }

  private get filterDefinition(): FilterDefinition {
    return generateFilterDefinition(this.filters);
  }

  ngAfterViewInit(): void {
    this.iframe.setAttribute("src", environment.makecodeSandboxUrl);
  }

  @HostListener("window:message", ["$event"])
  onPostMessage(event: { data: {} }): void {
    this.receiveMessage(event);
  }

  receiveMessage({ data }): void {
    if (data.type !== "pxthost") {
      return;
    }

    if (data.action === "workspacesync") {
      const tempProj = JSON.parse(JSON.stringify(this.project ?? ""));
      if (tempProj && !tempProj?.source?.text["main.blocks"]) {
        tempProj.source.text[
          "main.blocks"
        ] = `<xml xmlns="https://developers.google.com/blockly/xml"></xml>`;
      }
      data.controllerId = "CodeNinjas";
      data.projects = tempProj ? [tempProj.source] : [];
      data.editor = {
        filters: JSON.parse(JSON.stringify(this.filterDefinition)),
        searchBar: true,
      };

      this.iframe.contentWindow.postMessage(data, "*");
      return;
    }

    if (data.action === "workspacesave") {
      this.project = {
        source: data.project,
        editor: {
          filters: this.filters,
          searchBar: true,
        },
        languageRestrictions: this.languageRestictions,
      };
      this.updateProjectDependencies(this.project);
    }
  }

  /**
   * Get list of dependencies used in project
   * @param project Make code project data
   * @returns list of dependencies used in project
   */
  getProjectDependencies(project: MakecodeProject): string[] {
    let dependencies = [];
    const pxtConfigStr = project?.source?.text["pxt.json"];
    if (pxtConfigStr) {
      const pxtConfig = JSON.parse(pxtConfigStr);
      const pxtDependencies = pxtConfig?.dependencies;
      if (pxtDependencies) {
        dependencies = Object.keys(pxtDependencies);
      }
    }
    return dependencies;
  }

  /**
   * Update project dependencies only if they have changed i.e. when new extensions are added
   * @param project Make code project data
   */
  updateProjectDependencies(project: MakecodeProject) {
    const dependencies = this.getProjectDependencies(project);
    if (
      this.projectDependencies.length !== dependencies.length ||
      !this.projectDependencies.every((element) =>
        dependencies.includes(element)
      )
    ) {
      this.projectDependencies = dependencies;
    }
  }

  openBlocksFilter(): void {
    this.displayBlocksFilter = true;
  }

  closeBlocksFilter(): void {
    this.displayBlocksFilter = false;
  }

  applyFilters(): void {
    this.project = {
      ...this.project,
      ...{
        editor: { filters: this.filters, searchBar: false },
      },
    };

    this.iframe.setAttribute("src", environment.makecodeSandboxUrl);
  }

  setLanguageRestiction(restriction: LanguageRestriction): void {
    this.languageRestictions = restriction;
    // no-blocks restriction not supported by make code
    if (restriction !== "no-blocks") {
      this.iframe.contentWindow.postMessage(
        {
          type: "pxteditor",
          action: "setlanguagerestriction",
          restriction,
        },
        "*"
      );
    }

    this.iframe.setAttribute("src", environment.makecodeSandboxUrl);
  }
}
