import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { detailsExpansion } from "src/app/app-animations";
import { Activities, Activity, ActivityType } from "src/app/models";

@Component({
  selector: "app-activity-list",
  templateUrl: "./activity-list.component.html",
  styleUrls: ["./activity-list.component.scss"],
  animations: [detailsExpansion],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListComponent {
  activityTypes: ActivityType[] = [
    "Build",
    "Explore",
    "Solve",
    "Discover",
    "Adventure",
    "Quest",
    "LevelUp",
  ];

  @HostBinding("class") class = "app-activity-list";

  @Input() activities: Activities;

  @Input() selection: Activity;

  @Output() add = new EventEmitter<ActivityType>();

  @Output() delete = new EventEmitter<Activity>();

  @Output() rearrange = new EventEmitter<Activities>();

  @Output() selectionChange = new EventEmitter<Activity>();

  showAddActivity = false;

  get expandedState(): string {
    return this.showAddActivity ? "expanded" : "collapsed";
  }

  addActivity(activityType: ActivityType): void {
    this.add.emit(activityType);
  }

  deleteActivity(): void {
    this.delete.emit(this.selection);
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<Activities>): void {
    moveItemInArray(this.activities, previousIndex, currentIndex);

    const newSort = this.activities.map((a, i) => ({ ...a, sortOrder: i }));

    this.rearrange.emit(newSort);
  }

  selectActivity(activity: Activity): void {
    this.selectionChange.emit(activity);
  }

  isSelected(activity: Activity): boolean {
    return (
      this.selection?.id === activity.id &&
      this.selection?.type === activity.type
    );
  }
}
