import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  EditProgramDto,
  Icon,
  Program,
  ProgramFormDto,
  ProgramFormResultDto,
} from "src/app/models";

export const loadPrograms = createAction(
  "[Program List Component] Load programs"
);
export const loadProgramsSuccess = createAction(
  "[Program List Component] Load programs success",
  props<{ programs: Program[] }>()
);
export const loadProgramsFailure = createAction(
  "[Program List Component] Load programs failure",
  props<{ error: HttpErrorResponse }>()
);

export const addProgram = createAction("[Program List Component] Add program");
export const addProgramConfirm = createAction(
  "[Program List Component] Add program confirm",
  props<{ program: ProgramFormDto }>()
);
export const addProgramCancel = createAction(
  "[Program List Component] Add program cancel"
);
export const addProgramSuccess = createAction(
  "[Program List Component] Add program success"
);
export const addProgramFailure = createAction(
  "[Program List Component] Add program failure",
  props<{ error: HttpErrorResponse }>()
);

export const editProgram = createAction(
  "[Program List Component] Edit program",
  props<{ program: Program }>()
);
export const loadIcon = createAction(
  "[Program List Component] Load program icon",
  props<{ program: Program }>()
);
export const loadIconFailure = createAction(
  "[Program List Component] Load program icon failure",
  props<{ error: HttpErrorResponse }>()
);
export const editProgramConfirm = createAction(
  "[Program List Component] Edit program confirm",
  props<{ program: ProgramFormResultDto }>()
);
export const editProgramCancel = createAction(
  "[Program List Component] Edit program cancel"
);
export const editProgramSuccess = createAction(
  "[Program List Component] Edit program success"
);
export const editProgramFailure = createAction(
  "[Program List Component] Edit program failure",
  props<{ error: HttpErrorResponse }>()
);

export const toggleProgramDisabled = createAction(
  "[Program List Component] Toggle program disabled",
  props<{ program: EditProgramDto }>()
);
export const toggleProgramDisabledSuccess = createAction(
  "[Program List Component] Toggle program disabled success"
);
export const toggleProgramDisabledFailure = createAction(
  "[Program List Component] Toggle program disabled failure",
  props<{ program: EditProgramDto; error: HttpErrorResponse }>()
);
