import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { appErrorReducer } from "./app-error-reducer";
import * as FeatureActions from "./actions";

@Injectable()
export class Effects {
  constructor(private actions$: Actions, private snackbar: MatSnackBar) {}

  noticeHttpError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.noticeHttpError),
        tap(({ error }) => {
          let errorMessage = "";
          switch (error.status) {
            case 500:
              errorMessage = "Internal server error.";
              break;
            default:
              errorMessage = appErrorReducer(error);
              break;
          }

          this.snackbar.open(errorMessage, "OK");
        })
      ),
    {
      dispatch: false,
    }
  );
}
