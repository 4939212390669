import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AnswerFieldQuestion } from "src/app/models";

@Component({
  selector: "app-answer-field-question",
  templateUrl: "./answer-field-question.component.html",
  styleUrls: ["./answer-field-question.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerFieldQuestionComponent {
  @HostBinding("class") class = "app-answer-field-question";

  @Input() set question(question: AnswerFieldQuestion) {
    this.myQuestion = question;
    this.answer.reset();
    this.answer.setValue(question?.answer || "");
  }

  get question(): AnswerFieldQuestion {
    return {
      ...this.myQuestion,
      answer: this.answer.value,
    };
  }

  get dirty(): boolean {
    return this.answer.dirty;
  }

  get valid(): boolean {
    return this.answer.valid;
  }

  answer = new FormControl("", Validators.required);

  private myQuestion: AnswerFieldQuestion;
}
