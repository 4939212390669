import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectMail } from "src/app/authentication/store/selectors";
import { LevelStatus } from "src/app/models";
import { AppStoreState } from "src/app/store";
import { environment } from "src/environments/environment";
import { selectIsSyncing } from "../level/store/selectors";

@Component({
  selector: "app-level-status-button",
  templateUrl: "./level-status-button.component.html",
  styleUrls: ["./level-status-button.component.scss"],
})
export class LevelStatusButtonComponent {
  constructor(private store$: Store<AppStoreState>) {}

  @HostBinding("class") class = "app-level-status-button";

  @Input() status: LevelStatus;

  @Output() statusChange = new EventEmitter<LevelStatus>();

  ALLOWED_EMAILS = environment.publishAllowedMails.map((mail) =>
    mail.toLowerCase()
  );
  selectMail$: Observable<string> = this.store$.select(selectMail);
  syncing$: Observable<boolean> = this.store$.select(selectIsSyncing);

  get nextStatusAction(): string {
    switch (this.status) {
      case "draft":
        return "Mark for Staging";
      case "testing_ready":
        return "Sync to Stage";
      case "testing":
        return "Mark for Prod";
      case "prod_ready":
        return "Publish"; // enabled only for admins
      case "published":
        return "Published"; // disabled
      default:
        return "Mark for Staging";
    }
  }

  upgradeStatus(): void {
    switch (this.status) {
      case "draft":
        this.statusChange.emit("testing_ready");
        break;
      case "testing_ready":
        this.statusChange.emit("testing");
        break;
      case "testing":
        this.statusChange.emit("prod_ready");
        break;
      case "prod_ready":
        this.statusChange.emit("published");
        break;
    }
  }
}
