<mat-dialog-content>
  <app-validation-item-form [item]="item"></app-validation-item-form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="delete()">Delete</button>
  <span fxFlex></span>
  <button mat-button [mat-dialog-close]="{ type: 'cancel' }">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!valid"
    (click)="close()">
    Done
  </button>
</mat-dialog-actions>
