<mat-radio-group
  aria-label="Select an option"
  [formControl]="selectedOptionControl">
  <mat-radio-button value="textMedia">Text & Media</mat-radio-button>
  <mat-radio-button value="quiz">Quiz</mat-radio-button>
</mat-radio-group>

<app-text-media-editor
  [class.selected]="selectedOptionControl.value === 'textMedia'"
  [step]="_step"></app-text-media-editor>

<app-quiz-builder
  [class.selected]="selectedOptionControl.value === 'quiz'"
  [questions]="questions"></app-quiz-builder>
