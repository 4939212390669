<button
  [ngClass]="{ border: !icon }"
  class="icon-button"
  (click)="openDialog()"
  mat-icon-button>
  <mat-icon *ngIf="!icon">upload</mat-icon>
  <img
    width="100%"
    height="100%"
    *ngIf="icon"
    [src]="icon | appImageUrl : 'icon'" />
</button>
