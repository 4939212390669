import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { selectSelectedActivity } from "src/app/levels/components/level/store/selectors";
import { LevelUpActivitiesService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";
import { updateLevelStatus } from "src/app/levels/components/level/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private snackbar: MatSnackBar,
    private service: LevelUpActivitiesService
  ) {}

  triggerLoadActivity$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      filter((a) => !!a && a.type === "LevelUp"),
      distinctUntilKeyChanged("id"),
      map(({ id: activityId }) =>
        FeatureActions.loadLevelUpActivity({ activityId })
      )
    )
  );

  loadLevelUpActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadLevelUpActivity),
      switchMap(({ activityId }) =>
        this.service.getLevelUpActivityById(activityId).pipe(
          map((activity) =>
            FeatureActions.loadLevelUpActivitySuccess({ activity })
          ),
          catchError((error) =>
            of(FeatureActions.loadLevelUpActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateActivity),
      switchMap(({ activity, mediaFile, isMediaDirty }) =>
        this.service.updateActivity(activity, mediaFile, isMediaDirty).pipe(
          map((mediaFile) =>
            FeatureActions.updateActivitySuccess({ activity, mediaFile })
          ),
          catchError((error) =>
            of(FeatureActions.updateActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateActivitySuccess),
      map((_) => {
        return updateLevelStatus({
          status: "draft",
        });
      })
    )
  );

  addStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addStep),
      exhaustMap(({ step }) =>
        this.service.addStep(step).pipe(
          map((step) => FeatureActions.addStepSuccess({ step })),
          catchError((error) => of(FeatureActions.addStepFailure({ error })))
        )
      )
    )
  );

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteStep),
      concatMap(({ step }) =>
        this.service.deleteStep(step.id).pipe(
          map(() => FeatureActions.deleteStepSuccess()),
          catchError((error) =>
            of(FeatureActions.deleteStepFailure({ step, error }))
          )
        )
      )
    )
  );
}
