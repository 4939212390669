<mat-list>
  <mat-list-item
    *ngFor="let item of _validationItems.controls; let index = index">
    <mat-form-field>
      <input matInput [formControl]="item.controls.text" />
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="removeItemAt(index)">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-list-item>
  <mat-list-item>
    <div fxFlex fxLayout="column">
      <button mat-button (click)="addItem()">Add Sensei Item</button>
    </div>
  </mat-list-item>
</mat-list>
