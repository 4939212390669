import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, State } from "./state";
import * as FeatureActions from "./actions";

const featureReducer = createReducer(
  initialState,
  on(
    FeatureActions.loadIconPage,
    FeatureActions.deleteIcon,
    FeatureActions.deleteIconConfirm,
    FeatureActions.uploadIcon,
    FeatureActions.replaceIcon,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    FeatureActions.replaceIconFailure,
    FeatureActions.uploadIconFailure,
    FeatureActions.loadIconPageFailure,
    FeatureActions.deleteIconFailure,
    FeatureActions.loadIconPageFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  ),
  on(FeatureActions.loadIconPageSuccess, (state, { iconPage }) => {
    const iconPages = { ...state.iconPages };
    iconPages[iconPage.pageIndex] = iconPage.items;
    return {
      ...state,
      iconPages,
      isLoading: false,
      totalPages: Math.ceil(iconPage.totalItems / state.pageSize),
    };
  }),
  on(
    FeatureActions.uploadIconSuccess,
    FeatureActions.replaceIconSuccess,
    (state, { icon: selectedIcon }) => ({
      ...state,
      iconPages: { 0: [] },
      pageIndex: 0,
      isLoading: false,
      selectedIcon,
    })
  ),
  on(FeatureActions.changeSelectedIcon, (state, { icon: selectedIcon }) => ({
    ...state,
    selectedIcon,
  })),
  on(FeatureActions.deleteIconSuccess, (state) => ({
    ...state,
    iconPages: { [state.pageIndex]: [] },
    isLoading: false,
  })),
  on(FeatureActions.goToPage, (state, { pageIndex }) => ({
    ...state,
    pageIndex,
  }))
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
