import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent implements OnInit, OnDestroy {
  @HostBinding("class") _class = "app-tags";

  @Output() tagChange = new EventEmitter<string>();

  @Output() add = new EventEmitter<string>();

  @Output() remove = new EventEmitter<string>();

  @Input() matches: string[];

  @Input() tags: string[] = [];

  @Input() label = "";

  get tag(): string {
    return this._tagControl.value;
  }

  _tagControl = new FormControl("");

  private destroyed$ = new Subject<void>();

  addTag(): void {
    this.add.emit(this.tag);
  }

  removeTag(tag: string): void {
    this.remove.emit(tag);
  }

  ngOnInit(): void {
    this._tagControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe((newTag) => this.tagChange.emit(newTag));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
