import {
  AppState,
  Document,
  DocumentFilters,
  DocumentsPage,
  DocumentsSort,
  PaginationParams,
} from "src/app/models";

export const featureKey = "documents";

export interface State extends AppState {
  documentsPage: DocumentsPage;
  selectedPage: PaginationParams;
  selectedDocument: Document;
  sortBy: DocumentsSort;
  filters: DocumentFilters;
  search: string;
  tagMatches: string[];
  isUpdating: boolean;
}

export const initialState: State = {
  documentsPage: null,
  selectedPage: { pageIndex: 0, pageSize: 6 },
  selectedDocument: null,
  sortBy: "dateAdded",
  filters: { camps: false, create: false, jr: false },
  search: null,
  tagMatches: [],
  isUpdating: false,
  isLoading: false,
  error: null,
};
