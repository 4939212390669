import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of, merge } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { AppStoreState } from "src/app/store";
import { CoursesService, ProgramsService } from "src/app/services";
import { selectProgramId } from "src/app/routing/store/selectors";
import { YesNoDialogComponent } from "src/app/dialogs/components/yes-no-dialog";
import { selectProgram } from "./selectors";
import * as FeatureActions from "./actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as CourseActions from "src/app/courses/components/edit-course/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private programsService: ProgramsService,
    private coursesService: CoursesService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  triggerLoadProgram$ = createEffect(() =>
    merge(
      this.store$.pipe(
        select(selectProgramId),
        filter((id) => !!id),
        map((id) => +id)
      ),
      this.actions$.pipe(
        ofType(
          FeatureActions.addCourseSuccess,
          FeatureActions.deleteCourseSuccess,
          CourseActions.updateCourseSuccess,
          FeatureActions.duplicateCourseSuccess
        ),
        withLatestFrom(this.store$.pipe(select(selectProgram))),
        map(([_, { id }]) => id)
      )
    ).pipe(map((programId) => FeatureActions.loadProgram({ programId })))
  );

  loadProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadProgram),
      switchMap(({ programId }) =>
        this.programsService.getProgram(programId).pipe(
          switchMap((program) => [
            FeatureActions.loadProgramSuccess({ program }),
            FeatureActions.loadCourses({ programId: program.id }),
          ]),
          catchError((error) =>
            of(FeatureActions.loadProgramFailure({ error }))
          )
        )
      )
    )
  );

  loadCourses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadCourses),
      switchMap(({ programId }) =>
        this.coursesService.getCoursesByProgramId(programId).pipe(
          map((courses) => FeatureActions.loadCoursesSuccess({ courses })),
          catchError((error) =>
            of(FeatureActions.loadCoursesFailure({ error }))
          )
        )
      )
    )
  );

  updateCourses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.rearrange),
      withLatestFrom(this.store$.pipe(select(selectProgram))),
      switchMap(([{ courses }, { id }]) =>
        this.coursesService.updateCourses(id, courses).pipe(
          map(() => FeatureActions.rearrangeSuccess()),
          catchError((error) => of(FeatureActions.rearrangeFailure({ error })))
        )
      )
    )
  );

  addCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addCourse),
      withLatestFrom(this.store$.pipe(select(selectProgram))),
      switchMap(([_, { id }]) =>
        this.coursesService.addCourse(id).pipe(
          map(() => FeatureActions.addCourseSuccess()),
          catchError((error) => of(FeatureActions.addCourseFailure({ error })))
        )
      )
    )
  );

  duplicateCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.duplicateCourse),
      exhaustMap(({ courseId }) =>
        this.coursesService.duplicateCourse(courseId).pipe(
          map(() => FeatureActions.duplicateCourseSuccess()),
          catchError((error) =>
            of(FeatureActions.duplicateCourseFailure({ error }))
          )
        )
      )
    )
  );

  confirmDeleteCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteCourse),
      switchMap(({ courseId }) =>
        this.dialog
          .open(YesNoDialogComponent)
          .afterClosed()
          .pipe(
            map((confirm) =>
              confirm
                ? FeatureActions.deleteCourseConfirm({ courseId })
                : FeatureActions.deleteCourseCancel()
            )
          )
      )
    )
  );

  deleteCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteCourseConfirm),
      switchMap(({ courseId }) =>
        this.coursesService.deleteCourse(courseId).pipe(
          map(() => FeatureActions.deleteCourseSuccess()),
          catchError((error) =>
            of(FeatureActions.deleteCourseFailure({ error }))
          )
        )
      )
    )
  );

  displayCourseDeletedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.deleteCourseSuccess),
        tap(() => this.displayMessage("Deleted successfully!"))
      ),
    {
      dispatch: false,
    }
  );

  displayCourseAddedFailedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.addCourseFailure),
        tap(() =>
          this.displayMessage(
            "Could not add course at this time. Please try again later."
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  private displayMessage(message: string): void {
    this.snackbar.open(message, "OK", { duration: 3000 });
  }
}
