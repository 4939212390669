import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { Course, Level, LevelStatus } from "src/app/models";
import { detailsExpansion } from "./edit-level-animations";

@Component({
  selector: "app-edit-level",
  templateUrl: "./edit-level.component.html",
  styleUrls: ["./edit-level.component.scss"],
  animations: [detailsExpansion],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLevelComponent {
  @HostBinding("class") class = "app-edit-level";

  @Input() course: Course;

  @Input() tagMatches: string[];

  @Input() set level(level: Level) {
    this.myLevel = level;
    this.titleControl.reset();
    this.titleControl.setValue(level?.title);
    this.descriptionControl.reset();
    this.descriptionControl.setValue(level?.description);
    this.mustCompletePreviousControl.reset();
    this.mustCompletePreviousControl.setValue(level?.mustCompletePrevious);
    this.tags.clear();
    this.tags.markAsPristine();
    (level.tags || []).forEach((tag) => this.tags.push(new FormControl(tag)));
  }

  get level(): Level {
    return this.myLevel;
  }

  @Output() levelChange = new EventEmitter<Level>();

  @Output() statusChange = new EventEmitter<LevelStatus>();

  @Output() tagChange = new EventEmitter<string>();

  get expandedState(): string {
    return this.expanded ? "expanded" : "collapsed";
  }

  titleControl = new FormControl("", Validators.required);
  descriptionControl = new FormControl("", Validators.required);
  mustCompletePreviousControl = new FormControl(false);
  tags = new FormArray([]);

  get _tags(): string[] {
    return this.tags.value;
  }

  get valid(): boolean {
    return (
      this.titleControl.valid &&
      this.descriptionControl.valid &&
      this.mustCompletePreviousControl.valid &&
      this.tags.valid
    );
  }

  get dirty(): boolean {
    return (
      this.titleControl.dirty ||
      this.descriptionControl.dirty ||
      this.mustCompletePreviousControl.dirty ||
      this.tags.dirty
    );
  }

  private get newLevel(): Level {
    return {
      id: this.level.id,
      courseId: this.level.courseId,
      sortOrder: 0,
      title: this.titleControl.value,
      description: this.descriptionControl.value,
      mustCompletePrevious: this.mustCompletePreviousControl.value,
      status: this.level.status,
      tags: this.tags.controls.map((test) => test.value),
    };
  }

  private myLevel: Level;
  private expanded = true;

  update(): void {
    this.levelChange.emit(this.newLevel);
  }

  changeLevelStatus(status: LevelStatus): void {
    this.statusChange.emit(status);
  }

  toggleDetails(): void {
    this.expanded = !this.expanded;
  }

  _onTagChange(keyword: string): void {
    this.tagChange.emit(keyword);
  }

  _onTagAdd(tag: string): void {
    if (
      this.tags.controls.find(
        (c) => c.value.toLowerCase().trim() === tag.toLowerCase().trim()
      )
    ) {
      return;
    }
    this.tags.push(new FormControl(tag));
    this.tags.markAsDirty();
  }

  _onTagRemove(tag: string): void {
    const index = this.tags.controls.findIndex((c) => c.value === tag);
    if (index === -1) {
      return;
    }

    this.tags.removeAt(index);
    this.tags.markAsDirty();
  }
}
