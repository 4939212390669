import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State } from "./state";

const selectState = createFeatureSelector<State>(featureKey);

export const selectUserRoles = createSelector(
  selectState,
  (state) => state.roles
);

export const selectUser = createSelector(selectState, (state) => state.user);

export const selectImage = createSelector(selectState, (state) => state.image);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);

export const selectMail = createSelector(selectUser, (user) =>
  user?.mail.toLowerCase()
);
