<div class="stepper-content">
  <div *ngIf="title" class="step-title">{{ title }}</div>

  <div
    *ngFor="let step of steps; let index = index"
    class="step-container"
    [class.active]="index === selectedIndex"
    [@.disabled]="disableAnimations"
    [@stepTransition]="getAnimationDirection(index)">
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
</div>

<div fxLayout fxLayoutGap="4px" *ngIf="isInMultipleMode">
  <button mat-icon-button (click)="addStep()">
    <mat-icon>add_circle_outline</mat-icon>
  </button>

  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    class="stepper-buttons"
    (cdkDropListDropped)="drop($event)">
    <div
      *ngFor="let step of steps; let index = index"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="4px"
      cdkDrag>
      <button
        mat-button
        type="button"
        [class.active]="index === selectedIndex"
        (click)="changeSelectedIndex(index)">
        {{ index + 1 }}
      </button>
      <button
        *ngIf="steps?.length > 1"
        mat-icon-button
        (click)="deleteStep(index)">
        <mat-icon class="delete-icon" [inline]="true">close</mat-icon>
      </button>
    </div>
  </div>
</div>
