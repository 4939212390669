<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content *ngIf="content">
  {{ content }}
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>No</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    Yes
  </button>
</mat-dialog-actions>
