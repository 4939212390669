<div fxLayout="column" fxLayoutGap="4px">
  <label id="filter-radio-group-label">Sort By</label>
  <app-filter-box>
    <mat-radio-group
      aria-labelledby="filter-radio-group-label"
      fxLayout="column"
      fxLayoutGap="5px"
      [ngModel]="mySortBy"
      (ngModelChange)="onValueChange($event)">
      <mat-radio-button value="dateAdded">Date Added</mat-radio-button>
      <mat-radio-button value="beltOrder">Tagged Belt Order</mat-radio-button>
      <mat-radio-button value="alpha">Alphabetical</mat-radio-button>
    </mat-radio-group>
  </app-filter-box>
</div>
