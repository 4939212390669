import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { AppState, identifyLevel, Level } from "src/app/models";

export const featureKey = "edit-course";

export interface LevelsState extends EntityState<Level> {}

export interface State extends AppState {
  levels: LevelsState;
}

export const levelsAdapter = createEntityAdapter<Level>({
  selectId: identifyLevel,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const initialState: State = {
  levels: levelsAdapter.getInitialState(),
  isLoading: false,
  error: "",
};
