import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Question, DiscoverActivityStep } from "src/app/models";
import { QuizBuilderComponent } from "src/app/quiz-builder";
import { TextMediaEditorComponent } from "../text-media-editor";

@Component({
  selector: "app-discover-activity-step",
  templateUrl: "./discover-activity-step.component.html",
  styleUrls: ["./discover-activity-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverActivityStepComponent {
  @HostBinding("class") class = "app-discover-activity-step";

  @Input() set value(step: DiscoverActivityStep) {
    this.myStep = step;

    this.selectedOptionControl.reset();
    this.selectedOptionControl.setValue(step?.type || "textMedia");

    this.questions = step?.questions || [];
  }

  get value(): DiscoverActivityStep {
    return {
      ...this.myStep,
      type: this.selectedOptionControl.value,
      text: this.textMediaBuilder?.text,
      questions: this.quizBuilder?.questions,
      mediaType: this.textMediaBuilder?.mediaType,
      videoUrl: this.textMediaBuilder?.videoUrl,
      image: this.textMediaBuilder?.image,
    };
  }

  @ViewChild(QuizBuilderComponent)
  quizBuilder: QuizBuilderComponent;

  @ViewChild(TextMediaEditorComponent)
  textMediaBuilder: TextMediaEditorComponent;

  get _step(): DiscoverActivityStep {
    return this.myStep;
  }

  private myStep: DiscoverActivityStep;

  selectedOptionControl = new FormControl("textMedia", Validators.required);

  questions: Question[] = [];

  get valid(): boolean {
    return !!(
      this.selectedOptionControl.valid &&
      (!this.textMediaBuilder || this.textMediaBuilder.valid) &&
      (!this.quizBuilder || this.quizBuilder.valid)
    );
  }

  get dirty(): boolean {
    return !!(
      this.selectedOptionControl.dirty ||
      this.textMediaBuilder?.dirty ||
      this.quizBuilder?.dirty
    );
  }
}
