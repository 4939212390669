import { Component, HostBinding, Input } from "@angular/core";
import { detailsExpansion } from "src/app/app-animations";

@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
  animations: [detailsExpansion],
})
export class ActivityComponent {
  @HostBinding("class") class = "app-activity";

  @Input() collapsed = false;

  get expandedState(): string {
    return this.collapsed ? "collapsed" : "expanded";
  }
}
