import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared";
import { containers } from "./containers";
import { components } from "./components";
import { dialogs } from "./dialogs";
import { directives } from "./directives";
import { EditorsModule } from "../editors/editors.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    EditorsModule,
    ReactiveFormsModule,
  ],
  declarations: [...containers, ...components, ...dialogs, ...directives],
  exports: [...containers],
  entryComponents: [...dialogs],
})
export class ValidationBuilderModule {}
