import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State, coursesAdapter } from "./state";

const selectState = createFeatureSelector<State>(featureKey);

export const selectProgram = createSelector(
  selectState,
  (state) => state.program
);

const selectCoursesState = createSelector(
  selectState,
  (state) => state.courses
);

export const { selectAll: selectCourses } =
  coursesAdapter.getSelectors(selectCoursesState);

export const selectSelectedCourse = createSelector(
  selectState,
  (state) => state.selectedCourse
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);
