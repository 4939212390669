import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { sortById } from "src/app/entity-adapter";
import { AppState, LevelUpActivity, LevelUpActivityStep } from "src/app/models";

export const featureKey = "level-up-activity";

export interface LevelUpActivityStepState
  extends EntityState<LevelUpActivityStep> {}

export interface State extends AppState {
  activity: LevelUpActivity;
  steps: LevelUpActivityStepState;
}

export const stepsAdapter = createEntityAdapter<LevelUpActivityStep>({
  sortComparer: sortById,
});

export const initialState: State = {
  activity: null,
  steps: stepsAdapter.getInitialState(),
  isLoading: false,
  error: "",
};
