import {
  Component,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import {
  Block,
  BlockNamespace,
  BLOCK_NAMESPACES,
  optimizeNamespaceDefintion,
} from "src/app/models";

import {
  filterPanelAnimations,
  OpenCloseState,
} from "./makecode-blocks-filter.animations";

@Component({
  selector: "app-makecode-blocks-filter",
  templateUrl: "./makecode-blocks-filter.component.html",
  styleUrls: ["./makecode-blocks-filter.component.scss"],
  animations: filterPanelAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeCodeBlocksFilterComponent {
  @HostBinding("class") class = "app-makecode-blocks-filter";

  @Input() open = false;
  private _projectDependencies: string[] = [];

  get projectDependencies() {
    return this._projectDependencies;
  }

  @Input() set projectDependencies(dependencies: string[]) {
    this._projectDependencies = dependencies;
    this.setFiltersForm();
    this.updateFiltersForm(this.filters);
  }

  @Output() openChange = new EventEmitter<boolean>();
  @Output() updateFilters = new EventEmitter<BlockNamespace[]>();

  @Output() filtersChange = new EventEmitter<BlockNamespace[]>();

  private _filters: BlockNamespace[];
  @Input() set filters(namespaces: BlockNamespace[] | null) {
    this._filters = namespaces ?? [];
    if (namespaces === null) {
      return;
    }
    this.updateFiltersForm(namespaces);
  }

  get filters(): BlockNamespace[] {
    return this._filters;
  }

  get formFilters(): BlockNamespace[] {
    return this.namespaceForms.value as BlockNamespace[];
  }

  filtersForm: FormGroup;
  formDictionary: { [key: string]: FormGroup } = {};
  filtersCache: BlockNamespace[] = [];

  constructor(private fb: FormBuilder) {
    this.setFiltersForm();
  }

  /**
   * Creates a new filters form
   */
  setFiltersForm() {
    this.filtersForm = this.fb.group({
      namespaces: this.fb.array(
        BLOCK_NAMESPACES.filter(
          (namespace) =>
            // if filter requires an extension check if extension is added in the dependency list then add the filter to the form
            !namespace.dependency ||
            this.projectDependencies.includes(namespace.dependency)
        ).map((namespace) =>
          this.registerForm(
            namespace,
            this.fb.group({
              ...namespace,
              blocks: this.fb.array(
                namespace.blocks
                  .filter(
                    (block) =>
                      // if filter requires an extension check if extension is added in the dependency list then add the filter to the form
                      !block.dependency ||
                      this.projectDependencies.includes(block.dependency)
                  )
                  .map((block) =>
                    this.registerForm(
                      block,
                      this.fb.group({
                        ...block,
                        active: namespace.active ? true : !!block.active,
                      })
                    )
                  )
              ),
            })
          )
        )
      ),
    });
  }

  /**
   * Updates filter form values based on provided filters
   * @param namespaces  Array of namespaces and blocks
   */
  updateFiltersForm(namespaces: BlockNamespace[]) {
    namespaces.forEach((namespace) => {
      this.setNamespaceInformation(namespace);
      this.setBlockInformation(namespace.blocks);
    });
    this.filtersCache = this.namespaceForms.value;
    this.filtersForm.markAsPristine();
  }

  get namespaceForms(): FormArray {
    return this.filtersForm.get("namespaces") as FormArray;
  }

  get animationState(): OpenCloseState {
    return this.open ? "open" : "close";
  }

  onUpdateClick(): void {
    this.closeSelf();

    const optimizedFilters = optimizeNamespaceDefintion(this.formFilters);
    this.filtersChange.emit(optimizedFilters);
    this.updateFilters.emit(optimizedFilters);
  }

  onBackClick(): void {
    this.closeSelf();
  }

  onOverlayClick(): void {
    this.namespaceForms.setValue(this.filtersCache);
    this.closeSelf();
  }

  onNamespaceChange(namespace: FormGroup): void {
    const namespaceValue = namespace.value as BlockNamespace;
    const blocksControls = namespace.get("blocks") as FormArray;

    blocksControls.setValue(
      namespaceValue.blocks.map((b) => ({
        ...b,
        active: namespaceValue.active,
      })),
      { emitEvent: false }
    );
  }

  onBlockChange(namespaceForm: FormGroup): void {
    const namespace = namespaceForm.value as BlockNamespace;

    const anyInactive = namespace.blocks.some((b) => b.active === false);
    namespaceForm.patchValue({ active: !anyInactive }, { emitEvent: false });
  }

  private setNamespaceInformation(namespace: BlockNamespace): void {
    const namespaceForm = this.formDictionary[namespace.name];

    if (namespaceForm) {
      namespaceForm.patchValue(
        { active: namespace.active },
        { emitEvent: false }
      );
      this.onNamespaceChange(namespaceForm);
    }
  }

  private setBlockInformation(blocks: Block[]): void {
    let lastUpdatedBlock = null;

    blocks.forEach((block) => {
      const blockForm = this.formDictionary[block.name];
      if (blockForm) {
        blockForm.patchValue({ active: block.active }, { emitEvent: false });
        lastUpdatedBlock = blockForm;
      }
    });

    if (lastUpdatedBlock) {
      this.onBlockChange(lastUpdatedBlock.parent.parent);
    }
  }

  private registerForm(unit: { name: string }, form: FormGroup): FormGroup {
    this.formDictionary[unit.name] = form;
    return form;
  }

  private closeSelf(): void {
    this.open = false;
    this.openChange.emit(this.open);
  }
}
