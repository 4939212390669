import { AfterViewInit } from "@angular/core";
import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AuthStoreSelectors } from "src/app/authentication";
import { AuthRole } from "src/app/models";
import { AppStoreState } from "src/app/store";

@Directive({
  selector: "[appUserCanDelete]",
})
export class UserCanDeleteDirective implements AfterViewInit, OnDestroy {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private store$: Store<AppStoreState>
  ) {}

  @Input("appUserCanDelete")
  set resource(resource: DeletableResource) {
    if (resource === "program" || resource === "programs") {
      this.requiredRole = "Program.Deleter";
    }

    if (resource === "course" || resource === "courses") {
      this.requiredRole = "Course.Deleter";
    }
  }

  private requiredRole: AuthRole;
  private destroyed$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.store$
      .pipe(
        takeUntil(this.destroyed$),
        select(AuthStoreSelectors.selectUserRoles),
        map((roles) => !roles.includes(this.requiredRole))
      )
      .subscribe((disabled) => this.toggleDisabled(disabled));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  private toggleDisabled(disabled: boolean): void {
    if (!this.requiredRole) {
      return;
    }

    this.renderer.setProperty(this.el.nativeElement, "disabled", disabled);
    this.renderer[["removeClass", "addClass"][+disabled]](
      this.el.nativeElement,
      "mat-button-disabled"
    );
  }
}

export type DeletableResource = "program" | "programs" | "course" | "courses";
