import { AppState, Program } from "src/app/models";

export const featureKey = "program-list";

export interface State extends AppState {
  programs: Program[];
  lastEditDate: Date;
}

export const initialState: State = {
  programs: [],
  lastEditDate: null,
  isLoading: false,
  error: "",
};
