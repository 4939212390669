import { Activity } from "./activity";
import { Question } from "./question";
import { ActivityStep } from "./step";

export interface DiscoverActivity extends Activity {
  title: string;
}

export interface DiscoverActivityStep extends ActivityStep {
  type: DiscoverContentType;
  questions?: Array<Question>;
  text?: string;
  videoUrl?: string;
  image?: string;
  mediaType: MediaType;
}

export type DiscoverContentType = "textMedia" | "quiz";
export type MediaType = "video" | "image";
