import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MakecodeEditorComponent } from "src/app/editors/components";
import { MakecodeProject } from "src/app/models";

@Component({
  templateUrl: "./makecode-editor-dialog.component.html",
  styleUrls: ["./makecode-editor-dialog.component.scss"],
})
export class MakecodeEditorDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: { project: MakecodeProject },
    private dialogRef: MatDialogRef<MakecodeEditorDialogComponent>
  ) {
    const newData = JSON.parse(JSON.stringify(data));
    if (newData?.project?.source?.header?.cloudUserId)
      delete newData.project.source.header.cloudUserId;
    this.project = newData.project;
  }

  @ViewChild(MakecodeEditorComponent)
  editor: MakecodeEditorComponent;

  project: MakecodeProject;

  get dirty(): boolean {
    return (
      this.editor?.project?.source?.header?.modificationTime !==
      this.project?.source?.header?.modificationTime
    );
  }

  get lastUpdated(): number {
    return (this.editor?.project?.source?.header?.modificationTime || 0) * 1000;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.editor.project = this.project;
    });
  }

  close(): void {
    this.dialogRef.close(this.editor.project);
  }

  openFilterPanel(): void {
    this.editor.openBlocksFilter();
  }
}
