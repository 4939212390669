import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ChallengeQuestActivity, MediaFile } from "../models";

@Injectable({ providedIn: "root" })
export class ChallengeQuestActivitiesService {
  constructor(private http: HttpClient) {}

  getChallengeQuestActivityById(
    id: number
  ): Observable<ChallengeQuestActivity> {
    return this.http.get<ChallengeQuestActivity>(
      `api/challengeQuestActivities/${id}`
    );
  }

  updateChallengeQuestActivity(
    activity: ChallengeQuestActivity,
    mediaFile: File | null
  ): Observable<MediaFile | null> {
    return this.http
      .put<void>(`api/challengeQuestActivities/${activity.id}`, activity)
      .pipe(
        switchMap((_) => {
          if (mediaFile === null && activity.mediaFile?.name !== null) {
            return this.http
              .delete(`api/challengeQuestActivities/${activity.id}/media`)
              .pipe(map((_) => null));
          } else if (
            mediaFile !== null &&
            mediaFile.name !== activity.mediaFile?.name
          ) {
            const formData = new FormData();

            formData.append("mediaFile", mediaFile);
            formData.append("activityId", activity.id.toString());

            return this.http.post<MediaFile>(
              `api/challengeQuestActivities/media`,
              formData
            );
          }

          return of(activity.mediaFile);
        })
      );
  }
}
