import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { DocumentsComponent } from "./containers";

const routes: Routes = [
  {
    path: "",
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      {
        path: "",
        component: DocumentsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class DocumentsRoutingModule {}
