import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from "@angular/core";
import {
  HintsEditorComponent,
  SenseiHelpEditorComponent,
} from "src/app/editors/components";
import { CodingStep } from "src/app/models";
import { ValidationListComponent } from "src/app/validation-builder";
import { EditInMakecodeComponent } from "../edit-in-makecode";

@Component({
  selector: "app-coding-step",
  templateUrl: "./coding-step.component.html",
  styleUrls: ["./coding-step.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodingStepComponent {
  @HostBinding("class") class = "app-coding-step";

  @Input() step: CodingStep;

  @ViewChild(HintsEditorComponent)
  hints: HintsEditorComponent;

  @ViewChild(SenseiHelpEditorComponent)
  senseiHelp: SenseiHelpEditorComponent;

  @ViewChild(EditInMakecodeComponent)
  codeEditor: EditInMakecodeComponent;

  @ViewChild(ValidationListComponent)
  validationItems: ValidationListComponent;

  get value(): CodingStep {
    return {
      ...this.step,
      hints: this.hints.value,
      senseiHelp: this.senseiHelp.value,
      project: this.codeEditor.project,
      validationItems: this.validationItems.items,
    };
  }

  get valid(): boolean {
    return !!(
      this.hints?.valid &&
      this.senseiHelp?.valid &&
      this.codeEditor?.valid &&
      this.validationItems?.valid
    );
  }

  get dirty(): boolean {
    return !!(
      this.hints?.dirty ||
      this.senseiHelp?.dirty ||
      this.codeEditor?.dirty ||
      this.validationItems?.dirty
    );
  }
}
