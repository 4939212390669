<div class="pagination">
  <button
    [disabled]="onFirstPage"
    class="nagivation-button"
    (click)="goPrevious()"
    mat-icon-button>
    <mat-icon [inline]="true">chevron_left</mat-icon>
  </button>
  <span
    class="page"
    *ngFor="let page of pages"
    [ngClass]="{ selected: pageIndex === page }"
    (click)="goPage(page)"></span>
  <button
    [disabled]="onLastPage"
    class="nagivation-button"
    (click)="goNext()"
    mat-icon-button>
    <mat-icon [inline]="true">chevron_right</mat-icon>
  </button>
</div>
