import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PerfectMatchValidationItem } from "src/app/models";
import { ChildFormBase, CHILD_FORM_TOKEN } from "../../directives";

@Component({
  selector: "app-perfect-match-validation-item",
  templateUrl: "./perfect-match-validation-item.component.html",
  styleUrls: ["./perfect-match-validation-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHILD_FORM_TOKEN,
      useExisting: forwardRef(() => PerfectMatchValidationItemComponent),
    },
  ],
})
export class PerfectMatchValidationItemComponent extends ChildFormBase {
  constructor(private fb: FormBuilder) {
    super();
  }

  @HostBinding("class") class = "app-perfect-match-validation-item";

  _form = this.fb.group({
    match: this.fb.control("", Validators.required),
  });

  get childForm(): FormGroup {
    return this._form;
  }

  setChildForm(item: PerfectMatchValidationItem): void {
    this._form.patchValue(item);
  }
}
