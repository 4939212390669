import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ProgramListComponent, EditProgramComponent } from "../components";

const routes: Routes = [
  {
    path: "",
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      { path: "programs", component: ProgramListComponent },
      { path: "programs/:programId", component: EditProgramComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProgramsRoutingModule {}
