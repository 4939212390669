import { Pipe, PipeTransform } from "@angular/core";

type TimeUnit =
  | "year"
  | "month"
  | "week"
  | "day"
  | "hour"
  | "minute"
  | "second";
type TimeUnitValue = { name: TimeUnit; value: number };

const TIME_UNITS: { [value: number]: TimeUnit } = {
  31536000: "year",
  2628000: "month",
  604800: "week",
  86400: "day",
  3600: "hour",
  60: "minute",
  1: "second",
};

@Pipe({
  name: "appRelativeDate",
})
export class RelativeDatePipe implements PipeTransform {
  transform(value: Date): string {
    const dateDiff = this.getDateDiff(value);
    const unit = this.selectTimeUnit(dateDiff);

    return this.generateString(dateDiff, unit);
  }

  private getDateDiff(date: Date): number {
    return (Date.now() - date.getTime()) / 1000;
  }

  private selectTimeUnit(dateDiffSeconds: number): TimeUnitValue {
    let name: TimeUnit = "second";
    let value = 1;

    Object.keys(TIME_UNITS).forEach((u) => {
      const unit = +u;

      if (dateDiffSeconds >= unit) {
        name = TIME_UNITS[u];
        value = unit;
        return -1;
      }
    });

    return { name, value };
  }

  private generateString(dateDiff: number, unit: TimeUnitValue): string {
    const value = Math.floor(dateDiff / unit.value);

    if (value === 0 && unit.name === "second") {
      return "just a moment";
    } else if (value > 1) {
      return `${value} ${unit.name}s`;
    } else {
      return `${value} ${unit.name}`;
    }
  }
}
