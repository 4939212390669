import { CodeViewerComponent } from "./code-viewer";
import { HintsEditorComponent } from "./hints-editor";
import { InstructionsEditorComponent } from "./instructions-editor";
import { IntroductionEditorComponent } from "./introduction-editor";
import {
  MakeCodeBlocksFilterComponent,
  MakecodeEditorComponent,
  MakecodeLanguageSelectorComponent,
} from "./makecode-editor";
import { RichTextEditorComponent } from "./rich-text-editor";
import { MediaEditorComponent } from "./media-editor";
import { NinjaRequirementsBuilderComponent } from "./ninja-requirements-builder";
import { SenseiHelpEditorComponent } from "./sensei-help-editor";

export const components = [
  CodeViewerComponent,
  HintsEditorComponent,
  InstructionsEditorComponent,
  IntroductionEditorComponent,
  MakecodeEditorComponent,
  RichTextEditorComponent,
  NinjaRequirementsBuilderComponent,
  SenseiHelpEditorComponent,
  MakeCodeBlocksFilterComponent,
  MakecodeLanguageSelectorComponent,
  MediaEditorComponent,
];

export {
  CodeViewerComponent,
  HintsEditorComponent,
  InstructionsEditorComponent,
  IntroductionEditorComponent,
  MakecodeEditorComponent,
  NinjaRequirementsBuilderComponent,
  SenseiHelpEditorComponent,
  MediaEditorComponent,
};
