import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State, stepsAdapter } from "./state";

const selectState = createFeatureSelector<State>(featureKey);
const selectStepsState = createSelector(selectState, (state) => state.steps);

export const selectBuildActivity = createSelector(
  selectState,
  (state) => state.activity
);

export const { selectAll: selectSteps } =
  stepsAdapter.getSelectors(selectStepsState);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);
