import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State } from "./state";

const selectState = createFeatureSelector<State>(featureKey);

export const selectDocumentsPage = createSelector(
  selectState,
  (state) => state.documentsPage
);

export const selectSelectedPage = createSelector(
  selectState,
  (state) => state.selectedPage
);

export const selectSelectedDocument = createSelector(
  selectState,
  (state) => state.selectedDocument
);

export const selectSortBy = createSelector(
  selectState,
  (state) => state.sortBy
);

export const selectFilters = createSelector(
  selectState,
  (state) => state.filters
);

export const selectSearch = createSelector(
  selectState,
  (state) => state.search
);

export const selectQueryParams = createSelector(
  selectSelectedPage,
  selectSortBy,
  selectFilters,
  selectSearch,
  (selectedPage, sortBy, filters, search) => ({
    ...selectedPage,
    ...filters,
    sortBy,
    search,
  })
);

export const selectTagMatches = createSelector(
  selectState,
  (state) => state.tagMatches
);

export const selectIsUpdating = createSelector(
  selectState,
  (state) => state.isUpdating
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);
