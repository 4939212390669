import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ChildFormBase } from "../../directives";

@Component({
  selector: "app-no-requirements-validation-item",
  template: `<p>✅ No Requirements</p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoRequirementsValidationItemComponent extends ChildFormBase {
  constructor(private fb: FormBuilder) {
    super();
  }

  @HostBinding("class") _class = "app-no-requirements-validation-item";

  private form = this.fb.group({});

  get childForm(): FormGroup {
    return this.form;
  }
}
