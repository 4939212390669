<div fxLayout="column" fxLayoutGap="16px">
  <app-group class="publish-actions-group">
    <div class="levels-title" fxLayout fxLayoutAlign="space-between center">
      <button
        [disabled]="syncing$ | async"
        mat-button
        class="dark-button"
        (click)="publishCourse('testing')">
        <div
          *ngIf="!(syncing$ | async) || button_pressed !== 'testing'"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mat-icon>cloud_upload</mat-icon>
          <span>Sync Belt to Staging</span>
        </div>
        <div
          *ngIf="(syncing$ | async) && button_pressed === 'testing'"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mat-icon class="spin">sync</mat-icon>
          <span>Syncing</span>
        </div>
      </button>
      <button
        [disabled]="
          !ALLOWED_EMAILS.includes(selectMail$ | async) || (syncing$ | async)
        "
        mat-button
        class="dark-button"
        (click)="publishCourse('published')">
        <div
          *ngIf="!(syncing$ | async) || button_pressed !== 'published'"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mat-icon>cloud_upload</mat-icon>
          <span>Sync Belt to Production</span>
        </div>
        <div
          *ngIf="(syncing$ | async) && button_pressed === 'published'"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mat-icon class="spin">sync</mat-icon>
          <span>Syncing</span>
        </div>
      </button>
    </div>
  </app-group>
  <app-group fxLayout="column">
    <div fxLayout>
      <app-icon-picker
        [icon]="iconControl.value"
        (iconChange)="onIconChange($event)">
      </app-icon-picker>
      <mat-form-field>
        <mat-label>Course Title</mat-label>
        <input matInput [formControl]="titleControl" />
      </mat-form-field>
      <div fxLayout fxLayoutAlign="center center">
        <button mat-icon-button><mat-icon>reply</mat-icon></button>
        <button
          mat-icon-button
          [appUserCanDelete]="'course'"
          (click)="deleteCourse()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleDetails()">
          <mat-icon>close_fullscreen</mat-icon>
        </button>
      </div>
    </div>
    <div [@bodyExpansion]="expandedState">
      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="70">
          <div>Course description</div>
          <app-rich-text-editor
            [formControl]="descriptionControl"
            placeholder="Please update description."
            [required]="true"></app-rich-text-editor>
        </div>
        <div fxFlex="30">
          <div>Course IDE</div>
          <mat-radio-group
            fxLayout="column"
            fxLayoutGap="8px"
            [style.margin]="'8px'"
            [(ngModel)]="selectedIDE">
            <mat-radio-button value="MakeCode">MakeCode</mat-radio-button>
            <mat-radio-button value="Scratch Jr" [disabled]="true">
              Scratch Jr
            </mat-radio-button>
            <mat-radio-button value="Unity" [disabled]="true">
              Unity
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-end" *ngIf="valid && dirty">
        <button color="primary" mat-raised-button (click)="updateCourse()">
          Update Course
        </button>
      </div>
    </div>
  </app-group>
  <app-group class="levels-group">
    <div class="levels-title" fxLayout fxLayoutAlign="space-between center">
      <div class="level-overview-title">Level Overview</div>
      <div fxLayoutAlign="center center">
        <button mat-button class="dark-button" (click)="addLevel()">
          <div fxLayoutAlign="center center" fxLayoutGap="4px">
            <mat-icon>add_circle_outline</mat-icon>
            <span>Add Level</span>
          </div>
        </button>
      </div>
    </div>
    <app-level-list
      [levels]="levels$ | async"
      (duplicate)="onDuplicateLevel($event)"
      (delete)="onDeleteLevel($event)"
      (rearrange)="onRearrangeLevels($event)">
    </app-level-list>
  </app-group>
</div>
