import { Component, HostBinding, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-introduction-editor",
  templateUrl: "./introduction-editor.component.html",
  styleUrls: ["./introduction-editor.component.scss"],
})
export class IntroductionEditorComponent {
  introductionControl = new FormControl("", Validators.required);

  @HostBinding("class") class = "app-introduction-editor";

  @Input() set value(value: string) {
    this.introductionControl.reset();
    this.introductionControl.setValue(value);
  }

  get value(): string {
    return this.introductionControl.value;
  }

  get valid(): boolean {
    return this.introductionControl.valid;
  }

  get dirty(): boolean {
    return this.introductionControl.dirty;
  }
}
