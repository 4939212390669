import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { LevelUpActivityStepBadge } from "src/app/models";

@Component({
  selector: "app-level-up-activity-step-badge-list",
  templateUrl: "./level-up-activity-step-badge-list.component.html",
  styleUrls: ["./level-up-activity-step-badge-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelUpActivityStepBadgeListComponent {
  constructor(private fb: FormBuilder) {}

  @HostBinding("class") class = "app-level-up-activity-step-badge-list";

  @Input() set badges(badges: LevelUpActivityStepBadge[]) {
    this.myBadges.clear();
    this.myBadges.reset();
    badges?.forEach((b) => this.myBadges.push(this.badgeToFormGroup(b)));
  }

  get badges(): LevelUpActivityStepBadge[] {
    return this.myBadges.controls.map(
      (fg: FormGroup) =>
        ({
          ...fg.value,
          icon: fg.value.icon?.id,
        } as LevelUpActivityStepBadge)
    );
  }

  get _badges(): FormArray {
    return this.myBadges;
  }

  get valid(): boolean {
    return this.myBadges.valid;
  }

  get dirty(): boolean {
    return this.myBadges.dirty;
  }

  private myBadges = new FormArray([]);

  onBadgeRemove(index: number): void {
    this.removeBadgeAt(index);
  }

  onBadgeAdd(): void {
    this.addBadge();
  }

  private addBadge(): void {
    this.myBadges.push(this.badgeToFormGroup());
    this.myBadges.markAsDirty();
  }

  private removeBadgeAt(index: number): void {
    this.myBadges.removeAt(index);
    this.myBadges.markAsDirty();
  }

  private badgeToFormGroup(
    badge: LevelUpActivityStepBadge = { title: "", description: "" }
  ): FormGroup {
    return this.fb.group({
      icon: [!!badge.icon ? { id: badge.icon } : null],
      title: [badge.title, [Validators.required]],
      description: [badge.description],
    });
  }
}
