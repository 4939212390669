import { createSelector } from "@ngrx/store";
import { BuildActivityStoreSelectors } from "../components/build-activity/store";
import { ChallengeQuestActivityStoreSelectors } from "../components/challenge-quest-activity/store";
import { CodeAdventureActivityStoreSelectors } from "../components/code-adventure-activity/store";
import { DiscoverActivityStoreSelectors } from "../components/discover-activity/store";
import { ExploreActivityStoreSelectors } from "../components/explore-activity/store";
import { SolveActivityStoreSelectors } from "../components/solve-activity/store";

export const selectIsLoading = createSelector(
  BuildActivityStoreSelectors.selectIsLoading,
  ChallengeQuestActivityStoreSelectors.selectIsLoading,
  CodeAdventureActivityStoreSelectors.selectIsLoading,
  DiscoverActivityStoreSelectors.selectIsLoading,
  ExploreActivityStoreSelectors.selectIsLoading,
  SolveActivityStoreSelectors.selectIsLoading,
  (...isLoadings) => isLoadings.find((isLoading) => isLoading)
);
