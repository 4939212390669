import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../directives";
import { EditorsModule } from "../editors";
import { ImagesModule } from "../images";
import { SharedModule } from "../shared";
import { components } from "./components";
import { containers } from "./containers";
import { DocumentsRoutingModule } from "./documents-routing.module";
import { TagsModule } from "src/app/tags/tags.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DirectivesModule,
    DocumentsRoutingModule,
    EditorsModule,
    ImagesModule,
    TagsModule,
  ],
  declarations: [...containers, ...components],
  exports: [...containers],
})
export class DocumentsModule {}
