import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Icon } from "src/app/models";

@Component({
  selector: "app-icon-list",
  templateUrl: "./icon-list.component.html",
  styleUrls: ["./icon-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconListComponent {
  @Input() icons: Icon[];

  @Input() selectedIconId: number;

  @Output() selectIcon = new EventEmitter<Icon>();

  get dataCount(): number {
    return this.icons.length;
  }

  select(icon: Icon): void {
    this.selectIcon.emit(icon);
  }
}
