<div class="upload-wraper">
  <div class="selected-area">
    <span class="icon-name">
      {{ selectedIcon?.name ?? "No Icon" }}
    </span>
    <label class="selected-from-hint">
      selected for <span class="bold">Upload Area</span>
    </label>
  </div>
  <app-file-uploader (uploadFile)="onImageChange($event)">
    <div class="upload-area">
      <h4>Drag & Drop File</h4>
      or
      <button color="primary" mat-raised-button>
        {{ actionTitle }}
      </button>
    </div>
  </app-file-uploader>
  <a
    *ngIf="!!selectedIcon?.id"
    class="delete-button"
    mat-button
    (click)="onDeleteButtonClick()">
    Delete
  </a>
</div>
