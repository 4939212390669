import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { AppState, identifyCourse, Course, Program } from "src/app/models";

export const featureKey = "edit-program";

export interface CoursesState extends EntityState<Course> {}

export interface State extends AppState {
  program: Program;
  courses: CoursesState;
  selectedCourse: Course;
}

export const coursesAdapter = createEntityAdapter<Course>({
  selectId: identifyCourse,
  sortComparer: (a, b) => a.order - b.order,
});

export const initialState: State = {
  program: null,
  courses: coursesAdapter.getInitialState(),
  selectedCourse: null,
  isLoading: false,
  error: "",
};
