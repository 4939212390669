import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ValidationItem, ValidationItemType } from "src/app/models";
import {
  ValidationItemDialogComponent,
  ValidationItemDialogResult,
} from "../../dialogs/validation-item-dialog";

@Component({
  selector: "app-validation-list-item",
  templateUrl: "./validation-list-item.component.html",
  styleUrls: ["./validation-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationListItemComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  @HostBinding("class") _class = "app-validation-list-item";

  @Output() delete = new EventEmitter<void>();

  @Input() set item(item: ValidationItem) {
    if (!this.originalItem) {
      this.originalItem = item;
    }

    this._form.patchValue(item, { emitEvent: false });

    this._requirementsFormArray.clear({ emitEvent: false });
    item?.requirements?.forEach((r) =>
      this._requirementsFormArray.push(this.fb.control(r), {
        emitEvent: false,
      })
    );
  }

  get item(): ValidationItem {
    return this._form.value;
  }

  get valid(): boolean {
    return this._form.valid;
  }

  get dirty(): boolean {
    return this._form.dirty;
  }

  _form = this.fb.group({
    text: this.fb.control(""),
    type: this.fb.control(
      "no-errors" as ValidationItemType,
      Validators.required
    ),
    points: this.fb.control(0, Validators.min(0)),
    failureText: this.fb.control(""),
    requirements: this.fb.array([]),
    mustCompleteAll: this.fb.control(Validators.required),
    wildCardVariableName: this.fb.control(""),
    wildCardParam1: this.fb.control("Ignore"),
    wildCardParam2: this.fb.control(""),
  });

  get _requirementsFormArray(): FormArray {
    return this._form.get("requirements") as FormArray;
  }

  get _text(): string {
    return this._form.controls["text"].value;
  }

  get typeFormControl(): FormControl {
    return this._form.get("type") as FormControl;
  }

  get wildCardVariableNameControl(): FormControl {
    return this._form.get("wildCardVariableName") as FormControl;
  }

  get wildCardParam1Control(): FormControl {
    return this._form.get("wildCardParam1") as FormControl;
  }

  get wildCardParam2Control(): FormControl {
    return this._form.get("wildCardParam2") as FormControl;
  }

  _pointsArray = new Array(11);

  private originalItem: ValidationItem;

  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this._form.controls["type"].valueChanges
      .pipe(
        filter(
          (type: ValidationItemType) =>
            type === "contains" ||
            type === "perfect-match" ||
            type === "variable-validation"
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((type) => this.openItem(type));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  openItem(type?: ValidationItemType): void {
    const dialogRef = this.dialog.open(ValidationItemDialogComponent, {
      disableClose: true,
      width: "726px",
    });

    const item: ValidationItem = this._form.value;
    if (type && type !== item.type) {
      item.type = type;
      item.requirements = [];
    }

    dialogRef.componentInstance.item = item;

    dialogRef
      .afterClosed()
      .subscribe(({ type, item, isDirty }: ValidationItemDialogResult) => {
        switch (type) {
          case "update":
            this.item = item;
            if (isDirty) {
              this._form.markAsDirty();
            }
            this.cdr.markForCheck();
            break;
          case "delete":
            this.delete.emit();
            break;
          case "cancel":
            this.item = this.originalItem;
            this.cdr.markForCheck();
            break;
        }
      });
  }
}
