import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State, stepsAdapter, subActivitiesAdapter } from "./state";

const selectState = createFeatureSelector<State>(featureKey);
const selectSubActivitiesState = createSelector(
  selectState,
  (state) => state.subActivities
);
const selectStepsState = createSelector(selectState, (state) => state.steps);

export const selectExploreActivity = createSelector(
  selectState,
  (state) => state.activity
);

const { selectAll: selectSubActivitiesFn } = subActivitiesAdapter.getSelectors(
  selectSubActivitiesState
);

export const selectSubActivities = createSelector(
  selectSubActivitiesFn,
  (s) => s
);

export const { selectAll: selectSteps } =
  stepsAdapter.getSelectors(selectStepsState);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const levelId = createSelector(
  selectState,
  (state) => state.activity.levelId
);

export const selectError = createSelector(selectState, (state) => state.error);
