import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { Document } from "src/app/models";

@Component({
  selector: "app-document-box",
  templateUrl: "./document-box.component.html",
  styleUrls: ["./document-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentBoxComponent {
  @HostBinding("class") class = "app-document-box";

  @Input() document: Document;

  @Input()
  @HostBinding("class.light-theme")
  selected: boolean;
  textPreview = false;

  get title(): string {
    return this.document?.title;
  }

  onImageError(): void {
    this.textPreview = true;
  }
}
