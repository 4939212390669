import { Component, HostBinding, Input } from "@angular/core";
import * as hljs from "highlight.js";

@Component({
  selector: "app-code-viewer",
  templateUrl: "./code-viewer.component.html",
  styleUrls: ["./code-viewer.component.scss"],
})
export class CodeViewerComponent {
  @HostBinding("class") class = "app-code-viewer ";

  @Input() set code(code: string) {
    this.codeHtmlContent = hljs.highlightAuto(code || "// No code here", [
      "typescript",
    ]).value;
  }

  codeHtmlContent: string;
}
