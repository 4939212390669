import { EditLevelComponent } from "./edit-level";
import { LevelComponent } from "./level";
import { LevelBreadcrumbsComponent } from "./level-breadcrumbs";
import { LevelListComponent } from "./level-list";
import { LevelStatusBarComponent } from "./level-status-bar";
import { LevelStatusButtonComponent } from "./level-status-button";

export const components = [
  EditLevelComponent,
  LevelBreadcrumbsComponent,
  LevelComponent,
  LevelListComponent,
  LevelStatusBarComponent,
  LevelStatusButtonComponent,
];

export {
  EditLevelComponent,
  LevelBreadcrumbsComponent,
  LevelComponent,
  LevelListComponent,
  LevelStatusBarComponent,
  LevelStatusButtonComponent,
};
