import { BlockNamespace } from "./block-namespaces";

export interface MakecodeProject {
  editor?: MakeCodeEditorConfig;
  source: MakeCodeSource;
  languageRestrictions?: LanguageRestriction;
}

export interface MakeCodeSource {
  header: MakecodeProjectHeader;
  text: {
    [x: string]: string;
  };
}

export interface MakeCodeEditorConfig {
  searchBar?: boolean;
  filters: BlockNamespace[];
}

export interface MakecodeProjectHeader {
  modificationTime: number;
  cloudUserId?: string;
  targetVersion: string;
}

export type LanguageRestriction =
  | ""
  | "python-only"
  | "javascript-only"
  | "blocks-only"
  | "no-blocks"
  | "no-python"
  | "no-javascript";

export type MakeCodeLanguage = "javascript" | "python" | "blocks";

export const availableLanguages: MakeCodeLanguage[] = [
  "blocks",
  "javascript",
  "python",
];

export const getLanguagesFromOption = (
  option: LanguageRestriction
): MakeCodeLanguage[] => {
  if (option === "") {
    return [...availableLanguages];
  }
  const [positive, language] = extractLanguageInformationFromOption(option);

  if (positive) {
    return [language];
  } else {
    return availableLanguages.filter((l) => l !== language);
  }
};

const extractLanguageInformationFromOption = (
  option: LanguageRestriction
): [boolean, MakeCodeLanguage] => {
  let language = "";
  let positive = false;

  if (option.includes("no-")) {
    language = option.substr(3, option.length);
  } else {
    language = option.substr(0, option.length - 5);
    positive = true;
  }

  return [positive, language as MakeCodeLanguage];
};

export const getLanguageOptionFromLanguages = (
  languages: MakeCodeLanguage[]
): LanguageRestriction => {
  if (languages.length === 0) {
    throw new Error("invalid language combination");
  }

  if (languages.length === 3) {
    return "";
  }

  let result = "";
  if (languages.length === 2) {
    const missingLanguage = availableLanguages.filter(
      (availableLanguage) => !languages.includes(availableLanguage)
    )[0];
    result = `no-${missingLanguage}`;
  } else {
    result = `${languages[0]}-only`;
  }

  return result as LanguageRestriction;
};
