import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../directives";
import { EditorsModule } from "../editors";
import { ImagesModule } from "../images";
import { QuizBuilderModule } from "../quiz-builder";
import { SharedModule } from "../shared";
import { ValidationBuilderModule } from "../validation-builder";
import { components } from "./components";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    SharedModule,
    EditorsModule,
    QuizBuilderModule,
    ValidationBuilderModule,
    ImagesModule,
    DragDropModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class ActivitiesModule {}
