import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, State, coursesAdapter } from "./state";
import * as FeatureActions from "./actions";
import { Course, identifyCourse } from "src/app/models";

const featureReducer = createReducer<State>(
  initialState,
  on(FeatureActions.loadProgram, (state, { programId }) => {
    let newState = state;
    if (state.program?.id !== programId) {
      newState = initialState;
    }

    return { ...newState, isLoading: true };
  }),
  on(
    FeatureActions.loadCourses,
    FeatureActions.addCourse,
    FeatureActions.deleteCourseConfirm,
    FeatureActions.duplicateCourse,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(FeatureActions.loadProgramSuccess, (state, { program }) => ({
    ...state,
    isLoading: false,
    program,
  })),
  on(FeatureActions.loadCoursesSuccess, (state, { courses }) => {
    let selectedCourse: Course;
    if (state.selectedCourse) {
      selectedCourse = courses.find((c) => c.id === state.selectedCourse.id);
    }

    if (!selectedCourse && courses.length) {
      selectedCourse = courses[0];
    }

    return {
      ...state,
      isLoading: false,
      courses: coursesAdapter.setAll(courses, state.courses),
      selectedCourse,
    };
  }),
  on(FeatureActions.changeSelectedCourse, (state, { course }) => ({
    ...state,
    selectedCourse: course,
  })),
  on(FeatureActions.rearrange, (state, { courses }) => ({
    ...state,
    isLoading: true,
    activities: coursesAdapter.updateMany(
      courses.map((c) => ({
        id: identifyCourse(c),
        changes: c,
      })),
      state.courses
    ),
  })),
  on(FeatureActions.deleteCourseSuccess, (state) => ({
    ...state,
    isLoading: false,
    selectedCourse: null,
  })),
  on(
    FeatureActions.addCourseSuccess,
    FeatureActions.duplicateCourseSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FeatureActions.loadProgramFailure,
    FeatureActions.loadCoursesFailure,
    FeatureActions.addCourseFailure,
    FeatureActions.deleteCourseFailure,
    FeatureActions.duplicateCourseFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
