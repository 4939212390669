<div fxLayout="column" fxLayoutGap="4px">
  <p>Program Display</p>
  <app-filter-box>
    <div fxLayout="column" fxLayoutGap="5px">
      <mat-slide-toggle [(ngModel)]="includeCreate">
        Tagged: CREATE
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="includeCamps">
        Tagged: Camps
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="includeJr">Tagged: Jr</mat-slide-toggle>
    </div>
  </app-filter-box>
</div>
