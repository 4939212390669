import { Component, HostBinding, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppStoreState } from "src/app/store";
import { Program } from "src/app/models";
import { Observable } from "rxjs";
import { selectLastEditDate, selectPrograms } from "./store/selectors";
import * as FeatureActions from "./store/actions";

@Component({
  selector: "app-program-list",
  templateUrl: "./program-list.component.html",
  styleUrls: ["./program-list.component.scss"],
})
export class ProgramListComponent implements OnInit {
  constructor(private store$: Store<AppStoreState>) {}

  @HostBinding("class") class = "app-program-list";

  programs$: Observable<Program[]> = this.store$.pipe(select(selectPrograms));
  lastUpdated$: Observable<Date> = this.store$.pipe(select(selectLastEditDate));

  ngOnInit(): void {
    this.store$.dispatch(FeatureActions.loadPrograms());
  }

  addProgram(): void {
    this.store$.dispatch(FeatureActions.addProgram());
  }

  editProgram(program: Program): void {
    this.store$.dispatch(FeatureActions.editProgram({ program }));
  }

  toggleDisabled(program: Program): void {
    this.store$.dispatch(FeatureActions.toggleProgramDisabled({ program }));
  }
}
