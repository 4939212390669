<div fxLayout fxLayoutAlign="start center">
  <a
    *ngIf="previous"
    class="back-button"
    mat-raised-button
    [routerLink]="previous.routerLink">
    <div class="back-button-wrapper">
      <mat-icon>chevron_left</mat-icon>
      <span>{{ previous.title | uppercase }}</span>
    </div>
  </a>
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <ng-container *ngIf="!last; else lastBreadcrumb">
      <a mat-button [routerLink]="breadcrumb.routerLink">
        {{ breadcrumb.title }}
      </a>
      <span>/</span>
    </ng-container>

    <ng-template #lastBreadcrumb>
      <button mat-button>
        {{ breadcrumb.title }}
      </button>
    </ng-template>
  </ng-container>
</div>
