import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { merge, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { YesNoDialogComponent } from "src/app/dialogs";
import { EditProgramStoreSelectors } from "src/app/programs/components/edit-program/store";
import { LevelsService, CoursesService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private levelsService: LevelsService,
    private coursesService: CoursesService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  triggerLoadLevels$ = createEffect(() =>
    merge(
      this.store$.pipe(
        select(EditProgramStoreSelectors.selectSelectedCourse),
        filter((course) => !!course),
        map(({ id }) => id)
      ),
      this.actions$.pipe(
        ofType(FeatureActions.addLevelSuccess),
        withLatestFrom(
          this.store$.pipe(
            select(EditProgramStoreSelectors.selectSelectedCourse),
            filter((course) => !!course)
          )
        ),
        map(([_, { id }]) => id)
      ),
      this.actions$.pipe(
        ofType(FeatureActions.copyLevelSuccess),
        withLatestFrom(
          this.store$.pipe(
            select(EditProgramStoreSelectors.selectSelectedCourse),
            filter((course) => !!course)
          )
        ),
        map(([_, { id }]) => id)
      )
    ).pipe(map((courseId) => FeatureActions.loadLevels({ courseId })))
  );

  loadLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadLevels),
      switchMap(({ courseId }) =>
        this.levelsService.getLevelsByCourseId(courseId).pipe(
          map((levels) => FeatureActions.loadLevelsSuccess({ levels })),
          catchError((error) => of(FeatureActions.loadLevelsFailure({ error })))
        )
      )
    )
  );

  addLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addLevel),
      exhaustMap(({ courseId }) =>
        this.levelsService.addLevel(courseId).pipe(
          map(() => FeatureActions.addLevelSuccess()),
          catchError((error) => of(FeatureActions.addLevelFailure({ error })))
        )
      )
    )
  );

  copyLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.copyLevel),
      exhaustMap(({ levelId }) =>
        this.levelsService.copyLevel(levelId).pipe(
          map(() => FeatureActions.copyLevelSuccess()),
          catchError((error) => of(FeatureActions.copyLevelFailure({ error })))
        )
      )
    )
  );

  confirmDeleteLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteLevel),
      switchMap(({ level }) =>
        this.dialog
          .open(YesNoDialogComponent)
          .afterClosed()
          .pipe(
            map((confirm) =>
              !!confirm
                ? FeatureActions.deleteLevelConfirm({ level })
                : FeatureActions.deleteLevelCancel()
            )
          )
      )
    )
  );

  deleteLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteLevelConfirm),
      switchMap(({ level }) =>
        this.levelsService.delete(level).pipe(
          switchMap(() => [
            FeatureActions.deleteLevelSuccess(),
            FeatureActions.loadLevels({ courseId: level.courseId }),
          ]),
          catchError((error) =>
            of(FeatureActions.deleteLevelFailure({ error }))
          )
        )
      )
    )
  );

  updateCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateCourse),
      exhaustMap(({ course }) =>
        this.coursesService.update(course).pipe(
          map(() => FeatureActions.updateCourseSuccess()),
          catchError((error) =>
            of(FeatureActions.updateCourseFailure({ error }))
          )
        )
      )
    )
  );

  updateLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.rearrange),
      withLatestFrom(
        this.store$.pipe(select(EditProgramStoreSelectors.selectSelectedCourse))
      ),
      switchMap(([{ levels }, { id }]) =>
        this.levelsService.updateLevels(id, levels).pipe(
          map(() => FeatureActions.rearrangeSuccess()),
          catchError((error) => of(FeatureActions.rearrangeFailure({ error })))
        )
      )
    )
  );

  displayCourseUpdatedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.updateCourseSuccess),
        tap(() => this.displayMessage("Updated successfully!"))
      ),
    {
      dispatch: false,
    }
  );

  displayLevelAddedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.addLevelSuccess),
        tap(() => this.displayMessage("Added successfully!"))
      ),
    {
      dispatch: false,
    }
  );

  displayLevelDeletedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.deleteLevelSuccess),
        tap(() => this.displayMessage("Deleted successfully!"))
      ),
    {
      dispatch: false,
    }
  );

  private displayMessage(message: string): void {
    this.snackbar.open(message, "OK", { duration: 3000 });
  }
}
