import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-icon-pagination",
  templateUrl: "./icon-pagination.component.html",
  styleUrls: ["./icon-pagination.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPaginationComponent {
  pages: number[] = [];

  @Output() goToPage = new EventEmitter<number>();

  @Input() set pageIndex(index: number) {
    this.myPageIndex = index;
  }

  get pageIndex(): number {
    return this.myPageIndex;
  }

  @Input() set totalPages(totalPages: number) {
    this.myTotalPages = totalPages;
    this.pages = Array(this.totalPages)
      .fill(0)
      .map((_, i) => i);
  }

  get totalPages(): number {
    return this.myTotalPages;
  }

  get onLastPage(): boolean {
    return !this.totalPages || this.pageIndex === this.totalPages - 1;
  }

  get onFirstPage(): boolean {
    return this.pageIndex === 0;
  }

  private myTotalPages = 1;
  private myPageIndex = 0;

  goNext(): void {
    if (!this.onLastPage) {
      this.goPage(this.pageIndex + 1);
    }
  }

  goPrevious(): void {
    if (!this.onFirstPage) {
      this.goPage(this.pageIndex - 1);
    }
  }

  protected goPage(pageIndex: number): void {
    this.goToPage.emit(pageIndex);
  }
}
