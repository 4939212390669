import { IdSelectorStr } from "@ngrx/entity/src/models";
export interface Level {
  id?: number;
  courseId: number;
  sortOrder: number;
  title: string;
  description?: string;
  mustCompletePrevious?: boolean;
  status?: LevelStatus;
  tags: string[];
}

export type Levels = Level[];

// export type LevelStatus =
//   | 'draft'
//   | 'testing-requested'
//   | 'testing-error'
//   | 'testing'
//   | 'publish-requested'
//   | 'publish-error'
//   | 'published';

export type LevelStatus =
  | "draft"
  | "testing_ready"
  | "testing"
  | "prod_ready"
  | "published";

export type PublishableEntities = "level" | "course" | "program";

export const identifyLevel: IdSelectorStr<Level> = (l) => `${l.id}`;
