import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, Subscription } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";
import { Course, Courses, Program } from "src/app/models";
import { AppStoreState } from "src/app/store";
import * as FeatureSelectors from "./store/selectors";
import * as FeatureActions from "./store/actions";
import { environment } from "src/environments/environment";
import { selectMail } from "src/app/authentication/store/selectors";
import { publishLevel } from "src/app/levels/components/level/store/actions";
import { selectIsSyncing } from "src/app/levels/components/level/store/selectors";

@Component({
  selector: "app-edit-program",
  templateUrl: "./edit-program.component.html",
  styleUrls: ["./edit-program.component.scss"],
})
export class EditProgramComponent implements OnDestroy, OnInit {
  constructor(private store$: Store<AppStoreState>) {
    this.selectedCourse$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((course) => (this.selectedCourse = course));
  }

  @HostBinding("class") class = "app-edit-program";

  private stateSubscription: Subscription;

  button_pressed = "";

  program$: Observable<Program> = this.store$.pipe(
    select(FeatureSelectors.selectProgram)
  );

  courses$: Observable<Courses> = this.store$.pipe(
    select(FeatureSelectors.selectCourses),
    delay(0)
  );

  selectedCourse$: Observable<Course> = this.store$.pipe(
    select(FeatureSelectors.selectSelectedCourse),
    delay(0)
  );

  ALLOWED_EMAILS = environment.publishAllowedMails.map((mail) =>
    mail.toLowerCase()
  );
  selectMail$: Observable<string> = this.store$.select(selectMail);
  syncing$: Observable<boolean> = this.store$.select(selectIsSyncing);

  selectedCourse: Course;

  private destroyed$ = new Subject<void>();

  ngOnInit() {
    this.stateSubscription = this.store$
      .select(selectIsSyncing)
      .subscribe((isSyncing) => {
        if (!isSyncing) this.button_pressed = "";
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
    this.stateSubscription.unsubscribe();
  }

  drop(
    courses: Courses,
    { previousIndex, currentIndex }: CdkDragDrop<string[]>
  ): void {
    moveItemInArray(courses, previousIndex, currentIndex);
    const newSort = courses.map((c, i) => ({ ...c, order: i }));
    this.store$.dispatch(FeatureActions.rearrange({ courses: newSort }));
  }

  selectCourse(course: Course): void {
    this.store$.dispatch(FeatureActions.changeSelectedCourse({ course }));
  }

  addCourse(): void {
    this.store$.dispatch(FeatureActions.addCourse());
  }

  deleteCourse({ id: courseId }: Course): void {
    this.store$.dispatch(FeatureActions.deleteCourse({ courseId }));
  }

  duplicateCourse(courseId: number): void {
    this.store$.dispatch(FeatureActions.duplicateCourse({ courseId }));
  }

  publishCourse(status, programName) {
    let magicWord = prompt(
      `You are about to publish ${programName} program to "${
        status === "published" ? "Production" : "Staging"
      }". Please type "${programName}" to confirm.`
    );
    if (!magicWord) return;
    if (magicWord.toLowerCase() === programName.toLowerCase()) {
      this.button_pressed = status;
      this.store$.dispatch(publishLevel({ status: status, entity: "program" }));
    } else {
      alert("Wrong input!! Please type correct spelling");
    }
  }
}
