import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { delay } from "rxjs/operators";
import { ActivityType } from "src/app/models";
import { AppStoreSelectors, AppStoreState } from "src/app/store";
import { ActivityTitleComponent } from "./activity-title.component";

@Component({
  template: ``,
})
export abstract class ActivityEditorBaseComponent implements OnDestroy {
  constructor(protected store$: Store<AppStoreState>) {}

  @ViewChild(ActivityTitleComponent)
  title: ActivityTitleComponent;

  activityType: ActivityType;

  public isLoading$: Observable<boolean> = this.store$.pipe(
    select(AppStoreSelectors.selectIsLoading),
    delay(0)
  );

  public collapsed = false;

  protected destroyed$ = new Subject<void>();

  public deleteActivity(): void {
    this.store$.dispatch(this.getDeleteAction());
  }

  public updateActivity(): void {
    this.store$.dispatch(this.getUpdateAction());
  }

  protected abstract getDeleteAction(): Action;
  protected abstract getUpdateAction(): Action;

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
