import { EditProgramComponent } from "./edit-program/edit-program.component";
import { ProgramFormComponent } from "./program-form";
import { ProgramListComponent } from "./program-list/program-list.component";

export const components = [
  EditProgramComponent,
  ProgramListComponent,
  ProgramFormComponent,
];

export { EditProgramComponent, ProgramListComponent, ProgramFormComponent };
