import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";

@Component({
  templateUrl: "./edit-text-dialog.component.html",
})
export class EditTextDialogComponent {
  title: string;
  label: string;
  textControl = new FormControl("", [Validators.required]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.title = data.title ? data.title : "";
    this.label = data.label ? data.label : "";
  }
}

interface DialogData {
  title: string;
  label: string;
}
