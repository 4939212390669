<div
  [formGroup]="_form"
  fxLayout
  fxLayoutAlign="center center"
  fxLayoutGap="8px">
  <button mat-button fxFlex="50" (click)="openItem()">
    <p class="text">{{ _text }}</p>
  </button>
  <app-validation-item-type-control
    fxFlex="40"
    formControlName="type"></app-validation-item-type-control>
  <mat-form-field fxFlex="10">
    <mat-select formControlName="points">
      <mat-option *ngFor="let p of _pointsArray; let i = index" [value]="i">
        {{ i }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
