import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppStoreState } from "../store";
import * as FeatureActions from "../store/actions";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppStoreState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes("graph.microsoft")) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        const { error } = response;
        this.store.dispatch(
          FeatureActions.noticeHttpError({ error: error || response })
        );
        return throwError(response);
      })
    );
  }
}
