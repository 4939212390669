import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatRipple } from "@angular/material/core";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent {
  @ViewChild(MatRipple) rippleRef?: MatRipple;
  @HostBinding("class") _class = "";

  @Input() accept = "image/png,image/jpeg,.svg";
  @Input() disableDop = false;

  @Output() uploadFile = new EventEmitter<File>();

  onFileChange(target: HTMLInputElement): void {
    if (target.files && target.files.length) {
      const targetFile = target.files.item(0);
      this.uploadFile.emit(targetFile);
    }
  }

  onFileDrop(e: DragEvent): void {
    if (this.disableDop) {
      return;
    }

    this.rippleRef?.launch(e.x, e.y);

    const files: File[] = Array.prototype.slice.call(
      e.dataTransfer?.files ?? []
    );

    if (files.length) {
      this.uploadFile.emit(files[0]);
    }

    this._class = "";
    e.preventDefault();
  }

  onDragEnter(e: DragEvent): void {
    this._class = "drag-over";
    e.preventDefault();
  }

  onDragLeave(e: DragEvent): void {
    this._class = "";
    e.preventDefault();
  }

  onDragOver(e: DragEvent): void {
    e.preventDefault();
  }
}
