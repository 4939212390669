import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { Effects } from "./store/effects";
import { reducer } from "./store/reducer";
import { featureKey } from "./store/state";

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([Effects]),
  ],
})
export class DocumentsStoreModule {}
