<app-feature *ngIf="program$ | async as program">
  <app-feature-title>{{ program.name }}</app-feature-title>

  <app-panel>
    <app-panel-title>Courses</app-panel-title>
    <div fxLayout>
      <div
        class="course-list-container"
        fxFlex="30"
        fxFlex.lg="25"
        fxFlex.gt-lg="15">
        <app-group>
          <mat-action-list
            fxLayout="column"
            fxLayoutGap="12px"
            style="flex: 1; overflow-y: auto; position: relative">
            <mat-list-item
              class="add-course-button dark-button"
              (click)="addCourse()">
              <span fxFlex fxLayoutAlign="center center">Add Course</span>
              <mat-icon>add_circle_outline</mat-icon>
            </mat-list-item>
            <div
              *ngIf="courses$ | async as courses"
              fxLayout="column"
              fxLayoutGap="12px"
              cdkDropList
              (cdkDropListDropped)="drop(courses, $event)">
              <mat-list-item
                *ngFor="let course of courses"
                cdkDragLockAxis="y"
                cdkDrag
                [class.selected]="selectedCourse?.id === course.id"
                (click)="selectCourse(course)">
                <div class="draggable" fxLayoutAlign="center center">
                  <mat-icon [inline]="true">drag_indicator</mat-icon>
                </div>
                <!-- icon goes here -->
                <span fxFlex fxLayoutAlign="center center">
                  {{ course.title }}
                </span>

                <div fxLayoutGap="4px">
                  <button mat-icon-button (click)="duplicateCourse(course.id)">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </div>
            <div fxLayout="column" fxLayoutGap="12px" class="end">
              <mat-list-item
                class="add-course-button dark-button"
                (click)="publishCourse('testing', program.name)"
                [disabled]="syncing$ | async"
                [class.disabled]="syncing$ | async">
                <div
                  *ngIf="!(syncing$ | async) || button_pressed !== 'testing'">
                  <span fxFlex fxLayoutAlign="center center"
                    >Sync Program to Staging</span
                  >
                  <mat-icon>cloud_upload</mat-icon>
                </div>
                <div *ngIf="(syncing$ | async) && button_pressed === 'testing'">
                  <span fxFlex fxLayoutAlign="center center">Syncing</span>
                  <mat-icon class="spin">sync</mat-icon>
                </div>
              </mat-list-item>
              <mat-list-item
                [disabled]="
                  !ALLOWED_EMAILS.includes(selectMail$ | async) ||
                  (syncing$ | async)
                "
                [class.disabled]="
                  !ALLOWED_EMAILS.includes(selectMail$ | async) ||
                  (syncing$ | async)
                "
                class="add-course-button dark-button"
                (click)="publishCourse('published', program.name)">
                <div
                  *ngIf="!(syncing$ | async) || button_pressed !== 'published'">
                  <span fxFlex fxLayoutAlign="center center"
                    >Sync Program to Production</span
                  >
                  <mat-icon>cloud_upload</mat-icon>
                </div>
                <div
                  *ngIf="(syncing$ | async) && button_pressed === 'published'">
                  <span fxFlex fxLayoutAlign="center center">Syncing</span>
                  <mat-icon class="spin">sync</mat-icon>
                </div>
              </mat-list-item>
            </div>
          </mat-action-list>
        </app-group>
      </div>

      <div
        class="course-container"
        fxLayout="column"
        fxFlex="70"
        fxFlex.lg="75"
        fxFlex.gt-lg="85"
        *ngIf="selectedCourse">
        <app-edit-course
          [course]="selectedCourse"
          (delete)="deleteCourse(selectedCourse)"></app-edit-course>
      </div>
    </div>
  </app-panel>
</app-feature>
