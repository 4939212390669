<mat-card>
  <div class="ql-container ql-snow preview" mat-card-image>
    <div
      *ngIf="textPreview; else imagePreview"
      [innerHtml]="document.text"></div>
  </div>
  <mat-card-content>
    <p>{{ title }}</p>
  </mat-card-content>
</mat-card>

<ng-template #imagePreview>
  <img
    [src]="document | appImageUrl : 'documentImage'"
    (error)="onImageError()" />
</ng-template>
