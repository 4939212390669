import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Icon, PaginatedResult } from "src/app/models";

export const loadIconPage = createAction("[Icon Library] Load icon page");
export const loadIconPageSuccess = createAction(
  "[Icon Library] Load icon page success",
  props<{ iconPage: PaginatedResult<Icon> }>()
);
export const loadIconPageFailure = createAction(
  "[Icon Library] Load icon page failure",
  props<{ error: HttpErrorResponse }>()
);

export const uploadIcon = createAction(
  "[Icon Library] Upload icon",
  props<{ iconFile: File }>()
);
export const uploadIconSuccess = createAction(
  "[Icon Library] Upload icon success",
  props<{ icon: Icon }>()
);
export const uploadIconFailure = createAction(
  "[Icon Library] Upload icon failure",
  props<{ error: HttpErrorResponse }>()
);

export const changeSelectedIcon = createAction(
  "[Icon Library] Change selected icon",
  props<{ icon: Icon }>()
);

export const replaceIcon = createAction(
  "[Icon Library] Replace icon",
  props<{ iconFile: File; iconId: string }>()
);
export const replaceIconSuccess = createAction(
  "[Icon Library] Replace icon success",
  props<{ icon: Icon }>()
);
export const replaceIconFailure = createAction(
  "[Icon Library] Replace icon failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteIcon = createAction(
  "[Icon Library] Delete icon",
  props<{ icon: Icon }>()
);
export const deleteIconConfirm = createAction(
  "[Icon Library] Delete icon confirm",
  props<{ icon: Icon }>()
);
export const deleteIconCancel = createAction(
  "[Icon Library] Delete icon cancel"
);
export const deleteIconSuccess = createAction(
  "[Icon Library] Delete icon success"
);
export const deleteIconFailure = createAction(
  "[Icon Library] Delete icon failure",
  props<{ error: HttpErrorResponse }>()
);

export const goToPage = createAction(
  "[Icon Library] Go to Page",
  props<{ pageIndex: number }>()
);

export const pageAlreadyLoaded = createAction(
  "[Icon Library] Icon page already loaded"
);
