import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, State } from "./state";
import * as FeatureActions from "./actions";

const featureReducer = createReducer<State>(
  initialState,
  on(FeatureActions.setUserRoles, (state, { roles }) => ({
    ...state,
    roles: roles || [],
  })),
  on(FeatureActions.loadUserProfile, FeatureActions.loadUserImage, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FeatureActions.loadUserProfileSuccess, (state, { user }) => ({
    ...state,
    isLoading: false,
    user,
  })),
  on(FeatureActions.loadUserImageSuccess, (state, { image }) => ({
    ...state,
    isLoading: false,
    image,
  })),
  on(
    FeatureActions.loadUserProfileFailure,
    FeatureActions.loadUserImageFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
