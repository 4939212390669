import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  defaultValidationRequirement,
  ValidationItem,
  ValidationItemType,
  ValidationRequirements,
} from "src/app/models";

@Component({
  selector: "app-validation-item-form",
  templateUrl: "./validation-item-form.component.html",
  styleUrls: ["./validation-item-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationItemFormComponent implements OnInit, OnDestroy {
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.onChangeSubs.push(
      this._selectedItemTypeControl.valueChanges.subscribe(
        (value: ValidationItemType) => {
          if (value === "variable-validation") {
            this._form.addControl(
              "wildCardVariableName",
              new FormControl("", Validators.required)
            );
            this._form.addControl(
              "wildCardParam1",
              new FormControl("Ignore", Validators.required)
            );
            this._form.addControl(
              "wildCardParam2",
              new FormControl("", Validators.required)
            );
          } else {
            this._form.removeControl("wildCardVariableName");
            this._form.removeControl("wildCardParam1");
            this._form.removeControl("wildCardParam2");
          }
        }
      )
    );
  }

  @HostBinding("class") _class = "app-validation-item-form";

  @Input() set item(item: ValidationItem) {
    this._form.patchValue(item);
    this._requirements = (item?.requirements?.length && item.requirements) || [
      defaultValidationRequirement,
    ];
  }

  get item(): ValidationItem {
    return this._form.value;
  }

  get valid(): boolean {
    return this._form.valid;
  }

  get dirty(): boolean {
    return this._form.dirty;
  }

  _form = this.fb.group({
    text: this.fb.control(""),
    type: this.fb.control("", Validators.required),
    points: this.fb.control(0, Validators.min(0)),
    failureText: this.fb.control(""),
    mustCompleteAll: this.fb.control(Validators.required),
    requirements: this.fb.array([]),
  });

  get _selectedItemTypeControl(): FormControl {
    return this._form.get("type") as FormControl;
  }

  get _requirementsForm(): FormArray {
    return this._form.get("requirements") as FormArray;
  }

  _pointsArray = new Array(11);

  _requirements: ValidationRequirements = [];

  private destroyed$ = new Subject<void>();

  private onChangeSubs: Subscription[] = [];

  ngOnInit(): void {
    this._form.controls["type"].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reset());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  deleteAt(index: number): void {
    this._requirements = this._requirements.filter((_, i) => index !== i);
    this.cdr.detectChanges();
  }

  addRequirement(): void {
    this._requirements.push(defaultValidationRequirement);
  }

  private reset(): void {
    this._requirements = [defaultValidationRequirement];
    this.cdr.markForCheck();
  }
}
