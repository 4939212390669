import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { Effects } from "./effects";
import { reducer } from "./reducer";
import { featureKey } from "./state";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([Effects]),
  ],
})
export class EditProgramStore {}
