import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";

export const detailsExpansion = trigger("bodyExpansion", [
  state("collapsed, void", style({ height: "0px", visibility: "hidden" })),
  state("expanded", style({ height: "*", visibility: "visible" })),
  transition(
    "expanded <=> collapsed, void => collapsed",
    animate("300ms cubic-bezier(0.4,0.0,0.2,1)")
  ),
]);
