import { Question } from "./question";

export interface SingleSelectQuestion extends Question {
  answers: SingleSelectQuestionAnswer[];
}

export interface SingleSelectQuestionAnswer {
  text: string;
  isCorrect?: boolean;
}
