import { Directive, HostBinding, Input } from "@angular/core";
import { LevelStatus } from "src/app/models";

@Directive({
  selector: `
    app-level-status-button[appLevelStatus],
    app-level-status-bar[appLevelStatus]
  `,
})
export class LevelStatusDirective {
  @Input() status: LevelStatus;

  @HostBinding("class.draft")
  get isDraft(): boolean {
    return this.status === "draft" || this.status === "testing_ready";
  }

  @HostBinding("class.testing")
  get isTesting(): boolean {
    return this.status === "testing" || this.status === "prod_ready";
  }

  @HostBinding("class.published")
  get isPublished(): boolean {
    return this.status === "published";
  }
}
