export interface Question {
  title: string;
  type: QuestionType;
  hint?: string;
  correctResponse?: string;
}

export type QuestionType =
  | "answer-field"
  | "single-select"
  | "multi-select"
  | "fill-in-the-blank"
  | "emoji-answer"
  | "upload-file";
