import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { DocumentsSort } from "src/app/models";

@Component({
  selector: "app-sort-by-control",
  templateUrl: "./sort-by-control.component.html",
  styleUrls: ["./sort-by-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortByControlComponent {
  @HostBinding("class") class = "app-sort-by-control";

  @Input() set sortBy(sortBy: DocumentsSort) {
    this.mySortBy = sortBy;
  }

  @Output() sortByChange = new EventEmitter<DocumentsSort>();

  mySortBy: DocumentsSort;

  onValueChange(sortBy: DocumentsSort): void {
    this.mySortBy = sortBy;
    this.sortByChange.emit(sortBy);
  }
}
