import { ContainsValidationItemComponent } from "./contains-validation-item";
import { NoRequirementsValidationItemComponent } from "./no-requirements-validation-item";
import { ParameterComponent } from "./parameter";
import { PerfectMatchValidationItemComponent } from "./perfect-match-validation-item";
import { ValidationItemFormComponent } from "./validation-item-form";
import { ValidationItemTypeControlComponent } from "./validation-item-type-control";
import { ValidationListItemComponent } from "./validation-list-item";
import { ValidationRequirementsFormComponent } from "./validation-requirements-form";
import { VariableValidationComponent } from "./variable-validation";

export const components = [
  ContainsValidationItemComponent,
  NoRequirementsValidationItemComponent,
  PerfectMatchValidationItemComponent,
  ParameterComponent,
  ValidationItemFormComponent,
  ValidationItemTypeControlComponent,
  ValidationListItemComponent,
  ValidationRequirementsFormComponent,
  VariableValidationComponent,
];
