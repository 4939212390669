import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { delay } from "rxjs/operators";
import { detailsExpansion } from "src/app/app-animations";
import { Course, Icon, Level, Levels } from "src/app/models";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./store/actions";
import { selectLevels } from "./store/selectors";
import { environment } from "src/environments/environment";
import { selectMail } from "src/app/authentication/store/selectors";
import { publishLevel } from "src/app/levels/components/level/store/actions";
import { selectIsSyncing } from "src/app/levels/components/level/store/selectors";

@Component({
  selector: "app-edit-course",
  templateUrl: "./edit-course.component.html",
  styleUrls: ["./edit-course.component.scss"],
  animations: [detailsExpansion],
})
export class EditCourseComponent implements OnDestroy, OnInit {
  constructor(private store$: Store<AppStoreState>) {}

  @HostBinding("class") class = "app-edit-course";

  @Input() set course(course: Course) {
    this.myCourse = course;
    this.titleControl.reset();
    this.titleControl.setValue(course.title);

    this.descriptionControl.reset();
    this.descriptionControl.setValue(course.description);

    this.iconControl.reset();
    this.iconControl.setValue(course.icon);
  }

  @Output() delete = new EventEmitter<void>();
  @Output() update = new EventEmitter<Course>();

  levels$: Observable<Levels> = this.store$.pipe(
    select(selectLevels),
    delay(0)
  );

  private stateSubscription: Subscription;

  button_pressed = "";

  ALLOWED_EMAILS = environment.publishAllowedMails.map((mail) =>
    mail.toLowerCase()
  );
  selectMail$: Observable<string> = this.store$.select(selectMail);
  syncing$: Observable<boolean> = this.store$.select(selectIsSyncing);

  titleControl = new FormControl("", Validators.required);
  descriptionControl = new FormControl("", Validators.required);
  iconControl = new FormControl(null);

  private myCourse: Course;

  collapsed = false;
  selectedIDE = "MakeCode"; // not integrated, just for show

  get expandedState(): string {
    return this.collapsed ? "collapsed" : "expanded";
  }

  get valid(): boolean {
    return this.titleControl.valid && this.descriptionControl.valid;
  }

  get dirty(): boolean {
    return (
      this.titleControl.dirty ||
      this.descriptionControl.dirty ||
      this.iconControl.dirty
    );
  }

  get myCourseId(): number | null {
    return this.myCourse.id;
  }

  ngOnInit() {
    this.stateSubscription = this.store$
      .select(selectIsSyncing)
      .subscribe((isSyncing) => {
        if (!isSyncing) this.button_pressed = "";
      });
  }

  ngOnDestroy(): void {
    this.stateSubscription.unsubscribe();
  }

  toggleDetails(): void {
    this.collapsed = !this.collapsed;
  }

  deleteCourse(): void {
    this.delete.emit();
  }

  addLevel(): void {
    this.store$.dispatch(
      FeatureActions.addLevel({ courseId: this.myCourseId })
    );
  }

  onDuplicateLevel({ id: levelId }: Level): void {
    this.store$.dispatch(FeatureActions.copyLevel({ levelId }));
  }

  onDeleteLevel(level: Level): void {
    this.store$.dispatch(FeatureActions.deleteLevel({ level }));
  }

  updateCourse(): void {
    this.store$.dispatch(
      FeatureActions.updateCourse({
        course: {
          ...this.myCourse,
          title: this.titleControl.value,
          description: this.descriptionControl.value,
          iconId: this.iconControl.value?.id,
        },
      })
    );
  }

  onRearrangeLevels(levels: Levels): void {
    this.store$.dispatch(FeatureActions.rearrange({ levels }));
  }

  onIconChange(icon: Icon): void {
    this.iconControl.setValue(icon);

    if (this.myCourse.icon?.id !== icon.id) {
      this.iconControl.markAsDirty();
    } else {
      this.iconControl.markAsPristine();
    }
  }

  publishCourse(status) {
    let magicWord = prompt(
      `You are about to publish ${this.myCourse.title} course to "${
        status === "published" ? "Production" : "Staging"
      }". Please type "${this.myCourse.title}" to confirm.`
    );
    if (!magicWord) return;
    if (magicWord.toLowerCase() === this.myCourse.title.toLowerCase()) {
      this.button_pressed = status;
      this.store$.dispatch(publishLevel({ status: status, entity: "course" }));
    } else {
      alert("Wrong input!! Please type correct spelling");
    }
  }
}
