import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonLoadingDirective } from "./button-loading";
import { DisableControlDirective } from "./disable-control";
import { FileLinkDirective } from "./file-link";
import { UserCanDeleteDirective } from "./user-can-delete";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    DisableControlDirective,
    UserCanDeleteDirective,
    ButtonLoadingDirective,
    FileLinkDirective,
  ],
  exports: [
    DisableControlDirective,
    UserCanDeleteDirective,
    ButtonLoadingDirective,
    FileLinkDirective,
  ],
})
export class DirectivesModule {}
