import { createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import { initialState, State } from "./state";
import * as FeatureActions from "./actions";

export const reducer = createReducer<State>(
  initialState,
  on(FeatureActions.loadDocumentsPage, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FeatureActions.loadDocumentsPageSuccess, (state, { page }) => ({
    ...state,
    isLoading: false,
    documentsPage: page,
    selectedDocument: null,
  })),
  on(FeatureActions.changeSelectedPage, (state, { pageSize, pageIndex }) => ({
    ...state,
    selectedPage: { pageSize, pageIndex },
  })),
  on(FeatureActions.changeFilters, (state, { filters }) => ({
    ...state,
    filters,
    selectedPage: { ...state.selectedPage, pageIndex: 0 },
  })),
  on(FeatureActions.changeSortBy, (state, { sortBy }) => ({
    ...state,
    sortBy,
  })),
  on(FeatureActions.changeSearch, (state, { search }) => ({
    ...state,
    search,
    selectedPage: { ...state.selectedPage, pageIndex: 0 },
  })),
  on(FeatureActions.createDocument, (state) => ({
    ...state,
    isUpdating: true,
  })),
  on(FeatureActions.createDocumentSuccess, (state, { document }) => ({
    ...state,
    isUpdating: false,
    selectedDocument: document,
    selectedPage: { ...state.selectedPage, pageIndex: 0 },
  })),
  on(FeatureActions.createDocumentFailure, (state) => ({
    ...state,
    isUpdating: false,
  })),
  on(FeatureActions.updateDocument, (state, { original, updates }) => ({
    ...state,
    isUpdating: true,
    documentsPage: {
      ...state.documentsPage,
      items: state.documentsPage.items.map((d) => {
        if (d.id === updates.id) {
          return { ...original, ...updates };
        }

        return d;
      }),
    },
  })),
  on(FeatureActions.updateDocumentSuccess, (state, { document }) => ({
    ...state,
    isUpdating: false,
    documentsPage: {
      ...state.documentsPage,
      items: state.documentsPage.items.map((d) => {
        if (d.id === document.id) {
          return document;
        }

        return d;
      }),
    },
  })),
  on(FeatureActions.updateDocumentFailure, (state, { original }) => ({
    ...state,
    isUpdating: false,
    documentsPage: {
      ...state.documentsPage,
      items: state.documentsPage.items.map((d) => {
        if (d.id === original.id) {
          return original;
        }

        return d;
      }),
    },
  })),
  on(FeatureActions.changeSelectedDocument, (state, { document }) => ({
    ...state,
    selectedDocument:
      state.selectedDocument?.id === document.id ? null : document,
  })),
  on(FeatureActions.searchTagsSuccess, (state, { tagMatches }) => ({
    ...state,
    tagMatches,
  })),
  on(
    FeatureActions.loadDocumentsPageFailure,
    FeatureActions.createDocumentFailure,
    FeatureActions.updateDocumentFailure,
    FeatureActions.searchTagsFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);
