<app-panel *ngIf="activity">
  <app-panel-title>
    <app-activity-type-icon-editor
      [activityType]="activityType"></app-activity-type-icon-editor>
    {{ activity.type }}
    <app-panel-subtitle>{{ activity.title }}</app-panel-subtitle>
  </app-panel-title>

  <app-activity [collapsed]="collapsed">
    <app-activity-title
      [title]="activity.title"
      (delete)="deleteActivity()"
      [(collapsed)]="collapsed"></app-activity-title>
    <app-activity-stepper
      (stepAdd)="addStep()"
      (stepDelete)="deleteStep($event)"
      (dropItem)="drop($event)"
      *ngIf="steps$ | async">
      <app-activity-step *ngFor="let step of mySteps">
        <mat-tab-group color="primary" fxFlex>
          <mat-tab label="Content">
            <app-discover-activity-step
              [value]="step"></app-discover-activity-step>
          </mat-tab>
        </mat-tab-group>
      </app-activity-step>
    </app-activity-stepper>
    <app-activity-actions>
      <button
        color="primary"
        mat-raised-button
        [disabled]="!dirty || !valid || (isLoading$ | async)"
        (click)="updateActivity()">
        Update Activity
      </button>
    </app-activity-actions>
  </app-activity>
</app-panel>
