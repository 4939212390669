import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { AuthRole, UserProfile } from "src/app/models";

export const setUserRoles = createAction(
  "[Authentication] Set user roles",
  props<{ roles: AuthRole[] }>()
);

export const loadUserProfile = createAction(
  "[Authentication] Load user profile"
);
export const loadUserProfileSuccess = createAction(
  "[Authentication] Load user profile success",
  props<{ user: UserProfile }>()
);
export const loadUserProfileFailure = createAction(
  "[Authentication] Load user profile failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadUserImage = createAction("[Authentication] Load user image");
export const loadUserImageSuccess = createAction(
  "[Authentication] Load user image success",
  props<{ image: Blob }>()
);
export const loadUserImageFailure = createAction(
  "[Authentication] Load user image failure",
  props<{ error: HttpErrorResponse }>()
);

export const logOut = createAction("[Authentication] Log out");
