<div fxLayout fxLayoutGap="16px">
  <app-rich-text-editor
    [formControl]="textControl"
    placeholder="This is what the Ninja sees when they first enter this activity"></app-rich-text-editor>

  <div class="media-container">
    <mat-radio-group
      aria-label="Select video or image"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="space-between"
      [formControl]="typeControl">
      <mat-radio-button value="video">
        <mat-form-field>
          <input
            matInput
            placeholder="Enter URL for video"
            [formControl]="videoUrlControl"
            [disabled]="typeControl.value === 'image'" />
        </mat-form-field>
      </mat-radio-button>
      <mat-radio-button value="image">
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="space-between">
          <div class="image-container">
            <img
              class="image"
              [src]="imageControl.value"
              alt="Thumbnail"
              fxFlex />
          </div>

          <div fxLayout="column" fxLayoutAlign="center">
            <input
              #fileUpload
              class="file-input"
              type="file"
              (change)="onImageChange($event)" />
            <button
              mat-raised-button
              color="primary"
              [disabled]="typeControl.value === 'video'"
              (click)="fileUpload.click()">
              Add Image
            </button>
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
