import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-filter-box",
  templateUrl: "./filter-box.component.html",
  styleUrls: ["./filter-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBoxComponent {
  @HostBinding("class") class = "app-filter-box";
}
