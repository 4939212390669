import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!httpRequest.url.startsWith("api")) {
      return next.handle(httpRequest);
    }

    const request = httpRequest.clone({
      url: `${environment.apiUrl}/${httpRequest.url}`,
    });

    return next.handle(request);
  }
}
