import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { merge, of } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { selectSelectedActivity } from "src/app/levels/components/level/store/selectors";
import { BuildActivitiesService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";
import { selectBuildActivity } from "./selectors";
import { updateLevelStatus } from "src/app/levels/components/level/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private service: BuildActivitiesService
  ) {}

  triggerLoadActivity$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      filter((a) => !!a && a.type === "Build"),
      distinctUntilKeyChanged("id"),
      map(({ id: activityId }) =>
        FeatureActions.loadBuildActivity({ activityId })
      )
    )
  );

  loadBuildActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadBuildActivity),
      switchMap(({ activityId }) =>
        this.service.getBuildActivityById(activityId).pipe(
          map((activity) =>
            FeatureActions.loadBuildActivitySuccess({ activity })
          ),
          catchError((error) =>
            of(FeatureActions.loadBuildActivityFailure({ error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.update),
      exhaustMap(({ activity, steps, mediaFile }) =>
        this.service.updateBuildActivity(activity, steps, mediaFile).pipe(
          map((mediaFile) =>
            FeatureActions.updateSuccess({ activity, steps, mediaFile })
          ),
          catchError((error) => of(FeatureActions.updateFailure({ error })))
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateSuccess),
      map((_) => {
        return updateLevelStatus({
          status: "draft",
        });
      })
    )
  );

  triggerLoadSteps$ = createEffect(() =>
    merge(
      this.store$.pipe(
        select(selectBuildActivity),
        filter((activity) => !!activity),
        map(({ id }) => id),
        distinctUntilChanged()
      )
    ).pipe(map((activityId) => FeatureActions.loadSteps({ activityId })))
  );

  loadSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadSteps),
      switchMap(({ activityId }) =>
        this.service.getSteps(activityId).pipe(
          map((steps) => FeatureActions.loadStepsSuccess({ steps })),
          catchError((error) => of(FeatureActions.loadStepsFailure({ error })))
        )
      )
    )
  );

  addStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addStep),
      exhaustMap(({ activityId }) =>
        this.service.addStep({ activityId }).pipe(
          map((id) =>
            FeatureActions.addStepSuccess({ step: { id, activityId } })
          ),
          catchError((error) => of(FeatureActions.addStepFailure({ error })))
        )
      )
    )
  );

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteStep),
      concatMap(({ step }) =>
        this.service.deleteStep(step.id).pipe(
          map(() => FeatureActions.deleteStepSuccess()),
          catchError((error) =>
            of(FeatureActions.deleteStepFailure({ step, error }))
          )
        )
      )
    )
  );
}
