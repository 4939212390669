<div
  fxLayout
  fxLayoutGap="16px"
  *ngFor="let answer of answers.controls; let index = index">
  <mat-form-field>
    <input
      matInput
      placeholder="Answer {{ index + 1 }}"
      [formControl]="answer.controls.text" />
  </mat-form-field>
  <div fxLayoutAlign="center center">
    <button
      mat-button
      class="dark-button"
      [class.incorrect]="!answer.controls.isCorrect.value"
      [class.correct]="answer.controls.isCorrect.value"
      (click)="toggleIsCorrect(answer.controls.isCorrect)">
      {{ getIsCorrectTitle(answer.controls.isCorrect.value) }}
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="removeAnswerAtIndex(index)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<button mat-button (click)="addAnswer()">Add Answer</button>
