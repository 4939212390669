import { ActivityTypeIcons, AppState } from "src/app/models";

export const featureKey = "activityType-icon";

export interface State extends AppState {
  activityTypeIcons: ActivityTypeIcons;
  isLoading: boolean;
  error: string;
}

export const initialState: State = {
  activityTypeIcons: {
    Build: null,
    Quest: null,
    Adventure: null,
    Discover: null,
    Explore: null,
    LevelUp: null,
    Solve: null,
  },
  isLoading: false,
  error: "",
};
