<app-panel *ngIf="activity">
  <app-panel-title>
    <app-activity-type-icon-editor
      [activityType]="activityType"></app-activity-type-icon-editor>
    {{ activity.type }}
    <app-panel-subtitle>{{ activity.title }}</app-panel-subtitle>
  </app-panel-title>

  <app-activity [collapsed]="collapsed">
    <app-activity-title
      [title]="activity.title"
      (delete)="deleteActivity()"
      [(collapsed)]="collapsed">
    </app-activity-title>

    <app-activity-content>
      <div fxLayout fxLayoutGap="8px">
        <mat-tab-group fxFlex="75" fxFlex.gt-lg="70" color="primary">
          <mat-tab label="Introduction">
            <app-introduction-editor [value]="activity.introduction">
              <app-media-editor
                [loading]="isLoading$ | async"
                [value]="activity.mediaFile">
              </app-media-editor>
            </app-introduction-editor>
          </mat-tab>
        </mat-tab-group>
        <app-activity-toggles
          fxFlex="25"
          fxFlex.gt-lg="30"
          [activity]="activity">
        </app-activity-toggles>
      </div>
    </app-activity-content>
    <app-activity-stepper
      (stepAdd)="addStep()"
      (stepDelete)="deleteStep($event)">
      <app-activity-step *ngFor="let step of steps$ | async">
        <app-level-up-activity-step [step]="step"></app-level-up-activity-step>
      </app-activity-step>
    </app-activity-stepper>
    <app-activity-actions>
      <button
        color="primary"
        mat-raised-button
        [disabled]="!dirty || !valid || (isLoading$ | async)"
        (click)="updateActivity()">
        Update Activity
      </button>
    </app-activity-actions>
  </app-activity>
</app-panel>
