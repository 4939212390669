import { AppState, Icon } from "src/app/models";

export const featureKey = "icon-library";

export interface State extends AppState {
  iconPages: { [key: number]: Icon[] };
  selectedIcon: Icon;
  pageIndex: number;
  totalPages: number;
  pageSize: number;
}

export const initialState: State = {
  iconPages: { 0: [] },
  selectedIcon: null,
  pageIndex: 0,
  totalPages: 1,
  pageSize: 24,
  isLoading: false,
  error: "",
};
