<div fxLayout fxLayoutGap="19px">
  <div class="rich-text-form-group">
    <span class="rich-text-lable">Question Title & Image</span>
    <app-rich-text-editor
      [formControl]="titleControl"
      placeholder="Question Text"></app-rich-text-editor>
  </div>
  <mat-form-field fxFlex="30">
    <mat-select [formControl]="typeControl">
      <mat-option
        *ngFor="let questionType of questionTypes"
        [value]="questionType.type">
        {{ questionType.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<mat-form-field>
  <mat-label> Hint message </mat-label>
  <input matInput [formControl]="hintControl" />
</mat-form-field>
<mat-form-field>
  <mat-label> Correct Response </mat-label>
  <input matInput [formControl]="correctResponseControl" />
</mat-form-field>
