import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QuestionType } from "src/app/models";

@Component({
  selector: "app-question-header",
  templateUrl: "./question-header.component.html",
  styleUrls: ["./question-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionHeaderComponent implements OnInit, OnDestroy {
  @HostBinding("class") class = "app-question-header";

  @Input() set title(title: string) {
    this.titleControl.reset();
    this.titleControl.setValue(title || "");
  }

  get title(): string {
    return this.titleControl.value;
  }

  get hint(): string {
    return this.hintControl.value;
  }

  get correctResponse(): string {
    return this.correctResponseControl.value;
  }

  @Input() set hint(value: string) {
    this.hintControl.reset();
    this.hintControl.setValue(value ?? "");
  }

  @Input() set correctResponse(value: string) {
    this.correctResponseControl.reset();
    this.correctResponseControl.setValue(value ?? "");
  }

  @Input() set type(type: QuestionType) {
    this.typeControl.setValue(type || "answer-field");
  }

  get type(): QuestionType {
    return this.typeControl.value;
  }

  @Output() typeChange = new EventEmitter<QuestionType>();

  get dirty(): boolean {
    return (
      this.titleControl.dirty ||
      this.typeControl.dirty ||
      this.hintControl.dirty ||
      this.correctResponseControl.dirty
    );
  }

  get valid(): boolean {
    return (
      this.titleControl.valid &&
      this.typeControl.valid &&
      this.hintControl.valid &&
      this.correctResponseControl.valid
    );
  }

  titleControl = new FormControl("", [Validators.required]);

  hintControl = new FormControl("");
  correctResponseControl = new FormControl("");

  typeControl = new FormControl("", Validators.required);

  questionTypes: { type: QuestionType; text: string }[] = [
    { type: "answer-field", text: "Answer Field" },
    { type: "emoji-answer", text: "Emoji Answer" },
    { type: "fill-in-the-blank", text: "Fill in the Blank" },
    { type: "multi-select", text: "Multi-select" },
    { type: "single-select", text: "Single-select" },
    { type: "upload-file", text: "Upload File" },
  ];

  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.typeControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((type) => this.typeChange.emit(type));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
