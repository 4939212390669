import { createSelector } from "@ngrx/store";
import { ActivitiesStoreSelectors } from "../activities/store";
import { CourseStoreSelectors } from "../courses/store";
import { LevelsStoreSelectors } from "../levels/store";
import { ProgramsStoreSelectors } from "../programs/store";
import { selectIsLoading as LevelUpLoading } from "../activities/components/level-up-activity/store/selectors";

export const selectIsLoading = createSelector(
  ActivitiesStoreSelectors.selectIsLoading,
  CourseStoreSelectors.selectIsLoading,
  LevelsStoreSelectors.selectIsLoading,
  ProgramsStoreSelectors.selectIsLoading,
  LevelUpLoading,
  (...isLoadings) => isLoadings.find((isLoading) => isLoading)
);
