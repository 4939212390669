import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  ExploreActivity,
  ExploreSubActivity,
  ExploreSubActivityStep,
  MediaFile,
} from "src/app/models";

export const loadExploreActivity = createAction(
  "[Explore Activity Component] Load explore activity",
  props<{ activityId: number }>()
);
export const loadExploreActivitySuccess = createAction(
  "[Explore Activity Component] Load explore activity success",
  props<{ activity: ExploreActivity }>()
);
export const loadExploreActivityFailure = createAction(
  "[Explore Activity Component] Load explore activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const loadSubActivities = createAction(
  "[Explore Activity Component] Load sub-activities",
  props<{ activityId: number }>()
);
export const loadSubActivitiesSuccess = createAction(
  "[Explore Activity Component] Load sub-activities success",
  props<{ subActivities: ExploreSubActivity[] }>()
);
export const loadSubActivitiesFailure = createAction(
  "[Explore Activity Component] Load sub-activities failure",
  props<{ error: HttpErrorResponse }>()
);

export const clearSubActivities = createAction(
  "[Explore Activity Component] Clear sub-activities"
);

export const addSubActivity = createAction(
  "[Explore Activity Component] Add sub-activity",
  props<{ title: string }>()
);
export const addSubActivitySuccess = createAction(
  "[Explore Activity Component] Add sub-activity success",
  props<{ subActivity: ExploreSubActivity }>()
);
export const addSubActivityFailure = createAction(
  "[Explore Activity Component] Add sub-activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateSubActivity = createAction(
  "[Explore Activity Component] Update sub-activity",
  props<{
    subActivity: ExploreSubActivity;
    mediaFile: File | null;
    levelId: number;
  }>()
);
export const updateSubActivitySuccess = createAction(
  "[Explore Activity Component] Update sub-activity success",
  props<{
    subActivity: ExploreSubActivity;
    mediaFile?: MediaFile;
    levelId: number;
  }>()
);
export const updateSubActivityFailure = createAction(
  "[Explore Activity Component] Update sub-activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteSubActivity = createAction(
  "[Explore Activity Component] Delete sub-activity",
  props<{ subActivity: ExploreSubActivity }>()
);
export const deleteSubActivityConfirm = createAction(
  "[Explore Activity Component] Delete sub-activity confirm",
  props<{ subActivity: ExploreSubActivity }>()
);
export const deleteSubActivityCancel = createAction(
  "[Explore Activity Component] Delete sub-activity cancel"
);
export const deleteSubActivitySuccess = createAction(
  "[Explore Activity Component] Delete sub-activity success",
  props<{ subActivityId: number }>()
);
export const deleteSubActivityFailure = createAction(
  "[Explore Activity Component] Delete sub-activity failure",
  props<{ subActivity: ExploreSubActivity; error: HttpErrorResponse }>()
);

export const addStep = createAction(
  "[Explore Activity Component] Add step",
  props<{ subActivityId: number }>()
);
export const addStepSuccess = createAction(
  "[Explore Activity Component] Add step success",
  props<{ step: ExploreSubActivityStep }>()
);
export const addStepFailure = createAction(
  "[Explore Activity Component] Add step failure",
  props<{ error: HttpErrorResponse }>()
);

export const deleteStep = createAction(
  "[Explore Activity Component] Delete step",
  props<{ step: ExploreSubActivityStep }>()
);
export const deleteStepSuccess = createAction(
  "[Explore Activity Component] Delete step success"
);
export const deleteStepFailure = createAction(
  "[Explore Activity Component] Delete step failure",
  props<{ step: ExploreSubActivityStep; error: HttpErrorResponse }>()
);
