import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { sortById } from "src/app/entity-adapter";
import {
  AppState,
  SolveActivity,
  SolveSubActivity,
  SolveSubActivityStep,
} from "src/app/models";

export const featureKey = "solve-activity";

export interface SolveSubActivitiesState
  extends EntityState<SolveSubActivity> {}

export interface SolveSubActivityStepsState
  extends EntityState<SolveSubActivityStep> {}

export interface State extends AppState {
  activity: SolveActivity;
  subActivities: SolveSubActivitiesState;
  steps: SolveSubActivityStepsState;
}

export const subActivitiesAdapter = createEntityAdapter<SolveSubActivity>({
  sortComparer: sortById,
});
export const stepsAdapter = createEntityAdapter<SolveSubActivityStep>({
  sortComparer: sortById,
});

export const initialState: State = {
  activity: null,
  subActivities: subActivitiesAdapter.getInitialState(),
  steps: stepsAdapter.getInitialState(),
  isLoading: false,
  error: "",
};
