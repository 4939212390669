import { Question } from "./question";

export interface MultiSelectQuestion extends Question {
  answers: MultiSelectQuestionAnswer[];
}

export interface MultiSelectQuestionAnswer {
  text: string;
  isCorrect?: boolean;
}
