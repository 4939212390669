import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Level, LevelStatus, Levels } from "../models";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LevelsService {
  constructor(private http: HttpClient) {}

  getLevelById(levelId: number): Observable<Level> {
    return this.http.get<Level>(`api/levels/${levelId}`);
  }

  getLevelsByCourseId(courseId: number): Observable<Levels> {
    return this.http.get<Levels>(`api/courses/${courseId}/levels`);
  }

  addLevel(courseId: number): Observable<void> {
    const newLevel: Level = {
      courseId,
      sortOrder: 0,
      title: "Untitled Level",
      status: "draft",
      tags: [],
    };
    return this.http.post<void>(`api/courses/${courseId}/levels`, newLevel);
  }

  copyLevel(levelId: number): Observable<void> {
    return this.http.post<void>(`api/levels/${levelId}/copy`, null);
  }

  update(level: Level): Observable<void> {
    return this.http.put<void>(`api/levels/${level.id}`, level);
  }

  updateLevelStatus(id: number, status: LevelStatus): Observable<void> {
    return this.http.put<void>(`api/updateLevelStatus/${id}/${status}`, {});
    // return this.http.post<void>('api/testingLevels', { id });
  }

  testLevel(id: number): Observable<void> {
    return this.http.post<void>("api/testingLevels", { id });
  }

  publishLevel(id: number): Observable<void> {
    return this.http.post<void>("api/publishedLevels", { id });
  }

  publishData(data: any): Observable<any> {
    console.log("Publishsable Data: ", data);
    return this.http.post<void>("api/exportClmData", data);
  }

  updateLevels(courseId: number, levels: Levels): Observable<void> {
    return this.http.put<void>(`api/courses/${courseId}/levels`, {
      levels,
    });
  }

  delete(level: Level): Observable<void> {
    return this.http.delete<void>(`api/levels/${level.id}`);
  }
}
