import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { DiscoverActivityStep, MediaType } from "src/app/models";

@Component({
  selector: "app-text-media-editor",
  templateUrl: "./text-media-editor.component.html",
  styleUrls: ["./text-media-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextMediaEditorComponent {
  constructor(private cdr: ChangeDetectorRef) {}

  @HostBinding("class") class = "app-text-media-editor";

  @Input() set step(step: DiscoverActivityStep) {
    this.textControl.reset();
    this.textControl.setValue(step?.text);

    this.typeControl.reset();
    this.typeControl.setValue(step?.mediaType || "video");

    this.videoUrlControl.reset();
    this.videoUrlControl.setValue(step?.videoUrl);

    this.imageControl.reset();
    this.imageControl.setValue(step?.image);
  }

  get text(): string {
    return this.textControl.value;
  }

  get mediaType(): MediaType {
    return this.typeControl.value;
  }

  get videoUrl(): string {
    return this.videoUrlControl.value;
  }

  get image(): string {
    return this.imageControl.value;
  }

  get valid(): boolean {
    return (
      this.textControl.valid &&
      this.typeControl.valid &&
      this.videoUrlControl.valid &&
      this.imageControl.valid
    );
  }

  get dirty(): boolean {
    return (
      this.textControl.dirty ||
      this.typeControl.dirty ||
      this.videoUrlControl.dirty ||
      this.imageControl.dirty
    );
  }

  textControl = new FormControl("");
  typeControl = new FormControl("", Validators.required);
  videoUrlControl = new FormControl("");
  imageControl = new FormControl("");

  onImageChange(event: Event): void {
    const reader = new FileReader();
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length) {
      const file = target.files.item(0);
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageControl.setValue(reader.result);
        this.imageControl.markAsDirty();
        this.cdr.markForCheck();
      };
    }
  }
}
