import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { Level, LevelStatus, Levels } from "src/app/models";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-level-list",
  templateUrl: "./level-list.component.html",
  styleUrls: ["./level-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelListComponent {
  @HostBinding("class") class = "app-level-list";

  @Input() levels: Levels;
  @Output() delete = new EventEmitter<Level>();
  @Output() duplicate = new EventEmitter<Level>();
  @Output() rearrange = new EventEmitter<Levels>();

  onDeleteButtonClick(level: Level): void {
    this.delete.emit(level);
  }

  onDuplicateButtonClick(level: Level): void {
    this.duplicate.emit(level);
  }

  onDrop({ previousIndex, currentIndex }: CdkDragDrop<Levels>): void {
    moveItemInArray(this.levels, previousIndex, currentIndex);
    const newSort = this.levels.map((a, i) => ({ ...a, sortOrder: i }));

    this.rearrange.emit(newSort);
  }

  getLevelStatusMessage(status: LevelStatus): string {
    switch (status) {
      case "draft":
        return "Draft";
      case "testing_ready":
        return "Ready for Stage";
      case "testing":
        return "Staging";
      case "prod_ready":
        return "Ready for Prod"; // enabled only for admins
      case "published":
        return "Published"; // disabled
      default:
        return "Draft";
    }
  }
}
