import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, State } from "./state";

const selectState = createFeatureSelector<State>(featureKey);

export const selectCurrentPage = createSelector(
  selectState,
  (state) => state.iconPages[state.pageIndex]
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectPageIndex = createSelector(
  selectState,
  (state) => state.pageIndex
);

export const selectTotalPages = createSelector(
  selectState,
  (state) => state.totalPages
);

export const selectSelectedIcon = createSelector(
  selectState,
  (state) => state.selectedIcon
);

export const selectSelectedPagination = createSelector(
  selectState,
  (state) => ({
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
  })
);

export const isPageLoaded = createSelector(selectState, (state) => ({
  isPageLoaded: !!state.iconPages[state.pageIndex],
}));

export const selectError = createSelector(selectState, (state) => state.error);
