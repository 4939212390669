import { Action, createReducer, on } from "@ngrx/store";
import { appErrorReducer } from "src/app/store/app-error-reducer";
import {
  initialState,
  State,
  stepsAdapter,
  subActivitiesAdapter,
} from "./state";
import * as FeatureActions from "./actions";

const featureReducer = createReducer<State>(
  initialState,
  on(
    FeatureActions.loadSolveActivity,
    FeatureActions.loadSubActivities,
    FeatureActions.updateSubActivity,
    FeatureActions.addSubActivity,
    FeatureActions.addStep,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(FeatureActions.loadSolveActivitySuccess, (state, { activity }) => ({
    ...state,
    isLoading: false,
    activity,
  })),
  on(FeatureActions.loadSubActivitiesSuccess, (state, { subActivities }) => ({
    ...state,
    isLoading: false,
    subActivities: subActivitiesAdapter.addMany(
      subActivities,
      state.subActivities
    ),
    steps: stepsAdapter.addMany(
      [].concat(...subActivities.map((a) => a.steps)),
      state.steps
    ),
  })),
  on(FeatureActions.clearSubActivities, (state) => ({
    ...state,
    subActivities: subActivitiesAdapter.getInitialState(),
    steps: stepsAdapter.getInitialState(),
  })),
  on(FeatureActions.addSubActivitySuccess, (state, { subActivity }) => ({
    ...state,
    isLoading: false,
    subActivities: subActivitiesAdapter.addOne(
      subActivity,
      state.subActivities
    ),
    steps: stepsAdapter.addMany(subActivity.steps, state.steps),
  })),
  on(
    FeatureActions.updateSubActivitySuccess,
    (state, { subActivity, mediaFile }) => ({
      ...state,
      isLoading: false,
      subActivities: subActivitiesAdapter.updateOne(
        {
          id: subActivity.id,
          changes: { ...subActivity, mediaFile },
        },
        state.subActivities
      ),
      steps: stepsAdapter.updateMany(
        subActivity.steps.map((s) => ({ id: s.id, changes: s })),
        state.steps
      ),
    })
  ),
  on(FeatureActions.deleteSubActivityConfirm, (state, { subActivity }) => ({
    ...state,
    isLoading: true,
    subActivities: subActivitiesAdapter.removeOne(
      subActivity.id,
      state.subActivities
    ),
  })),
  on(FeatureActions.deleteSubActivityFailure, (state, { subActivity }) => ({
    ...state,
    subActivities: subActivitiesAdapter.addOne(
      subActivity,
      state.subActivities
    ),
  })),
  // Clean-up orphaned steps
  on(FeatureActions.deleteSubActivitySuccess, (state, { subActivityId }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.removeMany(
      (s) => s.subActivityId === subActivityId,
      state.steps
    ),
  })),
  on(FeatureActions.addStepSuccess, (state, { step }) => ({
    ...state,
    isLoading: false,
    steps: stepsAdapter.addOne(step, state.steps),
  })),
  on(FeatureActions.deleteStep, (state, { step }) => ({
    ...state,
    isLoading: true,
    steps: stepsAdapter.removeOne(step.id, state.steps),
  })),
  on(FeatureActions.deleteStepFailure, (state, { step }) => ({
    ...state,
    steps: stepsAdapter.addOne(step, state.steps),
  })),
  on(
    FeatureActions.loadSolveActivityFailure,
    FeatureActions.loadSubActivitiesFailure,
    FeatureActions.updateSubActivityFailure,
    FeatureActions.deleteSubActivityFailure,
    FeatureActions.addSubActivityFailure,
    FeatureActions.addStepFailure,
    FeatureActions.deleteStepFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error: appErrorReducer(error),
    })
  )
);

export function reducer(state: State, action: Action): State {
  return featureReducer(state, action);
}
