<mat-form-field>
  <mat-select [formControl]="type">
    <mat-select-trigger>{{ selectedItemTypeText }}</mat-select-trigger>
    <mat-option
      *ngFor="let itemType of _itemTypes | keyvalue : _originalOrder"
      [value]="itemType.key">
      <mat-icon>{{ itemType.value.icon }}</mat-icon>
      <span>{{ itemType.value.text }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
