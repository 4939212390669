import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { CodeAdventureActivity, MediaFile } from "src/app/models";

export const loadCodeAdventureActivity = createAction(
  "[Code Adventure Activity Component] Load activity",
  props<{ activityId: number }>()
);
export const loadCodeAdventureActivitySuccess = createAction(
  "[Code Adventure Activity Component] Load activity success",
  props<{ activity: CodeAdventureActivity }>()
);
export const loadCodeAdventureActivityFailure = createAction(
  "[Code Adventure Activity Component] Load activity failure",
  props<{ error: HttpErrorResponse }>()
);

export const updateActivity = createAction(
  "[Code Adventure Activity Component] Update activity",
  props<{ activity: CodeAdventureActivity; mediaFile: File | null }>()
);
export const updateActivitySuccess = createAction(
  "[Code Adventure Activity Component] Update activity success",
  props<{ activity: CodeAdventureActivity; mediaFile?: MediaFile }>()
);
export const updateActivityFailure = createAction(
  "[Code Adventure Activity Component] Update activity failure",
  props<{ error: HttpErrorResponse }>()
);
