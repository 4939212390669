import { Component, HostBinding, Input } from "@angular/core";
import { Breadcrumb } from "src/app/models";

@Component({
  selector: "app-breadcrumb-bar",
  templateUrl: "breadcrumb-bar.component.html",
  styleUrls: ["breadcrumb-bar.component.scss"],
})
export class BreadcrumbBarComponent {
  @HostBinding("class") class = "app-breadcrumb-bar";

  @Input() previous: Breadcrumb;

  @Input() breadcrumbs: Breadcrumb[] = [];
}
