import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { Document } from "src/app/models";

@Component({
  selector: "app-document-form",
  templateUrl: "./document-form.component.html",
  styleUrls: ["./document-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DocumentFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DocumentFormComponent,
    },
  ],
})
export class DocumentFormComponent
  implements ControlValueAccessor, OnDestroy, Validator
{
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  @HostBinding("class") class = "app-document-form";

  @Input() tagMatches: string[];

  @Output() tagChange = new EventEmitter<string>();

  form: FormGroup = this.fb.group({
    title: [null, [Validators.required, Validators.maxLength(50)]],
    text: [null],
    image: [null],
    tags: this.fb.array([]),
  });

  get valid(): boolean {
    return this.form.valid;
  }

  get _tags(): string[] {
    return this.form.controls.tags.value;
  }

  get _document(): Document {
    return this.document;
  }

  private onTouched: () => void;

  private onChangeSubs: Subscription[] = [];

  private get tagsFormArray(): FormArray {
    return this.form.controls.tags as FormArray;
  }

  private document: Document;

  writeValue(document: Document): void {
    this.form.patchValue(
      {
        title: document?.title || "",
        text: document?.text || "",
      },
      { emitEvent: false }
    );

    this.tagsFormArray.clear({ emitEvent: false });
    (document?.tags || []).forEach((t) =>
      this.tagsFormArray.push(this.fb.control(t), { emitEvent: false })
    );

    this.document = document;

    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (document: Document) => void): void {
    const sub = this.form.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.form[["enable", "disable"][+isDisabled]]();
    this.cdr.markForCheck();
  }

  validate(): ValidationErrors {
    if (this.form.valid) {
      return null;
    }

    let errors: any = {};

    errors = this.addControlErrors(errors, "title");
    errors = this.addControlErrors(errors, "text");
    errors = this.addControlErrors(errors, "image");
    errors = this.addControlErrors(errors, "tags");

    return errors;
  }

  addControlErrors(
    allErrors: any,
    controlName: string
  ): { [controlName: string]: ValidationErrors } {
    const errors = { ...allErrors };

    const controlErrors = this.form.controls[controlName].errors;

    if (controlErrors) {
      errors[controlName] = controlErrors;
    }

    return errors;
  }

  ngOnDestroy(): void {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  _onImageChange(image: File): void {
    this.form.controls["image"].patchValue(image);
  }

  _onTagChange(keyword: string): void {
    this.tagChange.emit(keyword);
  }

  _onTagAdd(tag: string): void {
    if (
      this.tagsFormArray.controls.find(
        (c) => c.value.toLowerCase().trim() === tag.toLowerCase().trim()
      )
    ) {
      return;
    }

    this.tagsFormArray.push(this.fb.control(tag));
    this.cdr.markForCheck();
  }

  _onTagRemove(tag: string): void {
    const index = this.tagsFormArray.controls.findIndex((c) => c.value === tag);
    if (index === -1) {
      return;
    }

    this.tagsFormArray.removeAt(index);
    this.cdr.markForCheck();
  }
}
