import { createEntityAdapter, EntityState } from "@ngrx/entity";
import {
  Activity,
  AppState,
  Course,
  identifyActivity,
  Level,
} from "src/app/models";

export const featureKey = "level";

export interface ActivitiesState extends EntityState<Activity> {}

export interface State extends AppState {
  level: Level;
  course: Course;
  activities: ActivitiesState;
  selectedActivity: Activity;
  tagMatches: string[];
  isUploading: boolean;
  isImagesUploading: boolean;
  isIconsUploading: boolean;
  fileUrl?: string;
}

export const activitiesAdapter = createEntityAdapter<Activity>({
  selectId: identifyActivity,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const initialState: State = {
  level: null,
  course: null,
  activities: activitiesAdapter.getInitialState(),
  selectedActivity: null,
  isLoading: false,
  isSyncing: false,
  error: "",
  tagMatches: [],
  isUploading: false,
  isImagesUploading: false,
  isIconsUploading: false,
};
