import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  Activities,
  Activity,
  ActivityType,
  ActivityTypeIcons,
  Icon,
} from "../models";

@Injectable({ providedIn: "root" })
export class ActivitiesService {
  constructor(private http: HttpClient) {}

  getActivitiesByLevelId(levelId: number): Observable<Activities> {
    return this.http.get<Activities>(`api/levels/${levelId}/activities`);
  }

  add(activityType: ActivityType, levelId: number): Observable<void> {
    return this.http.post<void>(`api/levels/${levelId}/activities`, {
      levelId,
      type: activityType,
    });
  }

  delete(activity: Activity): Observable<void> {
    return this.http.request<void>("delete", "api/activities/", {
      body: { type: activity.type, activityId: activity.id },
    });
  }

  update(levelId: number, activities: Activities): Observable<void> {
    return this.http.put<void>(`api/levels/${levelId}/activities`, {
      activities,
    });
  }

  setActivityTypeIconByProgramId(
    programId: number,
    activityType: ActivityType,
    iconId: string
  ): Observable<void> {
    return this.http.post<void>(`api/programs/${programId}/ActivityTypes`, {
      iconId,
      activityType,
    });
  }

  getActivityTypeIconByProgramId(
    programId: number
  ): Observable<ActivityTypeIcons> {
    return this.http
      .get<{ [keys in ActivityType]: string }>(
        `api/programs/${programId}/ActivityTypes`
      )
      .pipe(
        map((activitytypeIconId) => {
          const result = {} as ActivityTypeIcons;
          Object.keys(activitytypeIconId).forEach(
            (k: ActivityType) =>
              (result[k] = { id: activitytypeIconId[k] } as Icon)
          );
          return result;
        })
      );
  }

  uploadeVideo(formdata: FormData, forceOverwrite?: boolean): Observable<any> {
    let api = "api/UploadVideo";
    if (forceOverwrite) api += `?forceOverwrite=${true}`;
    return this.http.post<any>(api, formdata, {
      responseType: "text" as "json",
    });
  }

  uploadImage(formdata: FormData): Observable<string> {
    let api = "api/uploadImage";
    return this.http.post<any>(api, formdata, {
      responseType: "text" as "json",
    });
  }

  uploadImages(): Observable<string> {
    let api = "api/uploadImages";
    return this.http.post<string>(api, null, {
      responseType: "text" as "json",
    });
  }

  uploadIcons(): Observable<string> {
    let api = "api/uploadIcons";
    return this.http.post<string>(api, null, {
      responseType: "text" as "json",
    });
  }
}
