<div [formGroup]="_form" fxLayout="column" fxLayoutGap="30px">
  <div>
    <span class="validation-header"> Information </span>
    <div fxLayout fxLayoutGap="8px" fxLayoutAlign="center center">
      <mat-form-field fxFlex>
        <input matInput formControlName="text" />
      </mat-form-field>
      <app-validation-item-type-control fxFlex="40" formControlName="type">
      </app-validation-item-type-control>
      <mat-form-field fxFlex="10">
        <mat-select formControlName="points">
          <mat-option *ngFor="let p of _pointsArray; let i = index" [value]="i">
            {{ i }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="validation-header"> Validation Failure Text </span>
    <div>
      <app-rich-text-editor
        class="rich-text-editor"
        [formControl]="_form.get('failureText')"
        placeholder="Failure text message goes here"></app-rich-text-editor>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayoutGap="16px"
    *ngIf="_selectedItemTypeControl.value == 'variable-validation'">
    <div fxLayout="row" class="no-margin">
      <span class="wildcard-header">Validation Defining</span>
    </div>
    <div fxLayout="row" class="no-margin">
      <div fxLayout class="wildcard-section">
        <div fxFlex="25" class="variable-label">
          <span>let <b>|wildcard|</b> =&nbsp;</span>
        </div>
        <div fxFlex="75">
          <mat-form-field>
            <input
              matInput
              placeholder="enter code here"
              formControlName="wildCardVariableName" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="wildcard-param-section">
      <div fxLayout="row" class="no-margin">
        <div fxLayout class="wildcard-section">
          <div
            fxFlex="25"
            class="variable-label wildcards-underline"
            fxLayoutGap="14px">
            <span>Parameter 1 &nbsp;</span>
          </div>
          <div fxFlex="75">
            <mat-form-field>
              <input matInput formControlName="wildCardParam1" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" class="no-margin">
        <div fxLayout class="wildcard-section">
          <div
            fxFlex="25"
            class="variable-label wildcards-underline"
            fxLayoutGap="14px">
            <span>Parameter 2 = </span>
          </div>
          <div fxFlex="75">
            <mat-form-field>
              <input
                matInput
                placeholder="enter code here"
                formControlName="wildCardParam2" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFill>
    <span class="wildcard-header"
      >Use <b>|wildcard|</b> in your validation text to match the ninja’s unique
      variable name.</span
    >
  </div>
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="validation-header"> Requirements </span>
      <mat-slide-toggle color="primary" formControlName="mustCompleteAll">
        Must Complete All
      </mat-slide-toggle>
    </div>
    <div
      fxLayout
      fxLayoutGap="4px"
      *ngFor="let requirement of _requirements; index as i">
      <div fxFlex>
        <app-validation-requirements-form
          [appParentForm]="_requirementsForm"
          [appChildFormValue]="requirement"
          [itemType]="
            _selectedItemTypeControl.value
          "></app-validation-requirements-form>
      </div>
      <div>
        <button mat-icon-button (click)="deleteAt(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="center center">
    <button mat-button (click)="addRequirement()">Add Requirement</button>
  </div>
</div>
