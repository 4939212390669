<div fxLayoutAlign="center center">
  <span class="separator" fxFlex></span>
  <button mat-button (click)="addSubActivity()">
    <div
      class="add-sub-activity-container"
      fxFlex
      fxLayoutAlign="center center"
      fxLayoutGap="4px">
      <mat-icon>add_circle_outline</mat-icon>
      <span>
        <ng-content></ng-content>
      </span>
    </div>
  </button>
  <span class="separator" fxFlex></span>
</div>
