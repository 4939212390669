import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-emoji-answer-question",
  templateUrl: "./emoji-answer-question.component.html",
  styleUrls: ["./emoji-answer-question.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiAnswerQuestionComponent {
  @HostBinding("class") class = "app-emoji-answer-question";
}
