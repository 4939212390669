import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TagsService {
  constructor(private http: HttpClient) {}
  searchTags(keyword: string): Observable<string[]> {
    const params = {
      keyword,
    };
    return this.http.get<string[]>("api/tags", { params });
  }
}
