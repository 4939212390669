import {
  transition,
  trigger,
  style,
  animate,
  state,
} from "@angular/animations";

const backdropUnsuported = /firefox/i.test(window.navigator.userAgent);

const openOverlayStyles = {
  pointerEvents: "auto",
  background: "rgba(0,0,0,0.3)",
};

const closeOverlayStyles = {
  pointerEvents: "none",
  background: "rgba(0,0,0,0)",
};

const backdropFilterOpen = {
  backdropFilter: "blur(4px)",
  ...openOverlayStyles,
};

const backDropFilterClosed = {
  backdropFilter: "blur(0px)",
  ...closeOverlayStyles,
};

export const openFilterPannel = trigger("openClose", [
  state("close", style({ transform: "translateX(-96%)" })),
  state("open", style({ transform: "translateX(0%)" })),
  transition("close <=> open", animate("300ms ease")),
]);

export const backgroundBlur = trigger("backgroudBlur", [
  state(
    "close",
    style(backdropUnsuported ? closeOverlayStyles : backDropFilterClosed)
  ),
  state(
    "open",
    style(backdropUnsuported ? openOverlayStyles : backdropFilterOpen)
  ),
  transition("close <=> open", animate("400ms ease")),
]);

export const filterPanelAnimations = [openFilterPannel, backgroundBlur];

export type OpenCloseState = "open" | "close";
