<div fxFlex fxLayout="column" fxLayoutGap="8px">
  <app-makecode-editor></app-makecode-editor>
  <div fxLayot fxLayoutGap="8px">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!dirty"
      (click)="openFilterPanel()">
      Filter Blocks
    </button>
    <app-makecode-language-selector
      [value]="project?.languageRestrictions"
      (valueChange)="editor.setLanguageRestiction($event)">
    </app-makecode-language-selector>
    <span fxFlex></span>
    <span class="last-updated">
      Last update from MakeCode: {{ lastUpdated | date : "medium" }}
    </span>
    <span fxFlex></span>
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!dirty"
      (click)="close()">
      Done
    </button>
  </div>
</div>
