import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { YesNoDialogComponent } from "src/app/dialogs/components/yes-no-dialog";
import { selectSelectedActivity } from "src/app/levels/components/level/store/selectors";
import { ExploreActivitiesService } from "src/app/services";
import { AppStoreState } from "src/app/store";
import * as FeatureActions from "./actions";
import { selectExploreActivity, selectSubActivities } from "./selectors";
import { updateLevelStatus } from "src/app/levels/components/level/store/actions";

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppStoreState>,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private service: ExploreActivitiesService
  ) {}

  triggerLoadActivity$ = createEffect(() =>
    this.store$.pipe(
      select(selectSelectedActivity),
      filter((a) => !!a && a.type === "Explore"),
      distinctUntilKeyChanged("id"),
      map(({ id: activityId }) =>
        FeatureActions.loadExploreActivity({ activityId })
      )
    )
  );

  loadExploreActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadExploreActivity),
      switchMap(({ activityId }) =>
        this.service.getExploreActivityById(activityId).pipe(
          map((activity) =>
            FeatureActions.loadExploreActivitySuccess({ activity })
          ),
          catchError((error) =>
            of(FeatureActions.loadExploreActivityFailure({ error }))
          )
        )
      )
    )
  );

  clearSubActivities$ = createEffect(() =>
    this.store$.pipe(
      select(selectExploreActivity),
      withLatestFrom(this.store$.pipe(select(selectSubActivities))),
      filter(
        ([activity, subActivities]) => !activity || !!subActivities.length
      ),
      map(() => FeatureActions.clearSubActivities())
    )
  );

  triggerLoadSubActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadExploreActivitySuccess),
      filter(({ activity }) => !!activity),
      map(({ activity }) =>
        FeatureActions.loadSubActivities({ activityId: activity.id })
      )
    )
  );

  loadSubActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.loadSubActivities),
      switchMap(({ activityId }) =>
        this.service.getSubActivities(activityId).pipe(
          map((subActivities) =>
            FeatureActions.loadSubActivitiesSuccess({ subActivities })
          ),
          catchError((error) =>
            of(FeatureActions.loadSubActivitiesFailure({ error }))
          )
        )
      )
    )
  );

  addSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addSubActivity),
      withLatestFrom(this.store$.pipe(select(selectExploreActivity))),
      exhaustMap(([{ title }, { id: activityId }]) =>
        this.service.addSubActivity({ title, activityId }).pipe(
          map(({ id, stepId }) =>
            FeatureActions.addSubActivitySuccess({
              subActivity: {
                id,
                activityId,
                title,
                steps: [{ id: stepId, subActivityId: id }],
              },
            })
          ),
          catchError((error) =>
            of(FeatureActions.addSubActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateSubActivity),
      exhaustMap(({ subActivity, mediaFile, levelId }) =>
        this.service.updateSubActivity(subActivity, mediaFile, levelId).pipe(
          map((mediaFile) =>
            FeatureActions.updateSubActivitySuccess({
              subActivity,
              mediaFile,
              levelId,
            })
          ),
          catchError((error) =>
            of(FeatureActions.updateSubActivityFailure({ error }))
          )
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.updateSubActivitySuccess),
      map((_) => {
        return updateLevelStatus({
          status: "draft",
        });
      })
    )
  );

  confirmDeleteSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteSubActivity),
      switchMap(({ subActivity }) =>
        this.dialog
          .open(YesNoDialogComponent)
          .afterClosed()
          .pipe(
            map((confirm) =>
              !!confirm
                ? FeatureActions.deleteSubActivityConfirm({ subActivity })
                : FeatureActions.deleteSubActivityCancel()
            )
          )
      )
    )
  );

  deleteSubActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteSubActivityConfirm),
      switchMap(({ subActivity }) =>
        this.service.deleteSubActivity(subActivity.id).pipe(
          map(() =>
            FeatureActions.deleteSubActivitySuccess({
              subActivityId: subActivity.id,
            })
          ),
          catchError((error) =>
            of(FeatureActions.deleteSubActivityFailure({ subActivity, error }))
          )
        )
      )
    )
  );

  addSubActivityStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.addStep),
      exhaustMap(({ subActivityId }) =>
        this.service.addSubActivityStep({ subActivityId }).pipe(
          map((id) =>
            FeatureActions.addStepSuccess({ step: { id, subActivityId } })
          ),
          catchError((error) => of(FeatureActions.addStepFailure({ error })))
        )
      )
    )
  );

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.deleteStep),
      concatMap(({ step }) =>
        this.service.deleteSubActivityStep(step.id).pipe(
          map(() => FeatureActions.deleteStepSuccess()),
          catchError((error) =>
            of(FeatureActions.deleteStepFailure({ step, error }))
          )
        )
      )
    )
  );

  displaySubActivityDeletedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FeatureActions.deleteSubActivityFailure,
          FeatureActions.deleteStepFailure
        ),
        tap(() =>
          this.displayMessage(
            "Could not delete at this time. Please try again later."
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  displaySubActivityUpdateFailedSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureActions.updateSubActivityFailure),
        tap(() =>
          this.displayMessage(
            "Could not update at this time. Please try again later."
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  private displayMessage(message: string): void {
    this.snackbar.open(message, "OK", { duration: 3000 });
  }
}
