import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-image-picker",
  templateUrl: "./image-picker.component.html",
  styleUrls: ["./image-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePickerComponent {
  constructor(
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  @HostBinding("class") _class = "app-image-picker";

  @Input() set url(url: string) {
    this.imageFile = null;
    this.updateImageUrl(url);
  }

  @Output() imageChange = new EventEmitter<File>();

  _imageUrl: SafeUrl;

  get actionTitle(): string {
    return this._imageUrl ? "Replace" : "Upload";
  }

  private imageFile: File;

  _onUploadImage(image: File): void {
    this.imageFile = image;
    this.imageChange.emit(image);
    this.updateImageUrl();
  }

  private updateImageUrl(url?: string): void {
    if (this.imageFile) {
      this._imageUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.imageFile)
      );
    } else {
      this._imageUrl = url;
    }

    this.cdr.markForCheck();
  }
}
