import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { Activity } from "src/app/models";

@Component({
  selector: "app-edit-activity",
  templateUrl: "./edit-activity.component.html",
  styleUrls: ["./edit-activity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditActivityComponent {
  @HostBinding("class") class = "app-edit-activity";

  @Input() activity: Activity;
}
