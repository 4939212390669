import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Background } from "../models";

@Injectable({ providedIn: "root" })
export class BackgroundsService {
  constructor(private http: HttpClient) {}

  uploadBackground(backgroundFile: File): Observable<Background> {
    const formData: FormData = new FormData();
    formData.append("file", backgroundFile, backgroundFile.name);

    return this.http.post<Background>("api/backgrounds", formData);
  }
}
